export default {
  path: '/admin/notifications',
  meta: { layout: 'admin' },
  component: () => import(
    /* webpackChunkName: "admin-notifications" */
    '@cms/components/notifications/Notifications.vue'),
  children: [
    {
      path: '/',
      name: 'admin.notifications.index',
      redirect: 'new'
    },
    {
      path: 'new',
      name: 'admin.notifications.new',
      component: () => import('@cms/components/notifications/CreateNotification.vue'),
      meta: {
        title: 'New Announcement'
      }
    },
    {
      path: 'list',
      name: 'admin.notifications.list',
      component: () => import('@cms/components/notifications/ListNotifications.vue'),
      meta: {
        title: 'Current Announcements'
      }
    }
  ]
}
