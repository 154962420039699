var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "module swatch-module" },
    [
      _c("module-controls", {
        attrs: {
          placeholder: _vm.moduleLabel,
          "action-buttons": _vm._actionButtons,
        },
        on: {
          "on-edit": _vm.$openModal,
          "on-remove": _vm.$deleteContentModuleConfirm,
        },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "module_content" },
        [
          _vm._isAdminArea && _vm.isEmpty
            ? [
                _c("div", { staticClass: "empty" }, [
                  _vm._v(
                    "\n        This module is empty. Start editing!\n      "
                  ),
                ]),
              ]
            : _vm._e(),
          _vm._v(" "),
          !_vm.isEmpty
            ? _c("div", { staticClass: "display-mode" }, [
                _c("div", {
                  directives: [
                    {
                      name: "b-tooltip",
                      rawName: "v-b-tooltip.hover",
                      modifiers: { hover: true },
                    },
                    {
                      name: "clipboard",
                      rawName: "v-clipboard:copy",
                      value: _vm.hex,
                      expression: "hex",
                      arg: "copy",
                    },
                    {
                      name: "clipboard",
                      rawName: "v-clipboard:success",
                      value: _vm.onCopyHex,
                      expression: "onCopyHex",
                      arg: "success",
                    },
                  ],
                  staticClass: "swatch",
                  style: {
                    "border-radius": _vm.radius + "px",
                    border: _vm.border + "px solid #e1e4e7",
                    "background-color": _vm.hex,
                  },
                  attrs: { title: _vm.copyHexMessage },
                }),
                _vm._v(" "),
                _vm.name
                  ? _c("div", { staticClass: "name" }, [
                      _vm._v("\n        " + _vm._s(_vm.name) + "\n      "),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("div", [
                  _vm.pantone
                    ? _c("div", { staticClass: "item" }, [
                        _c("span", [_vm._v("Pantone®")]),
                        _vm._v(" "),
                        _c("span", [_vm._v(_vm._s(_vm.pantone))]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.cmyk.c !== "" &&
                  _vm.cmyk.m !== "" &&
                  _vm.cmyk.y !== "" &&
                  _vm.cmyk.k !== ""
                    ? _c("div", { staticClass: "item" }, [
                        _c("span", [_vm._v("CMYK")]),
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.cmyk.c) +
                              _vm._s(_vm.cmyk_spacer) +
                              _vm._s(_vm.cmyk.m) +
                              _vm._s(_vm.cmyk_spacer) +
                              _vm._s(_vm.cmyk.y) +
                              _vm._s(_vm.cmyk_spacer) +
                              _vm._s(_vm.cmyk.k) +
                              "\n          "
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.rgba.r !== "" && _vm.rgba.g !== "" && _vm.rgba.b !== ""
                    ? _c("div", { staticClass: "item" }, [
                        _c("span", [_vm._v("RGB")]),
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.rgba.r) +
                              _vm._s(_vm.rgba_spacer) +
                              _vm._s(_vm.rgba.g) +
                              _vm._s(_vm.rgba_spacer) +
                              _vm._s(_vm.rgba.b)
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "item" }, [
                    _c("span", [_vm._v("HEX")]),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(_vm.hex))]),
                  ]),
                ]),
              ])
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }