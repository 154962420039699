var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("focus-trap", { attrs: { active: _vm.displayModal } }, [
    _c("section", { staticClass: "modal", attrs: { tabindex: "-1" } }, [
      _c("div", { staticClass: "modal__body share" }, [
        _c("div", { staticClass: "modal__controls" }, [
          _c(
            "button",
            {
              staticClass: "close",
              attrs: {
                type: "button",
                title: _vm.$t("site_actions_close_label"),
              },
              on: { click: _vm.onClose, keydown: _vm.onClose },
            },
            [_c("icon", { attrs: { id: "ic_close" } })],
            1
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "modal__contents" },
          [
            _c("header", [_c("icon", { attrs: { id: "ic_share" } })], 1),
            _vm._v(" "),
            _c("EmailModalForm", {
              attrs: {
                id: _vm.id,
                file: _vm.file,
                link: _vm.link,
                title: _vm.$t("site_actions_share_label"),
              },
              on: { "email-sent": _vm.onClose },
            }),
            _vm._v(" "),
            _c("div", { staticClass: "share-controls" }, [
              _c("h4", [_vm._v("Or copy link")]),
              _vm._v(" "),
              _c("div", { staticClass: "link-area" }, [
                _c("div", { staticClass: "form-input text-input" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.link,
                        expression: "link",
                      },
                    ],
                    attrs: { id: "share-link-input", type: "text" },
                    domProps: { value: _vm.link },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.link = $event.target.value
                      },
                    },
                  }),
                ]),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    directives: [
                      {
                        name: "clipboard",
                        rawName: "v-clipboard:copy",
                        value: _vm.link,
                        expression: "link",
                        arg: "copy",
                      },
                      {
                        name: "clipboard",
                        rawName: "v-clipboard:success",
                        value: _vm.onCopy,
                        expression: "onCopy",
                        arg: "success",
                      },
                      {
                        name: "clipboard",
                        rawName: "v-clipboard:error",
                        value: _vm.onFail,
                        expression: "onFail",
                        arg: "error",
                      },
                    ],
                    staticClass: "btn secondary",
                    attrs: {
                      id: "direct-link-copy",
                      title: _vm.$t("site_actions_copy_direct-link_label"),
                    },
                  },
                  [_vm._v("\n              Copy\n            ")]
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _c("small", [
                  _vm._v(
                    "Only people with access to " +
                      _vm._s(_vm.$t("site_messaging_site-name_label")) +
                      " can open this link"
                  ),
                ]),
              ]),
            ]),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }