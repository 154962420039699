var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "form-input search-select-input",
      class: { "has-error": _vm.error },
    },
    [
      _c("label", {
        directives: [
          {
            name: "sanitize",
            rawName: "v-sanitize",
            value: _vm.fieldLabel,
            expression: "fieldLabel",
          },
        ],
        class: { active: _vm.isActive },
        attrs: { for: _vm.id },
      }),
      _vm._v(" "),
      _c("ModelSelect", {
        attrs: {
          id: _vm.id,
          options: _vm.list,
          placeholder: `${_vm.label} ${_vm.required ? "*" : ""}`,
        },
        model: {
          value: _vm.input,
          callback: function ($$v) {
            _vm.input = $$v
          },
          expression: "input",
        },
      }),
      _vm._v(" "),
      _vm.required && _vm.error
        ? _c("div", { staticClass: "error" }, [
            _vm._v("\n    " + _vm._s(_vm.errorText) + "\n  "),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }