import ClientTheme from '@client/theme'
const theme = ClientTheme?.theme ?? 'legacy'
const authPages = window.AppStore?.getters['Settings/beamFeatures']?.authPages
const hasAuth = !!('enabled' === authPages || 'published' === authPages)

const meta = { layout: 'auth' }

let routes = [
  {
    path: '/contact',
    name: 'auth.contact',
    meta,
    component: () => import(
      /* webpackChunkName: "auth-contact" */
      '@core/components/auth/contact.vue')
  },
  {
    path: '/forgot',
    name: 'auth.forgot',
    meta,
    component: () => import(
      /* webpackChunkName: "auth-forgot" */
      '@core/components/auth/forgot.vue')
  },
  {
    path: '/login',
    name: 'auth.login',
    meta,
    component: () => import(
      /* webpackChunkName: "auth-login" */
      '@core/components/auth/login.vue')
  },
  {
    path: '/logout',
    name: 'auth.logout',
    meta,
    component: () => import(
      /* webpackChunkName: "auth-logout" */
      '@core/components/auth/logout.vue')
  },
  {
    path: '/request-access',
    name: 'auth.request-access',
    meta,
    component: () => import(
      /* webpackChunkName: "auth-request-access" */
      '@core/components/auth/request-access.vue')
  },
  {
    path: '/reset/:verification_code',
    name: 'auth.reset',
    meta,
    component: () => import(
      /* webpackChunkName: "auth-reset" */
      '@core/components/auth/reset.vue')
  },
  {
    path: '/reset/:verification_code/new',
    name: 'auth.reset.new',
    meta,
    component: () => import(
      /* webpackChunkName: "auth-reset" */
      '@core/components/auth/reset.vue')
  },
  {
    path: '/sso',
    name: 'auth.sso',
    meta,
    component: () => import(
      /* webpackChunkName: "auth-sso" */
      '@core/components/auth/sso.vue')
  }
]

if (theme !== 'legacy' && hasAuth) {
  routes.forEach(route => {
    if (route.name === 'auth.sso') { return }

    route.component = () => import(
      /* webpackChunkName: "auth-routes" */
      '@theme/layouts/MainContent.vue'
    )
  })
}

export default routes
