import _ from 'underscore'
import ClientConfig from '@client/config'
import { CookieManager, HttpClient } from '@core/js'
import moment from 'moment/moment'

global.loginPath = ClientConfig.environment[process.env.NODE_ENV].login || '/login'
global.ssoPath = ClientConfig.environment[process.env.NODE_ENV].ssoLogin[0] || '/login'
global.logoutPath = ClientConfig.environment[process.env.NODE_ENV].logout || '/login'

const Globals = {
  getCookie (cname) {
    return CookieManager.getItem(cname)
  },
  setCookie (name, value, ttl) {
    CookieManager.setItem(
      name,
      value,
      ttl,
      '/',
      window.location.hostname,
      window.location.protocol === 'https:'
    )
  },
  unsetCookie (name) {
    CookieManager.removeItem(name, '/', window.location.hostname)
  },
  init (env = 'core') {
    window._ = _
    window.axios = HttpClient
    window.moment = moment
    window.CookieManager = {
      setCookie: this.setCookie,
      getCookie: this.getCookie,
      unsetCookie: this.unsetCookie
    }
    window.beam = {
      apiBaseUrl: process.env.API_URL || 'https://api.beamapi.localdev:82/v1/',
      area: env,
      env: process.env.NODE_ENV
    }
  },
  /**
   * Bypass Login for SSO
   * @description if client wants to bypass the login page for SSO,
   * this will force a redirect to the config SSO login.
   *
   */
  checkSsoBypass () {
    const authToken = CookieManager.getItem('jwt')
    const hasToken = authToken && authToken !== 'false'
    const hasBypass = ClientConfig.environment[process.env.NODE_ENV].login_bypass === true

    if (!hasToken && hasBypass) {
      // exclusions provides a "back door" for the vendor login
      const exclusions = [
        'boasignage.beam3.monigle.net',
        'boasignage-2.beam3.monigle.net'
      ]

      if (!exclusions.includes(window.location.host)) {
        if (window.location.hostname === 'signage.bankofamerica.com' && window.location.pathname !== '/sso') {
          const redirect = ClientConfig.environment[process.env.NODE_ENV].ssoLogin || '/login'
          window.location = redirect
        }
      }
    }
  }
}

export { Globals }
