import actions from '../actions/actions'
import mutations from '../mutations/mutations'

export default {
  namespaced: true,
  state: {
    users: [],
    currentUser: false,
    filterParameters: {
      status: null,
      entity: null,
      group: null,
      role: null,
      dateBefore: null,
      dateAfter: null,
      order: null
    },
    searchParameters: {
      term: null
    },
    pagination: {
      per_page: 12,
      page: 1,
      pages: 1,
      items: 0
    },
    stats: {
      active: 0,
      deleted: 0,
      deniedAccessRequests: 0,
      inactive: 0,
      neverLoggedIn: 0,
      pendingAccessRequests: 0
    },
    isLoading: false,
    newElementCreated: false,
    view: 'list',
    filterModalOpen: false,
    singleUser: {},
    singleUserActive: false
  },
  mutations,
  actions,
  getters: {
    currentUser: state => {
      return state.currentUser
    }
  }
}
