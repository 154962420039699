var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "focus-trap",
    {
      model: {
        value: _vm.displayModal,
        callback: function ($$v) {
          _vm.displayModal = $$v
        },
        expression: "displayModal",
      },
    },
    [
      _c("section", { staticClass: "modal", attrs: { tabindex: "-1" } }, [
        _c(
          "div",
          {
            class: [
              "modal__body",
              "video",
              {
                embed:
                  _vm.ctaContentType === "embeded" &&
                  _vm.videoSource === "embed_code",
              },
            ],
          },
          [
            _c("div", { staticClass: "modal__controls" }, [
              _c(
                "button",
                {
                  staticClass: "close",
                  attrs: {
                    type: "button",
                    title: _vm.$t("site_actions_close_label"),
                  },
                  on: { click: _vm.onClose, keydown: _vm.onClose },
                },
                [_c("icon", { attrs: { id: "ic_close" } })],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "modal__contents" }, [
              _c("div", { staticClass: "_container" }, [
                _vm.ctaContentType === "uploaded"
                  ? _c("div", { staticClass: "uploaded" }, [
                      _c("video", {
                        ref: "video",
                        attrs: {
                          src: _vm.ctaContentVideoUrl,
                          type: "video/mp4",
                          controls: _vm.ctaContentVideoControls,
                          autoplay: _vm.ctaContentVideoAutoplay,
                          loop: _vm.ctaContentVideoLoop,
                          alt: _vm.ctaContentVideoCaption,
                        },
                        domProps: { muted: _vm.ctaContentVideoMute },
                        on: {
                          keydown: function ($event) {
                            !_vm._isAdminArea ? _vm.$toggleVideoPlay : null
                          },
                        },
                      }),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.ctaContentType === "embeded" &&
                _vm.videoSource === "embed_code"
                  ? _c("div", {
                      staticClass: "embedded",
                      domProps: {
                        innerHTML: _vm._s(_vm.ctaContentVideoEmbedCode),
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.ctaContentType === "embeded" &&
                _vm.videoSource === "youtube"
                  ? _c("div", { class: ["youtube", _vm.videoAspect] }, [
                      _c("iframe", {
                        staticClass: "iframe_youtube",
                        attrs: {
                          src: _vm.compiledEmbedURLyoutube,
                          frameborder: "0",
                          allow:
                            "accelerometer; encrypted-media; gyroscope; picture-in-picture",
                        },
                      }),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.ctaContentType === "embeded" &&
                _vm.videoSource === "brightcove"
                  ? _c("div", { class: ["brightcove", _vm.videoAspect] }, [
                      _c("iframe", {
                        staticClass: "iframe_brightcove",
                        attrs: {
                          src: _vm.compiledEmbedURLbrightcove,
                          allowfullscreen: "",
                          webkitallowfullscreen: "",
                          mozallowfullscreen: "",
                        },
                      }),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.ctaContentType === "embeded" && _vm.videoSource === "vimeo"
                  ? _c("div", { class: ["vimeo", _vm.videoAspect] }, [
                      _c("iframe", {
                        staticClass: "iframe_vimeo",
                        attrs: {
                          src: _vm.compiledEmbedURLvimeo,
                          allowfullscreen: "",
                          webkitallowfullscreen: "",
                          mozallowfullscreen: "",
                        },
                      }),
                    ])
                  : _vm._e(),
              ]),
            ]),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }