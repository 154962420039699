var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: [
        "vc-photoshop",
        _vm.disableFields ? "vc-photoshop__disable-fields" : "",
      ],
      attrs: { role: "application", "aria-label": "PhotoShop color picker" },
    },
    [
      _c("div", { staticClass: "vc-ps-head", attrs: { role: "heading" } }, [
        _vm._v(_vm._s(_vm.head)),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "vc-ps-body" }, [
        _c(
          "div",
          { staticClass: "vc-ps-saturation-wrap" },
          [
            _c("saturation", {
              on: { change: _vm.childChange },
              model: {
                value: _vm.colors,
                callback: function ($$v) {
                  _vm.colors = $$v
                },
                expression: "colors",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "vc-ps-hue-wrap" },
          [
            _c(
              "hue",
              {
                attrs: { direction: "vertical" },
                on: { change: _vm.childChange },
                model: {
                  value: _vm.colors,
                  callback: function ($$v) {
                    _vm.colors = $$v
                  },
                  expression: "colors",
                },
              },
              [
                _c("div", { staticClass: "vc-ps-hue-pointer" }, [
                  _c("i", { staticClass: "vc-ps-hue-pointer--left" }),
                  _c("i", { staticClass: "vc-ps-hue-pointer--right" }),
                ]),
              ]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            class: [
              "vc-ps-controls",
              _vm.disableFields ? "vc-ps-controls__disable-fields" : "",
            ],
          },
          [
            _c("div", { staticClass: "vc-ps-previews" }, [
              _c("div", { staticClass: "vc-ps-previews__label" }, [
                _vm._v(_vm._s(_vm.newLabel)),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "vc-ps-previews__swatches" }, [
                _c("div", {
                  staticClass: "vc-ps-previews__pr-color",
                  style: { background: _vm.colors.hex },
                  attrs: { "aria-label": `New color is ${_vm.colors.hex}` },
                }),
                _vm._v(" "),
                _c("div", {
                  staticClass: "vc-ps-previews__pr-color",
                  style: { background: _vm.currentColor },
                  attrs: {
                    "aria-label": `Current color is ${_vm.currentColor}`,
                  },
                  on: { click: _vm.clickCurrentColor },
                }),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "vc-ps-previews__label" }, [
                _vm._v(_vm._s(_vm.currentLabel)),
              ]),
            ]),
            _vm._v(" "),
            !_vm.disableFields
              ? _c("div", { staticClass: "vc-ps-actions" }, [
                  _c(
                    "div",
                    {
                      staticClass: "vc-ps-ac-btn",
                      attrs: { role: "button", "aria-label": _vm.acceptLabel },
                      on: { click: _vm.handleAccept },
                    },
                    [_vm._v(_vm._s(_vm.acceptLabel))]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "vc-ps-ac-btn",
                      attrs: { role: "button", "aria-label": _vm.cancelLabel },
                      on: { click: _vm.handleCancel },
                    },
                    [_vm._v(_vm._s(_vm.cancelLabel))]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "vc-ps-fields" },
                    [
                      _c("ed-in", {
                        attrs: { label: "h", desc: "°", value: _vm.hsv.h },
                        on: { change: _vm.inputChange },
                      }),
                      _vm._v(" "),
                      _c("ed-in", {
                        attrs: {
                          label: "s",
                          desc: "%",
                          value: _vm.hsv.s,
                          max: 100,
                        },
                        on: { change: _vm.inputChange },
                      }),
                      _vm._v(" "),
                      _c("ed-in", {
                        attrs: {
                          label: "v",
                          desc: "%",
                          value: _vm.hsv.v,
                          max: 100,
                        },
                        on: { change: _vm.inputChange },
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "vc-ps-fields__divider" }),
                      _vm._v(" "),
                      _c("ed-in", {
                        attrs: { label: "r", value: _vm.colors.rgba.r },
                        on: { change: _vm.inputChange },
                      }),
                      _vm._v(" "),
                      _c("ed-in", {
                        attrs: { label: "g", value: _vm.colors.rgba.g },
                        on: { change: _vm.inputChange },
                      }),
                      _vm._v(" "),
                      _c("ed-in", {
                        attrs: { label: "b", value: _vm.colors.rgba.b },
                        on: { change: _vm.inputChange },
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "vc-ps-fields__divider" }),
                      _vm._v(" "),
                      _c("ed-in", {
                        staticClass: "vc-ps-fields__hex",
                        attrs: { label: "#", value: _vm.hex },
                        on: { change: _vm.inputChange },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.hasResetButton
                    ? _c(
                        "div",
                        {
                          staticClass: "vc-ps-ac-btn",
                          attrs: { "aria-label": "reset" },
                          on: { click: _vm.handleReset },
                        },
                        [_vm._v(_vm._s(_vm.resetLabel))]
                      )
                    : _vm._e(),
                ])
              : _vm._e(),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }