var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "nav-search form-item" }, [
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.searchValue,
          expression: "searchValue",
        },
      ],
      ref: "input",
      class: { active: _vm.searchActive },
      attrs: {
        id: "nav-search-field",
        type: "search",
        role: "search",
        "aria-label": _vm.$t("layout_regions_primary-nav-search_label"),
        placeholder: _vm.$t("forms_search_field_placeholder"),
      },
      domProps: { value: _vm.searchValue },
      on: {
        keydown: [
          function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            )
              return null
            return _vm.handleSubmit.apply(null, arguments)
          },
          function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
            )
              return null
            return _vm.toggleSearch.apply(null, arguments)
          },
        ],
        input: function ($event) {
          if ($event.target.composing) return
          _vm.searchValue = $event.target.value
        },
      },
    }),
    _vm._v(" "),
    _c(
      "label",
      {
        attrs: {
          for: "nav-search-field",
          tabindex: "0",
          role: "button",
          "aria-label": _vm.$t("forms_search_field_label"),
          placeholder: _vm.$t("forms_search_field_placeholder"),
        },
        on: {
          click: function ($event) {
            if (
              $event.ctrlKey ||
              $event.shiftKey ||
              $event.altKey ||
              $event.metaKey
            )
              return null
            return _vm.toggleSearch.apply(null, arguments)
          },
          keydown: [
            function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              )
                return null
              return _vm.toggleSearch.apply(null, arguments)
            },
            function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "space", 32, $event.key, [
                  " ",
                  "Spacebar",
                ])
              )
                return null
              $event.preventDefault()
              return _vm.toggleSearch.apply(null, arguments)
            },
          ],
        },
      },
      [
        _vm.$t("forms_search_field_label")
          ? _c(
              "span",
              {
                class: { active: _vm.searchActive },
                attrs: { id: "labelText" },
              },
              [_vm._v(_vm._s(_vm.$t("forms_search_field_label")))]
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "span",
          { class: { active: _vm.searchActive }, attrs: { id: "searchIcon" } },
          [
            _c(
              "transition",
              { attrs: { name: "fade", mode: "out-in" } },
              [
                !_vm.searchActive
                  ? _c("Icon", { attrs: { id: "ic_search" } })
                  : _c("Icon", {
                      attrs: { id: "ic_close" },
                      on: { click: _vm.clearValue },
                    }),
              ],
              1
            ),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }