var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        role: "presentation",
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 16 16",
        "enable-background": "new 0 0 16 16",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M8 0c-4.4 0-8 3.6-8 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm0 15c-3.9 0-7-3.1-7-7s3.1-7 7-7 7 3.1 7 7-3.1 7-7 7zm4-5.4c.1.1.1.3 0 .4 0 0-.1.1-.2.1h-7.6c-.1-.1-.2-.2-.2-.3 0-.1 0-.1.1-.2l3.8-4.5c0-.1.2-.1.3-.1l3.8 4.6z",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }