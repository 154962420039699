var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        role: "presentation",
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 18 18",
        "enable-background": "new 0 0 18 18",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M1.8 3.6H0v12.6c0 .99.81 1.8 1.8 1.8h12.6v-1.8H1.8V3.6zM16.2 0H5.4c-.99 0-1.8.81-1.8 1.8v10.8c0 .99.81 1.8 1.8 1.8h10.8c.99 0 1.8-.81 1.8-1.8V1.8c0-.99-.81-1.8-1.8-1.8zm-.9 8.1h-3.6v3.6H9.9V8.1H6.3V6.3h3.6V2.7h1.8v3.6h3.6v1.8z",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }