export default [
  {
    path: '/401',
    name: 'unauthorized',
    component: () => import(
      /* webpackChunkName: "main-unauthorized" */
      '@core/components/Unauthorized.vue')
  },
  {
    path: '/404',
    name: 'notfound',
    component: () => import(
      /* webpackChunkName: "main-notfound" */
      '@core/components/NotFound.vue')
  }
]
