var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "module asset-module" },
    [
      _c("module-controls", {
        attrs: {
          placeholder: _vm.placeholder,
          "action-buttons": _vm._actionButtons,
        },
        on: { "on-edit": _vm.onEdit, "on-remove": _vm.onRemove },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "module_content" },
        [
          _vm._isAdminArea
            ? _c("div", { staticClass: "container display-mode" }, [
                _vm.asset
                  ? _c("div", { staticClass: "asset-container" }, [
                      _vm.file
                        ? _c(
                            "div",
                            [
                              _c("AssetImage", {
                                attrs: {
                                  "module-data": _vm.moduleData.displayOptions,
                                  asset: _vm.file,
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ])
                  : _c("p", { staticClass: "display-text" }, [
                      _c("strong", [_vm._v(_vm._s(_vm.libraryName))]),
                    ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          !_vm._isAdminArea && _vm.contentAssets.length
            ? _c("AssetImage", {
                attrs: {
                  "module-data": _vm.moduleData.displayOptions,
                  asset: _vm.file,
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }