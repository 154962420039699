var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "data-table-display" }, [
    _c("div", { staticClass: "search-filters" }, [
      _c("div", { attrs: { id: "search-form" } }, [
        _c("div", { staticClass: "search-term" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.searchTerm,
                expression: "searchTerm",
              },
            ],
            ref: "input",
            attrs: {
              id: `search-term_${_vm.tableId}`,
              type: "search",
              name: "search",
              autocomplete: "disabled",
              placeholder: _vm.$t("forms_search_field_placeholder"),
              "aria-label": _vm.$t("forms_search_field_label"),
              "aria-required": "true",
            },
            domProps: { value: _vm.searchTerm },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.searchTerm = $event.target.value
              },
            },
          }),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "control-buttons" }, [
        !_vm.displayFilters
          ? _c(
              "button",
              {
                staticClass: "cta-button primary",
                on: { click: _vm.openFilters },
              },
              [_vm._v("\n        Open Filters\n      ")]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.displayFilters
          ? _c(
              "button",
              {
                staticClass: "cta-button primary",
                on: { click: _vm.openFilters },
              },
              [_vm._v("\n        Close Filters\n      ")]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.allowExport
          ? _c(
              "button",
              {
                staticClass: "cta-button secondary",
                on: { click: _vm.exportCSV },
              },
              [_vm._v("\n        Export Data\n      ")]
            )
          : _vm._e(),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "sort-controls" }, [
      _c(
        "div",
        { staticClass: "order-controls" },
        [
          _vm._l(_vm.sortOptions, function (option) {
            return _c(
              "button",
              {
                key: option,
                class: { selected: _vm.selectedSortOption === option },
                on: {
                  click: function ($event) {
                    return _vm.sortByOption(option)
                  },
                },
              },
              [_vm._v("\n        " + _vm._s(option) + "\n      ")]
            )
          }),
          _vm._v(" "),
          _vm.filterOptions.length > 0
            ? _c("div", [
                _c("span", { staticClass: "filter-count" }, [
                  _vm._v(_vm._s(_vm.filterOptions.length)),
                ]),
                _vm._v("Filters Selected\n      "),
              ])
            : _vm._e(),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "page-results" },
        [
          _c("div", { staticClass: "results" }, [
            _vm._v(
              "\n        Displaying " +
                _vm._s(_vm.startRowIndex) +
                "-" +
                _vm._s(_vm.endRowIndex) +
                " of\n        " +
                _vm._s(_vm.sortedAndFilteredRows.length) +
                " results\n      "
            ),
          ]),
          _vm._v(" "),
          _vm._l(_vm.perPageOptions, function (option) {
            return _c(
              "button",
              {
                key: option,
                class: { selected: _vm.selectedPerPageOption === option },
                on: {
                  click: function ($event) {
                    _vm.selectedPerPageOption = option
                  },
                },
              },
              [_vm._v("\n        " + _vm._s(option) + "\n      ")]
            )
          }),
        ],
        2
      ),
    ]),
    _vm._v(" "),
    _c("hr"),
    _vm._v(" "),
    _c("div", { staticClass: "filter-controls" }, [
      _vm.displayFilters
        ? _c(
            "div",
            { staticClass: "filter-section" },
            _vm._l(_vm.filterColumns, function (column) {
              return _c(
                "div",
                {
                  key: `${column.id}-filter`,
                  staticClass: "filter-categories",
                },
                [
                  _c("h4", [_vm._v(_vm._s(column.name))]),
                  _vm._v(" "),
                  _vm._l(column.data_table_column_options, function (option) {
                    return _c(
                      "div",
                      {
                        key: `${option.id}-filter-option`,
                        staticClass: "filter-option",
                      },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.filterOptions,
                              expression: "filterOptions",
                            },
                          ],
                          attrs: { type: "checkbox" },
                          domProps: {
                            value: option.value,
                            checked: Array.isArray(_vm.filterOptions)
                              ? _vm._i(_vm.filterOptions, option.value) > -1
                              : _vm.filterOptions,
                          },
                          on: {
                            change: function ($event) {
                              var $$a = _vm.filterOptions,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = option.value,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    (_vm.filterOptions = $$a.concat([$$v]))
                                } else {
                                  $$i > -1 &&
                                    (_vm.filterOptions = $$a
                                      .slice(0, $$i)
                                      .concat($$a.slice($$i + 1)))
                                }
                              } else {
                                _vm.filterOptions = $$c
                              }
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("label", [_vm._v(_vm._s(option.value))]),
                      ]
                    )
                  }),
                ],
                2
              )
            }),
            0
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.displayFilters && _vm.filterColumns.length > 0
        ? _c(
            "a",
            {
              staticClass: "clear-filters",
              on: { click: _vm.clearFilterOptions },
            },
            [_vm._v("Clear filters")]
          )
        : _vm._e(),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "data-table-list" }, [
      _c("div", { staticClass: "registries-table" }, [
        _c("table", { staticClass: "data-table" }, [
          _c("thead", [
            _c(
              "tr",
              _vm._l(_vm.activeColumns, function (column) {
                return _c(
                  "th",
                  {
                    key: column.id,
                    style: {
                      width: column.width + "px",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.sortData(column)
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n              " +
                        _vm._s(column.name) +
                        "\n              "
                    ),
                    _vm.selectedSortOption === column.id
                      ? _c("span", [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.sortDirection === "asc" ? "▲" : "▼") +
                              "\n              "
                          ),
                        ])
                      : _vm._e(),
                  ]
                )
              }),
              0
            ),
          ]),
          _vm._v(" "),
          _c(
            "tbody",
            _vm._l(_vm.paginatedData, function (row) {
              return _c("DataRow", {
                key: row.id,
                attrs: { row: row, "active-columns": _vm.activeColumns },
              })
            }),
            1
          ),
        ]),
        _vm._v(" "),
        !_vm._isAdminArea
          ? _c(
              "div",
              { staticClass: "pagination" },
              [
                _c(
                  "button",
                  {
                    attrs: { disabled: _vm.currentPage === 1 },
                    on: { click: _vm.prevPage },
                  },
                  [_c("icon", { attrs: { id: "ic_chevron_left" } })],
                  1
                ),
                _vm._v(" "),
                _vm._l(_vm.visiblePages, function (page, index) {
                  return _c(
                    "span",
                    {
                      key: index,
                      class: { active: _vm.currentPage === page },
                      on: {
                        click: function ($event) {
                          return _vm.goToPage(page)
                        },
                      },
                    },
                    [_vm._v(_vm._s(page))]
                  )
                }),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    attrs: { disabled: _vm.currentPage === _vm.totalPages },
                    on: { click: _vm.nextPage },
                  },
                  [_c("icon", { attrs: { id: "ic_chevron_right" } })],
                  1
                ),
              ],
              2
            )
          : _vm._e(),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }