import actions from '@core/store/actions/actions'
import mutations from '@core/store/mutations/mutations'

export default {
  namespaced: true,
  state: {
    helpdesk_statuses: [],
    config: {},
    isLoading: false
  },
  mutations,
  actions
}
