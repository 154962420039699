var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        role: "presentation",
        xmlns: "http://www.w3.org/2000/svg",
        width: "16",
        height: "16",
        viewBox: "0 0 16 16",
      },
    },
    [
      _vm.title
        ? _c("title", { domProps: { textContent: _vm._s(_vm.title) } })
        : _vm._e(),
      _vm._v(" "),
      _c("g", { attrs: { fill: "#4f758b" } }, [
        _c("path", {
          attrs: {
            fill: "#4f758b",
            d: "M12.7,11.7l-1.4-1.4L13.6,8l-2.3-2.3l1.4-1.4l3,3c0.4,0.4,0.4,1,0,1.4L12.7,11.7z",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            fill: "#4f758b",
            d: "M3.3,11.7l-3-3c-0.4-0.4-0.4-1,0-1.4l3-3l1.4,1.4L2.4,8l2.3,2.3L3.3,11.7z",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d: "M6,15c-0.1,0-0.2,0-0.3-0.1c-0.5-0.2-0.8-0.7-0.6-1.3l4-12c0.2-0.5,0.7-0.8,1.3-0.6 c0.5,0.2,0.8,0.7,0.6,1.3l-4,12C6.8,14.7,6.4,15,6,15z",
          },
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }