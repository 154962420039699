import actions from '../actions/actions'
import mutations from '../mutations/mutations'

export default {
  namespaced: true,
  state: {
    pagination: {
      per_page: 12,
      page: 1,
      pages: 1,
      items: 0
    },
    filterParameters: null,
    searchParameters: null,
    bookmarks: [],
    isLoading: false,
    newElementCreated: false,
    view: 'list'
  },
  mutations,
  actions
}
