export default [
  {
    name: 'row_100',
    type: 'row',
    label: 'Full Width',
    numCols: 1,
    cols: [],
    data: {},
    svg: 'row_100'
  },
  {
    name: 'row_50_50',
    type: 'row',
    label: 'Halves',
    numCols: 2,
    cols: [],
    data: {},
    svg: 'row_50_50'
  },
  {
    name: 'row_33_33_33',
    type: 'row',
    label: 'Thirds',
    numCols: 3,
    cols: [],
    data: {},
    svg: 'row_33_33_33'
  },
  {
    name: 'row_25_25_25_25',
    type: 'row',
    label: 'Fourths',
    numCols: 4,
    cols: [],
    data: {},
    svg: 'row_25_25_25_25'
  },
  {
    name: 'row_16_16_16_16_16_16',
    type: 'row',
    label: 'Sixths',
    numCols: 6,
    cols: [],
    data: {},
    svg: 'row_16_16_16_16_16_16'
  },
  {
    name: 'row_33_66',
    type: 'row',
    label: '1/3 x 2/3',
    numCols: 2,
    cols: [],
    data: {},
    svg: 'row_33_66'
  },
  {
    name: 'row_66_33',
    type: 'row',
    label: '2/3 x 1/3',
    numCols: 2,
    cols: [],
    data: {},
    svg: 'row_66_33'
  },
  {
    name: 'row_25_75',
    type: 'row',
    label: '1/4 x 3/4',
    numCols: 2,
    cols: [],
    data: {},
    svg: 'row_25_75'
  },
  {
    name: 'row_75_25',
    type: 'row',
    label: '3/4 x 1/4',
    numCols: 2,
    cols: [],
    data: {},
    svg: 'row_75_25'
  },
  {
    name: 'row_25_25_50',
    type: 'row',
    label: '1/4 x 1/4 x 1/2',
    numCols: 3,
    cols: [],
    data: {},
    svg: 'row_25_25_50'
  },
  {
    name: 'row_25_50_25',
    type: 'row',
    label: '1/4 x 1/2 x 1/4',
    numCols: 3,
    cols: [],
    data: {},
    svg: 'row_25_50_25'
  },
  {
    name: 'row_50_25_25',
    type: 'row',
    label: '1/2 x 1/4 x 1/4',
    numCols: 3,
    cols: [],
    data: {},
    svg: 'row_50_25_25'
  },
  {
    name: 'mosaic_v1',
    type: 'row',
    label: 'Mosaic Option 1',
    numCols: 6,
    cols: [],
    svg: 'mosaic_v1',
    root_only: true
  }
]
