var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      staticStyle: { "enable-background": "new 0 0 22 18.3" },
      attrs: {
        role: "presentation",
        version: "1.1",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
        x: "0px",
        y: "0px",
        viewBox: "0 0 22 18.3",
        "xml:space": "preserve",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M0,4.8c0-0.2,0.1-0.4,0.3-0.4l9.6-4.1C10.2,0.1,10.6,0,11,0c0.4,0,0.8,0.1,1.1,0.2l9.6,4.1C21.9,4.4,22,4.6,22,4.8\n  s-0.1,0.4-0.3,0.4l-9.6,4.1c-0.3,0.1-0.7,0.2-1.1,0.2s-0.8-0.1-1.1-0.2L0.3,5.2C0.1,5.1,0,5,0,4.8z M21.7,13l-3-1.3l-5.9,2.6\n  c-0.5,0.2-1.2,0.4-1.8,0.4s-1.2-0.1-1.8-0.4l-5.9-2.6l-3,1.3C0.1,13.1,0,13.3,0,13.5c0,0.2,0.1,0.4,0.3,0.4l9.6,4.2\n  c0.3,0.2,0.7,0.2,1.1,0.2s0.8-0.1,1.1-0.2l9.6-4.2c0.2-0.1,0.3-0.3,0.3-0.4C22,13.3,21.9,13.1,21.7,13z M21.7,8.6l-3-1.3l-5.9,2.6\n  c-0.5,0.2-1.2,0.4-1.8,0.4s-1.2-0.1-1.8-0.4L3.3,7.3l-3,1.3C0.1,8.7,0,8.9,0,9.1c0,0.2,0.1,0.4,0.3,0.4l9.6,4.2\n  c0.3,0.2,0.7,0.2,1.1,0.2s0.8-0.1,1.1-0.2l9.6-4.2C21.9,9.5,22,9.3,22,9.1C22,8.9,21.9,8.7,21.7,8.6z",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }