export default [
  {
    name: 'divider',
    label: 'Divider',
    data: {
      color: '#000000',
      thickness: 1,
      marginTop: 10,
      marginBottom: 10
    }
  },
  {
    name: 'headline',
    label: 'Headline',
    data: {
      levels: 4,
      selectedLevel: 1,
      text: ''
    }
  },
  {
    name: 'wysiwyg',
    label: 'WYSIWYG Editor',
    data: {
      text: ''
    }
  },
  {
    name: 'imageembed',
    label: 'Image',
    data: {
      url: '',
      title: ''
    }
  },
  {
    name: 'cta',
    label: 'Call To Action',
    data: {
      text: '',
      url: '',
      newTab: false
    }
  },
  {
    name: 'spacer',
    label: 'Spacer',
    data: {
      height: '20'
    }
  }
]
