import _ from 'underscore'
import sanitizeHtml from 'sanitize-html'
import { GetSanitizeConfig } from '@core/js'

const sanitizeConfig = GetSanitizeConfig();

export default {
    props: {
        label: {
            type: String,
            default: ''
        },
        name: {
            type: String,
            default: ''
        },
        disabled: {
            type: [String, Boolean],
            default: false
        },
        descby: {
            type: [Boolean, String],
            default: false
        },
        model: [String, Number, Object, Boolean],
        required: {
            type: [String, Boolean],
            default: false
        },
        validate: [String, Boolean],
        errorMessage: String,
        match: String,
        placeholder: String,
        maxlength: [String, Number],
        hideAsterisk: Boolean,
        ariaLabel: String,
        helpText: String
    },
    data() {
        return {
            error: false,
            errorMsg: 'This field is required',
            errorText: 'This field is required',
            id: null,
            isActive: false,
            modelOnCreated: '',
            instance: null
        }
    },
    computed: {
        parentFormId() {
            return this.$parent.formId
        },
        input: {
            get(e) {
                this.toggleLabel(e)
                return this.model
            },
            set(value) {
                const isCMS = !!(window.AppStore.state.Global.cms);

                if (typeof value === 'string' && !isCMS) {
                    // only sanitize strings
                    value = sanitizeHtml(value, sanitizeConfig);
                }

                if (this.modelOnCreated !== value) {
                    // Plugins
                    this.$formUpdated(this.parentFormId, true)
                }

                if (this.required === 'true' || this.required === true) {
                    this.ifRequired(value)
                }

                if (this.validate && !!value) {
                    this.validateInput(value)
                }

                this.$emit(
                    'update:model',
                    this.validate === 'phone' ? this.formatPhone(value) : value
                )
            }
        },
        getValidate() {
            return this.validate.toLowerCase()
        },
        fieldLabel() {
            return this.required && !this.hideAsterisk
                ? this.label + ' <span class="required">*</span>'
                : this.label
        },
        description() {
            if (this.error) {
                return `error_${this.id}`
            }

            if (this.descby) {
                return `error_${this.id} ` + this.descby
            }

            return null
        }
    },
    methods: {
        debounceInput: _.debounce(function (value) {
            this.validateInput(value)
        }, 10),
        ifRequired(value) {
            if (typeof value === 'string') {
                value = value
                    .replace(/^\s+|\s+$/g, '') // trim
                    .replace(/[^a-zA-Z0-9 -_]/g, '') // remove invalid chars
            }
            this.error = (value === undefined || value === null || value === '')
        },
        validateInput(value) {
            if (this.getValidate === 'password') {
                // method from password input components
                this.fieldValidation(value)
            }

            if (this.getValidate === 'checkbox') {
                this.error = !(value)
            }

            if (this.getValidate === 'email') {
                this.error = !this.$validationTests.isEmail(value)
            }

            if (this.getValidate === 'phone') {
                this.error = !this.$validationTests.isPhone(value)
            }

            if (this.getValidate === 'number') {
                this.error = !!isNaN(value)
            }

            if (this.getValidate === 'uploadedFiles') {
                this.error = value === "" || value === null
            }

            if (this.getValidate === 'match') {
                this.error = !(value === this.match || (!value && !this.match))
            }

            this.$emit('inputError', this.error)
        },
        onValidate() {
            // This is a hook to trigger required / validate from
            // the parent form component upon submit

            if (this.required === 'true' || this.required === true) {
                this.ifRequired(this.model)
            }

            if (this.validate && !!this.model) {
                this.validateInput(this.model)
            }

            if (typeof this.$parent.updateErrors === 'function') {
                this.$parent.updateErrors(this.id, this.error)
            }

            if (this.error) {
                this.$emit('inputError', this.error)
            }
        },
        toggleLabel(e) {
            this.isActive = e.type === 'focus' || this.model || this.model === 0 || (!e.type && this.model === '') || !!this.placeholder
            this.$emit('emitFocus')
        },
        formatPhone(v) {
            const s2 = `${v}`.replace(/\D/g, '')
            const m = s2.match(/^(\d{3})(\d{3})(\d{4})$/)
            return !m ? v : `(${m[1]}) ${m[2]}-${m[3]}`
        },
        focusout() {
            this.$emit('emitFocusout')
        },
        keyup() {
            this.$emit('keyup')
        }
    },
    created() {
        // randomID() @ /js/plugins/index.js
        this.id = this.$utilities.randomID()
        this.modelOnCreated = this.model
        this.errorText = this.errorMessage ? this.errorMessage : this.errorMsg
    }
}
