import actions from '../actions/actions'
import mutations from '../mutations/mutations'

export default {
  namespaced: true,
  state: {
    range: {},
    filters: {},
    search_term: '',
    snapshot: {},
    users: {},
    user: {},
    library: {},
    asset: {},
    search: {},
    assets: {},
    pages: {},
    page: {},
    help_desk: {},
    roi: {},

    showcase: {},
    topShowcaseSubmissions: {},
    topPPTRequests: {},
    filterModalOpen: false,
    optionsActive: null,
    ppt_requests: {}
  },
  mutations,
  actions
}
