var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "dl",
    { staticClass: "accordion" },
    [
      _vm._l(_vm.elements, function (element, index) {
        return [
          _c(
            "dt",
            {
              key: index,
              class: { open: _vm.isOpenRow(element.id) },
              attrs: {
                role: "button",
                tabindex: "0",
                "aria-pressed": !!_vm.isOpenRow(element.id),
              },
              on: {
                keydown: function ($event) {
                  return _vm.toggleRow(element.id, $event)
                },
                click: function ($event) {
                  return _vm.toggleRow(element.id)
                },
              },
            },
            [
              _c("div", { staticClass: "wrapper" }, [
                _c("h4", [_vm._v(_vm._s(element.data.title))]),
                _vm._v(" "),
                _c(
                  "span",
                  { staticClass: "toggle" },
                  [
                    element.data.cta
                      ? _c("span", { staticClass: "cta" }, [
                          _vm._v(_vm._s(_vm.cta(element))),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("Icon", {
                      staticClass: "large",
                      attrs: { id: "ic_expand_more" },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              element.data.description
                ? _c("p", [
                    _vm._v(
                      "\n        " +
                        _vm._s(element.data.description) +
                        "\n      "
                    ),
                  ])
                : _vm._e(),
            ]
          ),
          _vm._v(" "),
          _c(
            "collapse-transition",
            { key: `dd_${index}`, attrs: { duration: 300 } },
            [
              _c(
                "dd",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isOpenRow(element.id) || _vm._isAdminArea,
                      expression: "isOpenRow(element.id) || _isAdminArea",
                    },
                  ],
                  key: `dd_${index}`,
                },
                [
                  _c(
                    "div",
                    [
                      _vm._l(element.rows, function (row, i) {
                        return [
                          _c("SectionBlock", {
                            key: i,
                            attrs: { row: row, "element-id": element.id },
                          }),
                        ]
                      }),
                    ],
                    2
                  ),
                ]
              ),
            ]
          ),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }