var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._isAdminArea || !_vm.hideHeader
    ? _c(
        "div",
        {
          staticClass: "interior-page-header",
          class: {
            "is-admin": _vm._isAdminUser,
            image: _vm.isBannerImage,
            "full-image": _vm.isFullImage,
            loading: !_vm.isLoaded,
            video: _vm.isVideo,
          },
          style: _vm.headerStyles,
          attrs: { role: "banner", "aria-label": _vm.label },
        },
        [
          _vm.isVideo
            ? _c(
                "video",
                {
                  staticClass: "bg-video",
                  attrs: { autoplay: "", loop: "", muted: "" },
                  domProps: { muted: true },
                },
                [
                  _c("source", {
                    attrs: {
                      src: _vm.headerData && _vm.headerData.imageSrc,
                      type: "video/mp4",
                    },
                  }),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "inner" }, [
            _c(
              "div",
              {
                staticClass: "header-items",
                class: { "no-title": !_vm.showTitle },
              },
              [
                _vm._isAdminArea && _vm.$userCan("pages.update")
                  ? _c(
                      "div",
                      {
                        staticClass: "edit-header",
                        on: { click: _vm.onEditHeader },
                      },
                      [
                        _c("span", [_vm._v("Page Title")]),
                        _vm._v(" "),
                        _c("icons", { attrs: { name: "edit" } }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.showTitle
                  ? [
                      _c("h1", { staticClass: "title" }, [
                        _vm._v(
                          "\n          " + _vm._s(_vm.pageTitle) + "\n        "
                        ),
                      ]),
                    ]
                  : _vm._e(),
              ],
              2
            ),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }