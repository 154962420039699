var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      staticStyle: { "enable-background": "new 0 0 20 15" },
      attrs: {
        role: "presentation",
        version: "1.1",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
        x: "0px",
        y: "0px",
        viewBox: "0 0 20 15",
        "xml:space": "preserve",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M14.5,15c0.3,0,0.5-0.2,0.5-0.5v-14C15,0.2,14.8,0,14.5,0h-9C5.2,0,5,0.2,5,0.5v14C5,14.8,5.2,15,5.5,15H14.5z M3.5,13\n  C3.8,13,4,12.8,4,12.5v-10C4,2.2,3.8,2,3.5,2h-3C0.2,2,0,2.2,0,2.5v10C0,12.8,0.2,13,0.5,13H3.5z M16,12.5c0,0.3,0.2,0.5,0.5,0.5h3\n  c0.3,0,0.5-0.2,0.5-0.5v-10C20,2.2,19.8,2,19.5,2h-3C16.2,2,16,2.2,16,2.5V12.5z",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }