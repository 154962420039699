var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "file-upload-base" },
    [
      _c(
        "form-root",
        {
          attrs: {
            "enable-submit": _vm.enableSubmit,
            "submit-button-text": !_vm.isLoadingSubmit
              ? "Send File"
              : "Submitting...",
            "form-id": "account-information-form",
          },
        },
        [
          _c("div", { staticClass: "flex grid" }, [
            _c(
              "div",
              { staticClass: "item" },
              [
                _c(
                  "div",
                  [
                    _c("TextInput", {
                      attrs: {
                        "hide-asterisk": true,
                        model: _vm.formData.file_name,
                        label: "File Name",
                      },
                      on: {
                        "update:model": function ($event) {
                          return _vm.$set(_vm.formData, "file_name", $event)
                        },
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "form-input text-input email" }, [
                  _c(
                    "label",
                    {
                      staticClass: "active",
                      staticStyle: { "margin-bottom": "0" },
                    },
                    [_vm._v("\n            Email Recipients\n          ")]
                  ),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.email,
                        expression: "email",
                      },
                    ],
                    staticClass: "email",
                    staticStyle: { "margin-top": "0 !important" },
                    attrs: {
                      label: "Recipient email",
                      role: "textbox",
                      type: "email",
                    },
                    domProps: { value: _vm.email },
                    on: {
                      blur: function ($event) {
                        return _vm.addEmail($event)
                      },
                      keydown: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.addEmail($event)
                      },
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.email = $event.target.value
                      },
                    },
                  }),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "flex list email-recipients" },
                  [
                    _vm._l(
                      _vm.formData.file_recipient,
                      function (recipient, index) {
                        return [
                          recipient.length > 0
                            ? _c("div", { key: index, staticClass: "item" }, [
                                _c("div", { staticClass: "item-header" }, [
                                  _c("div", { staticClass: "item-wrapper" }, [
                                    _c("div", { staticClass: "recipient" }, [
                                      _vm._v(
                                        "\n                    " +
                                          _vm._s(recipient) +
                                          "\n                  "
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "email-delete",
                                        on: {
                                          click: function ($event) {
                                            return _vm.removeEmail(index)
                                          },
                                        },
                                      },
                                      [
                                        _c("icon", {
                                          attrs: { id: "ic_close" },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]),
                                ]),
                              ])
                            : _vm._e(),
                        ]
                      }
                    ),
                  ],
                  2
                ),
                _vm._v(" "),
                _c(
                  "div",
                  [
                    _c("SelectInput", {
                      ref: "expiration",
                      attrs: {
                        list: _vm.expirationOptions,
                        model: _vm.selectedExpiration,
                        label: "Expiration (days)",
                        required: "true",
                      },
                      on: {
                        "update:model": function ($event) {
                          _vm.selectedExpiration = $event
                        },
                        "change-event": _vm.expirationDate,
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  [
                    _c("TextInput", {
                      ref: "comments",
                      attrs: {
                        model: _vm.formData.comments,
                        label: "Additional Comments",
                      },
                      on: {
                        "update:model": function ($event) {
                          return _vm.$set(_vm.formData, "comments", $event)
                        },
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("FileUploaderInput", {
                  ref: "fileUpload",
                  attrs: {
                    "is-required": true,
                    "is-uploading": _vm.isUploading,
                  },
                  on: { uploading: _vm.handleUploading },
                }),
                _vm._v(" "),
                _c(
                  "div",
                  [
                    _c("CheckboxInput", {
                      ref: "selItemNewTab",
                      attrs: {
                        model: _vm.formData.email_confirmation,
                        label:
                          "Receive an email when the recipient(s) downloads the file(s)",
                        required: "false",
                      },
                      on: {
                        "update:model": function ($event) {
                          return _vm.$set(
                            _vm.formData,
                            "email_confirmation",
                            $event
                          )
                        },
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }