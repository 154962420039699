import cssVars from "css-vars-ponyfill";
import { HttpClient } from "@core/js";

function randomID(n = 8) {
  // Returns a random 8 character string
  const seed = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
  const l = seed.length;

  let text = "";
  for (let i = 0; i < n; i++) {
    text += seed.charAt(Math.floor(Math.random() * l));
  }

  return text;
}

function deepMerge(obj) {
  return JSON.parse(JSON.stringify(obj));
}

function convertInlineStyles(htmlString = "", el = null) {
  if (el && htmlString.length) {
    // remove empty style attributes
    htmlString = htmlString.replace(/style\s*=\s*"\s*"/gi, "");
    // map inline style attributes
    const inlineStyles = htmlString.matchAll(
      /style\s*=\s*("|')(?<styles>.*?)("|')/gi
    );
    if (inlineStyles) {
      for (const value of inlineStyles) {
        if (value.groups.styles) {
          const className = randomID();

          // replace inline style attributes with class names
          htmlString = htmlString.replace(value[0], `class="${className}"`);

          // inject inline styles into a style tag and append to element
          const style = document.createElement("style");

          // !important truly sucks, but this is the only way to force
          // simple class styles to work identically to inline style attributes
          const styleArray = value.groups.styles.trim().split(";");
          let styles = "";
          styleArray.forEach((value) => {
            if (value) {
              styles += `${value} !important;`;
            }
          });

          style.innerHTML = `.${className} { ${styles} }`;
          style.nonce = __webpack_nonce__;

          el.appendChild(style);
          // appending to the element removes style tags when component is destroyed
        }
      }
    }
  }

  return htmlString;
}

function sanitizeParamValue(value) {

  // Removes unused parameters on query strings for API requests
  // by unsetting falsy values, empty arrays, and empty objects

  if (!value) {
    // remove falsy values
    return undefined;
  }

  if (Array.isArray(value) && !value.length) {
    // remove empty arrays
    return undefined;
  }

  if (
    Object.prototype.toString.call(value) === "[object Object]" &&
    !Object.keys(value).length
  ) {
    // remove empty objects
    return undefined;
  }

  return value;

}

function strToSlug(str) {
  str = str.replace(/^\s+|\s+$/g, ""); // trim
  str = str.toLowerCase();

  // remove accents, swap ñ for n, etc
  var from = "åàáãäâèéëêìíïîòóöôùúüûñç·/,:;";
  var to = "aaaaaaeeeeiiiioooouuuunc-----";

  for (var i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
  }

  str = str
    .replace(/[^a-z0-9 -_]/g, "") // remove invalid chars
    .replace(/\s+/g, "-") // collapse whitespace and replace by -
    .replace(/-+/g, "-"); // collapse dashes

  return str;
}

function validateMenuItemOrder(item) {
  return item.order ? Math.max(parseInt(item.order, 10), 1) : 1;
}

function getQueryStringParameter(name) {
  name = name.replace(/[[]/, "[").replace(/[\]]/, "\\]");
  var regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
  var results = regex.exec(location.search);

  return results === null
    ? ""
    : decodeURIComponent(results[1].replace(/\+/g, " "));
}

function updateQueryStringParameter(uri, key, value) {
  var re = new RegExp("([?&])" + key + "=.*?(&|$)", "ig");
  var separator = uri.indexOf("?") !== -1 ? "&" : "?";
  if (uri.match(re)) {
    return uri.replace(re, "$1" + key + "=" + value + "$2");
  } else {
    return uri + separator + key + "=" + value;
  }
}

function getDefaultAssetSrc(asset, size = 2) {
  const mimeArr = asset.mime_type.split("/");
  const fileExt = asset.file_name.split(".")[1];
  const mimeGroup = mimeArr[0];
  const mimeDetail = mimeArr[1];

  if (fileExt === "ai" || fileExt === "ait") {
    return `/assets/asset_default/ai_${size}x.png`;
  }

  if (fileExt === "indd" || fileExt === "indt") {
    return `/assets/asset_default/indesign_${size}x.png`;
  }

  if (fileExt === "aep" || fileExt === "aepx" || fileExt === "mogrt") {
    return `/assets/asset_default/ae_${size}x.png`;
  }

  let src = "/assets/asset_default/";

  switch (mimeGroup) {
    case "text":
      src += "text";
      break;
    case "video":
      if (mimeDetail === "x-ms-asf") {
        src += "audio";
      } else {
        src += "video";
      }
      break;
    case "audio":
      src += "audio";
      break;
    case "application":
      if (mimeDetail === "zip" || mimeDetail === "x-rar") {
        src += "zip";
      } else if (mimeDetail === "pdf") {
        src += "text";
      } else if (
        mimeDetail ===
          "vnd.openxmlformats-officedocument.presentationml.presentation" ||
        mimeDetail === "vnd.ms-powerpoint"
      ) {
        src += "pres";
      } else if (
        mimeDetail ===
          "vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
        mimeDetail === "vnd.ms-excel"
      ) {
        src += "spreadsheet";
      } else if (
        mimeDetail ===
          "vnd.openxmlformats-officedocument.wordprocessingml.document" ||
        mimeDetail === "msword"
      ) {
        src += "text";
      } else if (mimeDetail === "postscript") {
        src += "image";
      } else {
        src += "file";
      }
      break;
    case "image":
      if (mimeDetail === "vnd.adobe.photoshop") {
        src += "ps";
      } else {
        src += "image";
      }
      break;
    default:
      src += "file";
  }

  return src.length > 22
    ? `${src}_${size}x.png`
    : `/assets/asset_default/file_${size}x.png`;
}

function toObject(arr) {
  let r = {};
  for (let i = 0; i < arr.length; i += 2) {
    let key = arr[i];
    let value = arr[i + 1];
    r[key] = value;
  }
  return r;
}

function clearAndUpper(str) {
  return str.replace(/(-|_)/, "").toUpperCase();
}

function normalizeString(str) {
  return str
    ? str
        .replace(
          /[A-Z]+/g,
          (match, offset) => (offset > 0 ? "-" : "") + match.toLowerCase()
        )
        .replace(/(\s|_|-)+/g, "-")
    : "";
}

function toCamelCase(str) {
  return str ? normalizeString(str).replace(/-\w/g, clearAndUpper) : "";
}

function toPascalCase(str) {
  return str ? normalizeString(str).replace(/(^\w|-\w)/g, clearAndUpper) : "";
}

function toKebabCase(str) {
  return str ? normalizeString(str) : "";
}

function toSnakeCase(str) {
  return str ? normalizeString(str).replace("-", "_") : "";
}

// Old variable creation for the legacy theme
function buildStyles(settings) {
  // Inserts the CSS variables into the head of the HTML document
  const colors = settings.themeColors || {};
  const fonts = settings.themeFonts || {};
  const theme = settings.themeStyles || {};
  const images = settings.themeImages || {};
  const themeColors = Object.entries(colors);
  let siteColors = [];

  for (let i = 0; i < themeColors.length; i++) {
    let entry;
    entry = Object.entries(themeColors[i]);
    // make all color names lowercase and replace empty space with dash
    // to make valid css var
    siteColors.push(entry[1][1].name.replace(/\s+/g, "-").toLowerCase());
    siteColors.push(entry[1][1].hex);
  }

  const cleanedColors = toObject(siteColors);

  let palette = Object.assign(theme, images, cleanedColors);
  let styles = ":root {";

  Object.keys(palette).map((key, index) => {
    if (
      typeof palette[key] === "undefined" ||
      typeof palette[key] === "object"
    ) {
      return;
    }
    if (Array.isArray(palette[key])) {
      // It's an image (stored in array)
      if (typeof palette[key][0] === "undefined") {
        return;
      }
      styles += `--${key}: url(${palette[key][0]}); `;
    } else {
      if (palette[key].indexOf("color_") >= 0) {
        // It's a color
        let color =
          palette[key] && colors[palette[key]]
            ? colors[palette[key]].hex
            : "#000";
        styles += `--${key}: ${color}; `;
      } else if (palette[key].indexOf("font_") >= 0) {
        // It's a font
        let font =
          palette[key] && fonts[palette[key]] ? fonts[palette[key]].value : "";
        styles += `--${key}: ${font}; `;
      } else {
        styles += `--${key}: ${palette[key]}; `;
      }
    }
  });

  styles += "}";

  return styles;
}

// Updated CSS variable system for clockwork theme
function parseStyleVars(styleVars = [], localesIsPublished) {
  let styles = ":root {";

  const defaultLocale =
    window.AppStore.getters["Locales/getActiveLocaleById"](1);
  let defaultVars = styleVars.filter(
    (item) => item.locale === defaultLocale?.locale ?? "en"
  );
  const activeLocale = window.AppStore.getters[
    "Locales/getActiveLocaleByLocale"
  ](window.AppI18n.locale);

  // Replace default variables with the locale overrides
  if (localesIsPublished && activeLocale && activeLocale.id !== 1) {
    let localeOverrides = styleVars.filter(
      (item) => item.locale === activeLocale.locale
    );

    localeOverrides.forEach((localeOverride) => {
      let found = false;

      defaultVars = defaultVars.map((defaultVar) => {
        if (localeOverride.key === defaultVar.key) {
          found = true;

          defaultVar.value = localeOverride.value;
        }

        return defaultVar;
      });

      if (!found) {
        defaultVars.push(localeOverride);
      }
    });
  }

  defaultVars.forEach((style) => {
    styles += `${style.key}: ${style.value};`;
  });

  styles += "}";

  return styles;
}

// update document page title
function updateDocumentTitle(siteName, title, isHome) {
  if (isHome || !title) {
    document.title = siteName;
  } else {
    document.title = title + " | " + siteName;
  }
}

export default {
  install(Vue) {
    Vue.prototype.$utilities = {
      convertInlineStyles,
      deepMerge,
      getDefaultAssetSrc,
      getQueryStringParameter,
      randomID,
      sanitizeParamValue,
      strToSlug,
      toCamelCase,
      toKebabCase,
      toPascalCase,
      toSnakeCase,
      updateDocumentTitle,
      updateQueryStringParameter,
      validateMenuItemOrder,
    };

    Vue.prototype.$onResize = function (file) {
      this.$store.dispatch("Global/setModal", {
        type: "ResizeModal",
        data: {
          file: file,
        },
      });
    };

    Vue.prototype.$formUpdated = function (formId, bool) {
      let storeFormUpdated = this.$store.state.Global.formUpdated;
      let newObj = Object.assign({}, storeFormUpdated, { [formId]: bool });
      this.$store.dispatch("Global/setProperty", {
        collection: "formUpdated",
        data: newObj,
      });
    };
    Vue.prototype.$onInfo = function (
      id,
      fileArray = [],
      options = null,
      actions = null
    ) {
      this.$store.dispatch("Global/setModal", {
        type: "FileModal",
        data: {
          id: id,
          fileArray: fileArray,
          options: options,
          actions: actions,
        },
      });
    };
    Vue.prototype.$onShare = function (id) {
      this.$store.dispatch("Assets/getNestedProperty", {
        collection: "assets",
        element: "asset",
        data: { id: id },
        url: `account/assets/${id}`,
      });
      this.$store.dispatch("Global/setModal", {
        type: "ShareFileModal",
        data: {
          type: "file",
          id: id,
        },
      });
    };
    // Vue.prototype.$onHelpdeskInfo = function (id, requestArray) {
    //   this.$store.dispatch('Global/setModal', {
    //     type: 'helpdesk',
    //     data: {
    //       id: id,
    //       requestArray: requestArray
    //     }
    //   })
    // }
    Vue.prototype.$onAssetDownload = function (id, src, conversionData = null) {
      const loadingId = this.$utilities.randomID();
      this.$setLoadingStatus(loadingId);

      axios({
        url: `assets/${id}/download`,
        method: "GET",
        params: conversionData,
      })
        .then((response) => {
          const link = document.createElement("a");
          link.href = response.data.download_url;
          link.download = src;
          document.body.appendChild(link);
          link.click();
          link.remove();
        })
        .catch((err) => {
          /* eslint-disable-next-line no-console */
          console.log(err);
        })
        .then(() => {
          this.$unsetLoadingStatus(loadingId);
        });
    };
    Vue.prototype.$onAsset = function (id, src, conversionData = null) {
      const assetId = parseInt(id, 10);
      const loadingId = this.$utilities.randomID();

      // check if library restrictions feature is turned on
      // check if library of asset is restricted
      
      HttpClient.get(`assets/${assetId}/library`)
        .then((res) => {
          const track = res?.data?.library?.[0]?.tracking ?? null;
          const restricted =
            res?.data?.library?.[0]?.restrict_downloads ?? null;

          if (track) {
            this.$setLoadingStatus(loadingId);

            this.$store.dispatch("Global/setModal", {
              type: "asset-tracking",
              data: {
                id: id,
                src: src,
                library_id: res.data.library[0].id,
                conversionData: conversionData,
              },
            });
          } else if (
            this._assetPermissionsArePublished &&
            restricted === 1 &&
            !this._isAdminUser
          ) {
            this.$store.dispatch("Global/setModal", {
              type: "RequestDownloadModal",
              data: {
                asset_id: assetId,
                library_id: res.data.library[0].id,
              },
            });
          } else {
            this.$onAssetDownload(id, src, conversionData);
          }
        })
        .catch((err) => {
          /* eslint-disable-next-line no-console */
          console.log(err);
        })
        .then(() => {
          this.$unsetLoadingStatus(loadingId);
        });
    };
    Vue.prototype.$onFile = function (id, requestId, src) {
      axios({
        url: `helpdesk-requests/${requestId}/files/${id}/download`,
        method: "GET",
      }).then((response) => {
        const link = document.createElement("a");
        link.href = response.data.helpdesk_file_url;
        link.download = src;
        document.body.appendChild(link);
        link.click();
        link.remove();
      });
    };
    Vue.prototype.$onEmail = function (id) {
      this.$store.dispatch("Assets/getNestedProperty", {
        collection: "assets",
        element: "asset",
        data: { id: id },
        url: `account/assets/${id}`,
      });
      this.$store.dispatch("Global/setModal", {
        type: "EmailFileModal",
        data: {
          type: "file",
          id: id,
        },
      });
    };
    Vue.prototype.$onLightbox = function (id) {
      this.$store.dispatch("Global/setModal", {
        type: "LightboxModal",
        data: Object.assign(this.$store.state.Global.modal.data, { id: id }),
      });
    };
    Vue.prototype.$isInCart = function (id) {
      return (
        typeof _.findWhere(this.$store.state.AssetQueue.assets, { id: id }) !==
        "undefined"
      );
    };
    Vue.prototype.$onQueueToggle = function (id) {
      const file = _.findWhere(this.$store.state.AssetQueue.assets, { id: id });
      if (typeof file === "undefined") {
        this.$store.dispatch("AssetQueue/createNestedProperty", {
          collection: "assets",
          element: "asset",
          data: {
            id: id,
          },
          url: "asset-queue",
        });
      } else {
        this.$store.dispatch("AssetQueue/deleteNestedProperty", {
          collection: "assets",
          element: "asset",
          data: file,
          url: `asset-queue/${id}`,
        });
      }
    };
    Vue.prototype.$setFavicon = function () {
      let favicon = document.querySelector("link[rel=icon]");
      let themeFavicon = this.$store.state.Theme?.theme?.themeImages?.favicon;

      if (themeFavicon && favicon) {
        if (
          typeof themeFavicon === "string" ||
          themeFavicon instanceof String
        ) {
          favicon.href = themeFavicon;
        } else {
          favicon.href = themeFavicon[0];
        }
      }
    };
    Vue.prototype.$setStyles = function () {
      this.$set(this.$store.state.Theme.theme, "stylesLoaded", false);
      const themeConfig = this.$store.state.Client.theme;
      let styles = "";

      if (themeConfig.theme === "legacy") {
        styles = buildStyles(this.$store.state.Theme.theme);
      } else {
        styles = parseStyleVars(
          this.$store.getters["Settings/styleVars"],
          this._localesIsPublished
        );
      }

      let css_vars = document.getElementById("css_config");
      css_vars.nonce = __webpack_nonce__;
      css_vars.innerHTML = styles;

      cssVars({
        watch: true,
      });

      this.$set(this.$store.state.Theme.theme, "stylesLoaded", true);
    };
    Vue.prototype.$validationTests = {
      isEmail: function (s) {
        const re = new RegExp(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
        return re.test(s);
      },
      isPassword: function (s) {
        // return an object with full password validation, per the config settings
        const reqs = Vue.prototype.$themeSettings.passwordRequirements;
        const regLength = new RegExp("^(?=.{" + reqs.length + ",})");
        const regLower = new RegExp("^(?=.*[a-z]{" + reqs.lowercase + ",})");
        const regNumber = new RegExp("^(?=.*[0-9]{" + reqs.number + ",})");
        const regSymbol = new RegExp(
          "^(?=.*[!@#$%^&*_]{" + reqs.symbol + ",})"
        );
        const regUpper = new RegExp("^(?=.*[A-Z]{" + reqs.uppercase + ",})");

        return {
          length: regLength.test(s),
          lowercase: regLower.test(s),
          number: regNumber.test(s),
          symbol: regSymbol.test(s),
          uppercase: regUpper.test(s),
        };
      },
      isPhone: function (s) {
        // Check for 10 digits.
        // This validation will not work for international numbers
        const n = `${s}`.replace(/\D/g, "");
        const re = new RegExp(/^\d{10}$/);
        return re.test(n);
      },
    };
    Vue.prototype.$setLoadingStatus = function (id) {
      // Use this function before Axios calls to show the loading spinner
      this.$store.commit(
        "Global/SET_LOADING_STATUS",
        {
          id: id,
          status: true,
        },
        {
          root: true,
        }
      );
    };
    Vue.prototype.$unsetLoadingStatus = function (id) {
      // Use this function after the Axion call to hide the loading spinner
      this.$store.commit(
        "Global/SET_LOADING_STATUS",
        {
          id: id,
          status: false,
        },
        {
          root: true,
        }
      );
    };
    Vue.prototype.$makeSomeToast = function (
      title = "",
      msg = "",
      variant = "danger",
      position = "b-toaster-top-right"
    ) {
      /*
      position can be one of the following:
      'b-toaster-top-right'
      'b-toaster-top-left'
      'b-toaster-top-center'
      'b-toaster-top-full'
      'b-toaster-bottom-right'
      'b-toaster-bottom-left'
      'b-toaster-bottom-center'
      'b-toaster-bottom-full'
      */
      this.$bvToast.toast(msg, {
        title: title,
        autoHideDelay: 5000,
        appendToast: true,
        variant: variant,
        toaster: position,
      });
    };
    Vue.prototype.$validateMenuItemOrder = function (item) {
      return item.order ? Math.max(parseInt(item.order, 10), 1) : 1;
    };
    Vue.prototype.$updateStoreParams = function (storeModule) {
      // storeModule = primary store module name, e.g. "Assets" or "MenuItems"
      let queryString = window.location.search.split("?")[1];

      if (typeof storeModule === "string" && !!queryString) {
        let query = {};
        const params = queryString.split("&");

        params.forEach((item) => {
          let set = item.split("=");
          if (set[0] && set[1]) {
            query[set[0]] = set[1];
          }
        });

        let pagination = {};
        let filterParameters = {};
        let searchParameters = {};

        Object.keys(query).forEach((key) => {
          const k = decodeURIComponent(key);
          let v = decodeURIComponent(query[key]);

          if (v) {
            if (v.indexOf(",") > -1) {
              v = v.split(",");
            }

            if (!isNaN(v)) {
              v = Number.isInteger(Number(v)) ? parseInt(v, 10) : parseFloat(v);
            }
          }

          switch (k) {
            case "per_page":
            case "page":
            case "pages":
            case "items":
              pagination[k] = v;
              break;
            case "library":
            case "order":
            case "origin":
              filterParameters[k] = v;
              break;
            case "filters":
              if (Array.isArray(v)) {
                filterParameters[k] = v;
              } else {
                filterParameters[k] = [v];
              }

              break;
            case "search":
            case "term":
            case "type":
            case "match":
            case "category":
              searchParameters[k] = v;
              break;
            default:
          }
        });

        let collection = this.$store.state[storeModule];
        collection.pagination = Object.assign(
          {},
          collection.pagination,
          pagination
        );
        collection.filterParameters = Object.assign(
          {},
          collection.filterParameters,
          filterParameters
        );
        collection.searchParameters = Object.assign(
          {},
          collection.searchParameters,
          searchParameters
        );
      }
    };
  },
};
