export default [
    {
        path: '/admin/data-tables',
        name: 'admin.data-tables',
        meta: { layout: 'admin' },
        component: () => import('@core/plugins/DataTables/components/index.vue'),
        children: [
            {
                path: '',
                name: 'admin.data-tables.index',
                component: () => import('@core/plugins/DataTables/components/cms/data-tables.vue')
            },
            {
                path: ':id',
                name: 'admin.data-tables.table',
                component: () => import('@core/plugins/DataTables/components/cms/data-tables.vue')
            },
            {
                path: 'new',
                name: 'admin.data-tables.new',
                component: () => import('@core/plugins/DataTables/components/cms/partials/title-description.vue')
            },
        ]
    },
    {
        path: '/admin/data-tables-management',
        name: 'admin.data-tables-management',
        meta: { layout: 'admin' },
        component: () => import(
            '@core/plugins/DataTables/components/cms/data-tables-management.vue'
        ),
        children: [
            {
                path: '',
                name: 'admin.data-tables-management.index',
                redirect: 'new'
            },
            {
                path: 'new',
                name: 'admin.data-tables-management.new',
                component: () => import(
                    '@core/plugins/DataTables/components/cms/partials/title-description.vue'
                )
            },
            {
                path: ':id',
                name: 'admin.data-tables-management.table',
                redirect: 'title'
            },
            {
                path: ':id/title',
                name: 'admin.data-tables-management.table.title',
                component: () => import(
                    '@core/plugins/DataTables/components/cms/partials/title-description.vue'
                )
            },
            {
                path: ':id/columns',
                name: 'admin.data-tables-management.table.columns',
                component: () => import(
                    '@core/plugins/DataTables/components/cms/partials/columns.vue'
                )
            },
        ]
    }
]