var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("iframe", {
    ref: "authIframe",
    attrs: { tabindex: "-1", "aria-hidden": "true", src: _vm.domain },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }