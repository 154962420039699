var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      staticStyle: { "enable-background": "new 0 0 20 15" },
      attrs: {
        role: "presentation",
        version: "1.1",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
        x: "0px",
        y: "0px",
        viewBox: "0 0 20 15",
        "xml:space": "preserve",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M12.4,15c0.3,0,0.5-0.2,0.5-0.5V8.6c0-0.3-0.2-0.5-0.5-0.5H7.6c-0.3,0-0.5,0.2-0.5,0.5v5.9c0,0.3,0.2,0.5,0.5,0.5H12.4z\n   M5.4,15c0.3,0,0.5-0.2,0.5-0.5v-14C5.9,0.2,5.7,0,5.4,0H0.5C0.2,0,0,0.2,0,0.5v14C0,14.8,0.2,15,0.5,15H5.4z M19.5,15\n  c0.3,0,0.5-0.2,0.5-0.5V8.6c0-0.3-0.2-0.5-0.5-0.5h-4.9c-0.3,0-0.5,0.2-0.5,0.5v5.9c0,0.3,0.2,0.5,0.5,0.5H19.5z M7.1,6.4\n  c0,0.3,0.2,0.5,0.5,0.5h12c0.3,0,0.5-0.2,0.5-0.5V0.5C20,0.2,19.8,0,19.5,0h-12C7.3,0,7.1,0.2,7.1,0.5V6.4z",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }