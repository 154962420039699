var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        role: "presentation",
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 14.8 18",
        "enable-background": "new 0 0 14.8 18",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M12.9 0h-11c-1 0-1.9.8-1.9 1.9v14.3c0 1 .9 1.9 1.9 1.9h11c1 0 1.9-.8 1.9-1.9v-14.3c0-1.1-.9-1.9-1.9-1.9zm.5 16.1c0 .3-.2.5-.5.5h-11c-.3 0-.5-.2-.5-.5v-14.2c0-.3.2-.5.5-.5h11c.3 0 .5.2.5.5v14.2zm-10.9-9.6c0-.5.3-.9.6-.9h3.9c.3 0 .6.4.6.9s-.3.9-.6.9h-3.9c-.3 0-.6-.4-.6-.9zm9 6.8c0 .5-.3.9-.7.9h-7.6c-.4 0-.7-.4-.7-.9s.3-.9.7-.9h7.6c.4 0 .7.4.7.9zm0-3.1c0 .5-.3.9-.7.9h-7.6c-.4 0-.7-.4-.7-.9s.3-.9.7-.9h7.6c.4 0 .7.4.7.9z",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }