var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "search-bar" },
    [
      [
        _vm.title
          ? _c("h4", [_vm._v("\n      " + _vm._s(_vm.title) + "\n    ")])
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "search-wrapper" },
          [
            _c(
              "label",
              {
                staticClass: "offscreen",
                attrs: { for: "homepage-search-bar" },
              },
              [_vm._v(_vm._s(_vm.placeholder))]
            ),
            _vm._v(" "),
            _vm.leftIconName
              ? _c("icon", {
                  staticClass: "left-search-icon",
                  attrs: { id: _vm.leftIconName ? _vm.leftIconName : "" },
                })
              : _vm._e(),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.searchValue,
                  expression: "searchValue",
                },
              ],
              staticClass: "search-input",
              class: {
                "left-icon-padding": _vm.leftIconName,
                "right-icon-padding": _vm.rightIconName,
              },
              attrs: {
                id: "homepage-search-bar",
                type: "text",
                placeholder: _vm.placeholder,
                readonly: _vm._isAdminArea,
              },
              domProps: { value: _vm.searchValue },
              on: {
                keydown: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  return _vm.handleSubmit.apply(null, arguments)
                },
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.searchValue = $event.target.value
                },
              },
            }),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "search-btn",
                attrs: {
                  role: "button",
                  "aria-label": _vm.$t("forms_search_field_label"),
                },
                on: { click: _vm.handleSubmit },
              },
              [
                _vm.rightIconName
                  ? _c("icon", {
                      staticClass: "right-search-icon",
                      attrs: {
                        id: _vm.rightIconName ? _vm.rightIconName : "search",
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        ),
      ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }