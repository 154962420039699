/**
 *
 * Http Client
 *
 * Adds custom header to requests for API to determine active client.
 * Also uses interceptors to add optional Authentication header.
 *
 * Axios Cheat Sheet:
 * @link https://kapeli.com/cheat_sheets/Axios.docset/Contents/Resources/Documents/index
 *
 */

import axios from 'axios'
import {CookieManager} from '@core/js'
import ClientConfig from '@client/config'


let headers = ClientConfig.request_headers
headers['Beam-Client-Name'] = ClientConfig.clientName

if (process.env.NODE_ENV === 'development') {
  headers['Strict-Transport-Security'] = undefined
}

const config = {
  baseURL: process.env.API_URL || 'https://api.beamapi.localdev:82/v1/',
  headers: headers
}

const HttpClient = axios.create(config)

/**
 * Auth Interceptor
 *
 * @description if present, add authorization token to request
 * @param {object} config
 * @returns axios config
 */
const authInterceptor = config => {
  const JWT = JSON.parse(CookieManager.getItem('jwt'))

  if (JWT) {
    config.headers.Authorization = JWT
  }

  const entity = window?.AppStore?.state?.Entities?.currentEntity?.id ?? null
  if (entity) {
    config.headers['Beam-Current-Entity'] = entity
  }

  config.headers['Accept-Language'] = window?.AppI18n?.locale ?? 'en'

  return config
}

HttpClient.interceptors.request.use(authInterceptor)
HttpClient.interceptors.response.use(
  response => response,
  error => {
    /* Bad token = log the user out
     * There is a similar version in place at
     * src/js/utilities/globals.js, in the checkAuthentication function
     */
    const status = parseInt(error?.response?.status ?? 0, 10)
    const message = error?.response?.data?.error?.message ?? 'An error occured'
    const config = error?.response?.config ?? {}

    // This is ugly, but should only be temporary until we set up better
    // error handling in the API
    const invalidToken =
      (status === 401 && message.includes('Token')) || (status === 500 && config.url === 'account/me')

    if (invalidToken) {
      // Invalid token, force logout
      window.App.$router.push({name: 'auth.logout'})
    }

    // const hasError = error?.response?.data?.error ?? null
    // if (hasError && status !== 500 && status !== 403 && status !== 404) {
    //   // Set global error state for AppToaster
    //   window.AppStore.commit('Global/SET_ERRORS', error.response.data.error, {
    //     root: true
    //   })
    // }

    // Keep the promises rolling
    return Promise.reject(error)
  }
)

export {HttpClient, headers as GlobalHeaders}
