import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

const InitI18n = () => {
  window.AppI18n = new VueI18n({
    locale: 'en',
    fallbackLocale: 'en',
    messages: window.AppStore.getters['Translations/messages']
  })
}

export { InitI18n }
