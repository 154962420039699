var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        role: "presentation",
        xmlns: "http://www.w3.org/2000/svg",
        width: "17",
        height: "14",
        viewBox: "0 0 17 14",
      },
    },
    [
      _c("g", { attrs: { fill: "none", "fill-rule": "evenodd" } }, [
        _c("path", { attrs: { d: "M-1-2h19v19H-1z" } }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            fill: "#979797",
            "fill-rule": "nonzero",
            d: "M14.682 0A2.33 2.33 0 0 1 17 2.333v9.334A2.33 2.33 0 0 1 14.682 14H2.318A2.33 2.33 0 0 1 0 11.667V2.333A2.33 2.33 0 0 1 2.318 0h12.364zm.772 3.827l-6.51 4.588a.769.769 0 0 1-.887 0L1.545 3.827v7.84c0 .426.35.777.773.777h12.364a.78.78 0 0 0 .772-.777v-7.84zm-.772-2.271H2.318c-.3 0-.562.176-.69.43L8.5 6.828l6.872-4.842a.777.777 0 0 0-.69-.43z",
          },
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }