import actions from '@core/store/actions/actions'
import mutations from '@core/store/mutations/mutations'

export default {
  namespaced: true,
  state: {
    pagination: {
      per_page: 12,
      page: 1,
      pages: 1,
      items: 0
    },
    stats: {
      all: 0,
      pending: 0,
      published: 0,
      deleted: 0
    },
    showcase_submission_comments: [],

    filterParameters: {},

    searchParameters: {
      term: ''
    }

    /*
    config: {},
    isLoading: false,
    newElementCreated: false,
    view: "list"
    */
  },
  mutations,
  actions
}
