var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: [
        { "checks-plus": _vm.checksAndPlus },
        "form-input",
        "checkbox-array-input",
        _vm.modelString,
      ],
      attrs: { role: "group", "aria-label": _vm.label },
    },
    [
      _vm.label
        ? _c("h6", [
            _vm._v("\n    " + _vm._s(_vm.label) + "\n    "),
            _vm.required && !_vm.hideAsterisk
              ? _c("span", { staticClass: "required" }, [
                  _vm._v("\n      *\n    "),
                ])
              : _vm._e(),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.detailsLabel
        ? _c("small", { staticClass: "details-label" }, [
            _vm._v("\n    " + _vm._s(_vm.detailsLabel) + "\n  "),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "checkbox-wrapper" },
        [
          _vm._l(_vm.list, function (value, index) {
            return [
              _c("div", { key: index, staticClass: "checkbox-input" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.input,
                      expression: "input",
                    },
                  ],
                  attrs: {
                    id: `${_vm.id}_${index}`,
                    type: "checkbox",
                    role: "checkbox",
                    name: _vm.name,
                    tabindex: "-1",
                  },
                  domProps: {
                    value: index,
                    checked: Array.isArray(_vm.input)
                      ? _vm._i(_vm.input, index) > -1
                      : _vm.input,
                  },
                  on: {
                    change: function ($event) {
                      var $$a = _vm.input,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = index,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 && (_vm.input = $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            (_vm.input = $$a
                              .slice(0, $$i)
                              .concat($$a.slice($$i + 1)))
                        }
                      } else {
                        _vm.input = $$c
                      }
                    },
                  },
                }),
                _vm._v(" "),
                _vm.checksAndPlus
                  ? _c(
                      "label",
                      {
                        ref: `label_${index}`,
                        refInFor: true,
                        staticClass: "check-or-plus",
                        attrs: { for: `${_vm.id}_${index}`, tabindex: "0" },
                        on: {
                          keydown: function ($event) {
                            return _vm.$triggerClickEvent(
                              $event,
                              `${_vm.id}_${index}`
                            )
                          },
                        },
                      },
                      [
                        _c(
                          "span",
                          { staticClass: "check" },
                          [_c("icon", { attrs: { id: "cms_check_mark" } })],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "span",
                          { staticClass: "plus" },
                          [_c("icon", { attrs: { id: "cms_plus" } })],
                          1
                        ),
                        _vm._v("\n          " + _vm._s(value) + "\n        "),
                      ]
                    )
                  : _c("label", {
                      directives: [
                        {
                          name: "sanitize",
                          rawName: "v-sanitize",
                          value: value,
                          expression: "value",
                        },
                      ],
                      ref: `label_${index}`,
                      refInFor: true,
                      attrs: { for: `${_vm.id}_${index}`, tabindex: "0" },
                      on: {
                        keydown: function ($event) {
                          return _vm.$triggerClickEvent(
                            $event,
                            `${_vm.id}_${index}`
                          )
                        },
                      },
                    }),
              ]),
            ]
          }),
        ],
        2
      ),
      _vm._v(" "),
      _vm.required && _vm.error
        ? _c("div", { staticClass: "error" }, [
            _vm._v("\n    " + _vm._s(_vm.errorMsg) + "\n  "),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }