var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: ["vc-sketch", _vm.disableAlpha ? "vc-sketch__disable-alpha" : ""],
      attrs: { role: "application", "aria-label": "Sketch color picker" },
    },
    [
      _c(
        "div",
        { staticClass: "vc-sketch-saturation-wrap" },
        [
          _c("saturation", {
            on: { change: _vm.childChange },
            model: {
              value: _vm.colors,
              callback: function ($$v) {
                _vm.colors = $$v
              },
              expression: "colors",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "vc-sketch-controls" }, [
        _c("div", { staticClass: "vc-sketch-sliders" }, [
          _c(
            "div",
            { staticClass: "vc-sketch-hue-wrap" },
            [
              _c("hue", {
                on: { change: _vm.childChange },
                model: {
                  value: _vm.colors,
                  callback: function ($$v) {
                    _vm.colors = $$v
                  },
                  expression: "colors",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          !_vm.disableAlpha
            ? _c(
                "div",
                { staticClass: "vc-sketch-alpha-wrap" },
                [
                  _c("alpha", {
                    on: { change: _vm.childChange },
                    model: {
                      value: _vm.colors,
                      callback: function ($$v) {
                        _vm.colors = $$v
                      },
                      expression: "colors",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "vc-sketch-color-wrap" },
          [
            _c("div", {
              staticClass: "vc-sketch-active-color",
              style: { background: _vm.activeColor },
              attrs: { "aria-label": `Current color is ${_vm.activeColor}` },
            }),
            _vm._v(" "),
            _c("checkboard"),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      !_vm.disableFields
        ? _c("div", { staticClass: "vc-sketch-field" }, [
            _c(
              "div",
              { staticClass: "vc-sketch-field--double" },
              [
                _c("ed-in", {
                  attrs: { label: "hex", value: _vm.hex },
                  on: { change: _vm.inputChange },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "vc-sketch-field--single" },
              [
                _c("ed-in", {
                  attrs: { label: "r", value: _vm.colors.rgba.r },
                  on: { change: _vm.inputChange },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "vc-sketch-field--single" },
              [
                _c("ed-in", {
                  attrs: { label: "g", value: _vm.colors.rgba.g },
                  on: { change: _vm.inputChange },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "vc-sketch-field--single" },
              [
                _c("ed-in", {
                  attrs: { label: "b", value: _vm.colors.rgba.b },
                  on: { change: _vm.inputChange },
                }),
              ],
              1
            ),
            _vm._v(" "),
            !_vm.disableAlpha
              ? _c(
                  "div",
                  { staticClass: "vc-sketch-field--single" },
                  [
                    _c("ed-in", {
                      attrs: {
                        label: "a",
                        value: _vm.colors.a,
                        "arrow-offset": 0.01,
                        max: 1,
                      },
                      on: { change: _vm.inputChange },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "vc-sketch-presets",
          attrs: {
            role: "group",
            "aria-label": "A color preset, pick one to set as current color",
          },
        },
        [
          _vm._l(_vm.presetColors, function (c) {
            return [
              !_vm.isTransparent(c)
                ? _c("div", {
                    key: c,
                    staticClass: "vc-sketch-presets-color",
                    style: { background: c },
                    attrs: { "aria-label": "Color:" + c },
                    on: {
                      click: function ($event) {
                        return _vm.handlePreset(c)
                      },
                    },
                  })
                : _c(
                    "div",
                    {
                      key: c,
                      staticClass: "vc-sketch-presets-color",
                      attrs: { "aria-label": "Color:" + c },
                      on: {
                        click: function ($event) {
                          return _vm.handlePreset(c)
                        },
                      },
                    },
                    [_c("checkboard")],
                    1
                  ),
            ]
          }),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }