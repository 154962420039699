var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "module formpicker-module" },
    [
      _vm._isSuperAdminUser
        ? _c("module-controls", {
            attrs: {
              placeholder: _vm.moduleLabel,
              "action-buttons": _vm.actionButtons,
            },
            on: {
              "on-edit": _vm.$openModal,
              "on-remove": _vm.$deleteContentModuleConfirm,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "module_content" },
        [
          _vm._isAdminArea && _vm.isEmpty
            ? _c("div", { staticClass: "empty" }, [
                _vm._v("\n      This module is empty. Start editing!\n    "),
              ])
            : _vm._e(),
          _vm._v(" "),
          !_vm.isEmpty && !_vm._formEditorEnabled
            ? [_c(_vm.moduleData.formId, { tag: "component" })]
            : _vm._e(),
          _vm._v(" "),
          _vm.formSelected && !_vm.formSubmitted && _vm._formEditorEnabled
            ? _c(
                "FormRoot",
                {
                  attrs: {
                    id: "form-picker",
                    "data-check": "LoginForm",
                    "form-id": "form-picker",
                    "aria-labelledby": "login-form-desc",
                    "submit-button-text": _vm.formSelected.submit_message,
                    "submit-button-variant": _vm.formSelected.submit_button_type
                      ? _vm.formSelected.submit_button_type
                      : "primary",
                    "allow-cancel-disable": false,
                    "enable-submit": _vm.formSelected.endpoint.includes("mfa"),
                  },
                },
                [
                  _vm.formComponents
                    ? [
                        _vm._l(_vm.formComponents, function (field, key) {
                          return [
                            _vm.showField(field)
                              ? [
                                  field.type.includes("-input")
                                    ? [
                                        _c(
                                          field.settings.includes("custom")
                                            ? `form-picker-${field.type}`
                                            : field.type,
                                          {
                                            key: key,
                                            ref: key,
                                            refInFor: true,
                                            tag: "components",
                                            attrs: {
                                              id: `${field.name}_${key}_${field.id}`,
                                              name: `${field.name}`,
                                              "data-check": `AuthForm_${key}`,
                                              match:
                                                "match-input" === field.type
                                                  ? _vm.formData.password
                                                  : null,
                                              list: field.type.includes(
                                                "select"
                                              )
                                                ? _vm.dropdownType(field)
                                                : field.form_component_options &&
                                                  field.form_component_options
                                                    .length > 0
                                                ? _vm.formatFormComponentOptions(
                                                    field.form_component_options
                                                  )
                                                : null,
                                              model: _vm.formData[field.name],
                                              label: field.label,
                                              "hide-asterisk":
                                                !field.required ||
                                                !_vm.formSelected.show_asterisk,
                                              required:
                                                field.required === 0
                                                  ? "false"
                                                  : "true",
                                              "error-message":
                                                field.error_message,
                                              placeholder: field.placeholder,
                                              descby: field.description || null,
                                              validate:
                                                !_vm.formSelected.endpoint.includes(
                                                  "login"
                                                )
                                                  ? _vm.validationType(
                                                      field.type
                                                    )
                                                  : null,
                                              disabled:
                                                _vm._isAdminArea ||
                                                _vm.requestPending,
                                              "data-id": field.id,
                                            },
                                            on: {
                                              "update:model": function (
                                                $event
                                              ) {
                                                return _vm.$set(
                                                  _vm.formData,
                                                  field.name,
                                                  $event
                                                )
                                              },
                                              inputError: _vm.handleInputError,
                                            },
                                          }
                                        ),
                                      ]
                                    : field.type === "mfa-verification"
                                    ? [
                                        _c("text-input", {
                                          key: key,
                                          ref: key,
                                          refInFor: true,
                                          staticClass: "mfa-input",
                                          attrs: {
                                            id: `${field.name}_${key}_${field.id}`,
                                            model:
                                              _vm.formData["multi_factor_code"],
                                            "data-check": `AuthForm_${key}`,
                                            type: "text",
                                            label: field.label,
                                            placeholder: field.placeholder,
                                            "aria-labelledby": field.label,
                                            required:
                                              field.required === 0
                                                ? "false"
                                                : "true",
                                            disabled: _vm._isAdminArea,
                                            "data-id": field.id,
                                          },
                                          on: {
                                            "update:model": function ($event) {
                                              return _vm.$set(
                                                _vm.formData,
                                                "multi_factor_code",
                                                $event
                                              )
                                            },
                                            inputError: _vm.handleInputError,
                                          },
                                        }),
                                        _vm._v(" "),
                                        _vm.mfaError
                                          ? _c(
                                              "span",
                                              {
                                                key: `mfa-error-${key}`,
                                                staticClass: "mfa-error",
                                                attrs: { role: "alert" },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                " +
                                                    _vm._s(
                                                      _vm.mfaErrorMessage
                                                    ) +
                                                    "\n              "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _c(
                                          "a",
                                          {
                                            key: `resend-link-${key}`,
                                            staticClass: "resend-code",
                                            attrs: { href: "#" },
                                            on: {
                                              click: function ($event) {
                                                $event.preventDefault()
                                                return _vm.resendCode.apply(
                                                  null,
                                                  arguments
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                Resend Code\n              "
                                            ),
                                          ]
                                        ),
                                      ]
                                    : field.type.includes("terms")
                                    ? [
                                        _c(
                                          "h3",
                                          {
                                            key: `terms_title_${key}`,
                                            staticClass: "terms-of-use-title",
                                          },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(field.label) +
                                                "\n              "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("div", {
                                          directives: [
                                            {
                                              name: "sanitize",
                                              rawName: "v-sanitize",
                                              value: field.description,
                                              expression: "field.description",
                                            },
                                          ],
                                          key: `terms_content_${key}`,
                                          staticClass: "terms-of-use",
                                          attrs: { id: "terms-of-use-markup" },
                                        }),
                                        _vm._v(" "),
                                        _c("checkbox-input", {
                                          key: key,
                                          ref: key,
                                          refInFor: true,
                                          attrs: {
                                            label:
                                              field.form_component_options[0]
                                                .value,
                                            "data-check": `LoginForm_${key}`,
                                            model: _vm.formData["terms_of_use"],
                                            required: "true",
                                            validate: _vm.validationType(
                                              field.type
                                            ),
                                            disabled:
                                              _vm._isAdminArea ||
                                              _vm.requestPending,
                                            "error-message":
                                              field.error_message,
                                          },
                                          on: {
                                            "update:model": function ($event) {
                                              return _vm.$set(
                                                _vm.formData,
                                                "terms_of_use",
                                                $event
                                              )
                                            },
                                            inputError: _vm.handleInputError,
                                          },
                                        }),
                                      ]
                                    : [
                                        _c(field.type, {
                                          directives: [
                                            {
                                              name: "sanitize",
                                              rawName: "v-sanitize",
                                              value: field.description,
                                              expression: "field.description",
                                            },
                                          ],
                                          key: `tag_${field.id}`,
                                          tag: "component",
                                          attrs: { id: field.id || null },
                                        }),
                                      ],
                                ]
                              : _vm._e(),
                          ]
                        }),
                        _vm._v(" "),
                        _vm.formSelected.endpoint.includes("login")
                          ? _c(
                              "div",
                              { staticClass: "auth-links reset-password" },
                              [
                                _c("a", { attrs: { href: "/forgot" } }, [
                                  _vm._v("Reset Password"),
                                ]),
                              ]
                            )
                          : _vm._e(),
                      ]
                    : _vm._e(),
                ],
                2
              )
            : _c("div", [
                _vm._formEditorEnabled &&
                _vm.formSelected &&
                _vm.formSelected.success_message
                  ? _c("div", {
                      directives: [
                        {
                          name: "sanitize",
                          rawName: "v-sanitize",
                          value: _vm.formSelected.success_message,
                          expression: "formSelected.success_message",
                        },
                      ],
                      staticClass: "success-text",
                    })
                  : _c("div", [
                      _vm._v(
                        "\n        Our team will review your request, typically within 2-3 business days,\n        and notify you of next steps via email.\n      "
                      ),
                    ]),
              ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }