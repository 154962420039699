var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "admin-navigation" } },
    [
      _c(
        "b-navbar",
        {
          attrs: {
            toggleable: "lg",
            "aria-label": _vm.$t("layout_regions_admin-nav_label"),
          },
        },
        [
          _c(
            "b-navbar-brand",
            { attrs: { to: _vm.dashboardLink } },
            [_c("BeamLogo")],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            [
              _c(
                "b-navbar-toggle",
                { ref: "navTrigger", attrs: { target: "nav-collapse" } },
                [_vm._v("\n        Menu\n      ")]
              ),
              _vm._v(" "),
              _c(
                "b-collapse",
                { attrs: { id: "nav-collapse", "is-nav": "" } },
                [
                  _c(
                    "b-navbar-nav",
                    { staticClass: "admin-links" },
                    [
                      _vm._isSuperAdminUser
                        ? _c(
                            "b-nav-item",
                            {
                              attrs: {
                                id: "dashboard",
                                to: {
                                  path: "/admin",
                                  query: _vm._query,
                                },
                              },
                            },
                            [
                              _c("Icon", { attrs: { id: "cms_dashboard" } }),
                              _vm._v(" "),
                              _c("span", { staticClass: "label" }, [
                                _vm._v("Dashboard"),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.$userCan("users.update")
                        ? _c(
                            "b-nav-item",
                            {
                              attrs: {
                                id: "users",
                                to: {
                                  path: "/admin/users",
                                  query: _vm._query,
                                },
                              },
                            },
                            [
                              _c("Icon", { attrs: { id: "cms_user" } }),
                              _vm._v(" "),
                              _c("span", { staticClass: "label" }, [
                                _vm._v("Users"),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.$userCan("pages.update") ||
                      _vm.$userCan("homepages.update")
                        ? _c(
                            "b-nav-item",
                            {
                              attrs: {
                                id: "menu-items",
                                to: {
                                  path: "/admin/menuitems/content",
                                  query: _vm._query,
                                },
                              },
                            },
                            [
                              _c("Icon", {
                                attrs: { id: "cms_pages_outline" },
                              }),
                              _vm._v(" "),
                              _c("span", { staticClass: "label" }, [
                                _vm._v("Pages"),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.$userCan("libraries.view")
                        ? _c(
                            "b-nav-item",
                            {
                              attrs: {
                                id: "libraries",
                                to: {
                                  path: "/admin/libraries/unassigned",
                                  query: _vm._query,
                                },
                              },
                            },
                            [
                              _c("Icon", { attrs: { id: "cms_folder" } }),
                              _vm._v(" "),
                              _c("span", { staticClass: "label" }, [
                                _vm._v("Assets"),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm._showcaseIsEnabled && _vm.$userCan("showcases.manage")
                        ? _c(
                            "b-nav-item",
                            {
                              attrs: {
                                id: "showcase",
                                to: {
                                  path: "/admin/showcase/1",
                                  query: _vm._query,
                                },
                              },
                            },
                            [
                              _c("Icon", { attrs: { id: "cms_showcase" } }),
                              _vm._v(" "),
                              _c("span", { staticClass: "label" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "features_showcase_general_label",
                                      "en"
                                    )
                                  )
                                ),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm._helpdeskIsEnabled && _vm.$userCan("helpdesk.update")
                        ? _c(
                            "b-nav-item",
                            {
                              attrs: {
                                id: "helpdesk",
                                to: {
                                  path: "/admin/helpdesk/open",
                                  query: _vm._query,
                                },
                              },
                            },
                            [
                              _c("Icon", { attrs: { id: "cms_help_desk" } }),
                              _vm._v(" "),
                              _c("span", { staticClass: "label" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "features_helpdesk_general_label",
                                      "en"
                                    )
                                  )
                                ),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm._formEditorEnabled && _vm.$userCan("forms.update")
                        ? _c(
                            "b-nav-item",
                            {
                              attrs: {
                                id: "settings",
                                to: {
                                  path: "/admin/formeditor",
                                  query: _vm._query,
                                },
                              },
                            },
                            [
                              _c("Icon", { attrs: { id: "cms_forms" } }),
                              _vm._v(" "),
                              _c("span", { staticClass: "label" }, [
                                _vm._v("Forms"),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm._dxgIsEnabled && _vm.$userCan("dxg.update")
                        ? _c(
                            "b-nav-item",
                            {
                              attrs: {
                                id: "dxg",
                                to: {
                                  path: "/admin/dxg",
                                  query: _vm._query,
                                },
                              },
                            },
                            [
                              _c("Icon", { attrs: { id: "cms_code" } }),
                              _vm._v(" "),
                              _c("span", { staticClass: "label" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("features_dxg_general_label", "en")
                                  )
                                ),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm._dataTablesEnabled
                        ? _c(
                            "b-nav-item",
                            {
                              attrs: {
                                id: "data-tables",
                                to: {
                                  path: "/admin/data-tables",
                                  query: _vm._query,
                                },
                              },
                            },
                            [
                              _c("icon", { attrs: { id: "cms_forms_alt" } }),
                              _vm._v(" "),
                              _c("span", { staticClass: "label" }, [
                                _vm._v("Data Tables"),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.$userCan("reporting.view")
                        ? _c(
                            "b-nav-item",
                            {
                              attrs: {
                                id: "reporting",
                                to: {
                                  path: "/admin/reporting",
                                  query: _vm._query,
                                },
                              },
                            },
                            [
                              _c("Icon", { attrs: { id: "cms_reports" } }),
                              _vm._v(" "),
                              _c("span", { staticClass: "label" }, [
                                _vm._v("Reports"),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm._notificationsAreEnabled && _vm._isAdminUser
                        ? _c(
                            "b-nav-item",
                            {
                              attrs: {
                                id: "notifications",
                                to: "/admin/notifications",
                              },
                            },
                            [
                              _c("icon", { attrs: { id: "cms_notification" } }),
                              _vm._v(" "),
                              _c("span", { staticClass: "label" }, [
                                _vm._v("Notifications"),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm._isSuperAdminUser
                        ? _c(
                            "b-nav-item",
                            {
                              attrs: {
                                id: "settings",
                                to: {
                                  path: "/admin/settings/appearance",
                                  query: _vm._query,
                                },
                              },
                            },
                            [
                              _c("Icon", { attrs: { id: "cms_settings" } }),
                              _vm._v(" "),
                              _c("span", { staticClass: "label" }, [
                                _vm._v("Settings"),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-navbar-nav",
            {
              staticClass: "ml-auto nav-menu",
              attrs: {
                "aria-label": _vm.$t(
                  "layout_regions_admin-nav-secondary_label"
                ),
              },
            },
            [
              _c(
                "b-nav-item-dropdown",
                {
                  attrs: { right: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "button-content",
                      fn: function () {
                        return [
                          _c("span", { staticClass: "titlecase" }, [
                            _vm._v(
                              _vm._s(
                                _vm.currentUser.first_name +
                                  " " +
                                  _vm.currentUser.last_name
                              )
                            ),
                          ]),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [
                  _vm._v(" "),
                  _vm._l(_vm.userDropdownItems, function (item, i) {
                    return _c(
                      "b-dropdown-item",
                      {
                        key: i,
                        attrs: { to: _vm.$generateRoute({ name: item.name }) },
                      },
                      [
                        _vm._v(
                          "\n          " + _vm._s(item.label) + "\n        "
                        ),
                      ]
                    )
                  }),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }