export default [
    {
        path: '/admin/formeditor/',
        component: () => import('../components/index.vue'),
        children: [
            {
                path: '',
                name: 'admin.formeditor.index',
                component: () => import('../components/cms/forms.vue')
            },
            {
                path: 'forms',
                name: 'admin.formeditor.forms',
                component: () => import('../components/cms/forms.vue')
            },
            {
                path: 'forms/:formId',
                name: 'admin.formeditor.form',
                component: () => import('../components/cms/form.vue')
            }
        ]
    }
]