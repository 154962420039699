import actions from '@core/store/actions/actions'
import mutations from '@core/store/mutations/mutations'

export default {
  namespaced: true,
  state: {
    showcases: [],
    currentShowcase: null
  },
  mutations,
  actions
}
