import actions from '../actions/actions'
import mutations from '../mutations/mutations'

export default {
  namespaced: true,
  state: {
    config: {},
    theme: {},
    isLoading: false,
    newElementCreated: false,
    searchParameters: null
  },
  mutations,
  actions
}
