var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "scroller", attrs: { id: "sidebar" } }, [
    _c(
      "section",
      [
        _vm.backButtonUrl && _vm.backButtonLabel
          ? _c(
              "RouterLink",
              { staticClass: "back-button", attrs: { to: _vm.backButtonUrl } },
              [
                _c("icon", { attrs: { id: "ic_chevron_left" } }),
                _vm._v(" " + _vm._s(_vm.backButtonLabel) + "\n    "),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "panel" },
          [
            _c("h4", [_vm._v(_vm._s(_vm.pageTitle))]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "sidebar-content",
                class: { "no-padding": _vm.noPadding },
              },
              [_vm._t("sidebar_content")],
              2
            ),
            _vm._v(" "),
            _vm.mergedFormOptions.enableForm
              ? _c(
                  "FormRoot",
                  {
                    staticClass: "sidebar-form",
                    class: _vm.mergedFormOptions.class,
                    attrs: {
                      "form-id": _vm.mergedFormOptions.formId,
                      "submit-button-text":
                        _vm.mergedFormOptions.submitButtonText,
                      "show-cancel-button":
                        _vm.mergedFormOptions.showCancelButton,
                      "enable-submit": _vm.mergedFormOptions.enableSubmit,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "form_buttons",
                          fn: function (slotData) {
                            return [
                              _vm._t("form_buttons", null, null, slotData),
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "sidebar-form-content" },
                      [_vm._t("form_content")],
                      2
                    ),
                  ]
                )
              : _vm._e(),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }