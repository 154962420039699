import { mapState } from 'vuex'
import Localization from '@core/mixins/Localization.js'

export default {
  mixins: [Localization],
  computed: {
    ...mapState({
      menuItems: state => state.NavigationMenuItems.menuItems
    }),
    settingsStore () {
      return this.$store.state.Settings.settings
    },
    userToolbarPosition () {
      return this.settingsStore.find(setting => setting.key === 'user-toolbar-position')?.value ?? 'footer'
    },
    // Filter menu items by locale if localization is published
    menuItemsFilteredByLocale () {
      if (this._localesIsPublished && this.currentLocale) {
        return this.menuItems.filter(item => item.locale_id === this.currentLocale.id)
      }

      return this.menuItems
    },
    navItems () {
      const filteredMenuItems = this.menuItemsFilteredByLocale.filter(item => {
        if (!item.visible || (item.parent_id && checkParentIsInvisible(item.parent_id))) {
          // if the item or any of its parents are hidden, do not include them in the menu list
          return false
        }

        if (item.type === 'page' || item.type === 'external') {
          return item
        } else if (item.type === 'directory' && item.children_tree.length) {
          for (let i = 0; i < item.children_tree.length; i++) {
            if (item.children_tree[i].status === 'PUBLISHED') {
              return item
            }
          }
        }
      })

      if (this.userToolbarPosition === 'nav') {
        filteredMenuItems.push(this.account)
      }

      return filteredMenuItems
    },
    hybridNavItemsDesktop () {
      // Hybrid navigation (Top + Left nav). Chanage all top level folders to pages.
      let topLevelItems = []

      this.navItems.forEach(element => {
        if (element.type === 'external') {
          topLevelItems.push(this.$utilities.deepMerge(element))
        }
        if (element.type === 'page') {
          topLevelItems.push(this.$utilities.deepMerge(element))
        }
        if (element.type === 'directory') {
          // change directory to a page
          let dirPage = this.$utilities.deepMerge(element)
          if (element.userToolbarPosition !== 'nav') {
            dirPage.type = 'page'
            dirPage.children_tree = []
            dirPage.action = this.linkFromChildren(element.children_tree)
          }
          topLevelItems.push(dirPage)
        }
      })

      return topLevelItems
    },
    hybridNavItemsMobile () {
      // Default navigation + Account folder
      let topLevelItems = []

      this.navItems.forEach(element => {
        topLevelItems.push(this.$utilities.deepMerge(element))
      })

      return topLevelItems
    },
    flattenedMenuItems () {
      return this.flattenMenuItems(this.menuItems, [])
    },
    isRelatedParent () {
      const guid = this.$route?.params?.guid ?? false
      if (guid) {
        const currentItem = this.flattenedMenuItems.find(item => item.action === guid)
        if (currentItem && currentItem.active && currentItem.status === 'PUBLISHED') {
          return parseInt(currentItem.top_level_id, 10)
        }
      }
      return false
    },
    account () {
      // helpdesk
      let helpdesk = {}
      helpdesk.action = '/helpdesk'
      helpdesk.label = 'Help Desk'
      helpdesk.parent_id = 1000000
      helpdesk.order = 1
      helpdesk.type = 'external'
      helpdesk.status = 'PUBLISHED'
      helpdesk.children_tree = []
      helpdesk.content_id = null
      helpdesk.entity = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
      helpdesk.expires_at = null
      helpdesk.id = 1000001
      helpdesk.active = false
      helpdesk.is_deleted = false
      helpdesk.is_public = true
      helpdesk.is_visible = true
      helpdesk.new_tab = false
      helpdesk.starts_at = null
      helpdesk.top_level_id = 1000001
      helpdesk.user_groups = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]

      // lightbox
      let lightbox = {}
      lightbox.action = '/lightbox'
      lightbox.label = this.$t('features_collections_general_label')
      lightbox.parent_id = 1000000
      lightbox.order = 1
      lightbox.type = 'external'
      lightbox.status = 'PUBLISHED'
      lightbox.children_tree = []
      lightbox.content_id = null
      lightbox.entity = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
      lightbox.expires_at = null
      lightbox.id = 1000002
      lightbox.active = false
      lightbox.is_deleted = false
      lightbox.is_public = true
      lightbox.is_visible = true
      lightbox.new_tab = false
      lightbox.starts_at = null
      lightbox.top_level_id = 1000002
      lightbox.user_groups = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]

      // bookmarks
      let bookmarks = {}
      bookmarks.action = '/bookmarks'
      bookmarks.label = this.$t('features_favorites_general_label')
      bookmarks.parent_id = 1000000
      bookmarks.order = 2
      bookmarks.type = 'external'
      bookmarks.status = 'PUBLISHED'
      bookmarks.children_tree = []
      bookmarks.content_id = null
      bookmarks.entity = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
      bookmarks.expires_at = null
      bookmarks.id = 1000003
      bookmarks.active = false
      bookmarks.is_deleted = false
      bookmarks.is_public = true
      bookmarks.is_visible = true
      bookmarks.new_tab = false
      bookmarks.starts_at = null
      bookmarks.top_level_id = 1000003
      bookmarks.user_groups = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]

      // profile
      let profile = {}
      profile.action = '/profile'
      profile.label = 'Profile'
      profile.parent_id = 1000000
      profile.order = 3
      profile.type = 'external'
      profile.status = 'PUBLISHED'
      profile.children_tree = []
      profile.content_id = null
      profile.entity = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
      profile.expires_at = null
      profile.id = 1000004
      profile.active = false
      profile.is_deleted = false
      profile.is_public = true
      profile.is_visible = true
      profile.new_tab = false
      profile.starts_at = null
      profile.top_level_id = 1000004
      profile.user_groups = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]

      // logout
      let logout = {}
      logout.action = '/logout'
      logout.label = 'Logout'
      logout.parent_id = 1000000
      logout.order = 4
      logout.type = 'external'
      logout.status = 'PUBLISHED'
      logout.children_tree = []
      logout.content_id = null
      logout.entity = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
      logout.expires_at = null
      logout.id = 1000005
      logout.active = false
      logout.is_deleted = false
      logout.is_public = true
      logout.is_visible = true
      logout.new_tab = false
      logout.starts_at = null
      logout.top_level_id = 1000005
      logout.user_groups = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]

      // Add account insuide of the top Nav
      let account = {}
      account.action = null
      account.label = 'Account'
      account.parent_id = null
      account.order = null
      account.type = 'directory'
      account.status = 'PUBLISHED'
      account.children_tree = []
      account.content_id = null
      account.entity = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
      account.expires_at = null
      account.id = 1000000
      account.active = false
      account.is_deleted = false
      account.is_public = true
      account.is_visible = true
      account.userToolbarPosition = this.userToolbarPosition
      account.new_tab = false
      account.starts_at = null
      account.top_level_id = 1000000
      account.user_groups = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]

      // for logged in user
      if (this.currentUser) {
        if (this._helpdeskIsPublished) {
          account.children_tree.push(helpdesk)
        }

        account.children_tree.push(lightbox)
        account.children_tree.push(bookmarks)
        account.children_tree.push(profile)
        account.children_tree.push(logout)
      }

      return account
    }
  },
  methods: {
    linkFromChildren (children) {
      // Get Link from the first page  or first page inside of a directory
      for (var i = 0; i < children.length; ++i) {
        if (children[i].type === 'page') {
          return children[i].action
        }
        if (children[i].type === 'directory') {
          return children[i].children_tree[0].action
        }
      }
    },
    flattenMenuItems (items, arr) {
      items.forEach((item) => {
        arr.push(item)
        if (item.children_tree.length) {
          return this.flattenMenuItems(item.children_tree, arr)
        }
      })
      return arr
    }
  },
  checkParentIsInvisible (id) {
    const parent = this.menuItems.find(item => parseInt(id, 10) === item.id)

    if (!parent.visible) {
      return true
    }

    if (parent.parent_id) {
      return this.checkParentIsInvisible(parent.parent_id)
    } else {
      return false
    }
  }
}
