var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        role: "presentation",
        width: "20px",
        height: "18px",
        viewBox: "0 0 20 18",
        version: "1.1",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
      },
    },
    [
      _c(
        "g",
        {
          attrs: {
            stroke: "none",
            "stroke-width": "1",
            fill: "none",
            "fill-rule": "evenodd",
          },
        },
        [
          _c(
            "g",
            { attrs: { transform: "translate(-734.000000, -9.000000)" } },
            [
              _c(
                "g",
                { attrs: { transform: "translate(734.000000, 8.000000)" } },
                [
                  _c("g", [
                    _c("rect", {
                      attrs: { x: "0", y: "0", width: "20", height: "20" },
                    }),
                    _vm._v(" "),
                    _c("path", {
                      attrs: {
                        d: "M18.3333333,1.66666667 C18.7935706,1.66666667 19.1666667,2.03976271 19.1666667,2.5 L19.1666667,17.5 C19.1666667,17.9602373 18.7935706,18.3333333 18.3333333,18.3333333 L15,18.3333333 C14.5397627,18.3333333 14.1666667,17.9602373 14.1666667,17.5 L14.1666667,2.5 C14.1666667,2.03976271 14.5397627,1.66666667 15,1.66666667 L18.3333333,1.66666667 Z M11.6666667,5.83333333 C12.126904,5.83333333 12.5,6.20642938 12.5,6.66666667 L12.5,17.5 C12.5,17.9602373 12.126904,18.3333333 11.6666667,18.3333333 L8.33333333,18.3333333 C7.87309604,18.3333333 7.5,17.9602373 7.5,17.5 L7.5,6.66666667 C7.5,6.20642938 7.87309604,5.83333333 8.33333333,5.83333333 L11.6666667,5.83333333 Z M5,10 C5.46023729,10 5.83333333,10.373096 5.83333333,10.8333333 L5.83333333,17.5 C5.83333333,17.9602373 5.46023729,18.3333333 5,18.3333333 L1.66666667,18.3333333 C1.20642938,18.3333333 0.833333333,17.9602373 0.833333333,17.5 L0.833333333,10.8333333 C0.833333333,10.373096 1.20642938,10 1.66666667,10 L5,10 Z M10.8333333,7.5 L9.16666667,7.5 L9.16666667,16.6666667 L10.8333333,16.6666667 L10.8333333,7.5 Z M4.16666667,11.6666667 L2.5,11.6666667 L2.5,16.6666667 L4.16666667,16.6666667 L4.16666667,11.6666667 Z M17.5,3.33333333 L15.8333333,3.33333333 L15.8333333,16.6666667 L17.5,16.6666667 L17.5,3.33333333 Z",
                        fill: "#fff",
                      },
                    }),
                  ]),
                ]
              ),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }