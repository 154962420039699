var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        role: "presentation",
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 21.3 16",
        "enable-background": "new 0 0 21.3 16",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M19.9 7.8l-4.9 4.9c-.8.9-2 1.3-3.2 1.3-1.2 0-2.3-.4-3.2-1.3-1.8-1.8-1.8-4.7 0-6.5l1.8-1.8 1.1 1.1-1.8 1.8c-1.2 1.2-1.2 3.1 0 4.2 1.2 1.2 3.1 1.2 4.2 0l4.9-4.9c1.2-1.2 1.2-3.1 0-4.2-.6-.6-1.3-.9-2.1-.9s-1.6.3-2.1.9l-.8.8-1.1-1.1.8-.8c1.8-1.8 4.7-1.8 6.5 0 1.7 1.8 1.7 4.7-.1 6.5zm-13.2 5.7c-1.2 1.2-3.1 1.2-4.2 0-1.2-1.2-1.2-3.1 0-4.2l4.9-4.9c.6-.6 1.3-.9 2.1-.9s1.6.3 2.1.9c1.2 1.2 1.2 3.1 0 4.2l-1.8 1.8 1.1 1.1 1.8-1.8c1.8-1.8 1.8-4.7 0-6.5-.9-.7-2-1.2-3.3-1.2s-2.3.5-3.2 1.3l-4.9 4.9c-1.8 1.8-1.8 4.7 0 6.5.9.9 2.1 1.3 3.3 1.3s2.3-.4 3.2-1.3l.8-.8-1.1-1.1-.8.7z",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }