export const SET_LOADING_STATUS = 'SET_LOADING_STATUS'
export const SET_SUCCESS = 'SET_SUCCESS'
export const SET_ERRORS = 'SET_ERRORS'
export const SET_INFO = 'SET_INFO'
export const SET_PROPERTY = 'SET_PROPERTY'
export const SET_NESTED_PROPERTY = 'SET_NESTED_PROPERTY'
export const UPDATE_NESTED_PROPERTY = 'UPDATE_NESTED_PROPERTY'
export const DELETE_PROPERTY = 'DELETE_PROPERTY'
export const DELETE_NESTED_PROPERTY = 'DELETE_NESTED_PROPERTY'
export const SET_PROPERTY_CREATED = 'SET_PROPERTY_CREATED'
