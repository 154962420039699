var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "tr",
    _vm._l(_vm.activeColumns, function (column) {
      return _c("data-cell", {
        key: column.id,
        attrs: { column: column, "row-data": _vm.row },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }