var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "vc-slider",
      attrs: { role: "application", "aria-label": "Slider color picker" },
    },
    [
      _c(
        "div",
        { staticClass: "vc-slider-hue-warp" },
        [
          _c("hue", {
            on: { change: _vm.hueChange },
            model: {
              value: _vm.colors,
              callback: function ($$v) {
                _vm.colors = $$v
              },
              expression: "colors",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "vc-slider-swatches", attrs: { role: "group" } },
        _vm._l(_vm.normalizedSwatches, function (swatch, index) {
          return _c(
            "div",
            {
              key: index,
              staticClass: "vc-slider-swatch",
              attrs: {
                "data-index": index,
                "aria-label": "color:" + _vm.colors.hex,
                role: "button",
              },
              on: {
                click: function ($event) {
                  return _vm.handleSwClick(index, swatch)
                },
              },
            },
            [
              _c("div", {
                staticClass: "vc-slider-swatch-picker",
                class: {
                  "vc-slider-swatch-picker--active": _vm.isActive(
                    swatch,
                    index
                  ),
                  "vc-slider-swatch-picker--white": swatch.l === 1,
                },
                style: {
                  background:
                    "hsl(" +
                    _vm.colors.hsl.h +
                    ", " +
                    swatch.s * 100 +
                    "%, " +
                    swatch.l * 100 +
                    "%)",
                },
              }),
            ]
          )
        }),
        0
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }