// import ('@client/scss/entry/index.scss')

import Vue from "vue";
import VueRouter from "vue-router";

import CmsRoutes from "@cms/routes";
import CoreRoutes from "@core/router/routes";

import {
  checkEnvironment,
  checkEntityParam,
  checkLocaleParam,
  checkLogout,
  checkAuthentication,
  importCss,
  checkMfaAccess,
} from "./route-guards";

Vue.use(VueRouter);

/** * ********** Scroll Position ********** ***/

const routerScrollBehavior = function (to, from, pos) {
  if (pos) {
    return pos;
  } else if (to.meta.scrollTop) {
    const top = isNaN(to.meta.scrollTop) ? 0 : parseInt(to.meta.scrollTop, 10);
    return { x: 0, y: top };
  } else {
    return { x: 0, y: 0 };
  }
};

/** * ********** Duplicate Navigation Error ********** ***/
// Hacky fix for router's duplicate navigation "error"
// If navigation errors start occurring, this will need to be deleted
const oldPushMethod = VueRouter.prototype.push;

VueRouter.prototype.push = function push(location) {
  return new Promise((resolve, reject) => {
    oldPushMethod.call(
      this,
      location,
      () => {
        resolve(this.currentRoute);
      },
      (error) => {
        if (error.name === "NavigationDuplicated") {
          resolve(this.currentRoute);
        } else {
          reject(error);
        }
      }
    );
  });
};

/** * ********** Initialize Router ********** ***/

export default {
  init(env) {
    const AppRouter = new VueRouter({
      mode: "history",
      routes: env === "core" ? CoreRoutes : CmsRoutes,
      linkActiveClass: "ancestor-link",
      linkExactActiveClass: "current-link",
      scrollBehavior: routerScrollBehavior,
    });

    AppRouter.beforeEach(checkLogout);
    AppRouter.beforeEach(checkEnvironment);
    AppRouter.beforeEach(checkEntityParam);
    AppRouter.beforeEach(checkAuthentication);

    AppRouter.beforeEach((to, from, next) => {
      if (to.meta.requiresMFA) {
        checkMfaAccess(to, from, next);
      } else {
        next();
      }
    });

    AppRouter.beforeEach(checkLocaleParam);

    if (env === "core") {
      AppRouter.afterEach(importCss);
    }

    return AppRouter;
  },
};
