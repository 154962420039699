export default {
  path: "asset-viewer",
  name: "asset-viewer.index",
  component: () =>
    import(
      /* webpackChunkName: "main-asset-queue-index" */
      "@core/components/assets/asset-viewer.vue"
    ),
  children: [
    {
      path: ":id",
      name: "asset-viewer.asset",
      component: () => {
        "@core/components/assets/asset-viewer.vue";
      },
    },
  ],
};
