/**
 * Icon subsets are in the assets folder, add new icons there.
 * ALL SUBSETS MUST HAVE MATCHING COMPONENTS!!!
 * NO ICON SHOULD EXIST IN ONE SUBSET WITHOUT EXISTING IN ALL OF THEM
 *
 * Any custom icons should be kept at "icons.custom" in the theme.js file
 * in the client's folder
 *
 * (https://monigleassociates.atlassian.net/wiki/spaces/B/pages/401735713/Iconography)
 * Icon Data Reference: https://material.io/resources/icons/?style=baseline
 * The "cms" object contains the BEAM icons and should NEVER BE CUSTOMIZED!!!
 * the "legacy" object contains older icons used on client sites.
 * The four subsets are "default", "outline", "rounded", and "sharp"
 * The preferred subset is specified in the client theme.js -> "icons.type" string
 */

import './scss/_index.scss'

import { Icon, IconSet, IconLibrary } from './components'
import Iconography from './store/Iconography'
import { mapState } from 'vuex'
import Theme from '@client/theme.js'

// Legacy, remove once new CMS icons are implemented
import Icons from './_legacy/icons.vue'

export default {
  install (Vue, options) {

    // Install global icon components
    // <icon>, <icon-set> & <icon-library> can be used in any template
    Vue.component('Icon', Icon)
    Vue.component('IconLibrary', IconLibrary)
    Vue.component('IconSet', IconSet)

    // Legacy, remove once new CMS icons are implemented
    Vue.component('Icons', Icons)

    // Register the Iconography store module
    window.AppStore.registerModule('Iconography', Iconography)

    // Populate icons to the Iconography store module
    const loadIcons = function () {
      const cms = require('./icons/subsets/cms')
      const legacy = require('./icons/subsets/legacy')
      const custom = Theme?.icons?.custom ?? {}
      let icons = {}

      let subset = Theme?.icons?.type ?? 'default'
      subset = subset.toLowerCase()

      switch (subset) {
        case 'outline':
          icons = require('./icons/subsets/outline')
          break
        case 'rounded':
          icons = require('./icons/subsets/rounded')
          break
        case 'sharp':
          icons = require('./icons/subsets/sharp')
          break
        default:
          icons = require('./icons/subsets/default')
      }

      // Merge all icons into a single object
      const iconography = Object.assign(
        icons,
        legacy,
        custom,
        cms
      )

      // Load full icon set into the store
      window.AppStore.state.Iconography.subset = subset
      window.AppStore.state.Iconography.iconography = iconography
    }

    loadIcons()

    // Install global mixin for global icon ID values for Settings > Iconography
    Vue.mixin({
      computed: {
        ...mapState({
          iconography: state => state.Iconography.iconography,
          theme: state => state.Theme.theme,
          settings: state => state.Settings.settings
        }),
        settingsIconographyObj () {
          return this.settings.find(item => item.key === 'global-iconography')
        },
        settingsIconography () {
          return this.settingsIconographyObj ? JSON.parse(this.settingsIconographyObj.value) : null
        },
        _collections_view () {
          return this.settingsIconography?.collections_view ?? 'ic_collections'
        },
        _collections_add () {
          return this.settingsIconography?.collections_add ?? 'ic_collections'
        },
        _download_get () {
          return this.settingsIconography?.download_get ?? 'ic_download'
        },
        _favorites_add () {
          return this.settingsIconography?.favorites_add ?? 'ic_star_border'
        },
        _favorites_view () {
          return this.settingsIconography?.favorites_view ?? 'ic_star'
        },
        _helpdesk_view () {
          return this.settingsIconography?.helpdesk_view ?? 'ic_help'
        },
        _logout_icon () {
          return this.settingsIconography?.logout_icon ?? 'ic_exit_to_app'
        },
        _profile_view () {
          return this.settingsIconography?.profile_view ?? 'ic_account_circle'
        },
        _share_view () {
          return this.settingsIconography?.share_view ?? 'ic_share'
        }
      }
    })
  }
}
