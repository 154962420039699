var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        role: "presentation",
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 20 20",
        "enable-background": "new 0 0 20 20",
      },
    },
    [
      _c("path", {
        attrs: {
          fill: "#008ACB",
          "fill-rule": "nonzero",
          d: "M19.69 18.194l-5.541-5.542a7.882 7.882 0 0 0-1.088-10.707 7.877 7.877 0 0 0-10.753.363 7.882 7.882 0 0 0-.364 10.756 7.877 7.877 0 0 0 10.705 1.089l5.54 5.542a1.074 1.074 0 0 0 1.5 0 1.062 1.062 0 0 0 0-1.501zM2.18 7.907a5.729 5.729 0 1 1 11.457-.002 5.729 5.729 0 0 1-11.458.002z",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }