var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "form-input radio-array-input",
      class: { styled: _vm.styled },
      attrs: { role: "group", "aria-label": _vm.label },
    },
    [
      _vm.label
        ? _c("h6", [
            _vm._v("\n    " + _vm._s(_vm.label) + "\n    "),
            _vm.required && !_vm.hideAsterisk
              ? _c("span", { staticClass: "required" }, [
                  _vm._v("\n      *\n    "),
                ])
              : _vm._e(),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.detailsLabel
        ? _c("small", { staticClass: "details-label" }, [
            _vm._v("\n    " + _vm._s(_vm.detailsLabel) + "\n  "),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.list, function (value, index) {
        return [
          _c("div", { key: index, staticClass: "radio-input" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.input,
                  expression: "input",
                },
              ],
              attrs: {
                id: `${_vm.id}_${index}`,
                name: _vm.name,
                role: "radio",
                type: "radio",
              },
              domProps: {
                value:
                  Array.isArray(value) || typeof value === "string"
                    ? index
                    : value.id,
                checked: _vm._q(
                  _vm.input,
                  Array.isArray(value) || typeof value === "string"
                    ? index
                    : value.id
                ),
              },
              on: {
                change: [
                  function ($event) {
                    _vm.input =
                      Array.isArray(value) || typeof value === "string"
                        ? index
                        : value.id
                  },
                  _vm.changeEvent,
                ],
              },
            }),
            _vm._v(" "),
            _c("label", { attrs: { for: `${_vm.id}_${index}` } }, [
              Array.isArray(value) || typeof value === "string"
                ? _c("span", [
                    _vm._v("\n          " + _vm._s(value) + "\n        "),
                  ])
                : _c("span", [
                    _vm._v("\n          " + _vm._s(value.label) + "\n        "),
                  ]),
              _vm._v(" "),
              _vm.styled
                ? _c(
                    "span",
                    { staticClass: "check" },
                    [_c("icon", { attrs: { id: "cms_check_mark" } })],
                    1
                  )
                : _vm._e(),
            ]),
          ]),
        ]
      }),
      _vm._v(" "),
      _vm.required && _vm.error
        ? _c("div", { staticClass: "error" }, [
            _vm._v("\n    " + _vm._s(_vm.errorMsg) + "\n  "),
          ])
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }