var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.slideData.headlineBool || _vm.slideData.captionBool
    ? _c(
        "b-container",
        { staticClass: "carousel-caption" },
        [
          _vm.slideData.headlineBool && _vm.slideData.headline.length
            ? [_c("h2", [_vm._v(_vm._s(_vm.slideData.headline))])]
            : _vm._e(),
          _vm._v(" "),
          _vm.slideData.captionBool && _vm.slideData.caption.length
            ? [_c("h3", [_vm._v(_vm._s(_vm.slideData.caption))])]
            : _vm._e(),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }