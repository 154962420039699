var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        role: "presentation",
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 20 20",
        "enable-background": "new 0 0 20 20",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M9 18.5h2v1.5h-2v-1.5zm-7.5-1.5h-1.5v3h3v-1.5h-1.5v-1.5zm-1.5-17v3h1.5v-1.5h1.5v-1.5h-3zm1.5 13h-1.5v2h1.5v-2zm9.5-13h-2v1.5h2v-1.5zm-9.5 9h-1.5v2h1.5v-2zm5.5-9h-2v1.5h2v-1.5zm-5.5 5h-1.5v2h1.5v-2zm3.5 15h2v-1.5h-2v1.5zm13.5-9h1.5v-2h-1.5v2zm-5.5 9h2v-1.5h-2v1.5zm5.5-5h1.5v-2h-1.5v2zm0-15h-1.5v1.5h1.5v1.5h1.5v-3h-1.5zm0 7h1.5v-2h-1.5v2zm-13.5.5h4v8h2v-8h4v-2h-10v2zm8-6h2v-1.5h-2v1.5zm5.5 17h-1.5v1.5h3v-3h-1.5v1.5z",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }