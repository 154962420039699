import { gsap } from 'gsap'
import { mapState } from 'vuex'
import ScrollTrigger from 'gsap/ScrollTrigger'

export default {
  install (Vue) {
    Vue.mixin({
      computed: {
        ...mapState({
          themeAnimation: state => state.Theme.theme.themeAnimation,
          storeAnimationTypes: state => state.Global.scrollTriggerAnimationTypes
        })
      },
      methods: {
        // greensock
        batch (targets, vars) {
          let varsCopy = {}
          let interval = vars.interval || 0.1
          let proxyCallback = (type, callback) => {
            let batch = []
            let delay = gsap.delayedCall(interval, () => { callback(batch); batch.length = 0 }).pause()
            return self => {
              batch.length || delay.restart(true)
              batch.push(self.trigger)
              vars.batchMax && vars.batchMax <= batch.length && delay.progress(1)
            }
          }
          let p
          for (p in vars) {
            varsCopy[p] = (~p.indexOf('Enter') || ~p.indexOf('Leave')) ? proxyCallback(p, vars[p]) : vars[p]
          }

          gsap.utils.toArray(targets).forEach(target => {
            let config = {}
            for (p in varsCopy) {
              config[p] = varsCopy[p]
            }
            config.trigger = target
            ScrollTrigger.create(config)
          })
        },
        // greensock
        animateFrom (elem, direction) {
          direction = direction | 1

          var x = 0
          // var y = direction * this.animationDistance()
          var y = direction * parseInt(elem.dataset.animationDistance)

          if (elem.dataset.animationClasses.includes('gs-left-to-right')) {
            // x = -this.animationDistance()
            x = -parseInt(elem.dataset.animationDistance)
            y = 0
          } else if (elem.dataset.animationClasses.includes('gs-right-to-left')) {
            // x = this.animationDistance()
            x = parseInt(elem.dataset.animationDistance)
            y = 0
          }

          // duration: this.animationDuration(),
          gsap.fromTo(elem, { x: x, y: y, autoAlpha: 0 }, {
            duration: parseFloat(elem.dataset.animationDuration),
            x: 0,
            y: 0,
            autoAlpha: 1,
            ease: 'expo',
            overwrite: 'auto'
          })
        },
        // greensock
        addAnimationClasses (selector, classArr) {
          gsap.utils.toArray(selector).forEach(elem => elem.classList.add(...classArr))
        },
        $animationFactory (elem) {
          const themeAnimation = this.$store.state.Theme.theme.themeAnimation
          const localTarget = elem.elements && elem.target !== 'sectionTitle'
            ? 'section'
            : 'row'
          const globalTarget = themeAnimation?.target ?? false
          const localTargetData = localTarget === 'section'
            ? elem.data && elem.data.animations ? elem.data.animations : {}
            : elem.data && elem.data.animations && elem.data.animations[0] ? elem.data.animations[0] : {}
          const localTargetDataExists = Object.keys(localTargetData).length
          const storeAnimationTypes = this.$store.state.Global.scrollTriggerAnimationTypes
          const localAnimationTypeIndex = localTargetData && localTargetData.type
          const globalAnimationTypeIndex = this.$store.state.Theme.theme.themeAnimation?.type ?? 0
          const globalAnimationSwitch = this.$store.state.Theme.theme.themeAnimation?.switch ?? false
          const scrollTriggerTypes = storeAnimationTypes.map(storeType => {
            storeType = storeType.toLowerCase()
            return storeType !== 'none'
              ? 'gs-' + storeType.replace(/\s/g, '-')
              : storeType
          })
          let animationType = ''

          if (Number.isInteger(localAnimationTypeIndex) && localAnimationSwitch()) {
            animationType = scrollTriggerTypes[localAnimationTypeIndex]
          } else if (Number.isInteger(globalAnimationTypeIndex)) {
            animationType = scrollTriggerTypes[globalAnimationTypeIndex]
          }

          function localAnimationSwitch () {
            return !!(localTargetDataExists && localTargetData.switch)
          }

          function animationDuration () {
            let localDuration = localTargetData.duration
            if (localAnimationSwitch() && localTargetDataExists && localDuration) {
              return parseFloat(localDuration)
            } else if (themeAnimation && themeAnimation.duration) {
              return parseFloat(themeAnimation.duration)
            } else {
              return 1.5
            }
          }

          function animationDistance () {
            let localDistance = localTargetData.distance
            if (localAnimationSwitch() && localTargetDataExists && localDistance) {
              return parseInt(localDistance)
            } else if (themeAnimation && themeAnimation.distance) {
              return parseInt(themeAnimation.distance)
            } else {
              return 100
            }
          }

          function classes () {
            if (themeAnimation && themeAnimation.switch) {
              return animationType !== 'none'
                ? `gs-reveal ${animationType}`
                : ''
            }
          }

          function assignClasses () {
            if ((globalTarget === localTarget) && globalAnimationSwitch) {
              return classes()
            }
            return ''
          }

          function assignDistance () {
            if ((globalTarget === localTarget) && globalAnimationSwitch) {
              return animationDistance()
            }
            return ''
          }

          function assignDuration () {
            if ((globalTarget === localTarget) && globalAnimationSwitch) {
              return animationDuration()
            }
            return ''
          }

          return {
            assignDistance: assignDistance(),
            assignDuration: assignDuration(),
            assignClasses: assignClasses()
          }
        },
        $initScrollToAnimation () {
          const _this = this
          const globalTarget = this.themeAnimation?.target
          const targetWithAnimation = function () {
            if (globalTarget && _this.themeAnimation?.switch && _this.$refs[globalTarget]) {
              let targetList = _this.$refs[globalTarget].filter(elem => {
                let myElem = elem.$el ? elem.$el : elem
                return myElem.dataset.animationClasses.includes('gs-reveal')
              })
              let sectionHeadlines = _this.$refs.sectionblock
                ? [_this.$refs.sectionblock]
                : []
              // Must merge section headlines if global row animation
              // selected.  Right now we don't have the ability
              // to override local level headline animation
              // settings so they must inherit global.
              let targetMerge = globalTarget === 'section'
                ? targetList
                : [...targetList, ...sectionHeadlines]
              return targetMerge
            }
            return null
          }

          if (this.themeAnimation?.switch) {
            _this.$nextTick(() => {
              if (targetWithAnimation()) {
                gsap.registerPlugin(ScrollTrigger)
                runScrollToAnimation()
              }
            })
          }

          function animationStart (elemClasses) {
            if (elemClasses.includes('gs-top-to-bottom') || elemClasses.includes('gs-left-to-right')) {
              return [-1, 1]
            } else {
              return [1, -1]
            }
          }

          function hide (elem) {
            gsap.set(elem, { autoAlpha: 0 })
          }

          function runScrollToAnimation () {
            targetWithAnimation().forEach(elem => {
              const globalTargetElem = elem.$el
                ? elem.$el
                : elem
              const datasetClasses = globalTargetElem.dataset.animationClasses
              hide(globalTargetElem) // assure that the element is hidden when scrolled into view

              ScrollTrigger.create({
                trigger: globalTargetElem,
                start: 'top 75%',
                onEnter: function () { _this.animateFrom(globalTargetElem, animationStart(datasetClasses)[0]) },
                onEnterBack: function () { _this.animateFrom(globalTargetElem, animationStart(datasetClasses)[1]) },
                onLeave: function () { hide(globalTargetElem) } // assure that the element is hidden when scrolled into view
              })
            })

            setTimeout(() => {
              ScrollTrigger.refresh()
            }, 2000)
          }
        },
        $destroyAnimationInstance () {
          ScrollTrigger.kill()
        }
      }
    })
  }
}
