__webpack_nonce__ = process.env.CSP_NONCE.toString('base64');

import Vue from 'vue'
import App from '@theme/Index.vue'

import {
  Globals,
  InitAnalytics,
  InitExtensions,
  InitI18n,
  InitPlugins,
  InitSentry,
  InitStore,
  SetParameters } from '@core/js'

// Mixins
import Accessibility from '@core/mixins/Accessibility.js'
Vue.mixin(Accessibility)

const env = 'core'

async function initApp() {
  Globals.init(env)
  // Prevent any layouts from rendering when going to the SSO URL
  Globals.checkSsoBypass()

  await InitStore({ env })

  InitI18n()

  SetParameters.locale()
  SetParameters.entity()

  // Initialize router
  const Router = require('@core/router').default
  const router = Router.init(env)

  InitSentry(router)

  InitPlugins({ env })
  InitExtensions({ env, router })
  InitAnalytics.init()

  // eslint-disable-line no-new
  window.App = new Vue({
    i18n: window.AppI18n,
    store: window.AppStore,
    router,
    http: {
      headers: {
        Accept: 'text/json,text/text'
      }
    },
    el: '#app',
    render: (h) => h(App)
  })
}

initApp()
