var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "module info-card-module" },
    [
      _c("module-controls", {
        attrs: {
          placeholder: _vm.moduleLabel,
          "action-buttons": _vm._actionButtons,
        },
        on: {
          "on-edit": _vm.$openModal,
          "on-remove": _vm.$deleteContentModuleConfirm,
        },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "module_content" },
        [
          _vm._isAdminArea && _vm.isEmpty
            ? _c("div", { staticClass: "empty" }, [
                _vm._v("\n      This module is empty. Start editing!\n    "),
              ])
            : _vm._e(),
          _vm._v(" "),
          !_vm.isEmpty
            ? [
                _c(
                  "div",
                  {
                    staticClass: "info-card",
                    class: { "link-block": _vm.moduleData.linkBlock },
                  },
                  [
                    _vm.moduleData.linkBlock
                      ? [
                          !_vm.moduleData.linkType
                            ? _c(
                                "a",
                                {
                                  staticClass: "link",
                                  attrs: {
                                    "aria-label": _vm.linkLabel,
                                    href: _vm.moduleData.linkUrl,
                                    target: _vm.moduleData.newTab
                                      ? "_blank"
                                      : "_self",
                                  },
                                },
                                [_vm._v(_vm._s(_vm.moduleData.linkTitle))]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.moduleData.linkType === 1
                            ? _c(
                                "a",
                                {
                                  staticClass: "link",
                                  attrs: {
                                    "aria-label": _vm.linkLabel,
                                    href: "#",
                                    target: "_self",
                                  },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.openVideoModal.apply(
                                        null,
                                        arguments
                                      )
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.moduleData.linkTitle))]
                              )
                            : _vm._e(),
                        ]
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.background
                      ? _c(
                          "div",
                          {
                            staticClass: "image",
                            style: {
                              backgroundImage: `url('${_vm.background}')`,
                              "background-position": "center",
                            },
                          },
                          [
                            _vm.moduleData.linkBlock &&
                            _vm.moduleData.linkType === 1
                              ? _c("icon", {
                                  attrs: { id: "ic_play_circle_filled" },
                                })
                              : _vm._e(),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "info" },
                      [
                        _vm.moduleData.label
                          ? _c("div", { staticClass: "label" }, [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.moduleData.label) +
                                  "\n          "
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c("div", { staticClass: "info-content" }, [
                          _vm.moduleData.headline
                            ? _c("div", { staticClass: "title" }, [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.moduleData.headline) +
                                    "\n            "
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.moduleData.description
                            ? _c("div", { staticClass: "description" }, [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.moduleData.description) +
                                    "\n            "
                                ),
                              ])
                            : _vm._e(),
                        ]),
                        _vm._v(" "),
                        !_vm.moduleData.linkBlock
                          ? [
                              !_vm.moduleData.linkType
                                ? _c(
                                    "a",
                                    {
                                      staticClass: "link",
                                      attrs: {
                                        "aria-label": _vm.linkLabel,
                                        href: _vm.moduleData.linkUrl,
                                        target: _vm.moduleData.newTab
                                          ? "_blank"
                                          : "_self",
                                      },
                                    },
                                    [_vm._v(_vm._s(_vm.moduleData.linkTitle))]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.moduleData.linkType === 1
                                ? _c(
                                    "a",
                                    {
                                      staticClass: "link",
                                      attrs: {
                                        "aria-label": _vm.linkLabel,
                                        href: "#",
                                        target: "_self",
                                      },
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.openVideoModal.apply(
                                            null,
                                            arguments
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v(_vm._s(_vm.moduleData.linkTitle))]
                                  )
                                : _vm._e(),
                            ]
                          : _vm._e(),
                      ],
                      2
                    ),
                  ],
                  2
                ),
              ]
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }