var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "popular module_content",
      class: [
        { "accordion-open": _vm.accordionOpen && _vm.onMobileSize },
        { "accordion-close": !_vm.accordionOpen && _vm.onMobileSize },
      ],
      on: { click: _vm.mobileClick },
    },
    [
      _vm.onMobileSize
        ? _c("Icons", { staticClass: "chevron", attrs: { name: "down" } })
        : _vm._e(),
      _vm._v(" "),
      _vm.iconUrl
        ? _c("img", {
            staticClass: "popular-icon",
            attrs: { src: _vm.iconUrl, alt: _vm.title },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("h4", [_vm._v(_vm._s(_vm.title))]),
      _vm._v(" "),
      _vm.popularFiltered.length
        ? _c(
            "ul",
            { staticClass: "list-unstyled", class: _vm.display },
            _vm._l(_vm.popularFiltered, function (page, index) {
              return _c(
                "li",
                { key: index },
                [
                  _c(
                    "router-link",
                    {
                      class: _vm.buttonClass,
                      attrs: {
                        to: _vm.$generateRoute({
                          name: "content.page",
                          params: {
                            guid: page.action,
                          },
                        }),
                      },
                    },
                    [_vm._v("\n        " + _vm._s(page.label) + "\n      ")]
                  ),
                ],
                1
              )
            }),
            0
          )
        : _c("p", [_vm._v("\n    No pages\n  ")]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }