var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "form",
    {
      attrs: { id: _vm.formId, autocomplete: "off" },
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.validateInputs.apply(null, arguments)
        },
      },
    },
    [
      _vm.responseError.length
        ? _c("div", { staticClass: "error-box" }, [
            _c("p", [_vm._v(_vm._s(_vm.responseError))]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm._t("default"),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "form-input submit-input",
          attrs: { id: "form-buttons" },
        },
        [
          _vm._t(
            "form_buttons",
            function () {
              return [
                _vm.showCancelButton
                  ? _c("button", {
                      class: [_vm.cancelButtonVariant, "default"],
                      attrs: {
                        id: "submit-cancel",
                        "data-check": "Submit_Cancel",
                        type: "submit",
                        value: _vm.cancelButtonText,
                      },
                      domProps: { textContent: _vm._s(_vm.cancelButtonText) },
                      on: {
                        click: function ($event) {
                          _vm.eventType = "cancel"
                        },
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.showSaveButton
                  ? _c("button", {
                      class: [_vm.submitButtonVariant],
                      attrs: {
                        id: "submit-save",
                        "data-check": "Submit_Save",
                        type: "submit",
                        disabled: _vm.buttonDisable,
                        value: _vm.submitButtonText,
                      },
                      domProps: { textContent: _vm._s(_vm.submitButtonText) },
                      on: {
                        click: function ($event) {
                          _vm.eventType = "submit"
                        },
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.showEmailButton
                  ? _c("button", {
                      class: [_vm.submitButtonVariant],
                      attrs: {
                        id: "submit-email",
                        "data-check": "Submit_Email",
                        type: "submit",
                        disabled: _vm.buttonDisable,
                        value: _vm.emailButtonText,
                      },
                      domProps: { textContent: _vm._s(_vm.emailButtonText) },
                      on: {
                        click: function ($event) {
                          _vm.eventType = "email"
                        },
                      },
                    })
                  : _vm._e(),
              ]
            },
            null,
            {
              buttonDisable: _vm.buttonDisable,
              changeEventType: _vm.changeEventType,
              cancelDisable: _vm.cancelDisable,
            }
          ),
        ],
        2
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }