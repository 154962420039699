var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "container",
      staticClass: "vc-saturation",
      style: { background: _vm.bgColor },
      on: {
        mousedown: _vm.handleMouseDown,
        touchmove: _vm.handleChange,
        touchstart: _vm.handleChange,
      },
    },
    [
      _c("div", { staticClass: "vc-saturation--white" }),
      _vm._v(" "),
      _c("div", { staticClass: "vc-saturation--black" }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "vc-saturation-pointer",
          style: { top: _vm.pointerTop, left: _vm.pointerLeft },
        },
        [_c("div", { staticClass: "vc-saturation-circle" })]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }