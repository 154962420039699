import './scss/index.scss'
import CrossAuth from './components/CrossAuth.vue'

export default {
  install (Vue) {
    Vue.component('CrossAuth', CrossAuth)
  }
}

/***
!!! IMPORTANT !!!
See the readme file for more information.

Cross-authentication will require modifications to the clients' htaccess file.
The "frame-ancestors" Content Security Policy will need to include any
associated domains, across all environments. If this policy is not updated,
the shared token will not work, as the iframe will be completely blocked.

Example:
https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Content-Security-Policy/frame-ancestors
***/
