import actions from '../actions/actions'
import mutations from '../mutations/mutations'

export default {
  namespaced: true,
  state: {
    roles: [],
    filterParameters: null,
    searchParameters: null,
    isLoading: false,
    newElementCreated: false
  },
  mutations,
  actions
}
