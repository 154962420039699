var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        role: "presentation",
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 14 2",
        "enable-background": "new 0 0 14 2",
      },
    },
    [_c("path", { attrs: { d: "M14 2h-14v-2h14v2z" } })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }