export default {
  path: 'groups',
  component: () => import(
    /* webpackChunkName: "admin-settings-groups-index" */
    '@cms/components/settings/groups/index.vue'),

  children: [
    {
      path: '',
      name: 'admin.groups.index',
      component: () => import(
        /* webpackChunkName: "admin-settings-groups-groups" */
        '@cms/components/settings/groups/groups.vue')
    },
    {
      path: ':id',
      name: 'admin.groups.group',
      component: () => import(
        /* webpackChunkName: "admin-settings-groups-group" */
        '@cms/components/settings/groups/group.vue')
    }
  ]
}
