var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.footerLogo
    ? _c("div", { staticClass: "footer-logo", class: _vm.logoPosition }, [
        _c("img", {
          attrs: {
            src: _vm.footerLogo,
            alt: `${_vm.siteName} Logo`,
            title: _vm.siteName,
          },
        }),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }