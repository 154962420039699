
/**
 * Use this to show the user that there is something happening behind the scenes.
 * <br/> i.e., when an api call has been made and we're awaiting results.
 * @displayName Loading Block
 *
 */
export default {
  name: 'LoadingBlock',
  props: {
    /**
     * sets the height of the block in px
     */
    height: {
      type: Number,
      default: 250,
      required: false,
      validator (val) {
        /** block shouldnt be smaller than 100px and no larger than 500px */
        return (val >= 100 && val <= 500) ? true : false
      }
    },
    /**
     * copy to display next to spinner. eg, loading logos...
     */
    text: {
      type: String,
      default: 'Loading...',
      required: false,
      validator (val) {
        /** error text should not be too long */
        if (val.length > 50) {
          /* eslint-disable-next-line no-console */
          console.warn('[LoadingBlock]: The provided loading text is long, consider shortening.')
        }
        return true
      }
    },
    /**
     * hide text and only show spinner
     */
    hideText: {
      type: Boolean,
      required: false
    },
    /**
     * hide text and only show spinner
     */
    hideSpinner: {
      type: Boolean,
      required: false
    },
    /**
     * @values spinner, grow
     */
    spinnerType: {
      type: String,
      required: false,
      default: 'spinner'
    }
  }
}
