import actions from '../actions/actions'
import mutations from '../mutations/mutations'

export default {
  namespaced: true,
  state: {
    hideAll: false,
    showDrafts: true,
    showStructure: true,
    selectedItemId: null,
    layout: '',
    linksList: [],
    menuItems: [],
    menuItemModules: [
      {
        id: 0,
        type: 'page',
        label: 'Page',
        icon: 'cms_page',
        hasContent: true
      },
      {
        id: 1,
        type: 'directory',
        label: 'Folder',
        icon: 'cms_folder',
        hasContent: false
      },
      {
        id: 2,
        type: 'external',
        label: 'Link',
        icon: 'cms_link',
        hasContent: false
      },
      {
        id: 3,
        type: 'homepage',
        label: 'Homepage',
        icon: 'cms_page',
        hasContent: true
      }
    ],
    isLoading: false,
    newElementCreated: false,
    filterParameters: {
      entity: null,
      group: null,
      role: null
    },
    searchParameters: {
      term: null
    },
    pagesSidebarStatus: false
  },
  mutations,
  actions,
  getters: {
    getMenuItemById: (state) => (id = 0) => {
      const ID = parseInt(id, 10)
      return (!isNaN(ID)) ? state.menuItems.find(item => ID === parseInt(item.id, 10)) : false
    },
    getMenuItemByContentId: (state) => (id = 0) => {
      const ID = parseInt(id, 10)
      return (!isNaN(ID)) ? state.menuItems.find(item => ID === parseInt(item.content_id, 10)) : false
    }
  }
}
