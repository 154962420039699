var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      staticClass: "transparent-star",
      attrs: {
        role: "presentation",
        xmlns: "http://www.w3.org/2000/svg",
        width: "20",
        height: "19",
        viewBox: "0 0 20 19",
      },
    },
    [
      _c("g", { attrs: { fill: "none", "fill-rule": "evenodd" } }, [
        _c("path", {
          attrs: {
            stroke: "#FFF",
            "stroke-width": "1.25",
            d: "M15.236 17.7l-1.385-5.955 4.622-4.005-6.093-.522L10 1.6 7.62 7.218l-6.093.522 4.622 4.005-1.385 5.956L10 14.542l5.236 3.159z",
          },
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }