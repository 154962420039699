var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("nav", { staticClass: "global-list-group-nav" }, [
    _vm.title
      ? _c("span", { staticClass: "menu-title" }, [_vm._v(_vm._s(_vm.title))])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "ul",
      { staticClass: "global-list-group" },
      [
        _vm.type === "simple"
          ? _vm._l(_vm.items, function (item, index) {
              return _c("router-link", {
                key: index,
                attrs: { to: item.url },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function ({
                        href,
                        navigate,
                        isActive,
                        isExactActive,
                      }) {
                        return [
                          _c(
                            "li",
                            {
                              class: [
                                (_vm.matchType === "exact" && isExactActive) ||
                                (_vm.matchType === "partial" && isActive)
                                  ? "router-link-active"
                                  : "",
                                "global-list-group-item",
                                _vm.itemDropdown.length && !item.disableDropdown
                                  ? "contains-dropdown"
                                  : "",
                              ],
                              on: {
                                click: function ($event) {
                                  return navigate(index)
                                },
                              },
                            },
                            [
                              _c(
                                "a",
                                {
                                  attrs: { href: href },
                                  on: { click: navigate },
                                },
                                [
                                  _c("span", [_vm._v(_vm._s(item.label))]),
                                  _vm._v(" "),
                                  item.count !== undefined
                                    ? _c("span", [_vm._v(_vm._s(item.count))])
                                    : _vm._e(),
                                ]
                              ),
                              _vm._v(" "),
                              _vm.itemDropdown.length && !item.disableDropdown
                                ? _c("GlobalDropdown", {
                                    ref: "dropdown",
                                    refInFor: true,
                                    attrs: {
                                      items: _vm.itemDropdown,
                                      index: index,
                                    },
                                    on: { "close-all": _vm.closeAllMenus },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              })
            })
          : _vm._l(_vm.items, function (item, index) {
              return _c(
                "li",
                {
                  key: index,
                  staticClass: "global-list-group-item",
                  class: [
                    { "router-link-active": _vm.localActiveIndex === index },
                    _vm.itemDropdown.length &&
                      !item.disableDropdown &&
                      "contains-dropdown",
                  ],
                  on: {
                    click: function ($event) {
                      return _vm.clickEvent(index)
                    },
                  },
                },
                [
                  _c("span", [_vm._v(_vm._s(item.label))]),
                  _vm._v(" "),
                  item.count !== undefined
                    ? _c("span", [_vm._v(_vm._s(item.count))])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.itemDropdown.length && !item.disableDropdown
                    ? _c("GlobalDropdown", {
                        ref: "dropdown",
                        refInFor: true,
                        attrs: { items: _vm.itemDropdown, index: index },
                        on: { "close-all": _vm.closeAllMenus },
                      })
                    : _vm._e(),
                ],
                1
              )
            }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }