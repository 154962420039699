var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.pages > 1 && _vm.page
    ? _c(
        "section",
        { staticClass: "pagination" },
        [
          [
            _c(
              "div",
              { staticClass: "pagination-container" },
              [
                _c(
                  "button",
                  {
                    staticClass: "secondary previous",
                    attrs: {
                      disabled: _vm.page === 1,
                      type: "button",
                      title: _vm.$t("site_actions_pagination_previous_label"),
                    },
                    on: { click: _vm.onPrevious },
                  },
                  [_c("icon", { attrs: { id: "ic_chevron_left" } })],
                  1
                ),
                _vm._v(" "),
                _vm._l(_vm.ellipsis, function (currentPage) {
                  return [
                    _c(
                      "button",
                      {
                        key: currentPage,
                        class: [
                          { current: currentPage === _vm.page },
                          "secondary",
                        ],
                        attrs: {
                          title: currentPage,
                          disabled: currentPage === _vm.page,
                          type: "button",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.onGoto(currentPage)
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n          " + _vm._s(currentPage) + "\n        "
                        ),
                      ]
                    ),
                  ]
                }),
                _vm._v(" "),
                _vm.showEllipsis
                  ? _c("span", { staticClass: "ellipsis" }, [_vm._v("...")])
                  : _vm._e(),
                _vm._v(" "),
                _vm.pages >= 7
                  ? _c(
                      "button",
                      {
                        class: [
                          { current: _vm.pages === _vm.page },
                          "secondary",
                        ],
                        attrs: {
                          title: _vm.pages,
                          disabled: _vm.pages === _vm.page,
                          type: "button",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.onGoto(_vm.pages)
                          },
                        },
                      },
                      [_vm._v("\n        " + _vm._s(_vm.pages) + "\n      ")]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "secondary next",
                    attrs: {
                      disabled: _vm.page === _vm.pages,
                      type: "button",
                      title: _vm.$t("site_actions_pagination_next_label"),
                    },
                    on: { click: _vm.onNext },
                  },
                  [_c("icon", { attrs: { id: "ic_chevron_right" } })],
                  1
                ),
              ],
              2
            ),
            _vm._v(" "),
            !_vm.hideMeta
              ? _c("p", { staticClass: "displaying-meta" }, [
                  _vm._v(
                    "\n      Viewing " +
                      _vm._s(_vm.itemsStart) +
                      " - " +
                      _vm._s(_vm.itemsEnd) +
                      " of " +
                      _vm._s(_vm.items) +
                      "\n    "
                  ),
                ])
              : _vm._e(),
          ],
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }