var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        role: "presentation",
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 24 24",
        "enable-background": "new 0 0 24 24",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M22,0H2C0.9,0,0,0.9,0,2v20c0,1.1,0.9,2,2,2h20c1.1,0,2-0.9,2-2V2C24,0.9,23.1,0,22,0z M22.2,22.2H1.7V1.7h20.5 V22.2z M4.5,7.1h15.1c0.2,0,0.3-0.1,0.3-0.3V4c0-0.2-0.1-0.3-0.3-0.3H4.5C4.3,3.7,4.2,3.8,4.2,4v2.8C4.2,6.9,4.3,7.1,4.5,7.1z M4.9,4.4h14.2v1.9H4.9V4.4z M4.5,11.8h15.1c0.2,0,0.3-0.1,0.3-0.3V8.8c0-0.2-0.1-0.3-0.3-0.3H4.5c-0.2,0-0.3,0.1-0.3,0.3v2.8 C4.2,11.7,4.3,11.8,4.5,11.8z M4.9,9.2h14.2v1.9H4.9V9.2z M4.5,16.6h15.1c0.2,0,0.3-0.1,0.3-0.3v-2.8c0-0.2-0.1-0.3-0.3-0.3H4.5 c-0.2,0-0.3,0.1-0.3,0.3v2.8C4.2,16.5,4.3,16.6,4.5,16.6z M4.9,14h14.2v1.9H4.9V14z M19.8,18.5v2.4c0,0.2-0.1,0.3-0.3,0.3h-5.4\tc-0.2,0-0.3-0.1-0.3-0.3v-2.4c0-0.2,0.1-0.3,0.3-0.3h5.4C19.7,18.1,19.8,18.3,19.8,18.5z",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }