export default {
  path: "/admin/libraries",
  meta: { layout: "admin" },
  component: () =>
    import(
      /* webpackChunkName: "admin-libraries-index" */
      "@cms/components/libraries/index.vue"
    ),
  children: [
    {
      path: "",
      name: "admin.libraries.index",
      component: () =>
        import(
          /* webpackChunkName: "admin-libraries-libraries" */
          "@cms/components/libraries/libraries.vue"
        ),
    },
    {
      path: ":id",
      name: "admin.libraries.library",
      component: () =>
        import(
          /* webpackChunkName: "admin-libraries-libraries" */
          "@cms/components/libraries/libraries.vue"
        ),
      children: [
        {
          path: ":asset",
          name: "admin.libraries.library.asset",
          component: () =>
            import(
              /* webpackChunkName: "admin-libraries-libraries" */
              "@cms/components/libraries/libraries.vue"
            ),
        },
      ],
    },
    {
      path: "permissions-management",
      name: "admin.libraries.permissions-management",
      component: () =>
        import(
          /* webpackChunkName: "admin-libraries-permissions-management" */
          "@cms/components/libraries/permissions-management.vue"
        ),
    },
  ],
};
