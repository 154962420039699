
export default {
  name: "AssetsTableMixin",
  methods: {
    onDownload(file, forceDownload = false) {
      if (
        forceDownload ||
        !this.canConvert(file.file_name) ||
        this.overrideMobile
      ) {
        this.$onAsset(file.id, file.title);
      } else {
        this.toggleAssetConversionDropdown(file.id);
      }
    },
    toggleAssetConversionDropdown(id) {
      this.conversionType = null;

      if (this.assetConversionActiveId === id) {
        this.assetConversionActiveId = null;
      } else {
        this.assetConversionActiveId = id;
      }
    },
  },
};
