/*
 * styles are set @theme/scss/base/_accessibility.scss
 * this mixin allows the focus styles to be disabled for mouse events, but customized for tab events
*/
export default {
  methods: {
    $setFocusClass (e) {
      // if Tab key...
      if (e && e.keyCode === 9) {
        e.target.classList.add('tab-focus')
      }
    },
    $unsetFocusClass (e) {
      e.target.classList.remove('tab-focus')
    },
    $setFocusListener () {
      const $el = this.$el

      if ($el && typeof $el.querySelectorAll === 'function') {
        const _this = this
        const focusEls = [
          ...$el.querySelectorAll('a, button, input, textarea, select, details, [tabindex]:not([tabindex="-1"])')
        ].filter(el => !el.hasAttribute('disabled'))

        focusEls.forEach((el) => {
          if (!el.getAttribute('data-focus-listener')) {
            el.dataset.focusListener = true
            el.addEventListener('keyup', _this.$setFocusClass)
            el.addEventListener('blur', _this.$unsetFocusClass)
          }
        })
      }
    },
    $unsetFocusListener (e) {
      const $el = this.$el

      if ($el && typeof $el.querySelectorAll === 'function') {
        const _this = this
        const focusEls = $el.querySelectorAll('[data-focus-listener]')

        focusEls.forEach((el) => {
          // el.removeAttribute('data-focus-listener')
          delete el.dataset.focusListener
          el.removeEventListener('keyup', _this.$setFocusClass)
          el.removeEventListener('blur', _this.$unsetFocusClass)
        })
      }
    },
    $toggleVideoPlay (e) {
      // Requires the video tag has a ref of "video"
      if (e.keyCode === 13 || e.keyCode === 32) {
        // enter or space bar
        e.preventDefault()
        e.stopPropagation()
        const isPaused = this.$refs.video.paused
        if (isPaused) {
          this.$refs.video.play()
        } else {
          this.$refs.video.pause()
        }
      }
    },
    $triggerClickEvent (e, id) {
      if (e && (e.keyCode === 32 || e.keyCode === 13)) {
        e.preventDefault()
        e.stopPropagation()

        document.getElementById(id).click()
      }
    }
  },
  updated () {
    this.$nextTick(() => {
      this.$setFocusListener()
    })
  },
  beforeDestroy () {
    this.$unsetFocusListener()
  }
}
