import { mapGetters, mapState } from "vuex";

export default {
  computed: {
    ...mapState({
      content: (state) => state.Content.content,
      menuItems: (state) => state.MenuItems.menuItems,
    }),
    ...mapGetters({
      isContentTypeHome: "Content/isContentTypeHome",
    }),
    _routeId() {
      return this.$route.params.id
        ? parseInt(this.$route.params.id, 10)
        : false;
    },
    _isContentAdminRoute() {
      return this.$route && this.$route.path.indexOf("/admin/content") === 0;
    },
    _currentMenu() {
      const type = this._isContentAdminRoute ? "content_id" : "id";
      return this.menuItems.find((menu) => menu[type] === this._routeId);
    },
    _isHomepageAdminRoute() {
      if (
        this.$route &&
        this.$route.path.indexOf("/admin/menuitems/homepage") === 0
      ) {
        // If user is on the homepage menuitems page
        return true;
      } else {
        return this.isContentTypeHome;
      }
    },
    _isAuthPage() {
      return this.content?.menu_item?.type === "auth";
    },
    _lockedContainerIds() {
      let ids = [];
      const sections = this.content?.text?.sections ?? [];

      sections.forEach((section) => {
        section.elements.forEach((element) => {
          element.rows.forEach((row) => {
            row.cols.forEach((col) => {
              col.modules.forEach((mod) => {
                // this will need to change to find if the form itself is locked
                if (mod.locked) {
                  ids.push(mod.id);
                  ids.push(col.id);
                  ids.push(row.id);
                  ids.push(element.id);
                  ids.push(section.id);
                }
              });
            });
          });
        });
      });

      return ids;
    },
    _hasLockedModule() {
      const controls = [
        "SectionControls",
        "ContentItemSectionRow",
        "ModuleControls",
      ];

      if (controls.includes(this.$options.name)) {
        let itemID;

        switch (this.$options.name) {
          case "ModuleControls":
            itemID = this.$parent?.moduleData?.id ?? 0;
            break;

          case "ContentItemSectionRow":
            itemID = this?.row?.id ?? 0;
            break;

          case "SectionControls":
            itemID = this?.section?.id ?? 0;
            break;

          default:
            itemID = 0;
        }

        return this._lockedContainerIds.includes(itemID);
      }

      return false;
    },
  },
};
