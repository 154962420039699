var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        role: "presentation",
        viewBox: "0 0 14 13",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("rect", { attrs: { x: "9", y: "0", width: "3", height: "3" } }),
      _vm._v(" "),
      _c("rect", { attrs: { x: "0", y: "1", width: "14", height: "1" } }),
      _vm._v(" "),
      _c("rect", { attrs: { x: "2", y: "5", width: "3", height: "3" } }),
      _vm._v(" "),
      _c("rect", { attrs: { x: "0", y: "6", width: "14", height: "1" } }),
      _vm._v(" "),
      _c("rect", { attrs: { x: "6", y: "10", width: "3", height: "3" } }),
      _vm._v(" "),
      _c("rect", { attrs: { x: "0", y: "11", width: "14", height: "1" } }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }