import actions from "../actions/actions";
import mutations from "../mutations/mutations";

export default {
  namespaced: true,
  state: {
    token: false,
    ttl: false,
  },
  mutations: Object.assign(
    {
      setMFA(state, mfa) {
        Object.assign(state, mfa);
        if (mfa) {
          state.ttl = mfa.ttl;
          window.CookieManager.setCookie("mfa", mfa.mfa_token, mfa.ttl);
        }
      },
      setToken(state, token) {
        if (token === null) {
          Object.assign(state, token);
        } else {
          state.token = token;
        }
      },
    },
    mutations
  ),
  actions: Object.assign(
    {
      getMFA(context) {
        context.commit(
          "setToken",
          JSON.parse(window.CookieManager.getCookie("mfa"))
        );
      },
      unsetMFA(context) {
        context.commit("setMFA", {
          token: false,
          ttl: false,
        });
      },
    },
    actions
  ),
};
