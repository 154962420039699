var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "modal" }, [
    _c("div", { staticClass: "modal__body request" }, [
      _c("div", { staticClass: "modal__controls" }, [
        _c(
          "button",
          {
            staticClass: "close",
            attrs: {
              type: "button",
              title: _vm.$t("site_actions_close_label"),
            },
            on: { click: _vm.onClose },
          },
          [_c("icon", { attrs: { id: "ic_close" } })],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "modal__header" },
        [_c("icon", { attrs: { id: "ic_download" } })],
        1
      ),
      _vm._v(" "),
      !_vm.submitted
        ? _c("div", { staticClass: "modal__contents" }, [
            _c("h2", { staticClass: "modal__title" }, [
              _vm._v("\n        Access required\n      "),
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "modal__text" }, [
              _vm._v(
                "\n        Contact your system administrator to request access to this asset\n        using the form below.\n      "
              ),
            ]),
            _vm._v(" "),
            _c(
              "form",
              { on: { submit: _vm.handleSubmit } },
              [
                _c("h6", [_vm._v("Purpose for request?")]),
                _vm._v(" "),
                _c("TextareaInput", {
                  ref: "purpose_of_access",
                  staticClass: "purpose",
                  attrs: {
                    placeholder: "I'd like to request access because...",
                    model: _vm.purpose_of_access,
                    required: "true",
                    validate: "true",
                    rows: 3,
                    maxlength: _vm.maxPurposeChars,
                  },
                  on: {
                    "update:model": function ($event) {
                      _vm.purpose_of_access = $event
                    },
                  },
                }),
                _vm._v(" "),
                _c("div", { staticClass: "characters-left" }, [
                  _c("small", [
                    _vm._v(
                      _vm._s(_vm.purposeCharactersLeft) +
                        " / " +
                        _vm._s(_vm.maxPurposeChars) +
                        " characters\n            left"
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "button btn primary",
                    attrs: { disabled: _vm.purpose_of_access.length === 0 },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.handleSubmit.apply(null, arguments)
                      },
                    },
                  },
                  [_vm._v("\n          Submit\n        ")]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "button btn secondary",
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.onClose.apply(null, arguments)
                      },
                    },
                  },
                  [_vm._v("\n          Cancel\n        ")]
                ),
              ],
              1
            ),
          ])
        : _c("div", { staticClass: "modal__contents" }, [
            _c("h2", { staticClass: "modal__title" }, [
              _vm._v("\n        Request sent\n      "),
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v("You will receive an email once the request is approved"),
            ]),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "button btn secondary",
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.onClose.apply(null, arguments)
                  },
                },
              },
              [_vm._v("\n        Close\n      ")]
            ),
          ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }