var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "showcase-carousel-global" }, [
    _c(
      "div",
      { ref: "slides", staticClass: "slides" },
      [
        _vm.slides.length === 0
          ? _c("div", { staticClass: "empty" })
          : _vm._e(),
        _vm._v(" "),
        _vm._l(_vm.slides, function (slide, index) {
          return _c(
            "div",
            {
              key: index,
              staticClass: "slide",
              style: `height:${_vm.height}px`,
              on: {
                mousedown: _vm.dragStart,
                touchstart: _vm.dragStart,
                mouseup: _vm.dragStop,
                touchend: _vm.dragStop,
                mousemove: _vm.adjustScroll,
                touchmove: _vm.adjustScroll,
              },
            },
            [
              _vm.linkOnImage
                ? _c(
                    "div",
                    {
                      staticClass: "image",
                      on: {
                        click: function ($event) {
                          return _vm.openShowcase(slide.link_url)
                        },
                      },
                    },
                    [
                      slide.image_url
                        ? _c("img", {
                            style: `max-height:${_vm.height}px; max-width:${_vm.height}px`,
                            attrs: { src: slide.image_url, alt: "Image" },
                          })
                        : _vm._e(),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              !_vm.linkOnImage
                ? _c("div", { staticClass: "image" }, [
                    slide.image_url
                      ? _c("img", {
                          style: `max-height:${_vm.height}px; max-width:${_vm.height}px`,
                          attrs: { src: slide.image_url, alt: "Image" },
                        })
                      : _vm._e(),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.showTitle
                ? _c(
                    "div",
                    { staticClass: "title" },
                    [
                      _c(_vm.titleStyle, { tag: "component" }, [
                        _vm._v(
                          "\n          " + _vm._s(slide.title) + "\n        "
                        ),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.showLink
                ? _c(
                    "div",
                    { staticClass: "link" },
                    [
                      _c("router-link", { attrs: { to: slide.link_url } }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(slide.link_label) +
                            "\n        "
                        ),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          )
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }