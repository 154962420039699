export default [
  {
    path: '/admin/helpdesk',
    meta: { layout: 'admin' },
    component: () => import('@core/plugins/HelpDesk/components/cms/AdminHelpDesk.vue'),
    children: [
      {
        path: '',
        name: 'admin.helpdesk.index',
        component: () => import('@core/plugins/HelpDesk/components/cms/AdminHelpDeskTable.vue')
      },
      {
        path: ':name',
        name: 'admin.helpdesk.table',
        component: () => import('@core/plugins/HelpDesk/components/cms/AdminHelpDeskTable.vue')
      }
    ]
  },
  {
    path: '/admin/helpdesk/requests/:id',
    name: 'admin.helpdesk.request',
    meta: { layout: 'admin' },
    component: () => import('@core/plugins/HelpDesk/components/cms/AdminHelpDeskRequest.vue'),
    children: [
      {
        path: '',
        name: 'admin.helpdesk.request.summary',
        component: () => import('@core/plugins/HelpDesk/components/cms/partials/requests/AdminHelpDeskRequestSummary.vue')
      },
      {
        path: 'messages',
        name: 'admin.helpdesk.request.messages',
        component: () => import('@core/plugins/HelpDesk/components/cms/partials/requests/AdminHelpDeskRequestConversations.vue')
      }
    ]
  }
]
