
import smoothscroll from 'smoothscroll-polyfill'

export default {
  name: 'BackToTop',
  data () {
    return {
      scrollDuration: 15,
      scrollSpeed: 50,
      topOffset: 50,
      isVisible: false,
      isScrolling: null
    }
  },
  methods: {
    toTop () {
      this.isScrolling = false

      window.scroll({
        top: 0,
        behavior: 'smooth'
      })
    },
    handleScroll () {
      this.isScrolling = true
      this.isVisible = document.body.scrollTop > this.topOffset || document.documentElement.scrollTop > this.topOffset
    }
  },
  created () {
    smoothscroll.polyfill()

    window.addEventListener('scroll', this.handleScroll)
    this.handleScroll()
  },
  destroyed () {
    window.removeEventListener('onwheel' in document ? 'wheel' : 'mousewheel', this.handleScroll)
  }
}
