var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        role: "presentation",
        width: "18px",
        height: "20px",
        viewBox: "0 0 18 20",
        version: "1.1",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
      },
    },
    [
      _c(
        "g",
        {
          attrs: {
            stroke: "none",
            "stroke-width": "1",
            fill: "none",
            "fill-rule": "evenodd",
          },
        },
        [
          _c(
            "g",
            { attrs: { transform: "translate(-159.000000, -8.000000)" } },
            [
              _c(
                "g",
                { attrs: { transform: "translate(158.000000, 8.000000)" } },
                [
                  _c("g", [
                    _c("rect", {
                      attrs: { x: "0", y: "0", width: "20", height: "20" },
                    }),
                    _vm._v(" "),
                    _c("path", {
                      attrs: {
                        fill: "#fff",
                        d: "M4.16666667,19.1666667 C2.78595479,19.1666667 1.66666667,18.0473785 1.66666667,16.6666667 L1.66666667,7.5 C1.66666667,7.24284131 1.78539404,7.00008665 1.98838282,6.84220649 L9.48838282,1.00887315 C9.78930823,0.77482006 10.2106918,0.77482006 10.5116172,1.00887315 L18.0116172,6.84220649 C18.214606,7.00008665 18.3333333,7.24284131 18.3333333,7.5 L18.3333333,16.6666667 C18.3333333,18.0473785 17.2140452,19.1666667 15.8333333,19.1666667 L4.16666667,19.1666667 Z M10,2.72238465 L3.33333333,7.90756984 L3.33333333,16.6666667 C3.33333333,17.126904 3.70642938,17.5 4.16666667,17.5 L6.66666667,17.4993333 L6.66666667,10 C6.66666667,9.57516558 6.98457099,9.22458169 7.39546844,9.17315952 L7.5,9.16666667 L12.5,9.16666667 C12.9602373,9.16666667 13.3333333,9.53976271 13.3333333,10 L13.3333333,10 L13.3326667,17.4993333 L15.8333333,17.5 C16.2935706,17.5 16.6666667,17.126904 16.6666667,16.6666667 L16.6666667,7.90756984 L10,2.72238465 Z M11.6666667,10.8333333 L8.33333333,10.8333333 L8.33266667,17.4993333 L11.6666667,17.4993333 L11.6666667,10.8333333 Z",
                      },
                    }),
                  ]),
                ]
              ),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }