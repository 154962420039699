export default [
  {
    name: 'row_100',
    label: 'full-width',
    numCols: 1,
    cols: [],
    svg: 'row_100'
  },
  {
    name: 'row_50_50',
    label: 'halves',
    numCols: 2,
    cols: [],
    svg: 'row_50_50'
  },
  {
    name: 'row_33_66',
    label: '33.3% / 66.6%',
    numCols: 2,
    cols: [],
    svg: 'row_33_66'
  },
  {
    name: 'row_66_33',
    label: '66.6% / 33.3%',
    numCols: 2,
    cols: [],
    svg: 'row_66_33'
  }
]
