var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("focus-trap", { attrs: { active: _vm.displayModal } }, [
    _c("section", { staticClass: "modal", attrs: { tabindex: "-1" } }, [
      _c("div", { staticClass: "modal__body" }, [
        _c("div", { staticClass: "modal__controls" }, [
          _c(
            "button",
            {
              staticClass: "close",
              attrs: {
                type: "button",
                title: _vm.$t("site_actions_close_label"),
              },
              on: { click: _vm.onClose },
            },
            [_vm._v("\n          ×\n        ")]
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "modal__contents" }, [
          _c("div", { staticClass: "scroller" }, [
            _c("dl", [
              _c("dt", [_vm._v("Archive name:")]),
              _vm._v(" "),
              _c("dd", [_vm._v(_vm._s(_vm.archive.ArchiveName))]),
              _vm._v(" "),
              _c("dt", [_vm._v("Created on:")]),
              _vm._v(" "),
              _c("dd", [
                _vm._v(
                  "\n              " +
                    _vm._s(
                      _vm._f("formatDate")(
                        _vm.archive.CreateDateTime,
                        "dddd, MMMM Do YYYY"
                      )
                    ) +
                    "\n            "
                ),
              ]),
              _vm._v(" "),
              _c("dt", [_vm._v("Contents:")]),
              _vm._v(" "),
              _c("dd", [
                _vm.archive.files.length
                  ? _c(
                      "ul",
                      [
                        _vm._l(_vm.archive.files, function (file) {
                          return [
                            _c("li", { key: file.id }, [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(file.title) +
                                  "\n                  "
                              ),
                            ]),
                          ]
                        }),
                      ],
                      2
                    )
                  : _vm._e(),
              ]),
            ]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }