var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        id: "beam_logo",
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 87 28",
        "enable-background": "new 0 0 87 28",
      },
      on: {
        click: function ($event) {
          return _vm.$emit("click")
        },
      },
    },
    [
      _c("path", {
        attrs: {
          id: "beam_logo_bar",
          d: "M72.8 26.6c0 .8-.6 1.4-1.4 1.4h-70c-.8 0-1.4-.6-1.3-1.4 0-.8.6-1.4 1.4-1.4h70c.7 0 1.4.6 1.3 1.4zm7.3-1.4h-3.2c-.8 0-1.4.6-1.4 1.4 0 .8.6 1.4 1.4 1.4h3.2c.8 0 1.4-.6 1.4-1.4.1-.8-.6-1.4-1.4-1.4zm5.4 0c-.8 0-1.4.6-1.4 1.4 0 .8.6 1.4 1.4 1.4s1.4-.6 1.4-1.4c0-.8-.6-1.4-1.4-1.4z",
        },
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          id: "beam_logo_name",
          d: "M72.9 12.8v8.7h-3.7c-.7 0-1.2-.5-1.2-1.2v-6.8c0-1.3-.9-2.1-2-2.1s-2 .8-2 2.1v8h-4.9v-8c0-1.3-.8-2.1-2-2.1s-2 .8-2 2.1v8h-4.9v-14.2h3.3c.6 0 1.1.5 1.1 1.1 1.1-.9 2.4-1.4 4-1.4 1.8 0 3.2.7 4.1 1.8 1.1-1.1 2.8-1.8 4.7-1.8 3.4 0 5.5 2.3 5.5 5.8zm-57.5 1.6c0 4.2-3.4 7.4-7.7 7.4-4.2 0-7.7-2.5-7.7-7.4v-14.2h3.3c.6 0 1.1.5 1.1 1.1v6.4c1-.5 2.2-.7 3.3-.7 4.3 0 7.7 3.2 7.7 7.4zm-4.7 0c0-1.6-1.3-3-3-3-1.6 0-3 1.3-3 3 0 1.6 1.3 3 3 3s3.1-1.3 3-3zm20.3-.7c0 .9-.2 1.8-.4 2.3h-9.3c.4.7 1 1.9 3.6 1.9 1 0 1.7-.2 2.5-.5l2.6 2.6c-1.6 1.2-3.4 1.8-5.4 1.8-4.7 0-8-3.2-8-7.4s3.3-7.4 7.6-7.4c4.2 0 6.8 3.1 6.8 6.7zm-4.4-.7c-.1-1.4-1.2-2.4-2.6-2.4-1.3 0-2.4.9-2.8 2.3l-.1.3v.1h5.5v-.3zm16.2-5.7h4.9v14.2h-4.9v-1.8c-1 1.2-2.3 2.1-4.1 2.1-3.5 0-6.7-3.2-6.7-7.4s3.2-7.4 6.7-7.4c1.9 0 3.2.8 4.1 1.9v-1.6zm-.1 7.2c0-1.6-1.3-3-3-3-1.6 0-3 1.3-3 3 0 1.6 1.3 3 3 3s3.1-1.4 3-3z",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }