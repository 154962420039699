var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "24",
        height: "15",
        viewBox: "0 0 24 15",
        role: "presentation",
      },
    },
    [
      _c("g", { attrs: { fill: "none", "fill-rule": "evenodd" } }, [
        _c("path", { attrs: { d: "M0-6h24v24H0z" } }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            fill: "#222428",
            "fill-rule": "nonzero",
            d: "M22.57 0H1.43C.64 0 0 .646 0 1.442v7.19c0 .796.64 1.442 1.43 1.442h11.656l1.471 3.524a.712.712 0 0 0 1.164.23l.924-.932 1.282 1.293a.71.71 0 0 0 1.01 0l1.072-1.082a.725.725 0 0 0 0-1.02l-1.281-1.292.714-.721h3.129c.789 0 1.429-.646 1.429-1.442v-7.19C24 .646 23.36 0 22.57 0zm-3.066 12.597l-1.072 1.082-1.787-1.803-1.43 1.443-2.858-6.85 6.79 2.884-1.43 1.442 1.787 1.802z",
          },
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }