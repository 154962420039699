import GlobalAssets from './mixins/GlobalAssets'
import PageTypeChecks from './mixins/PageTypeChecks'
import Permissions from './mixins/Permissions'
import QueryFunctions from './mixins/QueryFunctions'
import RouteHelpers from './mixins/RouteHelpers'
import ScrollTriggerAnimation from './mixins/ScrollTriggerAnimation'

export default {
  install (Vue) {
    Vue.use(GlobalAssets)
    Vue.use(PageTypeChecks)
    Vue.use(Permissions)
    Vue.use(QueryFunctions)
    Vue.use(RouteHelpers)
    Vue.use(ScrollTriggerAnimation)
  }
}
