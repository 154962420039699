export default {
  computed: {
    _rootPath () {
      let url = this.$route.fullPath
      const separator = (url.indexOf('?') > -1) ? '&' : '?'
      return url + separator
    },
    queryAssetId () {
      let id = null
      let query = window.location.search.substring(1)

      if (query.indexOf('asset_id=') > -1) {
        let qs = new URLSearchParams(query)
        let asset = qs.get('asset_id')
        id = (asset) ? parseInt(asset, 10) : null
      }

      return id
    },
    assetFiles () {
      if (this.$options.name === 'AssetImage') {
        return [this.asset]
      } else {
        return (this.files) ? this.files : this.assets
      }
      // this.isPopular = !!(this.$options.name === 'PopularAssets')
    }
  },
  watch: {
    asset () {
      if (this.asset.id === this.queryAssetId) {
        this.openModal()
      }
    }
  },
  methods: {
    async openModal () {
      if (this.queryAssetId && !this.$store.state.Global.modal.type && !this._isAdminArea) {
        const modalActions = this?.modalActions ??
          ['Title', 'Description', 'FileName', 'Format', 'Created', 'Modified', 'Expiration', 'Usage']
        const assetActions = this?.assetActions ??
          ['Download', 'Email', 'Modal']


        this.$nextTick(() => {
          const asset = this.assetFiles.find((asset) => this.queryAssetId === parseInt(asset.id, 10))
          if (asset) {
            this.$onInfo(this.queryAssetId, this.assetFiles, modalActions, assetActions)
          } else {
            this.$bvToast.toast(
              this.$t('notifications_toaster_asset-view-noauth_message'),
              {
                title: this.$t('notifications_toaster_asset-view-noauth_title'),
                autoHideDelay: 5000,
                appendToast: true,
                variant: 'danger'
              }
            )
          }
        })
      }
    }
  },
  beforeCreate () {
    if (window.location.search.includes('asset_id=')) {
      this.$updateStoreParams('Assets')
    }
  },
  mounted () {
    // Only call the method if the loaded asset component is the one from the query, and watch for changes
    if (this.$options.name !== 'AssetImage' || (this.asset.id === this.queryAssetId)) {
      this.openModal()
    }
  }
}
