var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "module data-table-module" },
    [
      _c("module-controls", {
        attrs: {
          placeholder: _vm.placeholder,
          "action-buttons": _vm.actionButtons,
        },
        on: {
          "on-edit": _vm.$openModal,
          "on-remove": _vm.$deleteContentModuleConfirm,
        },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "module_content" }, [
        _vm._isAdminArea && _vm.isEmpty
          ? _c("div", { staticClass: "empty" }, [
              _vm._v("\n      This module is empty. Start editing!\n    "),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm._isAdminArea && _vm.tables.length === 0
          ? _c("div", { staticClass: "container display-mode" }, [
              _c(
                "p",
                { staticClass: "display-text" },
                [
                  _vm._v(
                    "\n        There are currently no data tables, please click\n        "
                  ),
                  _c(
                    "router-link",
                    { attrs: { to: "/admin/data-table-management/new" } },
                    [_vm._v("\n          here\n        ")]
                  ),
                  _vm._v("\n        to create one.\n      "),
                ],
                1
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.tableId
          ? _c(
              "div",
              [
                _vm.tableId && _vm.tableRows && _vm.columns
                  ? _c("DataTableContent", {
                      attrs: {
                        rows: _vm.tableRows,
                        columns: _vm.columns,
                        "table-id": _vm.tableId,
                      },
                    })
                  : _vm._e(),
              ],
              1
            )
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }