import Vue from 'vue'

const InitExtensions = (config) => {
  const settings = window.AppStore.getters['Settings/beamFeatures'] ?? null

  if (settings) {
    if ('enabled' === settings.dxg || 'published' === settings.dxg) {
      const Dxg = require('@core/plugins/Dxg').default
      Vue.use(Dxg, config)
    }

    if ('enabled' === settings.helpdesk || 'published' === settings.helpdesk) {
      const HelpDesk = require('@core/plugins/HelpDesk').default
      Vue.use(HelpDesk, config)
    }

    if ('enabled' === settings.pptGenerator || 'published' === settings.pptGenerator) {
      const PPTGenerator = require('@core/plugins/PPTGenerator').default
      Vue.use(PPTGenerator, config)
    }

    if ('enabled' === settings.showcase || 'published' === settings.showcase) {
      const Showcase = require('@core/plugins/Showcase').default
      Vue.use(Showcase, config)
    }

    if (true) {
      const FormEditor = require('@core/plugins/FormEditor').default
      Vue.use(FormEditor, config)
    }

    if (true) {
      const DataTables = require('@core/plugins/DataTables').default
      Vue.use(DataTables, config)
    }
  }
}

export { InitExtensions }
