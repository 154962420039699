import actions from '../actions/actions'
import mutations from '../mutations/mutations'

export default {
  namespaced: true,
  state: {
    theme: {},
    isLoading: false,
    newElementCreated: false,
    filterParameters: null,
    searchParameters: null
  },
  getters: {
    themeFooter: state => {
      return Object.keys(state.theme).length
        ? state.theme.themeFooter ? state.theme.themeFooter : {}
        : {}
    },
    themeStrings: state => {
      return state?.theme?.themeStrings ?? null
    }
  },
  mutations,
  actions
}
