var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "form-input",
      class: _vm.isToggle ? "toggle-input" : "checkbox-input",
    },
    [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.input,
            expression: "input",
          },
        ],
        attrs: {
          id: _vm.id,
          type: "checkbox",
          role: "checkbox",
          disabled: _vm.disabled,
        },
        domProps: {
          value: _vm.value,
          checked: Array.isArray(_vm.input)
            ? _vm._i(_vm.input, _vm.value) > -1
            : _vm.input,
        },
        on: {
          change: function ($event) {
            var $$a = _vm.input,
              $$el = $event.target,
              $$c = $$el.checked ? true : false
            if (Array.isArray($$a)) {
              var $$v = _vm.value,
                $$i = _vm._i($$a, $$v)
              if ($$el.checked) {
                $$i < 0 && (_vm.input = $$a.concat([$$v]))
              } else {
                $$i > -1 &&
                  (_vm.input = $$a.slice(0, $$i).concat($$a.slice($$i + 1)))
              }
            } else {
              _vm.input = $$c
            }
          },
        },
      }),
      _vm._v(" "),
      _c("label", {
        directives: [
          {
            name: "sanitize",
            rawName: "v-sanitize",
            value: _vm.label,
            expression: "label",
          },
        ],
        class: { disabled: _vm.disabled },
        attrs: { for: _vm.id },
      }),
      _vm._v(" "),
      _vm.required && _vm.error
        ? _c("div", {
            staticClass: "error",
            attrs: { id: `error_${_vm.id}` },
            domProps: { textContent: _vm._s(_vm.errorText.trim()) },
          })
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }