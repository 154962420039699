var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        role: "presentation",
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 16 16",
        "enable-background": "new 0 0 16 16",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M0 15h16v-1h-16v1zm9.5-7.5h2.7l-4.2 4.2-4.2-4.2h2.7v-6h3v6z",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }