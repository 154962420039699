var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.showEntitySwitcher &&
    _vm._entitiesIsPublished &&
    _vm.activeEntities.length > 0
    ? _c(
        "div",
        { staticClass: "entity-switcher" },
        [
          _c("VueSelect", {
            staticClass: "entities-dropdown",
            attrs: {
              clearable: false,
              options: _vm.activeEntities,
              searchable: false,
              label: "label",
            },
            on: { input: _vm.updateLocation },
            scopedSlots: _vm._u(
              [
                {
                  key: "open-indicator",
                  fn: function () {
                    return [_c("Icon", { attrs: { id: "ic_arrow_drop_down" } })]
                  },
                  proxy: true,
                },
                {
                  key: "selected-option",
                  fn: function (entity) {
                    return [
                      _c("i18n", {
                        attrs: {
                          path: "features_entities_entity-switcher-label",
                          tag: "span",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "entityName",
                              fn: function () {
                                return [_vm._v(_vm._s(entity.label))]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          true
                        ),
                      }),
                    ]
                  },
                },
                {
                  key: "option",
                  fn: function (entity) {
                    return [
                      entity.avatar_string
                        ? _c("EntityAvatar", { attrs: { entity: entity } })
                        : _vm._e(),
                      _vm._v(" "),
                      _c("span", [_vm._v(_vm._s(entity.label))]),
                      _vm._v(" "),
                      _vm.currentEntity && _vm.currentEntity.id === entity.id
                        ? _c(
                            "span",
                            { staticClass: "pull-right" },
                            [_c("Icon", { attrs: { id: "ic_done" } })],
                            1
                          )
                        : _vm._e(),
                    ]
                  },
                },
              ],
              null,
              false,
              2992839280
            ),
            model: {
              value: _vm.selectedEntity,
              callback: function ($$v) {
                _vm.selectedEntity = $$v
              },
              expression: "selectedEntity",
            },
          }),
          _vm._v(" "),
          _vm.activeEntities.length
            ? _c(
                "ul",
                { staticClass: "list-unstyled entities-list" },
                _vm._l(_vm.activeEntities, function (entity, index) {
                  return _c("li", { key: index }, [
                    _c(
                      "a",
                      {
                        class: {
                          active:
                            _vm.currentEntity &&
                            _vm.currentEntity.id === entity.id,
                        },
                        attrs: { href: "#" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.updateLocation(entity)
                          },
                        },
                      },
                      [_vm._v(_vm._s(entity.label))]
                    ),
                  ])
                }),
                0
              )
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }