var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("FileUploader", {
    ref: "uploader",
    staticClass: "form-input",
    class: {
      "has-error": _vm.required && _vm.error && _vm.validateUpload,
    },
    attrs: { "is-required": _vm.isRequired },
    on: { uploading: _vm.handleUploading },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }