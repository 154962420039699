import actions from '../actions/actions'
import mutations from '../mutations/mutations'

export default {
  namespaced: true,
  state: {
    options: [],
    filterParameters: null,
    searchParameters: null,
    isLoading: false
  },
  mutations,
  actions
}
