/**
 * Initialize App
 *
 * Mixin which loads base app data. Used in root vue app components:
 *
 * Check to see if user previously logged in, then load app settings, theme,
 * menu items, and such.
 *
 */

import { EventBus } from '@core/js'
import { mapState } from 'vuex'

export default {
  name: 'InitializeApp',
  computed: {
    ...mapState({
      content: state => state.Content,
      jwt: state => state.JWT.token,
      settings: state => state.Settings.settings,
      theme: state => state.Theme.theme,
      users: state => state.Users
    }),
    authRoutes () {
      return this.$route.meta.layout
        ? !!(this.$route.meta.layout === 'auth')
        : false
    },
    isAdminPage () {
      return !!(this.$route.path.indexOf('/admin') === 0)
    },
    permittedAreas () {
      return (this.users.currentUser) ? this.users.currentUser.permissions.areas : []
    }
  },
  watch: {
    theme (data) {
      const bodyStyles = document.querySelector('#fout')
      bodyStyles.nonce = __webpack_nonce__

      if (Object.entries(data).length > 0 && data.stylesLoaded) {
        // setTimeout allows theme setup to fully finish
        window.setTimeout(() => {
          bodyStyles.innerHTML = 'body { opacity: 1 !important }'
        }, 150)
      } else {
        bodyStyles.innerHTML = 'body { opacity: 0 !important }'
      }
    },
    $route (to, from) {
      if (this.isAdminPage && this.checkAdminRoute(to.path) === false) {
        // Deny access to unauthorized admin areas
        this.$router.push(this.$generateRoute({ name: 'unauthorized' }))
      }

      if (!this.jwt && !this.authRoutes && (this.isAdminPage || !this._publicIsPublished)) {
        // If no JWT AND not on an auth route AND either an admin page OR not an active public site
        this.$router.push(this.$generateRoute({ name: 'auth.login' }))
      }

      this.setHtmlClass()

      // reset focus to top-of-document on every route navigation
      // this helps accessibility by not preserving focus somewhere in the middle of the page
      document.activeElement.blur()
      const app = document.getElementById('app')
      if (app && !to.meta.preserveFocus) {
        app.focus()
      }
      // And any open menu dropdowns need to close
      this.closeFocusedDropdowns()
    }
  },
  methods: {
    checkAdminRoute (path) {
      // super admins can do anything
      if (this._isSuperAdminUser) {
        return true
      }
      // area string does not include IDs
      // remove digits (IDs) and any double, leading, or trailing slashes, and trim whitespace
      const cleanPath = path.replace('/admin', '').replace(/[0-9]/g, '').replace(/\/\//g, '/').replace(/^\/|\/$/g, '').trim()

      if (cleanPath === '') {
        // A blank cleanPath will take users to the dashboard, dashboard is not part of the areas
        return true
      }

      const areas = this.permittedAreas
      if (areas.length > 0) {
        if (cleanPath.includes('settings/') || cleanPath.includes('helpdesk/requests')) {
          // settings and helpdesk have specific areas that can be locked down
          // settings have sub-sub-areas that can be ignored, so they need to be removed from the path
          const parts = cleanPath.split('/')
          return areas.includes(parts[0] + '/' + parts[1])
        } else {
          // all other areas only need "top level" access
          const subPaths = cleanPath.split('/')
          return areas.includes(subPaths[0])
        }
      }

      return false
    },
    setHtmlClass () {
      if (this.isAdminPage) {
        document.documentElement.className = 'admin'
      } else {
        document.documentElement.className = 'main'
      }
    },
    closeFocusedDropdowns () {
      let items = document.getElementsByClassName('nav-toggle')

      for (let i = 0; i < items.length; i++) {
        items[i].ariaExpanded = false
      }
    },
    findFocusableElement (container) {
      let children = container.children

      if (children.length === 0) {
        return
      }

      for (let i = 0; i < children.length; i++) {
        let item = children[i]

        if (item.tabIndex === 0) {
          return item
        }

        let result = this.findFocusableElement(item)

        if (result) {
          return result
        }
      }
    },
    skipToMainContent () {
      if (this.$route.name === 'home.index') { // Homepage
        let container = document.querySelector('.main__article')
        let item = this.findFocusableElement(container)

        if (item) {
          item.focus()
        }
      } else { // Content page
        let interiorBanner = document.getElementById('interior-page-banner-container')
        let item = this.findFocusableElement(interiorBanner)
        
        if (item) {
          item.focus()
        }
      }
    },
    initNavigationEvents () {
      this.toggleDropdown()
      this.closeDropdownFocusOut()
      this.clearFocusOnEnter()
    },
    toggleDropdown () {
      let navToggle = document.getElementsByClassName('nav-toggle')

      if (navToggle) {
        for (let i = 0; i < navToggle.length; i++) {
          navToggle[i].addEventListener('keyup', (e) => {
            if (e.key === 'Enter' || e.key === ' ' || e.key === 'Spacebar') {
              this.toggleAriaExpanded(e.target)
            }
          })
        }
      }
    },
    toggleAriaExpanded (element) {
      let ariaExpanded = element.getAttribute('aria-expanded')

      if (ariaExpanded === 'true') {
        ariaExpanded = 'false'
      } else {
        ariaExpanded = 'true'
      }

      element.ariaExpanded = ariaExpanded
    },
    findUpTag (el, className, stopAt) {
      while (!el.classList.contains(stopAt)) {
        el = el.parentNode

        if (el.classList.contains(className)) {
          return el
        }
      }

      return null
    },
    closeDropdownFocusOut () {
      let primaryWrapperEl = document.getElementsByClassName('primary-wrapper')

      if (primaryWrapperEl.length > 0) {
        primaryWrapperEl[0].addEventListener('keyup', (e) => {
          if (e.key === 'Tab') {
            if (!this.isMenuFocused()) {
              this.closeFocusedDropdowns()
            }
          }
        })
      }
    },
    isMenuFocused () {
      let isItemFromMenu = !!this.findUpTag(document.activeElement, 'nav-menu', 'primary')
      let isExpandedNavToggle = document.activeElement.classList.contains('nav-toggle') &&
        (document.activeElement.getAttribute('aria-expanded') === 'true')

      return isItemFromMenu || isExpandedNavToggle
    },
    clearFocusOnEnter () {
      let navItemsEl = document.getElementsByClassName('nav-items')

      if (navItemsEl.length > 0) {
        navItemsEl[0].addEventListener('keyup', (e) => {
          if (e.key === 'Enter') {
            if (!e.target.classList.contains('nav-toggle') && e.target.tagName !== 'SPAN') {
              document.getElementById('app').focus()
              this.closeFocusedDropdowns()
            }
          }
        })
      }
    }
  },
  async beforeCreate () {
    // Enable this once associated sites is implemented
    this.$store.dispatch('Settings/getProperty', {
      collection: 'associateDomains',
      url: 'settings/associated'
    })

    await this.$store.dispatch('JWT/getJWT')

    if (this.jwt) {
      if (window.beam.area !== 'cms') {
        await this.$store.dispatch('Entities/getProperty', {
          collection: 'entities',
          url: 'account/entities'
        })
      }

      this.$store.dispatch('Bookmarks/getProperty', {
        collection: 'bookmarks',
        url: 'account/bookmarks'
      })
    }

    await this.$store.dispatch('Theme/getProperty', {
      collection: 'theme',
      url: 'config/theme'
    })

    this.$store.dispatch('Forms/getProperty', {
      collection: 'forms',
      element: 'forms',
      url: 'forms'
    })

    this.$setFavicon()
    this.$setStyles()
  },
  mounted () {
    window.addEventListener('mouseup', (e) => {
      this.closeFocusedDropdowns()
    })

    window.addEventListener('keyup', (e) => {
      if (e.keyCode === 27) {
        // escape key
        this.closeFocusedDropdowns()
      }
    })

    const skipLink = document.getElementById('skip-link')
    // This doesn't exist in the CMS, so we need to check to prevent a console error
    if (skipLink) {
      skipLink.addEventListener('keyup', (e) => {
        if (e.key === 'Enter') {
          // return/enter key
          this.skipToMainContent()
        }
      })
    }

    EventBus.$on('nav-items-loaded', () => {
      setTimeout(() => {
        this.initNavigationEvents()
      }, 500)
    })
  }
}
