import * as types from "./mutation-types";
import Vue from "vue";

export default {
  [types.SET_ERRORS](state, data) {
    state.errors = data;
  },
  [types.SET_SUCCESS](state, data) {
    state.success = data;
  },
  [types.SET_INFO](state, data) {
    state.info = data;
    /*state.info = {
      title: 'Title',
      message: 'Message'
    }*/
  },
  [types.SET_PROPERTY](state, data) {
    state[data.collection] = data.data;
  },
  /**
   * Sets a nested object. Can either create and new obj
   * if one does not exist or update existing property
   */
  [types.SET_NESTED_PROPERTY](state, data) {
    let index = state[data.collection].findIndex(
      (item) => item.id === data.data.id
    );

    if (index !== -1) {
      Vue.set(state[data.collection], index, data.data);
    } else {
      state[data.collection].push(data.data);
    }
  },
  [types.UPDATE_NESTED_PROPERTY](state, data) {
    Object.assign(
      state[data.collection].find((item) => item.id === data.data.id),
      data.data
    );
  },
  /**
   * @todo look at action, only need to send collection not whole obj
   */
  [types.DELETE_PROPERTY](state, data) {
    state[data.collection] = false;
  },
  [types.DELETE_NESTED_PROPERTY](state, data) {
    let index = state[data.collection].findIndex((obj) => {
      return obj.id === data.data.id;
    });
    if (index > -1) {
      state[data.collection].splice(index, 1);
    }
  },
  /**
   * @todo use collection name instead of randomId,
   * that way we can do more precise loading feedback
   * instead of the awful global overlay.
   *
   * if (data.collection) {
   *  state[data.collection]?.isLoading = data.status
   * }
   *
   */
  [types.SET_LOADING_STATUS](state, data) {
    let o = state.isLoading;
    if (data.status) {
      state.isLoading = Object.assign({}, o, { [data.id]: data.status });
    } else {
      delete o[data.id];
      state.isLoading = Object.assign({}, o);
    }
  },
  [types.SET_PROPERTY_CREATED](state, data) {
    state.newElementCreated = data;
  },
};
