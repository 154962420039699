var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { class: ["filter", { active: _vm.showFilter }] }, [
    _c(
      "div",
      { staticClass: "filter-list-controls" },
      [
        _c("search-form", {
          ref: "search_" + _vm.libModID,
          attrs: {
            local: true,
            "auto-search": false,
            "lib-mod-i-d": _vm.libModID,
          },
          on: { loadAssets: _vm.onSearch },
        }),
        _vm._v(" "),
        _vm.filter_categories && _vm.filter_categories.length
          ? _c("div", { staticClass: "filter-toggle" }, [
              !_vm.showFilter
                ? _c(
                    "button",
                    {
                      staticClass: "primary filter-button",
                      attrs: {
                        type: "button",
                        role: "button",
                        title: _vm.$t("filters_actions_open_label"),
                      },
                      on: {
                        click: function ($event) {
                          !_vm._isAdminArea ? (_vm.showFilter = true) : null
                        },
                      },
                    },
                    [
                      _c("icon", { attrs: { id: "ic_tune" } }),
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("filters_actions_open_label")) +
                          "\n      "
                      ),
                    ],
                    1
                  )
                : _c(
                    "button",
                    {
                      staticClass: "primary filter-button",
                      attrs: {
                        type: "button",
                        role: "button",
                        title: _vm.$t("filters_actions_close_label"),
                      },
                      on: {
                        click: function ($event) {
                          !_vm._isAdminArea ? (_vm.showFilter = false) : null
                        },
                      },
                    },
                    [
                      _c("icon", { attrs: { id: "ic_tune" } }),
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("filters_actions_close_label")) +
                          "\n      "
                      ),
                    ],
                    1
                  ),
            ])
          : _vm._e(),
      ],
      1
    ),
    _vm._v(" "),
    _vm.showShareLink
      ? _c("div", { staticClass: "share-controls" }, [
          _c(
            "button",
            { staticClass: "btn text-btn", on: { click: _vm.openModal } },
            [
              _c("icon", { attrs: { id: "ic_share" } }),
              _vm._v("\n      Share Filtered Library\n    "),
            ],
            1
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.showFilter && _vm.filter_categories.length
      ? _c(
          "section",
          { staticClass: "filter-list" },
          [
            _vm._l(_vm.filter_categories, function (c) {
              return [
                _c(
                  "div",
                  { key: `category-${c.id}`, staticClass: "filter-categories" },
                  [
                    _c("h4", [_vm._v(_vm._s(c.label))]),
                    _vm._v(" "),
                    _c(
                      "ul",
                      [
                        _vm._l(_vm.getFilters(c.id), function (t) {
                          return [
                            _c("li", { key: `filter-${t.id}` }, [
                              _c(
                                "div",
                                { staticClass: "form-input checkbox-input" },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.selectedFilters,
                                        expression: "selectedFilters",
                                      },
                                    ],
                                    attrs: {
                                      id: `filterChoice_${_vm.id}_${t.id}`,
                                      type: "checkbox",
                                      name: `filterChoice_${_vm.id}`,
                                    },
                                    domProps: {
                                      value: t.id,
                                      checked: Array.isArray(
                                        _vm.selectedFilters
                                      )
                                        ? _vm._i(_vm.selectedFilters, t.id) > -1
                                        : _vm.selectedFilters,
                                    },
                                    on: {
                                      change: function ($event) {
                                        var $$a = _vm.selectedFilters,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = t.id,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              (_vm.selectedFilters = $$a.concat(
                                                [$$v]
                                              ))
                                          } else {
                                            $$i > -1 &&
                                              (_vm.selectedFilters = $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1)))
                                          }
                                        } else {
                                          _vm.selectedFilters = $$c
                                        }
                                      },
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("label", {
                                    directives: [
                                      {
                                        name: "sanitize",
                                        rawName: "v-sanitize",
                                        value: t.label,
                                        expression: "t.label",
                                      },
                                    ],
                                    attrs: {
                                      for: `filterChoice_${_vm.id}_${t.id}`,
                                    },
                                  }),
                                ]
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "description" }, [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(t.description) +
                                    "\n              "
                                ),
                              ]),
                            ]),
                          ]
                        }),
                      ],
                      2
                    ),
                  ]
                ),
              ]
            }),
          ],
          2
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.showFilter && _vm.filter_categories.length
      ? _c(
          "button",
          {
            staticClass: "clear-filters secondary",
            attrs: {
              type: "button",
              title: _vm.$t("filters_actions_clear_label"),
            },
            on: { click: _vm.clearFilters },
          },
          [
            _vm._v(
              "\n    " + _vm._s(_vm.$t("filters_actions_clear_label")) + "\n  "
            ),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }