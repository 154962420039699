var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: _vm.appClasses, attrs: { id: "page", role: "document" } },
    [
      _c(
        "button",
        {
          staticClass: "cta-button primary",
          attrs: {
            id: "skip-link",
            title: _vm.$t("site_messaging_skip-to-content"),
          },
          on: { click: _vm.skipToMainContent },
        },
        [
          _vm._v(
            "\n    " + _vm._s(_vm.$t("site_messaging_skip-to-content")) + "\n  "
          ),
        ]
      ),
      _vm._v(" "),
      _c(_vm.layout, { tag: "component" }),
      _vm._v(" "),
      _vm.modalType
        ? _c(_vm.modalType, {
            tag: "component",
            attrs: { "ready-prop": _vm.modalReady },
            on: { ready: _vm.modalReadyEmit },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("AppToaster"),
      _vm._v(" "),
      _c("Impersonate"),
      _vm._v(" "),
      _vm.backToTopVisibility ? _c("BackToTop") : _vm._e(),
      _vm._v(" "),
      _c("IconSet"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }