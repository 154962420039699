export default {
  data: () => {
    return {
      windowWidth: 0
    }
  },
  computed: {
    mobileBreakpoint () {
      return (this.$store.state.Theme.theme &&
        this.$store.state.Theme.theme.themeStyles &&
        this.$store.state.Theme.theme.themeStyles.mobile_nav_breakpoint_end)
        ? parseInt(this.$store.state.Theme.theme.themeStyles.mobile_nav_breakpoint_end, 10)
        : 1024
    },
    overrideMobile () {
      return this.windowWidth <= this.mobileBreakpoint
    }
  },
  mounted () {
    this.windowWidth = window.innerWidth
    this.$nextTick(() => {
      window.addEventListener('resize', () => {
        this.windowWidth = window.innerWidth
      })
    })
  }
  // NOTE: may want to add beforeDestroy
  // beforeDestroy() {
  //   window.removeEventListener("resize", this.windowWidth);
  // }
}
