var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "popular-asset" }, [
    _vm.iconUrl
      ? _c("img", {
          staticClass: "popular-icon",
          attrs: { src: _vm.iconUrl, alt: _vm.title },
        })
      : _vm._e(),
    _vm._v(" "),
    _vm.title
      ? _c("h4", [_vm._v("\n    " + _vm._s(_vm.title) + "\n  ")])
      : _vm._e(),
    _vm._v(" "),
    _vm.assets.length
      ? _c(
          "ul",
          [
            _vm._l(_vm.assets, function (asset) {
              return [
                _c("li", { key: asset.id, staticClass: "item" }, [
                  _c("div", { attrs: { id: "asset-module-" + asset.id } }, [
                    _c("div", { staticClass: "container" }, [
                      _c("div", { staticClass: "asset-container" }, [
                        _c("div", { staticClass: "asset-wrapper" }, [
                          _c(
                            "a",
                            {
                              attrs: { href: `/assets/info/${asset.id}` },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.$onInfo(asset.id, _vm.assets)
                                },
                              },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: asset.thumbnail_url,
                                  alt: asset.title,
                                },
                              }),
                            ]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "asset-buttons" }, [
                            _c(
                              "button",
                              {
                                staticClass: "secondary",
                                attrs: {
                                  type: "button",
                                  title: _vm.$t("site_actions_download_label"),
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.$onAsset(asset.id, asset.title)
                                  },
                                },
                              },
                              [
                                _c("icon", {
                                  attrs: { id: _vm._download_get },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "button",
                              {
                                staticClass: "secondary",
                                attrs: {
                                  type: "button",
                                  title: _vm.$t("site_actions_email_label"),
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.$onEmail(asset.id)
                                  },
                                },
                              },
                              [_c("icon", { attrs: { id: "ic_email" } })],
                              1
                            ),
                          ]),
                        ]),
                      ]),
                    ]),
                  ]),
                ]),
              ]
            }),
          ],
          2
        )
      : _c("p", [_vm._v("\n    No assets available\n  ")]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }