import { mapState, mapGetters } from 'vuex'

export default {
  install (Vue) {
    Vue.mixin({
      data() {
        return {
          logoKeys: [
            'primary_site_logo',
            'secondary_site_logo'
          ],
        }
      },
      computed: {
        ...mapState({
          settings: (state) => state.Settings.settings,
          currentEntity: (state) => state.Entities.currentEntity,
        }),
        ...mapGetters({
          coreSettings: ['Settings/coreSettings']
        }),
        _globalImages() {
          let images = {};

          if (this._entitiesIsPublished) {
            let logos = this.coreSettings.filter(setting => {
              if (this.logoKeys.includes(setting.key)) {
                const settingId = (setting.entity_id) ? parseInt(setting.entity_id, 10) : null
                return settingId === this.currentEntity?.id
              }
              return false
            })

            const primaryLogo = logos.find(setting => setting.key === 'primary_site_logo')
            images.primaryLogo = primaryLogo?.value ?? null;

            const secondaryLogo = logos.find(setting => setting.key === 'secondary_site_logo')
            images.secondaryLogo = secondaryLogo?.value ?? null;

            return images
          }

          const primaryLogo = this.settings.find(
            (setting) => setting.key === "primary_site_logo"
          );
          images.primaryLogo = primaryLogo?.value ?? null;

          const secondaryLogo = this.settings.find(
            (setting) => setting.key === "secondary_site_logo"
          );
          images.secondaryLogo = secondaryLogo?.value ?? null;

          return images;
        },
      },
    });
  }
}
