var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "module search-module" },
    [
      _c("module-controls", {
        attrs: {
          placeholder: _vm.$t("forms_search_field_placeholder"),
          "action-buttons": _vm._actionButtons,
        },
        on: {
          "on-edit": _vm.$openModal,
          "on-remove": _vm.$deleteContentModuleConfirm,
        },
      }),
      _vm._v(" "),
      _c("search-bar", {
        class: { admin: _vm._isAdminArea },
        attrs: { "component-data": _vm.moduleData },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }