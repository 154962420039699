var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "module image-module",
      class: { "full-bleed": _vm.isFullBleed },
    },
    [
      _c("module-controls", {
        attrs: { placeholder: "Image", "action-buttons": _vm._actionButtons },
        on: {
          "on-edit": _vm.$openModal,
          "on-remove": _vm.$deleteContentModuleConfirm,
        },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "module_content" }, [
        _vm.isEmpty
          ? _c("div", { staticClass: "empty" }, [
              _c(
                "div",
                { staticClass: "nodropzone" },
                [
                  _c("icon", { attrs: { id: "ic_photo_library" } }),
                  _vm._v("\n        No image "),
                  _vm._isAdminArea
                    ? _c("span", [
                        _vm._v("-- Please edit module to upload image"),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c(
          "figure",
          { class: _vm.figureClass, style: _vm.figureStyles },
          [
            _vm.link
              ? [
                  _c(
                    "a",
                    {
                      staticClass: "img-link link-reset",
                      attrs: {
                        href: _vm.link,
                        "aria-label": _vm.caption
                          ? `$t('site_messaging_read-more_label') ${_vm.caption}`
                          : null,
                        target: _vm.target ? "_blank" : false,
                      },
                    },
                    [
                      _c("img", {
                        style: [
                          {
                            maxWidth: _vm.image_data
                              ? _vm.image_data.maxWidth + "%"
                              : "100%",
                          },
                          _vm.imageAlignment,
                        ],
                        attrs: {
                          src: _vm.url,
                          alt: _vm.alt,
                          width: _vm.image_data ? _vm.image_data.width : "",
                          height: _vm.image_data ? _vm.image_data.height : "",
                        },
                      }),
                      _vm._v(" "),
                      _vm.caption
                        ? _c("figcaption", [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.caption) +
                                "\n          "
                            ),
                          ])
                        : _vm._e(),
                    ]
                  ),
                ]
              : [
                  _c("img", {
                    style: [
                      {
                        maxWidth: _vm.image_data
                          ? _vm.image_data.maxWidth + "%"
                          : "100%",
                      },
                      _vm.imageAlignment,
                    ],
                    attrs: {
                      src: _vm.url,
                      alt: _vm.alt,
                      width: _vm.image_data ? _vm.image_data.width : "",
                      height: _vm.image_data ? _vm.image_data.height : "",
                    },
                  }),
                  _vm._v(" "),
                  _vm.caption
                    ? _c("figcaption", [
                        _vm._v(
                          "\n          " + _vm._s(_vm.caption) + "\n        "
                        ),
                      ])
                    : _vm._e(),
                ],
          ],
          2
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }