module.exports = {
  ic_beam_cog: {
    viewbox: '0 0 24 24',
    content: '<path d="M14.4,22.1L14,21.8c-1.1-1-2.8-1-4,0l-0.4,0.4L9.1,22c-0.7-0.2-1.4-0.5-2.1-0.8l-0.5-0.3l0-0.5c-0.1-1.5-1.3-2.7-2.8-2.8l-0.5,0L2.9,17c-0.4-0.7-0.6-1.3-0.9-2.1l-0.2-0.5L2.2,14c1-1.1,1-2.8,0-4L1.9,9.6L2,9.1C2.2,8.4,2.5,7.7,2.9,7l0.3-0.5l0.5,0c1.5-0.1,2.7-1.3,2.8-2.8l0-0.5L7,2.9C7.7,2.5,8.4,2.2,9.1,2l0.5-0.2L10,2.2c1.1,1,2.7,1,3.9,0.1l0.5-0.5L14.9,2c0.7,0.2,1.4,0.5,2,0.8l0.5,0.3l0,0.5c0.1,1.5,1.3,2.7,2.8,2.8l0.5,0L21.1,7c0.4,0.6,0.6,1.3,0.8,2l0.2,0.5L21.8,10c-1,1.1-1,2.8,0,4l0.4,0.4L22,14.9c-0.2,0.7-0.5,1.4-0.8,2.1l-0.3,0.5l-0.5,0c-1.5,0.1-2.7,1.3-2.8,2.8l0,0.5L17,21.1c-0.6,0.4-1.3,0.6-2.1,0.9L14.4,22.1z M12,19c1,0,2,0.3,2.8,0.9c0.2-0.1,0.5-0.2,0.7-0.3c0.4-2,2-3.6,4-4c0.1-0.2,0.2-0.5,0.3-0.7c-1.2-1.7-1.2-4,0-5.7c-0.1-0.2-0.2-0.5-0.3-0.7c-2-0.4-3.6-2-4-4c-0.2-0.1-0.5-0.2-0.7-0.3c-1.7,1.2-4,1.2-5.7,0C8.9,4.2,8.7,4.3,8.4,4.4c-0.4,2-2,3.6-4,4C4.3,8.7,4.2,8.9,4.1,9.2c1.2,1.7,1.2,4,0,5.7c0.1,0.2,0.2,0.5,0.3,0.7c2,0.4,3.6,2,4,4c0.2,0.1,0.5,0.2,0.7,0.3C10,19.3,11,19,12,19z M12,9c1.7,0,3,1.3,3,3s-1.3,3-3,3s-3-1.3-3-3S10.3,9,12,9z"/>'
  },
  ic_beam_briefcase: {
    viewbox: '0 0 24 24',
    content: '<path d="M21,21H3V7h18V21z M5,19h14V9H5V19z M17,7h-2V5H9v2H7V3h10V7z M13,15.5h2v-2h-2v-2h-2v2H9v2h2v2h2V15.5z"/>'
  },
  ic_beam_logout: {
    viewbox: '0 0 24 24',
    content: '<path d="M16.9,6.4l-1.5,1.5l3.2,3.1H14V3H3v18h11v-8h4.5l-3.1,3.2l1.5,1.5l5.6-5.6L16.9,6.4z M12,13v6H5V5h7v6H7.5v2H12z"/>'
  }
}
