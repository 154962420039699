var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      staticStyle: { "enable-background": "new 0 0 18.8 20" },
      attrs: {
        role: "presentation",
        version: "1.1",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
        x: "0px",
        y: "0px",
        viewBox: "0 0 18.8 20",
        "xml:space": "preserve",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M5.6,0c0.6,0,1,0.4,1,1l0,6.2h5.5l0-6.2c0-0.6,0.4-1,1-1h4.6c0.6,0,1,0.4,1,1v18c0,0.6-0.4,1-1,1h-4.6c-0.6,0-1-0.4-1-1\n  l0-6.2H6.6l0,6.2c0,0.6-0.4,1-1,1H1c-0.6,0-1-0.4-1-1V1c0-0.6,0.4-1,1-1H5.6z",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }