var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "form",
    {
      staticClass: "search-form",
      attrs: {
        id: "search-form",
        autocomplete: "disabled",
        role: "search",
        "aria-label": _vm.$t("layout_regions_site-search_label"),
      },
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.onSubmit($event, true)
        },
      },
    },
    [
      _c("div", { staticClass: "search-term" }, [
        _c("label", { attrs: { for: `search-term_${_vm.id}` } }, [
          _vm._v(_vm._s(_vm.$t("forms_search_field_label"))),
        ]),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.term,
              expression: "term",
            },
          ],
          ref: "input",
          attrs: {
            id: `search-term_${_vm.id}`,
            type: "search",
            name: "search",
            autocomplete: "off",
            placeholder: _vm.$t("forms_search_field_placeholder"),
            "aria-label": _vm.$t("forms_search_field_label"),
            "aria-required": "true",
          },
          domProps: { value: _vm.term },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.term = $event.target.value
            },
          },
        }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "search-submit" }, [
        _c(
          "button",
          {
            attrs: {
              type: !_vm._isAdminArea ? "submit" : "button",
              title: _vm.$t("site_actions_submit_label"),
            },
          },
          [_c("icons", { attrs: { name: "search" } })],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }