var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.menuStack
    ? _c(
        "div",
        {
          directives: [
            {
              name: "scroll",
              rawName: "v-scroll",
              value: _vm.stickyScroll,
              expression: "stickyScroll",
            },
            {
              name: "resize",
              rawName: "v-resize",
              value: _vm.stickyScroll,
              expression: "stickyScroll",
            },
          ],
          ref: "navMenu",
          staticClass: "left-nav-container",
        },
        [
          _c("menu", [
            typeof _vm.menuStack === "object"
              ? _c(
                  "ul",
                  { staticClass: "level_1 list-unstyled" },
                  [
                    _c(
                      "li",
                      [
                        (_vm.menuStack ? _vm.menuStack.type === "page" : false)
                          ? _c(
                              "router-link",
                              {
                                class: [
                                  {
                                    active:
                                      _vm.menuStack.id === _vm.menuActive.id,
                                  },
                                ],
                                attrs: {
                                  title: _vm.menuStack.label,
                                  to: _vm.$getRouteObject(_vm.menuStack),
                                },
                              },
                              [
                                _vm._v(
                                  "\n          " +
                                    _vm._s(_vm.menuStack.label) +
                                    "\n        "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        (
                          _vm.menuStack
                            ? _vm.menuStack.type === "external"
                            : false
                        )
                          ? _c("a", {
                              attrs: {
                                href: _vm.menuStack
                                  ? _vm.menuStack.action
                                  : "#",
                                target: _vm.menuStack
                                  ? _vm.menuStack.new_tab
                                    ? "_blank"
                                    : "_self"
                                  : "_self",
                              },
                              domProps: {
                                textContent: _vm._s(
                                  _vm.menuStack ? _vm.menuStack.label : ""
                                ),
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        (
                          _vm.menuStack
                            ? _vm.menuStack.type === "directory"
                            : false
                        )
                          ? _c("span", [
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.menuStack.label) +
                                  "\n        "
                              ),
                            ])
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    (
                      _vm.menuStack
                        ? _vm.menuStack.id === _vm.menuActive.id
                        : false
                    )
                      ? _c("NavLinksAnchors", {
                          attrs: {
                            "section-headlines": _vm.sectionHeadlines,
                            sections: _vm.sections,
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm._l(
                      _vm.menuStack ? _vm.menuStack.children_tree : [],
                      function (item_l2, index) {
                        return _c(
                          "ul",
                          {
                            key: `ul_2_${index}`,
                            class: [
                              "level_2",
                              "list-unstyled",
                              { "has-sub": item_l2.type === "directory" },
                            ],
                          },
                          [
                            _c(
                              "li",
                              { key: `li_2_${index}` },
                              [
                                item_l2.type === "page"
                                  ? _c(
                                      "router-link",
                                      {
                                        class: [
                                          {
                                            active:
                                              item_l2.id === _vm.menuActive.id,
                                          },
                                        ],
                                        attrs: {
                                          title: item_l2.label,
                                          to: _vm.$getRouteObject(item_l2),
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n            " +
                                            _vm._s(item_l2.label) +
                                            "\n          "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                item_l2.type === "external"
                                  ? _c("a", {
                                      attrs: {
                                        href: item_l2.action,
                                        target: item_l2.new_tab
                                          ? "_blank"
                                          : "_self",
                                      },
                                      domProps: {
                                        textContent: _vm._s(item_l2.label),
                                      },
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                item_l2.type === "directory"
                                  ? _c("span", [_vm._v(_vm._s(item_l2.label))])
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            item_l2.id === _vm.menuActive.id
                              ? _c("NavLinksAnchors", {
                                  key: `nav_2_${index}`,
                                  attrs: {
                                    "section-headlines": _vm.sectionHeadlines,
                                    sections: _vm.sections,
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _vm._l(
                              item_l2.children_tree,
                              function (item_l3, index2) {
                                return _c(
                                  "ul",
                                  {
                                    key: `ul_2_${index2}`,
                                    class: [
                                      "level_3",
                                      "list-unstyled",
                                      {
                                        "has-sub": item_l3.type === "directory",
                                      },
                                    ],
                                  },
                                  [
                                    _c(
                                      "li",
                                      { key: `li_3_${index2}` },
                                      [
                                        item_l3.type === "page"
                                          ? _c(
                                              "router-link",
                                              {
                                                class: [
                                                  {
                                                    active:
                                                      item_l3.id ===
                                                      _vm.menuActive.id,
                                                  },
                                                ],
                                                attrs: {
                                                  title: item_l3.label,
                                                  to: _vm.$getRouteObject(
                                                    item_l3
                                                  ),
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n              " +
                                                    _vm._s(item_l3.label) +
                                                    "\n            "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        item_l3.type === "external"
                                          ? _c("a", {
                                              attrs: {
                                                href: item_l3.action,
                                                target: item_l3.new_tab
                                                  ? "_blank"
                                                  : "_self",
                                              },
                                              domProps: {
                                                textContent: _vm._s(
                                                  item_l3.label
                                                ),
                                              },
                                            })
                                          : _vm._e(),
                                        _vm._v(" "),
                                        item_l3.type === "directory"
                                          ? _c("span", [
                                              _vm._v(_vm._s(item_l3.label)),
                                            ])
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    item_l3.id === _vm.menuActive.id
                                      ? _c("NavLinksAnchors", {
                                          key: `nav_3_${index2}`,
                                          attrs: {
                                            "section-headlines":
                                              _vm.sectionHeadlines,
                                            sections: _vm.sections,
                                          },
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm._l(
                                      item_l3.children_tree,
                                      function (item_l4, index3) {
                                        return _c(
                                          "ul",
                                          {
                                            key: `ul_3_${index3}`,
                                            staticClass:
                                              "level_4 list-unstyled",
                                          },
                                          [
                                            _c(
                                              "li",
                                              { key: `li_4_${index3}` },
                                              [
                                                item_l4.type === "page"
                                                  ? _c(
                                                      "router-link",
                                                      {
                                                        class: [
                                                          {
                                                            active:
                                                              item_l4.id ===
                                                              _vm.menuActive.id,
                                                          },
                                                        ],
                                                        attrs: {
                                                          title: item_l4.label,
                                                          to: _vm.$getRouteObject(
                                                            item_l4
                                                          ),
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                " +
                                                            _vm._s(
                                                              item_l4.label
                                                            ) +
                                                            "\n              "
                                                        ),
                                                      ]
                                                    )
                                                  : _c("span", [
                                                      _vm._v(
                                                        _vm._s(item_l4.label)
                                                      ),
                                                    ]),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            item_l4.id === _vm.menuActive.id
                                              ? _c("NavLinksAnchors", {
                                                  key: `nav_4_${index3}`,
                                                  attrs: {
                                                    "section-headlines":
                                                      _vm.sectionHeadlines,
                                                    sections: _vm.sections,
                                                  },
                                                })
                                              : _vm._e(),
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                  ],
                                  2
                                )
                              }
                            ),
                          ],
                          2
                        )
                      }
                    ),
                  ],
                  2
                )
              : _vm._e(),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }