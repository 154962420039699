var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "module cta-module" },
    [
      _c("module-controls", {
        attrs: {
          placeholder: _vm.moduleLabel,
          "action-buttons": _vm._actionButtons,
        },
        on: {
          "on-edit": _vm.$openModal,
          "on-remove": _vm.$deleteContentModuleConfirm,
        },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "module_content" }, [
        _c("div", { class: ["button-wrapper", _vm.moduleData.alignment] }, [
          _c(
            "a",
            {
              class: ["cta-button", _vm.buttonType],
              attrs: { href: _vm.moduleData.url, target: _vm.buttonTarget },
            },
            [_vm._v("\n        " + _vm._s(_vm.buttonText) + "\n      ")]
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }