var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [
      _vm._globalImages.primaryLogo
        ? [
            _c(
              "div",
              {
                class: `${_vm.headerLogo}`,
                style: {
                  backgroundImage: `url(${_vm._globalImages.primaryLogo})`,
                },
              },
              [_vm._m(0)]
            ),
          ]
        : _vm._e(),
      _vm._v(" "),
      [
        _c("div", { class: `${_vm.headerSiteName}` }, [
          _c("h1", [
            _vm._v(
              "\n        " +
                _vm._s(_vm.$t("site_messaging_site-name_label")) +
                "\n      "
            ),
          ]),
        ]),
      ],
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("a", { attrs: { href: "/" } }, [_c("span", [_vm._v("Home")])])
  },
]
render._withStripped = true

export { render, staticRenderFns }