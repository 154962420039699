export default {
  path: 'localization',
  component: () => import(
    /* webpackChunkName: "admin-settings-localization-index" */
    '@cms/components/settings/localization/index.vue'),

  children: [
    {
      path: '',
      name: 'admin.localization.index',
      component: () => import(
        /* webpackChunkName: "admin-settings-localization-text" */
        '@cms/components/settings/localization/strings.vue')
    }
  ]
}
