import ArchiveModal from "@core/components/modals/archive.vue";
import ClientVideoModal from "@core/components/modals/video.vue";
import EmailFileModal from "@core/components/modals/email-file.vue";
import FileModal from "@core/components/modals/file.vue";
import LightboxModal from "@core/components/modals/lightbox.vue";
import LinkCopy from "@core/components/modals/link-copy.vue";
import ResizeModal from "@core/components/modals/resize.vue";
import RequestDownloadModal from "@core/components/modals/request-download.vue";
import ShareFileModal from "@core/components/modals/ShareFileModal.vue";
import AssetTracking from "@core/components/modals/asset-tracking/index.vue";

export default {
  name: "ModalsImport",
  components: {
    ArchiveModal,
    AssetTracking,
    ClientVideoModal,
    EmailFileModal,
    FileModal,
    LightboxModal,
    LinkCopy,
    ResizeModal,
    RequestDownloadModal,
    ShareFileModal,
  },
};
