import { mapState } from 'vuex'

export default {
  name: 'FormEditorMixin',
  computed: {
    ...mapState({
      forms: state => state.Forms.forms,
      formComponents: state => state.FormComponents.formComponents,
      formComponentOptions: state => state.FormComponentOptions.formComponentOptions
    }),
    formId () {
      return parseInt(this.$route.params.formId)
    },
    forms () {
      return this.$store.state.Forms.forms
    },
    form () {
      return this.forms?.find(item => item.id === this.formId) ?? null
    },
    currentForm () {
      return this.$store.state.Forms.currentForm
    },
    isFormUpdated: {
      get () {
        return this.$store.state.Forms.formUpdate?.['form-editor'] ?? false
      },
      set (value) {
        this.$store.state.Forms.eventType = value
      }
    },
    eventType: {
      get () {
        return this.$store.state.Forms.eventType
      },
      set (value) {
        this.$store.state.Forms.eventType = value
      }
    }
  },
  methods: {
    async loadForms () {
      await this.$store.dispatch('Forms/getProperty', {
        collection: 'forms',
        element: 'forms',
        url: 'forms'
      })
    },
    loadForm () {
      return this.$store.dispatch('Forms/getProperty', {
        collection: 'currentForm',
        element: 'form',
        url: `/forms/${this.formId}`
      })
    },
    loadFormComponents () {
      this.$store.dispatch('FormComponents/getProperty', {
        collection: 'formComponents',
        url: 'form-components',
        element: 'form_components'
      })
    },
    cancelChanges () {
      this.$router.push({
        path: '/admin/formeditor/' + this.formId
      })
    }
  }
}
