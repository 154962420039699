var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: { isAdmin: _vm._isAdminUser },
      attrs: { id: "homepage-carousel-container" },
    },
    [
      _c("div", { staticClass: "carousel-container clearfix" }, [
        _c("div", { staticClass: "left-content" }, [
          _vm.window.width > 1023 && _vm.activeFramesLength > 1
            ? _c("div", { staticClass: "overline-container" }, [
                _c(
                  "button",
                  {
                    staticClass: "animation-button back",
                    attrs: {
                      type: "button",
                      title: _vm.$t("site_actions_pagination_previous_label"),
                    },
                    on: {
                      click: function ($event) {
                        return _vm.animation("back")
                      },
                    },
                  },
                  [_c("Icons", { attrs: { name: "salesforce-search-arrow" } })],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "overline-box" }, [
                  _c(
                    "div",
                    { ref: "overlineParent", staticClass: "overline" },
                    [
                      _c(
                        "div",
                        {
                          ref: "overlineWrapper",
                          staticClass: "overline-wrapper",
                        },
                        [
                          _vm._l(_vm.activeFrames, function (item, index) {
                            return [
                              _c(
                                "span",
                                {
                                  key: index,
                                  class: {
                                    active: index === _vm.childrenTarget,
                                  },
                                },
                                [
                                  _c(
                                    "button",
                                    {
                                      ref: "overline",
                                      refInFor: true,
                                      staticClass: "pass-thru",
                                      class: {
                                        active: index === _vm.childrenTarget,
                                      },
                                      attrs: { type: "button" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.overlineClick(index)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                    " +
                                          _vm._s(item.overline) +
                                          "\n                  "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          }),
                        ],
                        2
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "animation-button next",
                    attrs: {
                      type: "button",
                      title: _vm.$t("site_actions_pagination_next_label"),
                    },
                    on: {
                      click: function ($event) {
                        return _vm.animation("next")
                      },
                    },
                  },
                  [_c("Icons", { attrs: { name: "salesforce-search-arrow" } })],
                  1
                ),
              ])
            : _c("div", { staticClass: "overline-single" }, [
                _c("span", { ref: "mobileOverline" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.currentFrame.overline) +
                      "\n        "
                  ),
                ]),
              ]),
          _vm._v(" "),
          _c(
            "div",
            { ref: "headerTextArea", staticClass: "header-text-area" },
            [
              _c("h1", [_vm._v(_vm._s(_vm.currentFrame.headline))]),
              _vm._v(" "),
              _vm.isVideoAndHasUrl
                ? [
                    _c(
                      "button",
                      {
                        staticClass: "cta-button primary",
                        attrs: {
                          type: "button",
                          title: _vm.$t(
                            "components_global_actions_open-in-modal_label"
                          ),
                        },
                        on: {
                          click: function ($event) {
                            return _vm.showVideoModal()
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.currentFrame.linkLabel) +
                            "\n          "
                        ),
                      ]
                    ),
                  ]
                : _vm.currentFrame.target === "_self"
                ? [
                    _vm.window.width > 1023
                      ? _c(
                          "router-link",
                          {
                            staticClass: "cta-button primary",
                            attrs: { to: _vm.currentFrame.linkUrl },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.currentFrame.linkLabel) +
                                "\n          "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]
                : [
                    _vm.window.width > 1023
                      ? _c(
                          "a",
                          {
                            staticClass: "cta-button primary",
                            attrs: {
                              href: _vm.currentFrame.linkUrl,
                              target: _vm.currentFrame.target,
                            },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.currentFrame.linkLabel) +
                                "\n          "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ],
            ],
            2
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "right-content-animation" }, [
          _c("div", { staticClass: "circle-wrapper" }, [
            _c("div", { staticClass: "main-circle-wrapper" }, [
              _vm.activeFramesLength > 0 && _vm.activeFrames[_vm.current].imgUrl
                ? _c("img", {
                    ref: "currentCircle",
                    staticClass: "main-circle current",
                    attrs: {
                      alt: _vm.currentFrame.centerImageAltText,
                      src: _vm.activeFrames[_vm.current].imgUrl,
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.activeFramesLength > 1 && _vm.activeFrames[_vm.onDeck].imgUrl
                ? _c("img", {
                    ref: "onDeckCircle",
                    staticClass: "main-circle",
                    attrs: {
                      alt: "Brand circle",
                      src: _vm.activeFrames[_vm.onDeck].imgUrl,
                    },
                  })
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { ref: "childCircleLarge", staticClass: "child-circle-large" },
              [
                _vm.currentFrame.childrenImages[0] !== "#"
                  ? _c("img", {
                      attrs: {
                        src: _vm.currentFrame.childrenImages[0],
                        alt: "Brand circle large",
                      },
                    })
                  : _vm._e(),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { ref: "childCircleMedium", staticClass: "child-circle-medium" },
              [
                _vm.currentFrame.childrenImages[1] !== "#"
                  ? _c("img", {
                      attrs: {
                        src: _vm.currentFrame.childrenImages[1],
                        alt: "Brand circle medium",
                      },
                    })
                  : _vm._e(),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { ref: "childCircleSmall", staticClass: "child-circle-small" },
              [
                _vm.currentFrame.childrenImages[2] !== "#"
                  ? _c("img", {
                      attrs: {
                        src: _vm.currentFrame.childrenImages[2],
                        alt: "Brand circle small",
                      },
                    })
                  : _vm._e(),
              ]
            ),
          ]),
        ]),
        _vm._v(" "),
        _vm.window.width < 1024
          ? _c(
              "div",
              { staticClass: "mobile-nav" },
              [
                _c(
                  "button",
                  {
                    staticClass: "animation-button back",
                    attrs: {
                      type: "button",
                      title: _vm.$t("site_actions_pagination_previous_label"),
                    },
                    on: {
                      click: function ($event) {
                        return _vm.animation("back")
                      },
                    },
                  },
                  [_c("Icons", { attrs: { name: "salesforce-search-arrow" } })],
                  1
                ),
                _vm._v(" "),
                _vm.currentFrame.target === "_self"
                  ? [
                      _c(
                        "router-link",
                        {
                          staticClass: "cta-button primary",
                          attrs: { to: _vm.currentFrame.linkUrl },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.currentFrame.linkLabel) +
                              "\n        "
                          ),
                        ]
                      ),
                    ]
                  : [
                      _c(
                        "a",
                        {
                          staticClass: "cta-button primary",
                          attrs: {
                            href: _vm.currentFrame.linkUrl,
                            target: _vm.currentFrame.target,
                          },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.currentFrame.linkLabel) +
                              "\n        "
                          ),
                        ]
                      ),
                    ],
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "animation-button next",
                    attrs: {
                      type: "button",
                      title: _vm.$t("site_actions_pagination_next_label"),
                    },
                    on: {
                      click: function ($event) {
                        return _vm.animation("next")
                      },
                    },
                  },
                  [_c("Icons", { attrs: { name: "salesforce-search-arrow" } })],
                  1
                ),
              ],
              2
            )
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }