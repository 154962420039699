var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "utility-bar-wrapper" } }, [
    _c(
      "div",
      {
        class: [{ mobile: _vm.mobile }, "overlay-menu"],
        attrs: {
          id: "utility-bar",
          role: "navigation",
          "aria-label": _vm.$t("layout_regions_utility-nav_label"),
        },
      },
      [
        !_vm.mobile
          ? _c("secondary-logo", { staticClass: "mobile-logo" })
          : _vm._e(),
        _vm._v(" "),
        _c(
          "ul",
          {
            class: [
              { single: _vm.tabs.length < 2 && _vm.hasHelpDesk === false },
              "utility-nav",
            ],
          },
          [
            _vm._l(_vm.tabs, function (tab, index) {
              return _c(
                "li",
                { key: index },
                [
                  tab.external
                    ? _c(
                        "a",
                        {
                          attrs: {
                            href: tab.url,
                            title: tab.name,
                            target: "_blank",
                          },
                        },
                        [
                          _vm._v(
                            "\n          " + _vm._s(tab.name) + "\n          "
                          ),
                          _c("icon", { attrs: { id: tab.src } }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  !tab.external
                    ? _c("router-link", {
                        attrs: { to: tab.url },
                        domProps: { textContent: _vm._s(tab.name) },
                      })
                    : _vm._e(),
                ],
                1
              )
            }),
            _vm._v(" "),
            _vm._dxgIsPublished
              ? _c(
                  "li",
                  { attrs: { id: "dxg-button" } },
                  [
                    _c(
                      "router-link",
                      {
                        attrs: {
                          to: _vm.$generateRoute({ name: "dxg.index" }),
                        },
                      },
                      [_vm._v("\n          Digital Exp Guide\n        ")]
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.hasHelpDesk
              ? _c(
                  "li",
                  [
                    _c(
                      "router-link",
                      {
                        attrs: {
                          to: _vm.$generateRoute({ name: "helpdesk.index" }),
                        },
                      },
                      [_vm._v("\n          Help Desk\n        ")]
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ],
          2
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }