var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "focus-trap",
    { attrs: { active: _vm.displayModal === true && _vm.file !== false } },
    [
      _vm.displayModal === true && _vm.file !== false
        ? _c("section", { staticClass: "modal", attrs: { tabindex: "-1" } }, [
            _c("div", { staticClass: "modal__body resize" }, [
              _c("div", { staticClass: "modal__controls" }, [
                _c(
                  "button",
                  {
                    staticClass: "close",
                    attrs: {
                      type: "button",
                      title: _vm.$t("site_actions_close_label"),
                    },
                    on: { click: _vm.onClose, keydown: _vm.onClose },
                  },
                  [_c("icon", { attrs: { id: "ic_close" } })],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "modal__contents" }, [
                _c("div", { staticClass: "preview" }, [
                  _c("img", {
                    attrs: {
                      src: _vm.file.thumbnail_url,
                      alt:
                        _vm.file.title ||
                        _vm.$t("site_messaging_no-description_label"),
                      role: "img",
                    },
                  }),
                ]),
                _vm._v(" "),
                _c(
                  "form",
                  {
                    staticClass: "resize-form",
                    attrs: { action: "" },
                    on: {
                      submit: function ($event) {
                        $event.preventDefault()
                        return _vm.submitForm.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "top" }, [
                      _c("h5", [_vm._v("Resize Image")]),
                      _vm._v(" "),
                      _c("div", { staticClass: "row row_50_50" }, [
                        _c(
                          "div",
                          { staticClass: "col" },
                          [
                            _c("TextInput", {
                              attrs: {
                                label: "Width",
                                name: "width",
                                model: _vm.resizeObj.width,
                                required: true,
                                "hide-asterisk": true,
                              },
                              on: {
                                "update:model": function ($event) {
                                  return _vm.$set(
                                    _vm.resizeObj,
                                    "width",
                                    $event
                                  )
                                },
                                keyup: _vm.updateWidthEvent,
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col" },
                          [
                            _c("TextInput", {
                              attrs: {
                                label: "Height",
                                name: "height",
                                model: _vm.resizeObj.height,
                                required: true,
                                "hide-asterisk": true,
                              },
                              on: {
                                "update:model": function ($event) {
                                  return _vm.$set(
                                    _vm.resizeObj,
                                    "height",
                                    $event
                                  )
                                },
                                keyup: _vm.updateHeightEvent,
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "row row_33_66" }, [
                        _c(
                          "div",
                          { staticClass: "col" },
                          [
                            _c("TextInput", {
                              attrs: {
                                label: "Scale",
                                name: "scale",
                                model: _vm.resizeObj.scale,
                                required: true,
                                "hide-asterisk": true,
                              },
                              on: {
                                "update:model": function ($event) {
                                  return _vm.$set(
                                    _vm.resizeObj,
                                    "scale",
                                    $event
                                  )
                                },
                                keyup: function ($event) {
                                  return _vm.updateScaleEvent(false)
                                },
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col slider-col" },
                          [
                            _c("vue-slider", {
                              attrs: {
                                width: "100%",
                                height: 2,
                                "dot-size": 22,
                                "dot-options": { tooltip: "always" },
                                "tooltip-formatter": "{value}%",
                                contained: true,
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.updateScaleEvent(true)
                                },
                              },
                              model: {
                                value: _vm.sliderScale,
                                callback: function ($$v) {
                                  _vm.sliderScale = $$v
                                },
                                expression: "sliderScale",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _vm.canConvert(_vm.file.file_name)
                        ? _c(
                            "div",
                            [
                              _c("SelectInput", {
                                attrs: {
                                  list: _vm.getConversionFileTypes(
                                    _vm.file.file_name
                                  ),
                                  model: _vm.resizeObj.fileFormat,
                                  label: "Select a file format...",
                                },
                                on: {
                                  "update:model": function ($event) {
                                    return _vm.$set(
                                      _vm.resizeObj,
                                      "fileFormat",
                                      $event
                                    )
                                  },
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "bottom" }, [
                      _c(
                        "button",
                        {
                          staticClass: "cta-button secondary",
                          attrs: {
                            type: "button",
                            title: _vm.$t("site_actions_cancel_label"),
                          },
                          on: {
                            click: function ($event) {
                              return _vm.onClose()
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("site_actions_cancel_label")) +
                              "\n            "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "cta-button primary",
                          attrs: {
                            type: "submit",
                            title: _vm.$t("site_actions_download_label"),
                          },
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("site_actions_download_label")) +
                              "\n            "
                          ),
                        ]
                      ),
                    ]),
                  ]
                ),
              ]),
            ]),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }