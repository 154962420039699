const RouteUtils = {
  getEntityNames () {
    let entityNames = []
    let entities = window?.AppStore?.state?.Entities?.entities ?? []

    if (!entities || entities.length < 1) {
      entities = [{ name: 'default' }]
    }

    entities.forEach(entity => {
      if (entity.name && !(entityNames.includes(entity.name))) {
        // sanitize entity name
        const name = entity.name.trim().replace(/\s+/g, '').toLowerCase().replace(/[^a-z0-9_]/gi, '-')
        entityNames.push(name)
      }
    })

    return entityNames
  },

  getLocaleNames () {
    let names = []
    const locales = window?.AppStore?.state?.Locales?.locales ?? [{ locale: 'en' }]

    locales.forEach(locale => {
      names.push(locale.locale)
    })
    return names // this should change to a dynamically-generated array later
  },

  generateParamRegex (names = []) {
    let regex = ''

    names.forEach((name, index) => {
      regex += `${(index === 0) ? '' : '|'}${name}`
    })

    return `(${regex})`
  },

  localeRegex () {

    return this.generateParamRegex(this.getLocaleNames())
  },

  entityRegex () {
    return this.generateParamRegex(this.getEntityNames())
},

  getRouteParams (routes = []) {
    let params = null
    const route = routes.find(route => {
      // return first route path with leading optional params
      return route.path.indexOf('/:') === 0
    })

    if (route && route.path) {
      // find first slash not followed by a colon
      const match = route.path.match(/\/(?!:)/)
      if (match && match.input) {
        // remove trailing slash
        params = match.input.slice(0, -1)
      }
    }

    return params
  },

  standardizePluginRoutePath (route = {}, params = null) {
    if (params) {
      let path = route.path
      if (path) {
        if (path.indexOf('/') === 0) {
          path = path.substring(1)
        }
        route.path = `${params}/${path}`
      }
    }

    return route
  }
}

export { RouteUtils }
