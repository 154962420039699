var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "file-indicator" }, [
    _c("div", { staticClass: "file-name-status" }, [
      _c("div", { staticClass: "file-name" }, [
        _vm._v("\n      " + _vm._s(_vm.file.name) + "\n    "),
      ]),
      _vm._v(" "),
      _vm.isUploadingData && !_vm.stopUpload
        ? _c("div", { staticClass: "status-bar" }, [
            _c("div", { staticClass: "status-worm" }),
          ])
        : _vm._e(),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { on: { click: _vm.cancelUpload } },
      [_c("icon", { attrs: { id: "ic_close" } })],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }