export default [
  {
    path: '/admin/showcase',
    redirect: '/admin/showcase/1'
  },
  {
    path: '/admin/showcase/:showcaseId',
    meta: { layout: 'admin' },
    component: () => import('../cms/index.vue'),
    children: [
      {
        path: '',
        name: 'admin.showcase.index',
        component: () => import('../cms/showcases.vue')
      },
      {
        path: 'comments',
        name: 'admin.showcase.comments',
        component: () => import('../cms/comments.vue')
      },
      {
        path: 'comments/:commentId',
        name: 'admin.showcase.comment',
        component: () => import('../cms/comments.vue')
      },
      {
        path: 'submission/:submissionId',
        name: 'admin.showcase.submission',
        component: () => import('../cms/submission.vue')
      }
    ]
  }
]
