import actions from '@core/store/actions/actions'
import mutations from '@core/store/mutations/mutations'

export default {
  namespaced: true,
  state: {
    helpdesk_topics: [],
    helpdesk_topics_items: {},
    filterParameters: null,
    searchParameters: null,
    isLoading: false,
    newElementCreated: false
  },
  getters: {
    getHelpdesk_topics: (state) => state.helpdesk_topics
  },
  mutations,
  actions
}
