var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.content !== false && !_vm.isContentTypeHome
    ? _c(
        "div",
        { staticClass: "content-region", class: _vm.sidebarClass },
        [
          _vm.beamFeatures.navType === "default"
            ? _c("AnchorLinks", {
                key: `anchorlink_${_vm.content.id}`,
                attrs: {
                  "section-headlines": _vm.contentRefs,
                  sections: _vm.anchorSections,
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.beamFeatures.navType === "hybrid"
            ? _c("NavLinks", {
                key: `navlink_${_vm.content.id}`,
                attrs: {
                  "section-headlines": _vm.contentRefs,
                  sections: _vm.anchorSections,
                },
              })
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }