var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      staticStyle: { "enable-background": "new 0 0 20 12" },
      attrs: {
        role: "presentation",
        version: "1.1",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
        x: "0px",
        y: "0px",
        viewBox: "0 0 20 12",
        "xml:space": "preserve",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M1.7,7.2C2,7.2,2.2,7,2.2,6.7V5.3C2.2,5,2,4.8,1.7,4.8H0.5C0.2,4.8,0,5,0,5.3v1.4C0,7,0.2,7.2,0.5,7.2H1.7z M1.7,12\n  c0.3,0,0.5-0.2,0.5-0.5v-1.4c0-0.3-0.2-0.5-0.5-0.5H0.5C0.2,9.6,0,9.8,0,10.1v1.4C0,11.8,0.2,12,0.5,12H1.7z M1.7,2.4\n  c0.3,0,0.5-0.2,0.5-0.5V0.5C2.2,0.2,2,0,1.7,0H0.5C0.2,0,0,0.2,0,0.5v1.4c0,0.3,0.2,0.5,0.5,0.5H1.7z M19.5,7.2\n  C19.8,7.2,20,7,20,6.7V5.3c0-0.3-0.2-0.5-0.5-0.5H4.9C4.7,4.8,4.4,5,4.4,5.3v1.4c0,0.3,0.2,0.5,0.5,0.5H19.5z M19.5,12\n  c0.3,0,0.5-0.2,0.5-0.5v-1.4c0-0.3-0.2-0.5-0.5-0.5H4.9c-0.3,0-0.5,0.2-0.5,0.5v1.4c0,0.3,0.2,0.5,0.5,0.5H19.5z M4.4,1.9\n  c0,0.3,0.2,0.5,0.5,0.5h14.6c0.3,0,0.5-0.2,0.5-0.5V0.5C20,0.2,19.8,0,19.5,0H4.9C4.7,0,4.4,0.2,4.4,0.5V1.9z",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }