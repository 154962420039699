var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        role: "presentation",
        xmlns: "http://www.w3.org/2000/svg",
        width: "18",
        height: "16",
        viewBox: "0 0 18 16",
      },
    },
    [
      _c("g", { attrs: { fill: "none", "fill-rule": "evenodd" } }, [
        _c("path", { attrs: { d: "M-1-2h20v20H-1z" } }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            stroke: "#979797",
            "stroke-width": "1.5",
            d: "M9.002 14.78l.702-.635c4.065-3.694 5.336-5.003 6.22-6.649.444-.825.66-1.608.66-2.413 0-2.152-1.682-3.833-3.834-3.833a4.24 4.24 0 0 0-3.179 1.478L9 3.398l-.571-.67A4.24 4.24 0 0 0 5.25 1.25c-2.152 0-3.833 1.68-3.833 3.833 0 .808.217 1.594.665 2.424.886 1.643 2.167 2.96 6.215 6.63l.705.642z",
          },
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }