export default [
  {
    path: '/helpdesk',
    beforeEnter: (to, from, next) => {
      const authToken = window.CookieManager.getCookie('jwt')
      const hasToken = authToken && 'false' !== authToken

      if (!hasToken) {
        next({ name: 'unauthorized' })
      }

      next()
    },
    component: () => import(
      /* webpackChunkName: "main-helpdesk-index" */
      '@core/plugins/HelpDesk/components/core/HelpDeskBase.vue'),
    children: [
      {
        path: '',
        name: 'helpdesk.index',
        component: () => import(
          /* webpackChunkName: "main-helpdesk-requests" */
          '@core/plugins/HelpDesk/components/core/HelpDesk.vue')
      },
      {
        path: 'success',
        name: 'helpdesk.success',
        component: () => import(
          /* webpackChunkName: "main-helpdesk-requests" */
          '@core/plugins/HelpDesk/components/core/HelpDeskRequestSuccess.vue')
      },
      {
        path: 'requests/:id',
        name: 'helpdesk.requests',
        component: () => import(
          /* webpackChunkName: "main-helpdesk-request" */
          '@core/plugins/HelpDesk/components/core/HelpDeskRequestDetails.vue')
      }
    ]
  }
]
