import actions from '../actions/actions'
import mutations from '../mutations/mutations'

export default {
  namespaced: true,
  state: {
    token: false,
    ttl: false
  },
  mutations: Object.assign({
    setJWT (state, jwt) {
      Object.assign(state, jwt)
      if (jwt) {
        state.ttl = jwt.ttl
        window.CookieManager.setCookie('jwt', JSON.stringify(jwt.token), jwt.ttl)

        // Remove notification cookie if feature is published, and it's configured to show again when the user logs in
        if (window.AppStore.getters['Settings/beamFeatures'].notifications === 'published') {
          const notificationCookie = JSON.parse(CookieManager.getCookie('viewed_notification'))

          if (notificationCookie && notificationCookie.show_on_login) {
            CookieManager.unsetCookie('viewed_notification')
          }
        }

      }
    },
    setToken (state, token) {
      if (token === null) {
        Object.assign(state, token)
      } else {
        state.token = token
      }
    }
  }, mutations),
  actions: Object.assign(
    {
      getJWT (context) {
        context.commit('setToken', JSON.parse(window.CookieManager.getCookie('jwt')))
      },
      unsetJWT (context) {
        context.commit('setJWT', {
          token: false,
          ttl: false
        })
      }
    },
    actions
  )
}
