var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("GlobalSidebar", {
    attrs: { "page-title": "Site Settings", "no-padding": true },
    scopedSlots: _vm._u([
      {
        key: "sidebar_content",
        fn: function () {
          return [
            _c("GlobalListGroup", {
              attrs: { items: _vm.filteredRoutes, "match-type": "partial" },
            }),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }