var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        role: "presentation",
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 18 14.9",
        "enable-background": "new 0 0 18 14.9",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M16.4 5.2v-.6c0-1-.8-1.9-1.9-1.9h-5.1c-.2.1-.4-.1-.5-.3l-.6-1.3c-.3-.7-1-1.1-1.7-1.1h-4.7c-1.1 0-1.9.8-1.9 1.9v11.3c0 1 .8 1.8 1.8 1.8h13.8c1.6 0 2.4-.6 2.4-1.8v-6.2c-.1-.9-.7-1.6-1.6-1.8zm-1.3-.6v.6h-10.5c-.9.2-1.5.8-1.6 1.6v6.3c0 .4-.4.5-.8.5s-.8-.1-.8-.5v-11.2c0-.3.3-.6.6-.6h4.7c.2 0 .4.1.5.4l.5 1.3c.3.7 1 1.1 1.7 1.1h5.1c.3 0 .6.2.6.5z",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }