var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.closeColorPicker,
          expression: "closeColorPicker",
        },
      ],
      staticClass: "form-input color-picker-input",
      class: {
        "has-error": _vm.required && _vm.error,
      },
    },
    [
      _vm.label
        ? _c("label", {
            staticClass: "active",
            attrs: { for: _vm.id },
            domProps: { innerHTML: _vm._s(_vm.fieldLabel) },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "wrapper" },
        [
          _c("Icon", { attrs: { id: "ic_colorize" } }),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.input,
                expression: "input",
              },
            ],
            attrs: {
              id: _vm.id,
              type: "text",
              placeholder: _vm.placeholder,
              name: _vm.name,
              role: "textbox",
              "data-check": "Input_TextBox",
              maxlength: _vm.maxlength || null,
              autocomplete: "off",
              disabled: _vm.disabled,
              "aria-invalid": _vm.error,
              "aria-describedby": _vm.description,
              "aria-required": _vm.required,
              "aria-label": _vm.label || _vm.ariaLabel,
            },
            domProps: { value: _vm.input },
            on: {
              focusout: _vm.focusout,
              focus: _vm.toggleLabel,
              blur: _vm.toggleLabel,
              keyup: _vm.keyup,
              click: _vm.toggleColorPicker,
              input: function ($event) {
                if ($event.target.composing) return
                _vm.input = $event.target.value
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("VueColorSketch", {
        class: { visible: _vm.showPicker },
        attrs: { "preset-colors": _vm.colors, "disable-alpha": true },
        model: {
          value: _vm.color,
          callback: function ($$v) {
            _vm.color = $$v
          },
          expression: "color",
        },
      }),
      _vm._v(" "),
      _vm.required && _vm.error
        ? _c("div", {
            staticClass: "error",
            attrs: { id: `error_${_vm.id}` },
            domProps: { textContent: _vm._s(_vm.errorText.trim()) },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.helpText
        ? _c("div", { staticClass: "help-text" }, [
            _vm._v("\n    " + _vm._s(_vm.helpText) + "\n  "),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }