var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        role: "presentation",
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 8.9 14.5",
        "enable-background": "new 0 0 8.9 14.5",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M8.4 11.7c.6.7.6 1.7 0 2.3-.6.6-1.6.6-2.3 0l-5.4-5.4-.2-.2c-.4-.3-.5-.8-.5-1.2 0-.4.1-.8.5-1.2l.2-.1 5.4-5.4c.6-.6 1.6-.6 2.3 0 .6.6.6 1.6 0 2.2l-4.5 4.5 4.5 4.5z",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }