// import ManagementIndex from "@cms/components/libraries/library-management.vue";

export default {
  path: "/admin/library-management",
  meta: { layout: "admin" },
  component: () =>
    import(
      /* webpackChunkName: "admin-libraries-library-mgmt" */
      "@cms/components/libraries/library-management.vue"
    ),
  children: [
    {
      path: "",
      name: "admin.library-management.index",
      redirect: "new",
    },
    {
      path: "new",
      name: "admin.library-management.new",
      component: () =>
        import(
          /* webpackChunkName: "admin-libraries-title-description" */
          "@cms/components/libraries/partials/title-description.vue"
        ),
    },
    {
      path: ":id",
      name: "admin.library-management.library",
      redirect: "title",
    },
    {
      path: ":id/title",
      name: "admin.library-management.library.title",
      component: () =>
        import(
          /* webpackChunkName: "admin-libraries-title-description" */
          "@cms/components/libraries/partials/title-description.vue"
        ),
    },
    {
      path: ":id/filters",
      name: "admin.library-management.library.filters",
      component: () =>
        import(
          /* webpackChunkName: "admin-libraries-filters" */
          "@cms/components/libraries/partials/filters.vue"
        ),
    },
    {
      path: ":id/permissions",
      name: "admin.library-management.library.permissions",
      component: () =>
        import(
          /* webpackChunkName: "admin-libraries-permissions" */
          "@cms/components/libraries/partials/permissions.vue"
        ),
    },
    {
      path: ":id/tracking",
      name: "admin.library-management.library.tracking",
      component: () =>
        import(
          /* webpackChunkName: "admin-libraries-tracking" */
          "@cms/components/libraries/partials/tracking.vue"
        ),
    },
    {
      path: ":id/restrictions",
      name: "admin.library-management.library.restrictions",
      component: () =>
        import(
          /* webpackChunkName: "admin-libraries-tracking" */
          "@cms/components/libraries/partials/restrictions.vue"
        ),
    },
  ],
};
