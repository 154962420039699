var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [
      !_vm.files.length
        ? [_c("p", [_vm._v(_vm._s(_vm.$tc("site_messaging_results", 0)))])]
        : [
            _c(
              "div",
              {
                staticClass: "flex table assets",
                class: [
                  _vm.viewType,
                  _vm.desktopGridClass,
                  _vm.tabletGridClass,
                  _vm.mobileGridClass,
                ],
              },
              [
                _vm._l(_vm.files, function (file) {
                  return [
                    _c("div", { key: file.id, staticClass: "item" }, [
                      _c("div", { staticClass: "preview-container" }, [
                        _c("div", { staticClass: "preview" }, [
                          _vm.viewType === "grid"
                            ? _c(
                                "a",
                                {
                                  directives: [
                                    {
                                      name: "b-tooltip",
                                      rawName: "v-b-tooltip.hover.top",
                                      modifiers: { hover: true, top: true },
                                    },
                                  ],
                                  attrs: {
                                    href: `${_vm._rootPath}asset_id=${file.id}`,
                                    title: file.title,
                                  },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      !_vm._isAdminArea
                                        ? _vm.$onInfo(
                                            file.id,
                                            _vm.files,
                                            _vm.modalActions,
                                            _vm.assetActions
                                          )
                                        : null
                                    },
                                  },
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: _vm.imageSrc(file, 2),
                                      alt: file.title,
                                    },
                                  }),
                                ]
                              )
                            : _c(
                                "a",
                                {
                                  attrs: {
                                    href: `${_vm._rootPath}asset_id=${file.id}`,
                                  },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      !_vm._isAdminArea
                                        ? _vm.$onInfo(
                                            file.id,
                                            _vm.files,
                                            _vm.modalActions,
                                            _vm.assetActions
                                          )
                                        : null
                                    },
                                  },
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: _vm.imageSrc(file, 1),
                                      alt: file.title,
                                    },
                                  }),
                                ]
                              ),
                          _vm._v(" "),
                          _c("footer", [
                            _c(
                              "button",
                              {
                                staticClass: "secondary",
                                attrs: {
                                  type: "button",
                                  title: _vm.$t("site_actions_download_label"),
                                },
                                on: {
                                  click: function ($event) {
                                    !_vm._isAdminArea
                                      ? _vm.onDownload(
                                          file,
                                          _vm.viewType !== "list"
                                        )
                                      : null
                                  },
                                },
                              },
                              [
                                _c("icon", {
                                  attrs: { id: _vm._download_get },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _vm.canResize(file) && !_vm.overrideMobile
                              ? _c(
                                  "button",
                                  {
                                    staticClass: "secondary",
                                    attrs: {
                                      type: "button",
                                      title: _vm.$t(
                                        "site_actions_resize_label"
                                      ),
                                    },
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        !_vm._isAdminArea
                                          ? _vm.$onResize(file)
                                          : null
                                      },
                                    },
                                  },
                                  [_c("icon", { attrs: { id: "ic_create" } })],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.canEmail && _vm.currentUser
                              ? _c(
                                  "button",
                                  {
                                    staticClass: "secondary",
                                    attrs: {
                                      type: "button",
                                      title: _vm.$t("site_actions_share_label"),
                                    },
                                    on: {
                                      click: function ($event) {
                                        !_vm._isAdminArea
                                          ? _vm.$onShare(file.id)
                                          : null
                                      },
                                    },
                                  },
                                  [
                                    _c("icon", {
                                      attrs: { id: _vm._share_view },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.canCollections && _vm.currentUser
                              ? _c(
                                  "button",
                                  {
                                    staticClass: "secondary",
                                    attrs: {
                                      type: "button",
                                      title: _vm.$t(
                                        "features_collections_actions_add_label"
                                      ),
                                    },
                                    on: {
                                      click: function ($event) {
                                        !_vm._isAdminArea
                                          ? _vm.$onLightbox(file.id)
                                          : null
                                      },
                                    },
                                  },
                                  [
                                    _c("icon", {
                                      attrs: { id: _vm._collections_add },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "description-container" }, [
                        _c("h5", [
                          _c("a", {
                            directives: [
                              {
                                name: "sanitize",
                                rawName: "v-sanitize",
                                value:
                                  _vm.namespace !== "Search"
                                    ? file.title
                                    : _vm.$options.filters.textHighlight(
                                        file.title,
                                        _vm.term
                                      ),
                                expression:
                                  "\n                  namespace !== 'Search'\n                    ? file.title\n                    : $options.filters.textHighlight(file.title, term)\n                ",
                              },
                            ],
                            attrs: {
                              href: `${_vm._rootPath}asset_id=${file.id}`,
                            },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                !_vm._isAdminArea
                                  ? _vm.$onInfo(
                                      file.id,
                                      _vm.files,
                                      _vm.modalActions,
                                      _vm.assetActions,
                                      true
                                    )
                                  : null
                              },
                            },
                          }),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "file-stat-container" }, [
                          _c("span", [
                            _vm._v(_vm._s(_vm._f("fileSize")(file.file_size))),
                          ]),
                          _vm._v(" "),
                          _c("span", { staticClass: "pipe" }, [_vm._v("|")]),
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm._f("fileExtension")(
                                  file.file_name.toUpperCase()
                                )
                              )
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", {
                          directives: [
                            {
                              name: "sanitize",
                              rawName: "v-sanitize",
                              value: file.description,
                              expression: "file.description",
                            },
                          ],
                          staticClass: "description truncate",
                        }),
                        _vm._v(" "),
                        file.filters
                          ? _c(
                              "ul",
                              { staticClass: "tags" },
                              [
                                _vm._l(file.filters, function (filter) {
                                  return [
                                    _c("li", { key: filter.id }, [
                                      _c("button", {
                                        directives: [
                                          {
                                            name: "sanitize",
                                            rawName: "v-sanitize",
                                            value: filter.label,
                                            expression: "filter.label",
                                          },
                                        ],
                                        staticClass: "text-btn",
                                        attrs: { type: "button" },
                                        on: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            return _vm.onApplyTag(filter.id)
                                          },
                                        },
                                      }),
                                    ]),
                                  ]
                                }),
                              ],
                              2
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.assetConversionEnabled &&
                        _vm.assetConversionActiveId === file.id
                          ? _c(
                              "div",
                              { staticClass: "asset-conversion" },
                              [
                                _c("SelectInput", {
                                  attrs: {
                                    list: _vm.getConversionFileTypes(
                                      file.file_name
                                    ),
                                    model: _vm.conversionType,
                                    label: "Choose a file type...",
                                  },
                                  on: {
                                    "update:model": function ($event) {
                                      _vm.conversionType = $event
                                    },
                                    "change-event": function ($event) {
                                      return _vm.downloadAsset(file)
                                    },
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "actions" },
                        [
                          _vm._t(
                            "action-buttons",
                            function () {
                              return [
                                _c("assets-table-buttons", {
                                  attrs: { file: file },
                                  on: {
                                    "toggle-asset-conversion-dropdown":
                                      _vm.toggleAssetConversionDropdown,
                                  },
                                }),
                              ]
                            },
                            { file: file }
                          ),
                        ],
                        2
                      ),
                    ]),
                  ]
                }),
              ],
              2
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }