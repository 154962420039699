var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.showFooter
    ? _c("div", {
        staticClass: "interior-page-footer",
        style: _vm.footerStyles,
        attrs: { role: "img", "aria-label": _vm.label },
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }