export default {
  computed: {
    hasChildNodes () {
      return this.$el.childNodes.length > 0
    },
    links () {
      return this.$el.querySelectorAll('a')
    }
  },
  methods: {
    async checkDownload (evt) {
      const fileId = evt.target.getAttribute('data-file-id') || 0

      if (parseInt(fileId, 10) > 0 && evt.target.href.indexOf('#') > -1) {
        evt.preventDefault()
        let res = null
        const fileName = evt.target.getAttribute('data-file-name') || 'File'
        const msg = 'You do not have access to download this file. Reach out to the site admin with any questions.'

        try {
          res = await axios.get(`assets/${fileId}/download`)
        } catch (err) {
          // do nothing
        } finally {
          if (res && res.data && res.data.download_url) {
            const link = document.createElement('a')
            link.href = res.data.download_url
            link.download = fileName
            document.body.appendChild(link)
            link.click()
            link.remove()
          } else {
            this.$dialog.alert(
              msg,
              { okText: 'Close' }
            ).then(() => {})
          }
        }
      }
    },
    addListeners () {
      if (this.hasChildNodes) {
        this.links.forEach(link => {
          const fileId = link.getAttribute('data-file-id') || 0
          if (parseInt(fileId, 10) > 0 && link.href.indexOf('#') > -1) {
            link.addEventListener('click', this.checkDownload)
          }
        })
      }
    },
    removeListeners () {
      if (this.hasChildNodes) {
        this.links.forEach(link => {
          const fileId = link.getAttribute('data-file-id') || 0
          if (parseInt(fileId, 10) > 0 && link.href.indexOf('#') > -1) {
            link.removeEventListener('click', this.checkDownload)
          }
        })
      }
    }
  },
  mounted () {
    this.addListeners()
  },
  beforeDestroy () {
    this.removeListeners()
  }
}
