import CmsRoutes from './routes/cms'
import CoreRoutes from './routes/core'
import GlobalShowcaseSubmissionComments from '@core/plugins/Showcase/store/global-showcase-submissions-comments'
import ShowcaseSubmissionComments from '@core/plugins/Showcase/store/showcase-submission-comments'
import ShowcaseSubmissionFiles from '@core/plugins/Showcase/store/showcase-submission-files'
import ShowcaseSubmissionFollows from '@core/plugins/Showcase/store/showcase-submission-follows'
import ShowcaseSubmissionsFeatured from '@core/plugins/Showcase/store/showcase-submissions-featured'
import ShowcaseSubmissionLikes from '@core/plugins/Showcase/store/showcase-submission-likes'
import ShowcaseSubmissions from '@core/plugins/Showcase/store/showcase-submissions'
import Showcases from '@core/plugins/Showcase/store/showcases'

import { RouteUtils } from '@core/js'

export default {
  install (Vue, options) {
    if (!options) {
      throw new Error('The Showcase Extension requires an options object!')
    }

    if (!options.router) {
      throw new Error('The Showcase Extension requires a router option!')
    }

    if (!options.env) {
      throw new Error('The Showcase Extension requires an env (cms|core) option!')
    }

    // Register the Showcase store module
    window.AppStore.registerModule('GlobalShowcaseSubmissionComments', GlobalShowcaseSubmissionComments)
    window.AppStore.registerModule('ShowcaseSubmissionComments', ShowcaseSubmissionComments)
    window.AppStore.registerModule('ShowcaseSubmissionFiles', ShowcaseSubmissionFiles)
    window.AppStore.registerModule('ShowcaseSubmissionFollows', ShowcaseSubmissionFollows)
    window.AppStore.registerModule('ShowcaseSubmissionsFeatured', ShowcaseSubmissionsFeatured)
    window.AppStore.registerModule('ShowcaseSubmissionLikes', ShowcaseSubmissionLikes)
    window.AppStore.registerModule('ShowcaseSubmissions', ShowcaseSubmissions)
    window.AppStore.registerModule('Showcases', Showcases)

    // Add routes to router
    const ShowcaseRoutes = (options.env === 'cms') ? CmsRoutes : CoreRoutes

    let params = RouteUtils.getRouteParams(options.router.getRoutes())

    ShowcaseRoutes.forEach(route => {
      route = RouteUtils.standardizePluginRoutePath(route, params)
      options.router.addRoute(route)
    })
  }
}
