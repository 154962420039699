var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "focus-trap",
    { attrs: { active: _vm.displayModal === true && _vm.file !== false } },
    [
      _vm.displayModal === true && _vm.file !== false
        ? _c("section", { staticClass: "modal", attrs: { tabindex: "-1" } }, [
            _c("div", { staticClass: "modal__body file" }, [
              _c("div", { staticClass: "modal__controls" }, [
                _c(
                  "button",
                  {
                    staticClass: "close",
                    attrs: {
                      type: "button",
                      title: _vm.$t("site_actions_close_label"),
                    },
                    on: { click: _vm.onClose, keydown: _vm.onClose },
                  },
                  [_c("icon", { attrs: { id: "ic_close" } })],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "modal__contents" }, [
                _c("div", { staticClass: "preview" }, [
                  _c(
                    "div",
                    { class: _vm.isAudio ? "audio-preview" : "" },
                    [
                      _vm.isVideo || _vm.isVideoPreview
                        ? _c("video", {
                            ref: "video",
                            attrs: {
                              src: _vm.file.preview_url,
                              poster: _vm.file.thumbnail_url,
                              controls: true,
                              controlslist: "nodownload",
                              disablePictureInPicture: "",
                            },
                            on: {
                              keydown: function ($event) {
                                !_vm._isAdminArea ? _vm.$toggleVideoPlay : null
                              },
                            },
                          })
                        : _vm.isAudio
                        ? [
                            _c("AudioPreview", {
                              attrs: {
                                "audio-source-file": _vm.file,
                                "asset-thumbnail": _vm.getAssetThumbnail(
                                  _vm.file,
                                  2
                                ),
                              },
                            }),
                          ]
                        : _c("img", {
                            attrs: {
                              src: _vm.getAssetThumbnail(_vm.file, 2),
                              alt: _vm.file.title || "No description available",
                            },
                          }),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c("footer", [
                    _vm.canDownload
                      ? _c(
                          "button",
                          {
                            staticClass: "secondary",
                            attrs: {
                              type: "button",
                              title: _vm.$t("site_actions_download_label"),
                            },
                            on: { click: _vm.onDownload },
                          },
                          [_c("icon", { attrs: { id: _vm._download_get } })],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.canResizeAsset
                      ? _c(
                          "button",
                          {
                            staticClass: "secondary",
                            attrs: {
                              type: "button",
                              title: _vm.$t("site_actions_resize_label"),
                            },
                            on: {
                              click: function ($event) {
                                return _vm.$onResize(_vm.file)
                              },
                            },
                          },
                          [_c("icon", { attrs: { id: "ic_create" } })],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.canEmail && _vm.currentUser
                      ? _c(
                          "button",
                          {
                            staticClass: "secondary",
                            attrs: {
                              type: "button",
                              title: _vm.$t("site_actions_share_label"),
                            },
                            on: {
                              click: function ($event) {
                                return _vm.$onShare(_vm.file.id)
                              },
                            },
                          },
                          [_c("icon", { attrs: { id: _vm._share_view } })],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.canCollections && _vm.currentUser
                      ? _c(
                          "button",
                          {
                            staticClass: "secondary",
                            attrs: {
                              type: "button",
                              role: "button",
                              title: _vm.$t(
                                "features_collections_general_label"
                              ),
                            },
                            on: {
                              click: function ($event) {
                                return _vm.$onLightbox(_vm.file.id)
                              },
                            },
                          },
                          [_c("icon", { attrs: { id: _vm._collections_add } })],
                          1
                        )
                      : _vm._e(),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "description scroller" },
                  [
                    _c("h4", { staticClass: "break-overflow" }, [
                      _vm.modalTitle
                        ? _c("span", [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.file.title) +
                                "\n            "
                            ),
                          ])
                        : _vm._e(),
                    ]),
                    _vm._v(" "),
                    _vm.modalDescription && _vm.file.description
                      ? _c("div", {
                          directives: [
                            {
                              name: "sanitize",
                              rawName: "v-sanitize",
                              value: _vm.file.description,
                              expression: "file.description",
                            },
                          ],
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.file.filters && _vm.file.filters.length > 0
                      ? [
                          _c("h5", [_vm._v("Tags")]),
                          _vm._v(" "),
                          _c(
                            "ul",
                            { staticClass: "tags" },
                            [
                              _vm._l(_vm.file.filters, function (cat) {
                                return [
                                  _c("li", { key: cat.id }, [
                                    _c("button", {
                                      directives: [
                                        {
                                          name: "sanitize",
                                          rawName: "v-sanitize",
                                          value: cat.label,
                                          expression: "cat.label",
                                        },
                                      ],
                                      staticClass: "text-btn",
                                      attrs: { type: "button" },
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.onApplyTag(cat.id)
                                        },
                                      },
                                    }),
                                  ]),
                                ]
                              }),
                            ],
                            2
                          ),
                        ]
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.modalFileName ||
                    _vm.modalFormat ||
                    _vm.modalFileSize ||
                    _vm.modalUsage ||
                    _vm.modalCreated ||
                    _vm.modalModified ||
                    _vm.modalExpiration ||
                    _vm.modalDownloadCount
                      ? _c("div", { staticClass: "details" }, [
                          _c("h5", [_vm._v("Details")]),
                          _vm._v(" "),
                          _c(
                            "dl",
                            [
                              _vm.file.file_name && _vm.modalFileName
                                ? [
                                    _c("dt", [_vm._v("File Name")]),
                                    _vm._v(" "),
                                    _c(
                                      "dd",
                                      { staticClass: "break-overflow" },
                                      [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(_vm.file.file_name) +
                                            "\n                "
                                        ),
                                      ]
                                    ),
                                  ]
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.file.file_name && _vm.modalFormat
                                ? [
                                    _c("dt", [_vm._v("Format")]),
                                    _vm._v(" "),
                                    _c(
                                      "dd",
                                      { staticClass: "break-overflow" },
                                      [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(
                                              _vm._f("fileExtension")(
                                                _vm.file.file_name.toUpperCase()
                                              )
                                            ) +
                                            "\n                "
                                        ),
                                      ]
                                    ),
                                  ]
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.file.file_size && _vm.modalFileSize
                                ? [
                                    _c("dt", [_vm._v("File Size")]),
                                    _vm._v(" "),
                                    _c(
                                      "dd",
                                      { staticClass: "break-overflow" },
                                      [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(
                                              _vm._f("fileSize")(
                                                _vm.file.file_size
                                              )
                                            ) +
                                            "\n                "
                                        ),
                                      ]
                                    ),
                                  ]
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.file.usage_description && _vm.modalUsage
                                ? [
                                    _c("dt", [_vm._v("Usage")]),
                                    _vm._v(" "),
                                    _c(
                                      "dd",
                                      {
                                        staticClass:
                                          "break-overflow usage-description",
                                      },
                                      [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(_vm.file.usage_description) +
                                            "\n                "
                                        ),
                                      ]
                                    ),
                                  ]
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.modalCreated
                                ? [
                                    _c("dt", [_vm._v("Created")]),
                                    _vm._v(" "),
                                    _c(
                                      "dd",
                                      { staticClass: "break-overflow" },
                                      [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(_vm.creation_date) +
                                            "\n                "
                                        ),
                                      ]
                                    ),
                                  ]
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.modalModified
                                ? [
                                    _c("dt", [_vm._v("Modified")]),
                                    _vm._v(" "),
                                    _c(
                                      "dd",
                                      { staticClass: "break-overflow" },
                                      [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(_vm.modified_date) +
                                            "\n                "
                                        ),
                                      ]
                                    ),
                                  ]
                                : _vm._e(),
                              _vm._v(" "),
                              _vm._isAdminArea
                                ? [
                                    _c("dt", [_vm._v("System Modified Date")]),
                                    _vm._v(" "),
                                    _c(
                                      "dd",
                                      { staticClass: "break-overflow" },
                                      [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(_vm.system_date) +
                                            "\n                "
                                        ),
                                      ]
                                    ),
                                  ]
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.modalExpiration
                                ? [
                                    _c("dt", [_vm._v("Expires")]),
                                    _vm._v(" "),
                                    _c(
                                      "dd",
                                      { staticClass: "break-overflow" },
                                      [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(_vm.expiration_date) +
                                            "\n                "
                                        ),
                                      ]
                                    ),
                                  ]
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.modalDownloadCount
                                ? [
                                    _c("dt", [_vm._v("Downloads")]),
                                    _vm._v(" "),
                                    _c(
                                      "dd",
                                      { staticClass: "break-overflow" },
                                      [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(_vm.download_count) +
                                            "\n                "
                                        ),
                                      ]
                                    ),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm._isAdminArea && _vm.allowDirectLink
                      ? _c("div", { staticClass: "direct-link" }, [
                          _c("div", { staticClass: "form-input text-input" }, [
                            _c(
                              "label",
                              {
                                staticClass: "active",
                                attrs: { for: "asset-direct-link" },
                              },
                              [_vm._v("Direct Link")]
                            ),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.link,
                                  expression: "link",
                                },
                              ],
                              attrs: { id: "asset-direct-link", type: "text" },
                              domProps: { value: _vm.link },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.link = $event.target.value
                                },
                              },
                            }),
                          ]),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              directives: [
                                {
                                  name: "clipboard",
                                  rawName: "v-clipboard:copy",
                                  value: _vm.link,
                                  expression: "link",
                                  arg: "copy",
                                },
                                {
                                  name: "clipboard",
                                  rawName: "v-clipboard:success",
                                  value: _vm.onCopy,
                                  expression: "onCopy",
                                  arg: "success",
                                },
                                {
                                  name: "clipboard",
                                  rawName: "v-clipboard:error",
                                  value: _vm.onFail,
                                  expression: "onFail",
                                  arg: "error",
                                },
                              ],
                              staticClass: "btn primary",
                              attrs: {
                                id: "direct-link-copy",
                                title: _vm.$t(
                                  "site_actions_copy_direct-link_label"
                                ),
                              },
                            },
                            [
                              _c("icon", { attrs: { id: "ic_content_copy" } }),
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.$t("site_actions_copy-link_label")
                                  ) +
                                  "\n            "
                              ),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.$userCan("libraries.update")
                      ? _c(
                          "router-link",
                          {
                            attrs: {
                              target: "_blank",
                              to: `/admin/libraries/${_vm.libraryId}/${_vm.file.id}`,
                            },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$t("site_actions_edit_label")) +
                                "\n          "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ],
                  2
                ),
              ]),
            ]),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }