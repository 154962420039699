var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("SettingsSidebar"),
      _vm._v(" "),
      _c("div", { staticClass: "main-wrapper" }, [
        _c(
          "main",
          { staticClass: "admin-settings", attrs: { id: "main" } },
          [_c("router-view")],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }