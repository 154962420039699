import actions from '@core/store/actions/actions'
import mutations from '@core/store/mutations/mutations'

export default {
  namespaced: true,
  state: {
    pagination: {
      per_page: 12,
      page: 1,
      pages: 1,
      items: 0
    },
    showcase_submissions: [],
    currentSubmission: [],
    filterParameters: {
      order: 'newest',
      status: 'published'
    },
    searchParameters: {
      term: ''
    },

    stats: {
      all: 0,
      published: 0,
      pending: 0,
      archived: 0
    },
    /*
    filterParameters: null,
    searchParameters: null,
    */
    config: {},
    isLoading: false,
    newElementCreated: false,
    view: 'list',
    formData: {
      featured: false
    },
    eventType: ''
  },
  mutations,
  actions
}
