var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isContentPage && _vm.isValidUser
    ? _c("FavoritesToggle", {
        attrs: { name: "transparent-start", show: _vm.showBookmark },
        on: { toggleFavorites: _vm.onBookmarkToggle },
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }