var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      staticStyle: { "enable-background": "new 0 0 17.8 20" },
      attrs: {
        role: "presentation",
        version: "1.1",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
        x: "0px",
        y: "0px",
        viewBox: "0 0 17.8 20",
        "xml:space": "preserve",
      },
    },
    [
      _c("path", {
        staticClass: "st0",
        attrs: {
          d: "M16.7,0H4.4L0,4.4v14.4C0,19.6,0.4,20,1.1,20h15.6c0.7,0,1.1-0.4,1.1-1.1V1.1C17.8,0.4,17.4,0,16.7,0z M5.6,4.5\n  c0,0.7-0.4,1.1-1.1,1.1H1.1l4.4-4.4V4.5z M12.2,17.2H5.6v-1.1h6.7V17.2z M9.2,13.9H8.6l-3-2.8l0.6-0.6l2.1,2l0-5.8h1.1l0,5.8l2.1-2\n  l0.6,0.6L9.2,13.9z",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }