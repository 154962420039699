var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        role: "presentation",
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 31 32",
        "enable-background": "new 0 0 31 32",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M25.5 0h-20.7c-2.6 0-4.8 2.2-4.8 4.8v22.4c0 2.6 2.2 4.8 4.8 4.8h21.4c2.7 0 4.8-2.2 4.8-4.8v-21.4l-5.5-5.8zm-10 19c-1.7 0-3-1.3-3-3s1.3-3 3-3 3 1.3 3 3-1.3 3-3 3zm4-12.7c0 .8-.7 1.5-1.5 1.5h-10c-.8 0-1.5-.7-1.5-1.5v-3.6c0-.8.7-1.5 1.5-1.5h10c.8 0 1.5.7 1.5 1.5v3.6z",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }