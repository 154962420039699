var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.items
    ? _c(
        "section",
        {
          staticClass: "carousel",
          on: { mouseover: _vm.deleteInterval, mouseout: _vm.createInterval },
        },
        [
          _c("h2", [_vm._v(_vm._s(_vm.title))]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "controls" },
            [
              _vm._l(_vm.items, function (item, index) {
                return [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.current,
                        expression: "current",
                      },
                    ],
                    key: item.id,
                    attrs: { type: "radio" },
                    domProps: {
                      value: index,
                      checked: _vm._q(_vm.current, index),
                    },
                    on: {
                      change: function ($event) {
                        _vm.current = index
                      },
                    },
                  }),
                ]
              }),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "stage" },
            [
              _vm._l(_vm.items, function (item, index) {
                return [
                  _c(
                    "div",
                    {
                      key: item.id,
                      staticClass: "carousel_item",
                      class: { active: _vm.current === index },
                    },
                    [
                      item.title
                        ? _c("h3", [
                            _vm._v(
                              "\n          " + _vm._s(item.title) + "\n        "
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      item.description
                        ? _c("p", [
                            _vm._v(
                              "\n          " +
                                _vm._s(item.description) +
                                "\n        "
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      item.format === "image"
                        ? [
                            _c("img", {
                              attrs: { src: item.location, alt: item.label },
                            }),
                          ]
                        : _vm._e(),
                      _vm._v(" "),
                      item.format === "video"
                        ? [
                            _c("video", {
                              ref: "video",
                              refInFor: true,
                              attrs: {
                                src: item.location,
                                autoplay: item.config_options.autoplay,
                                loop: item.config_options.loop,
                                controls: item.config_options.controls,
                                poster: item.location_image,
                              },
                              domProps: { muted: item.config_options.muted },
                              on: {
                                keydown: function ($event) {
                                  !_vm._isAdminArea
                                    ? _vm.$toggleVideoPlay
                                    : null
                                },
                              },
                            }),
                          ]
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.$getRouteObject(item) !== null
                        ? [
                            _c(
                              "router-link",
                              {
                                attrs: {
                                  to: _vm.$getRouteObject(item),
                                  target:
                                    item.new_tab === true ? "_blank" : "_self",
                                },
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(item.additional_text) +
                                    "\n          "
                                ),
                              ]
                            ),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                ]
              }),
            ],
            2
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }