import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      publishedLocales: 'Locales/publishedLocales'
    }),
    currentLocaleCode () {
      return this.$i18n.locale
    },
    currentLocale () {
      return this.publishedLocales.find(item => item.locale === this.currentLocaleCode)
    }
  }
}
