var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        role: "presentation",
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 16 16",
        "enable-background": "new 0 0 16 16",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M12.7 11.3v-2.4h-3.8v3.8h2.3l-3.2 3.3-3.3-3.3h2.3v-3.8h-3.7v2.3l-3.3-3.2 3.3-3.3v2.3h3.8v-3.7h-2.4l3.3-3.3 3.3 3.3h-2.4v3.8h3.8v-2.4l3.3 3.3-3.3 3.3z",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }