import VSanitize from 'v-sanitize'

// These functions obviously require the v-santize package.

const GetSanitizeConfig = function (env = 'client') {
  let sanitizeDefaults = VSanitize.defaults

  // Add any desired tags
  const allowedTags = [
    'svg',
    'path',
    'g',
    'circle',
    'defs',
    'desc',
    'ellipse',
    'filter',
    'line',
    'mpath',
    'pattern',
    'polyline',
    'polygon',
    'rect',
    // 'style',
    'text',
    'title',
    'use'
  ]

  // Add attributes for new tags
  const allowedAttributes = {
    '*': ['class', 'style'],
    'svg': ['*'],
    'path': ['*'],
    'g': ['*'],
    'circle': ['*'],
    'defs': ['*'],
    'desc': ['*'],
    'ellipse': ['*'],
    'filter': ['*'],
    'line': ['*'],
    'mpath': ['*'],
    'pattern': ['*'],
    'polyline': ['*'],
    'polygon': ['*'],
    'rect': ['*'],
    // 'style': ['*'],
    'text': ['*'],
    'title': ['*'],
    'use': ['*']
  }

  allowedTags.forEach(tag => {
    sanitizeDefaults.allowedTags.push(tag)
  })

  for (const [key, value] of Object.entries(allowedAttributes)) {
    sanitizeDefaults.allowedAttributes[key] = value
  }

  if (env === 'cms') {
    sanitizeDefaults.allowedTags.push('iframe')
    sanitizeDefaults.allowedAttributes.iframe = ['*']
  }

  return sanitizeDefaults
}

export { GetSanitizeConfig }
