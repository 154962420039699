var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "favorites",
      class: [
        { "accordion-open": _vm.accordionOpen && _vm.onMobileSize },
        { "accordion-close": !_vm.accordionOpen && _vm.onMobileSize },
      ],
      on: { click: _vm.mobileClick },
    },
    [
      _vm.onMobileSize
        ? _c("Icons", { staticClass: "chevron", attrs: { name: "down" } })
        : _vm._e(),
      _vm._v(" "),
      _vm.iconUrl
        ? _c("img", {
            staticClass: "favorites-icon",
            attrs: { src: _vm.iconUrl, alt: _vm.title },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.title.trim()
        ? _c("h4", { staticClass: "favorites-title" }, [
            _vm._v("\n    " + _vm._s(_vm.title) + "\n  "),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.bookmarksFiltered.length
        ? _c(
            "ul",
            { staticClass: "list-unstyled" },
            _vm._l(_vm.bookmarksFiltered.slice(0, 4), function (item) {
              return _c(
                "li",
                { key: item.id },
                [
                  item.bookmarked_item
                    ? _c(
                        "router-link",
                        {
                          attrs: {
                            to: _vm.$generateRoute({
                              name: "content.page",
                              params: {
                                guid: item.bookmarked_item.menu_item.action,
                              },
                            }),
                          },
                        },
                        [
                          _vm._v(
                            "\n        " +
                              _vm._s(item.bookmarked_item.menu_item.label) +
                              "\n      "
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              )
            }),
            0
          )
        : _c("p", { staticClass: "no-links" }, [
            _vm._v(
              "\n    " +
                _vm._s(_vm.$t("features_favorites_no-results")) +
                "\n  "
            ),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }