var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "form-input multiselect-input" },
    [
      _c("label", {
        directives: [
          {
            name: "sanitize",
            rawName: "v-sanitize",
            value: _vm.fieldLabel,
            expression: "fieldLabel",
          },
        ],
        attrs: { for: _vm.id },
      }),
      _vm._v(" "),
      _c("multi-select", {
        attrs: {
          id: _vm.id,
          options: _vm.list,
          "selected-options": _vm.model,
          placeholder: _vm.placeholder,
        },
        on: { select: _vm.onSelect, searchchange: _vm.onSearchChange },
      }),
      _vm._v(" "),
      _vm.required && _vm.error
        ? _c("div", { staticClass: "error" }, [
            _vm._v("\n    " + _vm._s(_vm.errorMsg) + "\n  "),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }