var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "icon-set-library" }, [
    _c("p", [
      _vm._v("Icon Subset: "),
      _c("strong", [_vm._v(_vm._s(_vm._f("capitalize")(_vm.subset)))]),
    ]),
    _vm._v(" "),
    _c("hr"),
    _vm._v(" "),
    _vm._m(0),
    _vm._v(" "),
    _c("hr"),
    _vm._v(" "),
    _c("p", [
      _c(
        "button",
        {
          staticClass: "default",
          attrs: { type: "button", title: _vm.buttonText },
          on: {
            click: function ($event) {
              _vm.displayLibrary = !_vm.displayLibrary
            },
          },
        },
        [_vm._v("\n      " + _vm._s(_vm.buttonText) + "\n    ")]
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.displayLibrary,
            expression: "displayLibrary",
          },
        ],
        staticClass: "icon-set-output",
      },
      [
        _c("div", { staticClass: "group-display" }, [
          _c(
            "button",
            {
              staticClass: "primary",
              class: { default: _vm.group === "cms_" },
              attrs: { type: "button", title: "Show Client Icons" },
              on: {
                click: function ($event) {
                  _vm.group = "ic_"
                },
              },
            },
            [_vm._v("\n        Client Icons\n      ")]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "primary",
              class: { default: _vm.group === "ic_" },
              attrs: { type: "button", title: "Show CMS Icons" },
              on: {
                click: function ($event) {
                  _vm.group = "cms_"
                },
              },
            },
            [_vm._v("\n        CMS Icons\n      ")]
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "group-reference" }, [
          _vm.group === "ic_"
            ? _c("p", [
                _c("small", [
                  _vm._v(
                    "The Client icons are the exact SVG code used for client-side rendering, complete with any customizations."
                  ),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.group === "cms_"
            ? _c("p", [
                _c("small", [
                  _vm._v(
                    "The CMS icons are only provided as a reference. They are unique to the Beam CMS and may not be modified."
                  ),
                ]),
              ])
            : _vm._e(),
        ]),
        _vm._v(" "),
        _vm._l(_vm.icons, function (icon, key) {
          return _c("div", { key: key, staticClass: "icon-sample" }, [
            _c(
              "div",
              [
                _c("icon", { attrs: { id: key } }),
                _vm._v(" "),
                _c("p", [
                  _c("strong", [_vm._v("ID")]),
                  _c("br"),
                  _vm._v(" " + _vm._s(key || "Missing ID!")),
                ]),
              ],
              1
            ),
          ])
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _c("small", [
        _vm._v(
          "This is a live style reference of the site's overall iconography. An icon's presence in this library does not provide or imply any possible usage or application."
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }