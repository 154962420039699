var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    { attrs: { viewBox: "0 0 20 20", xmlns: "http://www.w3.org/2000/svg" } },
    [
      _c("path", {
        attrs: {
          d: "M19 6.5v7c0 3.038-2.462 5.5-5.5 5.5-2.942 0-5.344-2.31-5.493-5.214 3.403-.766 4.915-3.159 4.99-6.981L13 6.5h6zm-5.5 7c-1.325 0-2.41 1.032-2.495 2.336L11 16h5c0-1.38-1.12-2.5-2.5-2.5zM12 1v6.5c0 3.038-2.462 5.5-5.5 5.5-2.963 0-5.38-2.344-5.496-5.279L1 7.5V1h11zm3.25 8.5c-.414 0-.75.336-.75.75s.336.75.75.75.75-.336.75-.75-.336-.75-.75-.75zM9 8H4c0 1.38 1.12 2.5 2.5 2.5S9 9.38 9 8zm-.25-4c-.414 0-.75.336-.75.75s.336.75.75.75.75-.336.75-.75S9.164 4 8.75 4zm-4.5 0c-.414 0-.75.336-.75.75s.336.75.75.75.75-.336.75-.75S4.664 4 4.25 4z",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }