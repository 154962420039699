var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        role: "presentation",
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 270 240",
      },
    },
    [_c("path", { attrs: { d: "M5 5h260L135 230" } })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }