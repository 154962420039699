var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        role: "presentation",
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 14 14",
        "enable-background": "new 0 0 14 14",
      },
    },
    [
      _c("g", { attrs: { fill: "none", "fill-rule": "nonzero" } }, [
        _c("path", { attrs: { d: "M-2 18V-2h20v20z" } }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            fill: "#0E1D2B",
            d: "M4.707 10.707a1 1 0 0 1-1.414-1.414l4-4a1 1 0 0 1 1.414 0l4 4a1 1 0 1 1-1.414 1.414L8 7.414l-3.293 3.293z",
          },
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }