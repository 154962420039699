var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "quicklinks v2 module_content",
      class: [
        { "accordion-open": _vm.accordionOpen && _vm.onMobileSize },
        { "accordion-close": !_vm.accordionOpen && _vm.onMobileSize },
      ],
      on: { click: _vm.mobileClick },
    },
    [
      _vm.onMobileSize
        ? _c("Icons", { staticClass: "chevron", attrs: { name: "down" } })
        : _vm._e(),
      _vm._v(" "),
      _vm.iconUrl
        ? _c("img", {
            staticClass: "quicklinks-icon",
            attrs: { src: _vm.iconUrl, alt: _vm.title },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("h4", { staticClass: "quicklinks-title" }, [
        _vm._v("\n    " + _vm._s(_vm.title) + "\n  "),
      ]),
      _vm._v(" "),
      _vm.linksFiltered.length
        ? _c(
            "ul",
            { staticClass: "list-unstyled" },
            _vm._l(_vm.linksFiltered, function (link, index) {
              return _c("li", { key: index }, [
                _c("a", { attrs: { href: link.url, target: link.target } }, [
                  _vm._v("\n        " + _vm._s(link.text) + "\n      "),
                ]),
              ])
            }),
            0
          )
        : _c("p", { staticClass: "no-links" }, [_vm._v("\n    No links\n  ")]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }