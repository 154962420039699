var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      staticStyle: { "enable-background": "new 0 0 20 20" },
      attrs: {
        role: "presentation",
        version: "1.1",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
        x: "0px",
        y: "0px",
        viewBox: "0 0 20 20",
        "xml:space": "preserve",
      },
    },
    [
      _c("path", {
        attrs: {
          fill: "#000000",
          d: "M19,6.2h-7.7l3.1,3.1c-1.2,1-3.4,1.8-5.8,1.4c-1.8-0.3-4.2-1.3-5.8-4.6L1,7c1.5,3.2,4.1,5.2,7.3,5.7c0.5,0.1,1,0.1,1.5,0.1\nc2.3,0,4.5-0.8,6-2.1l3.1,3.1V6.2z",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }