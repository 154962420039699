var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        id: "beam_icon_set",
        "aria-hidden": "true",
        role: "presentation",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _vm._l(_vm.iconography, function (icon, key) {
        return [
          icon.content
            ? _c("symbol", {
                key: key,
                attrs: { id: key, viewBox: icon.viewbox || "0 0 24 24" },
                domProps: { innerHTML: _vm._s(icon.content) },
              })
            : _vm._e(),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }