var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "vc-alpha" }, [
    _c(
      "div",
      { staticClass: "vc-alpha-checkboard-wrap" },
      [_c("checkboard")],
      1
    ),
    _vm._v(" "),
    _c("div", {
      staticClass: "vc-alpha-gradient",
      style: { background: _vm.gradientColor },
    }),
    _vm._v(" "),
    _c(
      "div",
      {
        ref: "container",
        staticClass: "vc-alpha-container",
        on: {
          mousedown: _vm.handleMouseDown,
          touchmove: _vm.handleChange,
          touchstart: _vm.handleChange,
        },
      },
      [
        _c(
          "div",
          {
            staticClass: "vc-alpha-pointer",
            style: { left: _vm.colors.a * 100 + "%" },
          },
          [_c("div", { staticClass: "vc-alpha-picker" })]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }