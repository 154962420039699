var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      key: _vm.row.id,
      class: [
        "row",
        _vm.$rowClass(_vm.row),
        _vm.gutter,
        { background_bleed: _vm.background_bleed },
      ],
      style: { backgroundImage: `${_vm.linearGradient}`, backgroundSize: 0 },
      attrs: { id: _vm.row.id },
    },
    [
      _vm._l(_vm.editedCols, function (c, n) {
        return [
          _c(
            "div",
            { key: c.id, staticClass: "col", class: `tile-${n}` },
            [
              _vm._l(c.modules, function (mod) {
                return [
                  _c(mod.name, {
                    key: mod.data.id,
                    tag: "component",
                    attrs: {
                      id: mod.data.id,
                      "module-data": mod.data,
                      "element-id": _vm.elementId,
                    },
                  }),
                ]
              }),
            ],
            2
          ),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }