import actions from '../actions/actions'
import mutations from '../mutations/mutations'
import moment from 'moment-timezone'
import TimezonesJSON from '@cms/assets/json/timezones.json'

export default {
  namespaced: true,
  state: {
    notifications: [],
    notification: {
      message: null,
      link_url: null,
      start_date: null,
      end_date: null,
      start_time: null,
      end_time: null,
      timezone: null,
      show_on_login: false,
      background_color: '',
      text_color: ''
    },
    isLoading: false
  },
  mutations,
  actions,
  getters: {
    activeNotifications: state => {
      return state.notifications.filter(item => {
        // This was the only way I was able to get it working on the correct timezone
        const now = moment(moment().tz(item.timezone).format('YYYY-MM-DD HH:mm:ss'))
        const startDate = moment(item.start_date + ' ' + item.start_time)

        if (item.end_date && item.end_time) {
          const endDate = moment(item.end_date + ' ' + item.end_time)

          return now.isBetween(startDate, endDate, 'minutes', '[]')
        }

        return startDate.isBefore(now)
      })
    },
    scheduledNotifications: state => {
      return state.notifications.filter(item => {
        const now = moment(moment().tz(item.timezone).format('YYYY-MM-DD HH:mm:ss'))

        const startDate = moment(item.start_date + ' ' + item.start_time)

        return startDate.isAfter(now)
      })
    }
  }
}
