var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { ref: "fileUpload", staticClass: "file-uploader" }, [
    _c("div", { staticClass: "button-holder" }, [
      _c("input", {
        ref: "uploadedFiles",
        staticClass: "input-file",
        attrs: {
          id: "file",
          required: _vm.isRequired,
          multiple: "",
          name: "file",
          type: "file",
        },
        on: { change: _vm.onFileChange },
      }),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "secondary",
          attrs: { type: "button" },
          on: { click: _vm.triggerClick },
        },
        [_vm._v("\n      Add file(s)\n    ")]
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "upload-file-section" },
      _vm._l(_vm.uploadedFiles, function (file, index) {
        return _c("FileIndicator", {
          key: index,
          attrs: { file: file, "is-uploading": _vm.isUploading },
          on: {
            onCancelUpload: function ($event) {
              return _vm.cancelUpload(file)
            },
          },
        })
      }),
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }