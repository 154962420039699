export default {
  path: 'asset-queue',
  component: () => import(
    /* webpackChunkName: "main-asset-queue-index" */
    '@core/components/asset-queue/index.vue'),
  children: [
    {
      path: '',
      name: 'assetqueue.index',
      component: () => import(
        /* webpackChunkName: "main-asset-queue-assets" */
        '@core/components/asset-queue/assets.vue')
    }
  ]
}
