var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "vc-compact",
      attrs: { role: "application", "aria-label": "Compact color picker" },
    },
    [
      _c(
        "ul",
        { staticClass: "vc-compact-colors", attrs: { role: "listbox" } },
        _vm._l(_vm.paletteUpperCase(_vm.palette), function (c) {
          return _c(
            "li",
            {
              key: c,
              staticClass: "vc-compact-color-item",
              class: { "vc-compact-color-item--white": c === "#FFFFFF" },
              style: { background: c },
              attrs: {
                role: "option",
                "aria-label": "color:" + c,
                "aria-selected": c === _vm.pick,
              },
              on: {
                click: function ($event) {
                  return _vm.handlerClick(c)
                },
              },
            },
            [
              _c("div", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: c === _vm.pick,
                    expression: "c === pick",
                  },
                ],
                staticClass: "vc-compact-dot",
              }),
            ]
          )
        }),
        0
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }