export default {
  install (Vue) {
    Vue.directive('document-click', {
      bind (el, binding, vnode) {
        el.event = function (event) {
          if (!(el === event.target || el.contains(event.target))) {
            vnode.context[binding.expression](event)
          }
        }
        document.body.addEventListener('click', el.event)
        document.body.addEventListener('touchend', el.event)
      },
      unbind (el) {
        document.body.removeEventListener('click', el.event)
        document.body.removeEventListener('touchend', el.event)
      }
    })
  }
}
