var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "header",
    {
      class: [
        { "is-sticky": _vm.hasStickyHeader },
        { "is-admin": _vm._isAdminUser },
        { peekaboo: _vm.beamFeatures.navType === "hybrid" },
        "header_wrapper",
      ],
      style: _vm.stickHeader,
      attrs: { id: "header_wrapper" },
    },
    [
      _c("nav", [
        _c(
          "div",
          {
            directives: [
              {
                name: "scroll",
                rawName: "v-scroll",
                value: _vm.onScroll,
                expression: "onScroll",
              },
            ],
            ref: "nav",
            class: [
              {
                peekaboo:
                  !_vm.overrideMobile && _vm.beamFeatures.navType === "hybrid",
              },
              { searchIsActive: _vm.searchIsActive },
              { "desktop-nav": !_vm.overrideMobile },
              { "mobile-nav": _vm.overrideMobile },
            ],
          },
          [
            _vm.showUtilityBar
              ? _c("utility-bar", {
                  class: { visible: _vm.showMenu },
                  attrs: { "has-help-desk": "", mobile: _vm.overrideMobile },
                })
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "primary-wrapper" }, [
              _c(
                "div",
                {
                  staticClass: "primary",
                  attrs: {
                    "aria-label": _vm.$t("layout_regions_primary-nav_label"),
                    role: "navigation",
                  },
                },
                [
                  _c("primary-logo", { ref: "logo" }),
                  _vm._v(" "),
                  _vm.showNavSiteName
                    ? _c(
                        "h2",
                        { staticClass: "site-name" },
                        [
                          _c(
                            "router-link",
                            {
                              attrs: {
                                to: _vm.$generateRoute({ name: "home.index" }),
                              },
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.$t("site_messaging_site-name_label")
                                  ) +
                                  "\n            "
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("PrimaryNavigation", {
                    class: [
                      "nav-items",
                      {
                        "overlay-menu":
                          _vm.overrideMobile && _vm.menuType === "overlay",
                        "drawer-menu":
                          _vm.overrideMobile && _vm.menuType === "drawer",
                        visible: _vm.showMenu,
                        "has-utility-bar":
                          _vm.overrideMobile && _vm.showUtilityBar,
                      },
                    ],
                    attrs: { level: 1, items: _vm.navItemsModified },
                    on: {
                      "close-menu": function ($event) {
                        _vm.showMenu = false
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("nav-search", { on: { searchActive: _vm.searchActive } }),
                  _vm._v(" "),
                  _vm.overrideMobile
                    ? [
                        !_vm.showMenu
                          ? _c("Icon", {
                              staticClass: "hamburger-icon",
                              attrs: { id: "ic_menu" },
                              nativeOn: {
                                click: function ($event) {
                                  return _vm.toggleMenu.apply(null, arguments)
                                },
                              },
                            })
                          : _c("Icon", {
                              staticClass: "hamburger-icon",
                              attrs: { id: "ic_close" },
                              nativeOn: {
                                click: function ($event) {
                                  return _vm.toggleMenu.apply(null, arguments)
                                },
                              },
                            }),
                      ]
                    : _vm._e(),
                ],
                2
              ),
            ]),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }