export default {
  path: '/admin/reporting',
  meta: { layout: 'admin' },
  component: () => import(
    /* webpackChunkName: "admin-reporting-index" */
    '@cms/components/reporting/index.vue'),
  children: [{
    path: '/',
    name: 'admin.reporting.index',
    redirect: 'snapshot'
  },
  {
    path: 'snapshot',
    name: 'admin.reporting.snapshot',
    meta: {
      title: 'Snapshot'
    },
    component: () => import('@cms/components/reporting/reports/snapshot.vue')
  },
  {
    path: 'users',
    name: 'admin.reporting.users',
    meta: {
      title: 'Users & Logins'
    },
    component: () => import('@cms/components/reporting/reports/users.vue')
  },
  {
    path: 'users/user/:user_id',
    name: 'admin.reporting.users.user',
    meta: {
      title: 'Users & Logins'
    },
    component: () => import('@cms/components/reporting/reports/user.vue')
  },
  {
    path: 'search',
    name: 'admin.reporting.search',
    meta: {
      title: 'Search Results'
    },
    component: () => import('@cms/components/reporting/reports/search.vue')
  },
  {
    path: 'assets',
    name: 'admin.reporting.assets',
    meta: {
      title: 'Files & Libraries'
    },
    component: () => import('@cms/components/reporting/reports/assets.vue')
  },
  {
    path: 'assets/asset/:asset_id',
    name: 'admin.reporting.assets.asset',
    meta: {
      title: 'Files & Libraries'
    },
    component: () => import('@cms/components/reporting/reports/asset.vue')
  },
  {
    path: 'assets/library/:library_id',
    name: 'admin.reporting.library',
    meta: {
      title: 'Files & Libraries'
    },
    component: () => import('@cms/components/reporting/reports/library.vue')
  },
  {
    path: 'pages',
    name: 'admin.reporting.pages',
    meta: {
      title: 'Pages'
    },
    component: () => import('@cms/components/reporting/reports/pages.vue')
  },
  {
    path: 'pages/page/:page_id',
    name: 'admin.reporting.pages.page',
    meta: {
      title: 'Pages'
    },
    component: () => import('@cms/components/reporting/reports/page.vue')
  },
  {
    path: 'help_desk',
    name: 'admin.reporting.helpdesk',
    meta: {
      title: 'Help Desk'
    },
    component: () => import('@cms/components/reporting/reports/help_desk.vue')
  },
  {
    path: 'showcase',
    name: 'admin.reporting.showcase',
    meta: {
      title: 'Showcase'
    },
    component: () => import('@cms/components/reporting/reports/ReportingShowcase.vue')
  },
  {
    path: 'ppt_generator',
    name: 'admin.reporting.ppt_generator',
    component: () => import('@cms/components/reporting/reports/ReportingPPTGenerator.vue')
  }
  ]
}
