import ContentRoute from "@cms/routes/modules/content";
import DashboardRoute from "@cms/routes/modules/dashboard";
import LibraryRoute from "@cms/routes/modules/libraries";
import LibraryManagementRoute from "@cms/routes/modules/library-management";
import MenuItemsRoute from "@cms/routes/modules/menu-items";
import ReportingRoute from "@cms/routes/modules/reporting";
import SettingsRoute from "@cms/routes/modules/settings";
import UsersRoute from "@cms/routes/modules/users";
import PermissionsManagementRoute from "@cms/routes/modules/permissions-management";

import Home from "@cms/routes/modules/home";
import Auth from "@cms/routes/modules/auth";
import NotificationsRoute from "@cms/routes/modules/notifications";

export default [
  Home,
  ...Auth,
  DashboardRoute,
  LibraryRoute,
  LibraryManagementRoute,
  ContentRoute,
  MenuItemsRoute,
  ReportingRoute,
  SettingsRoute,
  UsersRoute,
  PermissionsManagementRoute,
  NotificationsRoute,
  {
    path: "/admin/401",
    name: "admin.unauthorized",
    component: () =>
      import(
        /* webpackChunkName: "admin-unauthorized" */
        "@cms/components/Unauthorized.vue"
      ),
  },
  {
    path: "/admin/404",
    name: "admin.notfound",
    component: () =>
      import(
        /* webpackChunkName: "admin-notfound" */
        "@cms/components/NotFound.vue"
      ),
  },
  {
    path: "*",
    component: () =>
      import(
        /* webpackChunkName: "admin-notfound" */
        "@cms/components/NotFound.vue"
      ),
  },
];
