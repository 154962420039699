import actions from '../actions/actions'
import mutations from '../mutations/mutations'

function compareRank( a, b ) {
  if (a.rank < b.rank) { return -1 }
  if (a.rank > b.rank) { return 1 }
  return 0
}

export default {
  namespaced: true,
  state: {
    currentEntity: null,
    entities: [],
    filterParameters: null,
    searchParameters: null,
    isLoading: false,
    newElementCreated: false
  },
  mutations,
  actions,
  getters: {
    activeEntities: state => {
      return state.entities.filter(entity => !!entity.active)
    },
    activeEntitiesRanked: state => {
      return state.entities.filter(entity => !!entity.active).sort(compareRank)
    }
  }
}
