var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        role: "presentation",
        xmlns: "http://www.w3.org/2000/svg",
        width: "16",
        height: "16",
        viewBox: "0 0 16 16",
      },
    },
    [
      _c("g", [
        _c("circle", {
          staticClass: "st0",
          attrs: { cx: "5.5", cy: "2.1", r: "1.3" },
        }),
        _vm._v(" "),
        _c("circle", {
          staticClass: "st0",
          attrs: { cx: "10.2", cy: "2.1", r: "1.3" },
        }),
        _vm._v(" "),
        _c("circle", {
          staticClass: "st0",
          attrs: { cx: "5.5", cy: "7", r: "1.3" },
        }),
        _vm._v(" "),
        _c("circle", {
          staticClass: "st0",
          attrs: { cx: "10.2", cy: "7", r: "1.3" },
        }),
        _vm._v(" "),
        _c("circle", {
          staticClass: "st0",
          attrs: { cx: "5.5", cy: "11.9", r: "1.3" },
        }),
        _vm._v(" "),
        _c("circle", {
          staticClass: "st0",
          attrs: { cx: "10.2", cy: "11.9", r: "1.3" },
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }