var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        role: "presentation",
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 16 16",
        "enable-background": "new 0 0 16 16",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M15.3 2h-3.9v-1c0-.6-.5-1-1.2-1h-4.4c-.7 0-1.2.5-1.2 1v1h-3.9c-.4 0-.7.3-.7.7 0 .3.3.7.7.7h.8l.8 10.4c0 1.2 1 2.2 2.3 2.2h6.7c1.2 0 2.3-1 2.3-2.2l.9-10.4h.8c.4 0 .7-.3.7-.7 0-.4-.3-.7-.7-.7zm-9.4-.6h4.1v.6h-4.1v-.6zm.3 11c-.4 0-.7-.3-.7-.7l-.2-5.5c0-.4.2-.7.6-.7h.1c.4 0 .7.3.7.7l.3 5.5c-.1.3-.4.6-.8.7zm4.3-.7c0 .4-.3.7-.7.7-.4 0-.7-.3-.7-.7l.3-5.5c0-.4.3-.7.7-.7.4 0 .7.3.7.7l-.3 5.5z",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }