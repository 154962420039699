var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      staticStyle: { "enable-background": "new 0 0 20 14.6" },
      attrs: {
        role: "presentation",
        version: "1.1",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
        x: "0px",
        y: "0px",
        viewBox: "0 0 20 14.6",
        "xml:space": "preserve",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M18,0H2C0.9,0,0,0.9,0,2v10.7c0,1.1,0.9,2,2,2H18c1.1,0,2-0.9,2-2V2C20,0.9,19.1,0,18,0z M8.3,10.7V3.9l5.4,3.4L8.3,10.7z",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }