var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "carousel-container-component",
      class: {
        "is-admin": _vm._isAdminUser,
        simple: _vm.carouselType,
        default: !_vm.carouselType,
      },
      attrs: { "aria-hidden": "true" },
    },
    [
      _vm.slides.length
        ? [
            _c(
              "b-carousel",
              {
                ref: "carousel",
                attrs: {
                  id: _vm.sliderId,
                  "aria-label": _vm.$t("components_carousel_region_label"),
                  interval: _vm.interval,
                  controls: _vm.showControls,
                  indicators: _vm.showControls,
                  "no-hover-pause": _vm.carouselType,
                },
                on: {
                  "sliding-start": _vm.onSlideStart,
                  "sliding-end": _vm.onSlideEnd,
                },
                model: {
                  value: _vm.slideNum,
                  callback: function ($$v) {
                    _vm.slideNum = $$v
                  },
                  expression: "slideNum",
                },
              },
              [
                _vm.carouselData.interval !== 0
                  ? _c(
                      "button",
                      {
                        staticClass: "carousel-toggle-button pass-thru",
                        attrs: {
                          type: "button",
                          title: _vm.$t(
                            "components_carousel_actions_toggle-playback_label"
                          ),
                        },
                        on: { click: _vm.toggleCarouselPlay },
                      },
                      [
                        _c("icon", {
                          attrs: {
                            id:
                              _vm.interval === 0
                                ? "ic_play_circle_outline"
                                : "ic_pause_circle_outline",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm._l(_vm.slides, function (slide) {
                  return _c(
                    "div",
                    {
                      key: slide.id,
                      class: { split: slide.data.slideSize === "split" },
                    },
                    [
                      _c(
                        "b-carousel-slide",
                        {
                          style: [
                            { "background-color": slide.data.BGhex },
                            {
                              height: _vm.carouselHeight
                                ? `${_vm.carouselHeight}px`
                                : false,
                            },
                          ],
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "img",
                                fn: function () {
                                  return [
                                    _vm.carouselType
                                      ? [
                                          _c(
                                            "div",
                                            { staticClass: "img-wrap" },
                                            [
                                              _vm.isVideo(slide)
                                                ? _c("video", {
                                                    ref: "carouselImg",
                                                    refInFor: true,
                                                    attrs: {
                                                      src: slide.data.url,
                                                      alt:
                                                        slide.data.alt ||
                                                        slide.data.caption,
                                                      autoplay: "",
                                                      muted: "true",
                                                      loop: slide.data.loop
                                                        ? slide.data.loop
                                                        : false,
                                                    },
                                                    domProps: { muted: true },
                                                    on: {
                                                      keydown: function (
                                                        $event
                                                      ) {
                                                        !_vm._isAdminArea
                                                          ? _vm.$toggleVideoPlay
                                                          : null
                                                      },
                                                    },
                                                  })
                                                : _c("img", {
                                                    ref: "carouselImg",
                                                    refInFor: true,
                                                    staticClass:
                                                      "d-block img-fluid",
                                                    attrs: {
                                                      src: slide.data.url,
                                                      alt:
                                                        slide.data.alt ||
                                                        slide.data.caption,
                                                    },
                                                  }),
                                            ]
                                          ),
                                        ]
                                      : [
                                          _c(
                                            "div",
                                            { staticClass: "split-wrap" },
                                            [
                                              _vm.isVideo(slide)
                                                ? _c("video", {
                                                    ref: "video",
                                                    refInFor: true,
                                                    attrs: {
                                                      src: slide.data.url,
                                                      alt:
                                                        slide.data.alt ||
                                                        slide.data.caption,
                                                      autoplay: "",
                                                      loop: slide.data.loop
                                                        ? slide.data.loop
                                                        : false,
                                                      muted: "true",
                                                    },
                                                    domProps: { muted: true },
                                                    on: {
                                                      keydown: function (
                                                        $event
                                                      ) {
                                                        !_vm._isAdminArea
                                                          ? _vm.$toggleVideoPlay
                                                          : null
                                                      },
                                                    },
                                                  })
                                                : _c("img", {
                                                    staticClass:
                                                      "d-block img-fluid",
                                                    attrs: {
                                                      src: slide.data.url,
                                                      alt:
                                                        slide.data.alt ||
                                                        slide.data.caption,
                                                    },
                                                  }),
                                            ]
                                          ),
                                        ],
                                    _vm._v(" "),
                                    !_vm.carouselType &&
                                    slide.data.slideSize !== "split"
                                      ? _c(
                                          "b-container",
                                          { staticClass: "carousel-caption" },
                                          [
                                            _c(
                                              "b-row",
                                              {
                                                staticClass:
                                                  "slide-content-row",
                                              },
                                              [
                                                _c(
                                                  "b-col",
                                                  {
                                                    staticClass:
                                                      "slide-content-col",
                                                  },
                                                  [
                                                    slide.data.headlineBool &&
                                                    slide.data.headline.length
                                                      ? [
                                                          _c("h1", [
                                                            _vm._v(
                                                              _vm._s(
                                                                slide.data
                                                                  .headline
                                                              )
                                                            ),
                                                          ]),
                                                        ]
                                                      : _vm._e(),
                                                    _vm._v(" "),
                                                    slide.data.captionBool &&
                                                    slide.data.caption.length
                                                      ? [
                                                          _c("h3", [
                                                            _vm._v(
                                                              _vm._s(
                                                                slide.data
                                                                  .caption
                                                              )
                                                            ),
                                                          ]),
                                                        ]
                                                      : _vm._e(),
                                                    _vm._v(" "),
                                                    !_vm.carouselType &&
                                                    slide.data.ctaBool &&
                                                    slide.data.ctaText
                                                      ? [
                                                          slide.data
                                                            .ctaAction ===
                                                          "open_modal"
                                                            ? [
                                                                !_vm.carouselType &&
                                                                slide.data
                                                                  .ctaText
                                                                  ? _c(
                                                                      "a",
                                                                      {
                                                                        staticClass:
                                                                          "cta-button",
                                                                        class: {
                                                                          primary:
                                                                            slide
                                                                              .data
                                                                              .ctaType ===
                                                                            "primary",
                                                                          secondary:
                                                                            slide
                                                                              .data
                                                                              .ctaType ===
                                                                            "secondary",
                                                                          link:
                                                                            slide
                                                                              .data
                                                                              .ctaType ===
                                                                            "link",
                                                                        },
                                                                        attrs: {
                                                                          target:
                                                                            slide
                                                                              .data
                                                                              .ctaTarget,
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.ctaClick(
                                                                                slide.data
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "\n                        " +
                                                                            _vm._s(
                                                                              slide
                                                                                .data
                                                                                .ctaText
                                                                            ) +
                                                                            "\n                      "
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                              ]
                                                            : [
                                                                _c(
                                                                  "a",
                                                                  {
                                                                    staticClass:
                                                                      "cta-button",
                                                                    class: {
                                                                      primary:
                                                                        slide
                                                                          .data
                                                                          .ctaType ===
                                                                        "primary",
                                                                      secondary:
                                                                        slide
                                                                          .data
                                                                          .ctaType ===
                                                                        "secondary",
                                                                      link:
                                                                        slide
                                                                          .data
                                                                          .ctaType ===
                                                                        "link",
                                                                    },
                                                                    attrs: {
                                                                      href: slide
                                                                        .data
                                                                        .ctaLink,
                                                                      target:
                                                                        slide
                                                                          .data
                                                                          .ctaTarget,
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                        " +
                                                                        _vm._s(
                                                                          slide
                                                                            .data
                                                                            .ctaText
                                                                        ) +
                                                                        "\n                      "
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                        ]
                                                      : _vm._e(),
                                                  ],
                                                  2
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            true
                          ),
                        },
                        [
                          slide.data.slideSize === "split"
                            ? _c(
                                "div",
                                { staticClass: "side_container" },
                                [
                                  slide.data.headlineBool
                                    ? _c("h2", {
                                        directives: [
                                          {
                                            name: "sanitize",
                                            rawName: "v-sanitize",
                                            value: slide.data.headline,
                                            expression: "slide.data.headline",
                                          },
                                        ],
                                        staticClass: "headline",
                                        style: {
                                          color: `${slide.data.headlineHex} !important`,
                                        },
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  slide.data.captionBool
                                    ? _c("h3", {
                                        directives: [
                                          {
                                            name: "sanitize",
                                            rawName: "v-sanitize",
                                            value: slide.data.caption,
                                            expression: "slide.data.caption",
                                          },
                                        ],
                                        staticClass: "caption",
                                        style: {
                                          color: `${slide.data.captionHex} !important`,
                                        },
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  slide.data.ctaBool
                                    ? [
                                        slide.data.ctaAction === "open_modal"
                                          ? [
                                              _c("a", {
                                                directives: [
                                                  {
                                                    name: "sanitize",
                                                    rawName: "v-sanitize",
                                                    value: slide.data.ctaText,
                                                    expression:
                                                      "slide.data.ctaText",
                                                  },
                                                ],
                                                staticClass: "cta-button",
                                                class: [slide.data.ctaType],
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.ctaClick(
                                                      slide.data
                                                    )
                                                  },
                                                },
                                              }),
                                            ]
                                          : [
                                              _c(
                                                "a",
                                                {
                                                  staticClass: "cta-button",
                                                  class: [slide.data.ctaType],
                                                  attrs: {
                                                    href: slide.data.ctaLink,
                                                    target:
                                                      slide.data.ctaTarget,
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                  " +
                                                      _vm._s(
                                                        slide.data.ctaText
                                                      ) +
                                                      "\n                "
                                                  ),
                                                ]
                                              ),
                                            ],
                                      ]
                                    : _vm._e(),
                                ],
                                2
                              )
                            : _vm._e(),
                        ]
                      ),
                    ],
                    1
                  )
                }),
              ],
              2
            ),
            _vm._v(" "),
            _vm.carouselType
              ? _c("SimpleSliderCaption", {
                  attrs: { "slide-data": _vm.currentSlide.data },
                })
              : _vm._e(),
          ]
        : _c(
            "div",
            {
              staticClass: "placeholder",
              style: { height: `${_vm.height}px` },
            },
            [
              _c(
                "p",
                [
                  _c("icon", { attrs: { id: "ic_photo_library" } }),
                  _vm._v(" No image "),
                  _vm._isAdminArea
                    ? _c("span", [
                        _vm._v("-- Please edit module to upload image"),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ]
          ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }