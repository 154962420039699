/** Global Component Override Plugin
 * (https://monigleassociates.atlassian.net/wiki/spaces/B/pages/401801239/Customizable+Components+Plugin)
 *
 * The client components are loaded into their own object in the Vue root, and
 * then overwrite the core local component, allowing complete customization
 * of any core local component by simply extending the local component into
 * the client's components folder and making the appropriate changes.
 *
 * EVERY core component MUST HAVE A UNIQUE NAME !!!
 *
 * It is important to use ABSOLUTE PATHS when importing components!!!
 * e.g. '@core/components/{folder}/{ComponentName.vue}'
 *
 * The client component and core component
 * MUST HAVE EXACTLY THE SAME "name" PROPERTY!!!
 *
 * CLIENT > LOCAL > GLOBAL
 **/

export default {
  install (Vue) {
    const getComponents = (requireComponents = {}) => {
      const components = {}
      requireComponents.keys().forEach(fileName => {
        const componentConfig = requireComponents(fileName)
        const component = componentConfig.default ? componentConfig.default : componentConfig

        let componentName = component.name
        if (!componentName) {
          componentName = fileName.split('/').pop().replace(/\.\w+$/, '')
        }

        components[componentName] = component
      })
      return components
    }

    // The context path must be a string, variables cannot be used
    // @client is set to the current client folder in Webpack config.resolve.alias
    const clientComponents = require.context('@client/components', true, /\.vue$/i)

    // Set the client components to the root $clientComponents object
    Vue.prototype.$clientComponents = getComponents(clientComponents)

    // Create a global function on the main Vue instance
    Vue.prototype.$customizeCoreComponents = function () {
      const clientComponents = this.$clientComponents

      Object.keys(clientComponents).forEach(name => {
        if (this.$options.name === name) {
          const component = Vue.extend(clientComponents[name])
          this.$options = Object.assign({}, this.$options, component.options)
        }
      })
    }

    // Global mixin on every component kicks off the magic
    Vue.mixin({
      beforeCreate () {
        this.$customizeCoreComponents()
      }
    })
  }
}
