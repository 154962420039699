var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        role: "presentation",
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 17 17",
        "enable-background": "new 0 0 17 17",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M1.7 10.7c-.1.1-.2.2-.2.3l-1.5 5.1c0 .2 0 .4.1.6.2.3.4.4.8.3 1.5-.5 3.1-1 4.6-1.4.4-.1.7-.3 1-.6 2.8-2.8 5.7-5.6 8.5-8.5l-4.5-4.5c-4.3 4.2-7.2 7.1-8.8 8.7zm2.6 3.9c-.6.2-1.2.3-1.7.5-.1 0-.2 0-.2-.1s-.1-.1-.2-.2c-.3-.2-.4-.5-.2-.8l.6-2.1c0-.1 0-.1.1-.2 0 0 .1 0 .1.1.2.3.5.3.8.3l1.2.1.1.9v.3c0 .3.1.5.3.7l.1.1c-.4.2-.7.3-1 .4zm12.2-9.5l-.9.9c-1.5-1.5-3-3-4.5-4.6 0 0 1-1.1 1.4-1.3.5-.3 1.2-.1 1.7.4l2.3 2.3c.7.7.7 1.6 0 2.3z",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }