var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: _vm.uploaderClass },
    [
      _vm._t("clip-uploader-action", null, { dragging: _vm.dragCounter > 0 }),
      _vm._v(" "),
      _vm._t("clip-uploader-body", null, { files: _vm.files }),
      _vm._v(" "),
      _c(
        "div",
        {
          ref: "clip-preview-template",
          staticClass: "clip-preview-template",
          staticStyle: { display: "none" },
        },
        [_c("div")]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }