var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "16",
        height: "18",
        viewBox: "0 0 16 18",
      },
    },
    [
      _c("g", { attrs: { fill: "none", "fill-rule": "evenodd" } }, [
        _c("path", {
          attrs: { d: "M0 0H24V24H0z", transform: "translate(-4 -3)" },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            fill: "#222428",
            "fill-rule": "nonzero",
            d: "M12.373 20.914c-.235.115-.511.115-.746 0-.23-.114-.618-.324-1.11-.626-.817-.502-1.634-1.083-2.4-1.74C5.872 16.618 4.5 14.43 4.5 12V5.455c0-.376.26-.703.631-.794l6.667-1.637c.133-.032.271-.032.404 0l6.667 1.637c.37.09.631.418.631.794V12c0 2.43-1.371 4.618-3.618 6.548-.765.657-1.582 1.238-2.399 1.74-.492.302-.88.512-1.11.626z",
            transform: "translate(-4 -3)",
          },
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }