var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "vc-twitter",
      class: {
        "vc-twitter-hide-triangle ": _vm.triangle === "hide",
        "vc-twitter-top-left-triangle ": _vm.triangle === "top-left",
        "vc-twitter-top-right-triangle ": _vm.triangle === "top-right",
      },
      style: {
        width: typeof _vm.width === "number" ? `${_vm.width}px` : _vm.width,
      },
    },
    [
      _c("div", { staticClass: "vc-twitter-triangle-shadow" }),
      _vm._v(" "),
      _c("div", { staticClass: "vc-twitter-triangle" }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "vc-twitter-body" },
        [
          _vm._l(_vm.defaultColors, function (color, index) {
            return _c("span", {
              key: index,
              staticClass: "vc-twitter-swatch",
              style: {
                background: color,
                boxShadow: `0 0 4px ${
                  _vm.equal(color) ? color : "transparent"
                }`,
              },
              on: {
                click: function ($event) {
                  return _vm.handlerClick(color)
                },
              },
            })
          }),
          _vm._v(" "),
          _c("div", { staticClass: "vc-twitter-hash" }, [_vm._v("#")]),
          _vm._v(" "),
          _c("editable-input", {
            attrs: { label: "#", value: _vm.hex },
            on: { change: _vm.inputChange },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "vc-twitter-clear" }),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }