var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "module video-module" },
    [
      _c("module-controls", {
        attrs: {
          placeholder: _vm.moduleLabel,
          "action-buttons": _vm._actionButtons,
        },
        on: {
          "on-edit": _vm.$openModal,
          "on-remove": _vm.$deleteContentModuleConfirm,
        },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "module_content" }, [
        _vm.ctaContentType === "embeded"
          ? _c("div", [
              _vm.ctaContentVideoEmbedCode
                ? _c("div", {
                    staticClass: "preview embedded",
                    domProps: {
                      innerHTML: _vm._s(_vm.ctaContentVideoEmbedCode),
                    },
                  })
                : _vm._e(),
            ])
          : _c(
              "div",
              [
                !_vm.url
                  ? _c("div", { staticClass: "empty" }, [_vm._v("No video")])
                  : _vm._e(),
                _vm._v(" "),
                _vm.url
                  ? [
                      _c(
                        "figure",
                        [
                          _c(
                            "p",
                            {
                              staticClass: "offscreen",
                              attrs: { id: _vm.videoDescId },
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.desc) +
                                  "\n          "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _vm.player.modal && _vm.poster
                            ? [
                                _c(
                                  "button",
                                  {
                                    staticClass: "poster-btn",
                                    attrs: {
                                      type: "button",
                                      title: _vm.$t(
                                        "components_global_actions_open-in-modal_label"
                                      ),
                                      "aria-describedby": _vm.videoDescId,
                                    },
                                    on: { click: _vm.showVideoModal },
                                  },
                                  [
                                    _c("icon", {
                                      attrs: { id: "ic_play_circle_filled" },
                                    }),
                                    _vm._v(" "),
                                    _c("img", {
                                      attrs: { src: _vm.poster, alt: _vm.alt },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            : [
                                _c("video", {
                                  ref: "video",
                                  attrs: {
                                    src: _vm.url,
                                    poster: _vm.poster,
                                    controls: _vm.player.controls,
                                    autoplay: _vm.player.autoplay,
                                    loop: _vm.player.loop,
                                    controlslist: "nodownload",
                                    disablePictureInPicture: "",
                                    title: _vm.desc,
                                    "aria-label": _vm.desc,
                                    "aria-describedby": _vm.videoDescId,
                                  },
                                  domProps: { muted: _vm.player.autoplay },
                                  on: {
                                    keydown: function ($event) {
                                      !_vm._isAdminArea
                                        ? _vm.$toggleVideoPlay
                                        : null
                                    },
                                  },
                                }),
                              ],
                          _vm._v(" "),
                          _vm.caption
                            ? _c("figcaption", [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(_vm.caption) +
                                    "\n          "
                                ),
                              ])
                            : _vm._e(),
                        ],
                        2
                      ),
                    ]
                  : _vm._e(),
              ],
              2
            ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }