var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "audio-wrapper" }, [
    _c("div", {
      staticClass: "audio-background",
      style: { backgroundImage: `url(${_vm.assetThumbnail})` },
    }),
    _vm._v(" "),
    _c("div", { staticClass: "audio-player-container" }, [
      _c("div", { staticClass: "audio-player" }, [
        _c("div", { staticClass: "player-dashboard" }, [
          _c("button", {
            class: _vm.playing ? "pause" : "play",
            on: { click: _vm.togglePlayback },
          }),
          _vm._v(" "),
          _c("p", { staticClass: "duration-readout" }, [
            _vm._v(
              "\n          " +
                _vm._s(_vm.formatTime(_vm.seek)) +
                " / " +
                _vm._s(_vm.formatTime(_vm.duration)) +
                "\n        "
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "progress-container" }, [
          _c("div", { staticClass: "progress-bar-container" }, [
            _c(
              "div",
              {
                staticClass: "progress-indicator",
                style: { width: _vm.progressWidth + "%" },
              },
              [_c("div", { staticClass: "thumb" })]
            ),
            _vm._v(" "),
            _c("input", {
              staticClass: "progress-bar",
              class: _vm.setDragClass ? "drag" : "",
              attrs: {
                type: "range",
                min: "0",
                max: "100",
                step: "2",
                "v-model": _vm.progressBar,
                "content-editable:true": "",
              },
              on: {
                click: function ($event) {
                  return _vm.onClick($event)
                },
                input: function ($event) {
                  return _vm.onInput($event)
                },
              },
            }),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "player-volume" }, [
          _c("button", {
            staticClass: "volume",
            class: _vm.volumeLevel <= 1 ? "muted" : "sound-on",
            on: {
              click: function ($event) {
                _vm.isVolActive = !_vm.isVolActive
              },
            },
          }),
          _vm._v(" "),
          _vm.isVolActive
            ? _c("div", { staticClass: "volume-slider" }, [
                _c("div", { staticClass: "volume-container" }, [
                  _c("div", { staticClass: "volume-control" }, [
                    _c("div", {
                      staticClass: "volume-fill",
                      style: { height: _vm.volumeLevel + "%" },
                    }),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.volumeLevel,
                          expression: "volumeLevel",
                        },
                      ],
                      staticClass: "volume-input",
                      attrs: {
                        orientation: "vertical",
                        type: "range",
                        step: "1",
                        min: "0",
                        max: "100",
                      },
                      domProps: { value: _vm.volumeLevel },
                      on: {
                        input: function ($event) {
                          return _vm.onVolInput($event)
                        },
                        __r: function ($event) {
                          _vm.volumeLevel = $event.target.value
                        },
                      },
                    }),
                  ]),
                ]),
              ])
            : _vm._e(),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }