export default {
  install (Vue) {
    Vue.filter('textHighlight', (data, term) => {
      if (data && term) {
        return data.replace(new RegExp(term, 'gi'), match => {
          return `<strong class="highlight">${match}</strong>`
        })
      }
      return data
    })

    Vue.filter('nl2br', (str) => {
      if (!str) return ''
      return str.replace(/(?:\r\n|\r|\n)/g, '<br />')
    })

    Vue.filter('formatDate', (str, fmt) => {
      if (!str) return ''
      return moment(str).format(fmt)
    })

    Vue.filter('phoneNumber', (str) => {
      if (!str) return ''
      const s2 = `${str}`.replace(/\D/g, '')
      const m = s2.match(/^(\d{3})(\d{3})(\d{4})$/)
      return !m ? null : `(${m[1]}) ${m[2]}-${m[3]}`
    })

    Vue.filter('fileExtension', (str) => {
      if (!str) return ''
      const fileArr = str.split('.')
      return fileArr[fileArr.length - 1]
    })

    Vue.filter('fileName', (str) => {
      if (!str) return ''
      const fileArr = str.split('/')
      return fileArr[fileArr.length - 1]
    })

    Vue.filter('capitalize', (str) => {
      if (!str) return ''
      str = str.toString()
      return str.charAt(0).toUpperCase() + str.slice(1)
    })

    Vue.filter('fileSize', (str) => {
      const byteUnits = [
        ' KB',
        ' MB',
        ' GB',
        ' TB',
        ' PB',
        ' EB',
        ' ZB',
        ' YB'
      ]
      let i = -1
      do {
        str = str / 1024
        i++
      } while (str > 1024)
      return Math.max(str, 0.1)
        .toFixed(1) + byteUnits[i]
    })
  }
}
