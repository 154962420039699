var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      staticStyle: { "enable-background": "new 0 0 20 20" },
      attrs: {
        role: "presentation",
        version: "1.1",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
        x: "0px",
        y: "0px",
        viewBox: "0 0 20 20",
        "xml:space": "preserve",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M15,18.5V20h-2v-1.5H15z M7,18.5V20H5v-1.5H7z M11,18.5V20H9v-1.5H11z M1.5,17v1.5H3V20H0v-3H1.5z M17,20v-1.5h1.5V17H20v3\n  H17z M15,5.5v2h-4v8H9v-8H5v-2H15z M1.5,13v2H0v-2H1.5z M20,13v2h-1.5v-2H20z M1.5,9v2H0V9H1.5z M20,9v2h-1.5V9H20z M1.5,5v2H0V5\n  H1.5z M20,5v2h-1.5V5H20z M20,0v3h-1.5V1.5H17V0H20z M3,0v1.5H1.5V3H0V0H3z M15,0v1.5h-2V0H15z M7,0v1.5H5V0H7z M11,0v1.5H9V0H11z",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }