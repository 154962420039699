const InitAnalytics = {
  isValidTrackingEnv() {
    const env = process.env.NODE_ENV;
    return true;
    // return env === "production" || env === "stage" || env === "staging";
  },

  getTrackingID(key = false) {
    let ID = null;
    if (key) {
      const values = window.AppStore.getters["Config/themeStrings"];
      // Hardcoding "en" key, continue if GA key is in the settings
      if (values && values[key]) {
        ID = values[key].replace(/[^a-zA-Z0-9-_]+/gi, "");
      }
    }
    return ID;
  },

  getTrackingSource(key = false) {
    let source = null;
    if (key) {
      const values = window.AppStore.getters["Config/themeStrings"];
      if (values && values[key]) {
        source = values[key];
      }
    }
    return source;
  },

  setTrackingValue(key = false, val = false) {
    if (typeof key === "string" && typeof val === "string") {
      const globalSettings = window.AppStore.state.Global;
      if (typeof globalSettings.tracking !== "undefined") {
        window.AppStore.state.Global.tracking[key] = val;
      } else {
        let obj = {};
        obj[key] = val;
        window.AppStore.state.Global.tracking = obj;
      }
    }
  },

  setGATracking() {
    if (this.isValidTrackingEnv()) {
      const gaCode = this.getTrackingID("global_ga_id");
      if (gaCode) {
        this.setTrackingValue("ga", gaCode);
        let remote = document.createElement("script");
        remote.type = "text/javascript";
        remote.async = true;
        remote.nonce = __webpack_nonce__;
        remote.src = "https://www.googletagmanager.com/gtag/js?id=" + gaCode;

        let local = document.createElement("script");
        local.type = "text/javascript";
        local.nonce = __webpack_nonce__;
        local.text =
          "window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments); } gtag('js', new Date()); gtag('config', '" +
          gaCode +
          "');";

        const head = document.getElementsByTagName("head")[0];
        head.appendChild(remote);
        head.appendChild(local);
      }
    }
  },

  setGTMTracking() {
    if (this.isValidTrackingEnv()) {
      const gtmID = this.getTrackingID("global_gtm_id");
      if (gtmID) {
        this.setTrackingValue("gtm", gtmID);
        let gtmScript = document.createElement("script");
        gtmScript.type = "text/javascript";
        gtmScript.nonce = __webpack_nonce__;
        gtmScript.text =
          "(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','" +
          gtmID +
          "');";

        const head = document.getElementsByTagName("head")[0];
        head.appendChild(gtmScript);
      }
    }
  },

  init() {
    this.setGATracking();
    this.setGTMTracking();
  },
};

export { InitAnalytics };
