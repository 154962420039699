import store from '@core/store'

function getPermissions (user) {
  const authPages = store.getters['Settings/beamFeatures'].authPages
  const allowAuthPages = !!('enabled' === authPages || 'published' === authPages)
  const abilities = user.permissions.abilities
  let userPerms = {
    authAdmin: false,
    homeAdmin: false,
    pageAdmin: false
  }

  if (user && abilities) {
    userPerms.authAdmin = allowAuthPages && !!(abilities.authpages && abilities.authpages.update)
    userPerms.homeAdmin = !!(abilities.homepages && abilities.homepages.update)
    userPerms.pageAdmin = !!(abilities.pages && abilities.pages.update)
  }

  return userPerms
}

export default {
  path: '/admin/menuitems',
  meta: { layout: 'admin' },
  component: () => import(
    /* webpackChunkName: "admin-menuitems-menuitems" */
    '@cms/components/menuitems/MenuItems.vue'),
  children: [
    {
      path: '',
      name: 'admin.menuitems.index',
      meta: { type: 'homepageMenuItems' },
      components: {
        sidebar: () => import(
          /* webpackChunkName: "admin-menu" */
          '@cms/components/menuitems/menu.vue'),
        main: () => import(
          /* webpackChunkName: "admin-menu-items" */
          '@cms/components/menuitems/display/menu-items.vue')
      }
    },
    {
      path: 'authpages',
      name: 'admin.menuitems.authpages',
      meta: { type: 'authpagesMenuItems' },
      beforeEnter (to, from, next) {
        const user = store.getters['Users/currentUser']
        const perms = getPermissions(user)

        if (!perms.pageAdmin && !perms.authAdmin) {
          next('/401')
        } else if (perms.pageAdmin && !perms.authAdmin) {
          next('/admin/menuitems/content')
        } else {
          next()
        }
      },
      components: {
        sidebar: () => import(
          /* webpackChunkName: "admin-menu" */
          '@cms/components/menuitems/menu.vue'),
        main: () => import(
          /* webpackChunkName: "admin-menu-items" */
          '@cms/components/menuitems/display/menu-items.vue')
      }
    },
    {
      path: 'authpages/assignments',
      name: 'admin.menuitems.authpages.assignments',
      meta: { type: 'authpagesMenuItems', fullWidth: true },
      components: {
        sidebar: () => import(
          /* webpackChunkName: "admin-menu" */
          '@cms/components/menuitems/menu.vue'),
        main: () => import(
          /* webpackChunkName: "admin-menu-items-auth-assignments" */
          '@cms/components/menuitems/assignments/AuthAssignments.vue')
      }
    },
    {
      path: 'homepage',
      name: 'admin.menuitems.homepage',
      meta: { type: 'homepageMenuItems' },
      beforeEnter (to, from, next) {
        const user = store.getters['Users/currentUser']
        const perms = getPermissions(user)

        if (!perms.pageAdmin && !perms.homeAdmin) {
          next('/401')
        } else if (perms.pageAdmin && !perms.homeAdmin) {
          next('/admin/menuitems/content')
        } else {
          next()
        }
      },
      components: {
        sidebar: () => import(
          /* webpackChunkName: "admin-menu" */
          '@cms/components/menuitems/menu.vue'),
        main: () => import(
          /* webpackChunkName: "admin-menu-items" */
          '@cms/components/menuitems/display/menu-items.vue')
      }
    },
    {
      path: 'homepage/assignments',
      name: 'admin.menuitems.homepage.assignments',
      meta: { type: 'homepageMenuItems', fullWidth: true },
      components: {
        // sidebar: () => import(
        //   /* webpackChunkName: "admin-menu" */
        //   '@cms/components/menuitems/menu.vue'),
        main: () => import(
          /* webpackChunkName: "admin-menu-items-homepage-assignments" */
          '@cms/components/menuitems/assignments/HomepageAssignments.vue')
      }
    },
    {
      path: 'content',
      name: 'admin.menuitems.content',
      beforeEnter (to, from, next) {
        const user = store.getters['Users/currentUser']
        const perms = getPermissions(user)

        if (!perms.pageAdmin && !perms.homeAdmin) {
          next('/401')
        } else if (!perms.pageAdmin && perms.homeAdmin) {
          next('/admin/menuitems/homepage')
        } else {
          next()
        }
      },
      components: {
        sidebar: () => import(
        /* webpackChunkName: "admin-menu" */
          '@cms/components/menuitems/menu.vue'),
        main: () => import(
          /* webpackChunkName: "admin-menu-items" */
          '@cms/components/menuitems/display/menu-items.vue')
      }
    },
    {
      path: ':id',
      name: 'admin.menuitems.content.page',
      components: {
        sidebar: () => import(
          /* webpackChunkName: "admin-menu-settings" */
          '@cms/components/menuitems/components/MenuItemsSidebar.vue'),
        main: () => import(
          /* webpackChunkName: "admin-menu-items" */
          '@cms/components/menuitems/display/menu-items.vue')
      },
      children: [
        {
          path: 'settings',
          name: 'admin.menuitems.content.page.settings',
          meta: { pageTitle: 'Settings' },
          component: () => import(
            /* webpackChunkName: "admin-menu-settings" */
            '@cms/components/menuitems/MenuItemsSettings.vue')
        },
        {
          path: 'permissions',
          name: 'admin.menuitems.content.page.permissions',
          meta: { pageTitle: 'Permissions' },
          component: () => import(
            /* webpackChunkName: "admin-menu-permissions" */
            '@cms/components/menuitems/MenuItemsPermissions.vue')
        }
      ]
    },
    {
      path: '/admin/menuitems/*',
      name: 'admin.menuitems.notfound',
      redirect: '/404'
    }
  ]
}
