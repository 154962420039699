var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "40",
        height: "40",
        viewBox: "0 0 40 40",
      },
    },
    [
      _c("g", { attrs: { fill: "none", "fill-rule": "evenodd" } }, [
        _c("path", {
          attrs: { d: "M0 0H40V40H0z", transform: "matrix(-1 0 0 1 40 0)" },
        }),
        _vm._v(" "),
        _c(
          "g",
          {
            attrs: {
              stroke: "#215CA0",
              "stroke-linecap": "round",
              "stroke-linejoin": "round",
              "stroke-width": "4",
            },
          },
          [
            _c("path", {
              attrs: {
                d: "M9.167 18.334c5.062 0 9.166-4.104 9.166-9.167S14.23 0 9.167 0C4.104 0 0 4.104 0 9.167c0 5.063 4.104 9.167 9.167 9.167zM15.833 15.833L26.666 26.671",
                transform: "matrix(-1 0 0 1 40 0) translate(6.667 6.667)",
              },
            }),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }