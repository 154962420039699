import CmsRoutes from './routes/cms'

import Forms from '@core/plugins/FormEditor/store/forms'
import FormComponents from '@core/plugins/FormEditor/store/formComponents'
import FormComponentOptions from '@core/plugins/FormEditor/store/formComponentOptions'

import { RouteUtils } from '@core/js'

export default {
  install (Vue, options) {
    if (!options) {
      throw new Error('The FormEditor Extension requires an options object!')
    }

    if (!options.router) {
      throw new Error('The FormEditor Extension requires a router option!')
    }

    // Register the FormEditor store module
    window.AppStore.registerModule('Forms', Forms)
    window.AppStore.registerModule('FormComponents', FormComponents)
    window.AppStore.registerModule('FormComponentOptions', FormComponentOptions)

    // Add routes to router
    const FormEditorRoutes = CmsRoutes

    let params = RouteUtils.getRouteParams(options.router.getRoutes())

    FormEditorRoutes.forEach(route => {
      route = RouteUtils.standardizePluginRoutePath(route, params)
      options.router.addRoute(route)
    })
  }
}
