var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "module zoomcard-module" },
    [
      _c("module-controls", {
        attrs: {
          placeholder: _vm.moduleLabel,
          "action-buttons": _vm._actionButtons,
        },
        on: {
          "on-edit": _vm.$openModal,
          "on-remove": _vm.$deleteContentModuleConfirm,
        },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "module_content" },
        [
          _vm._isAdminArea && _vm.isEmpty
            ? _c("div", { staticClass: "empty" }, [
                _vm._v("\n      This module is empty. Start editing!\n    "),
              ])
            : _vm._e(),
          _vm._v(" "),
          !_vm.isEmpty
            ? [
                _c("div", { staticClass: "zoomcard-content-client" }, [
                  _c(
                    "button",
                    { staticClass: "pass-thru", on: { click: _vm.showLarge } },
                    [
                      _c("img", {
                        attrs: { src: _vm.imageSmall, alt: _vm.smallAlt },
                      }),
                      _vm._v(" "),
                      _vm._m(0),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _vm.zoomed
                  ? _c("focus-trap", { attrs: { active: _vm.zoomed } }, [
                      _c(
                        "div",
                        {
                          staticClass: "zoomcard-zoomed",
                          attrs: { tabindex: "-1" },
                        },
                        [
                          _c("div", { staticClass: "header" }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.imageTitle) +
                                " " +
                                _vm._s(_vm.zoom_level_prc) +
                                "%\n            "
                            ),
                            _c("button", {
                              staticClass: "close_btn",
                              attrs: {
                                title: _vm.$t("site_actions_close_label"),
                              },
                              on: { click: _vm.hideLarge },
                            }),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              attrs: {
                                id: "image_container",
                                tabindex: "0",
                                "aria-label": _vm.$t(
                                  "components_zoomcard_controls_tip"
                                ),
                              },
                              on: {
                                keydown: function ($event) {
                                  if ($event.target !== $event.currentTarget)
                                    return null
                                  return _vm.moveImg.apply(null, arguments)
                                },
                                mousemove: _vm.buttonTimer,
                                touchstart: _vm.buttonTimer,
                                touchend: _vm.buttonTimer,
                                click: _vm.buttonTimer,
                              },
                            },
                            [
                              _c("img", {
                                ref: "image_move",
                                style: {
                                  height: `${_vm.image_height}px`,
                                  width: `${_vm.image_width}px`,
                                  left: `${_vm.image_left}px`,
                                  top: `${_vm.image_top}px`,
                                },
                                attrs: {
                                  id: "image_move",
                                  src: _vm.imageLarge,
                                  alt: _vm.largeAlt,
                                },
                                on: {
                                  mousedown: _vm.dragStart,
                                  touchstart: _vm.dragStart,
                                  mouseup: _vm.dragStop,
                                  touchend: _vm.dragStop,
                                  mousemove: _vm.adjustZoomImage,
                                  touchmove: _vm.adjustZoomImage,
                                },
                              }),
                              _vm._v(" "),
                              _vm.buttons_visible
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "image_btn in",
                                      class: {
                                        inactive:
                                          _vm.zoom_level >= _vm.zoom_levels ||
                                          _vm.zoom_level_prc >= 100,
                                      },
                                    },
                                    [
                                      _c("button", {
                                        staticClass: "pass-thru",
                                        attrs: {
                                          type: "button",
                                          title: _vm.$t(
                                            "components_global_actions_zoom-in_label"
                                          ),
                                          disabled:
                                            _vm.zoom_level >= _vm.zoom_levels ||
                                            _vm.zoom_level_prc >= 100,
                                        },
                                        on: { click: _vm.zoomIn },
                                      }),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.buttons_visible
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "image_btn out",
                                      class: { inactive: _vm.zoom_level === 0 },
                                    },
                                    [
                                      _c("button", {
                                        staticClass: "pass-thru",
                                        attrs: {
                                          type: "button",
                                          title: _vm.$t(
                                            "components_global_actions_zoom-out_label"
                                          ),
                                          disabled: _vm.zoom_level === 0,
                                        },
                                        on: { click: _vm.zoomOut },
                                      }),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.buttons_visible
                                ? _c("div", { staticClass: "image_btn fit" }, [
                                    _c("button", {
                                      staticClass: "pass-thru",
                                      attrs: {
                                        type: "button",
                                        title: _vm.$t(
                                          "components_global_actions_fullscreen_label"
                                        ),
                                      },
                                      on: { click: _vm.zoomFit },
                                    }),
                                  ])
                                : _vm._e(),
                            ]
                          ),
                        ]
                      ),
                    ])
                  : _vm._e(),
              ]
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "image-overlay" }, [
      _c("div", { staticClass: "mglass" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }