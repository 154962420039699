var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "module showcase-carousel-module" },
    [
      _c("module-controls", {
        attrs: {
          placeholder: _vm.moduleLabel,
          "action-buttons": _vm._actionButtons,
        },
        on: {
          "on-edit": _vm.$openModal,
          "on-remove": _vm.$deleteContentModuleConfirm,
        },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "module_content" },
        [
          _vm._isAdminArea && _vm.isEmpty
            ? [_vm._v("\n      You don't have any Featured Posts.\n    ")]
            : _vm._e(),
          _vm._v(" "),
          _vm._isAdminArea && !_vm.isEmpty
            ? [
                _c("ShowcaseCarousel", {
                  attrs: {
                    "link-on-image": false,
                    slides: _vm.slides,
                    height: parseInt(_vm.moduleData.height, 10),
                    "show-title": _vm.moduleData.title,
                    "show-link": _vm.moduleData.link,
                    "title-style": _vm.moduleData.titleStyle,
                  },
                }),
              ]
            : _vm._e(),
          _vm._v(" "),
          !_vm._isAdminArea && !_vm.isEmpty
            ? [
                _c("ShowcaseCarousel", {
                  attrs: {
                    "link-on-image": true,
                    slides: _vm.slides,
                    height: parseInt(_vm.moduleData.height, 10),
                    "show-title": _vm.moduleData.title,
                    "show-link": _vm.moduleData.link,
                    "title-style": _vm.moduleData.titleStyle,
                  },
                }),
              ]
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }