var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("td", { ref: "cell", staticClass: "cell" }, [
    _c(
      "div",
      {
        style: {
          width: _vm.column.width + "px",
          maxWidth: _vm.column.width + "px",
          minWidth: _vm.column.width + "px",
          height: _vm.isExpanded ? "auto" : "50px",
          maxHeight: _vm.isExpanded ? "none" : "50px",
          overflow: _vm.isExpanded ? "visible" : "hidden",
        },
      },
      [
        _vm.column.type === "link"
          ? [
              _c(
                "div",
                { staticClass: "link", on: { click: _vm.redirectToLink } },
                [
                  _c("a", { staticClass: "text" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.getLinkData.linkText) +
                        "\n        "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("icon", {
                    staticClass: "icon",
                    attrs: { id: "ic_launch" },
                  }),
                ],
                1
              ),
            ]
          : [_vm._v("\n      " + _vm._s(_vm.value) + "\n    ")],
      ],
      2
    ),
    _vm._v(" "),
    _vm.exceedsMaxCharacters
      ? _c(
          "a",
          {
            staticClass: "expand-collapse",
            on: { click: _vm.toggleExpansion },
          },
          [_vm._v(_vm._s(_vm.isExpanded ? "Show Less" : "Read More"))]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }