var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 21 21",
        role: "presentation",
      },
    },
    [
      _c("circle", {
        staticStyle: { fill: "none" },
        attrs: { cx: "10.5", cy: "10.5", r: "9.5" },
      }),
      _vm._v(" "),
      _c("path", {
        staticStyle: { fill: "currentColor" },
        attrs: {
          d: "M14.5,6A8.5,8.5,0,1,1,6,14.5,8.51,8.51,0,0,1,14.5,6m0-2A10.5,10.5,0,1,0,25,14.5,10.5,10.5,0,0,0,14.5,4Z",
          transform: "translate(-4 -4)",
        },
      }),
      _vm._v(" "),
      _c("line", {
        staticStyle: {
          fill: "none",
          stroke: "currentColor",
          "stroke-miterlimit": "10",
          "stroke-width": "2px",
        },
        attrs: { x1: "7", y1: "7", x2: "14", y2: "14" },
      }),
      _vm._v(" "),
      _c("line", {
        staticStyle: {
          fill: "none",
          stroke: "currentColor",
          "stroke-miterlimit": "10",
          "stroke-width": "2px",
        },
        attrs: { x1: "14", y1: "7", x2: "7", y2: "14" },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }