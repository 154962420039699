var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: ["vc-hue", _vm.directionClass] }, [
    _c(
      "div",
      {
        ref: "container",
        staticClass: "vc-hue-container",
        attrs: {
          role: "slider",
          "aria-valuenow": _vm.colors.hsl.h,
          "aria-valuemin": "0",
          "aria-valuemax": "360",
        },
        on: {
          mousedown: _vm.handleMouseDown,
          touchmove: _vm.handleChange,
          touchstart: _vm.handleChange,
        },
      },
      [
        _c(
          "div",
          {
            staticClass: "vc-hue-pointer",
            style: { top: _vm.pointerTop, left: _vm.pointerLeft },
            attrs: { role: "presentation" },
          },
          [_c("div", { staticClass: "vc-hue-picker" })]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }