var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.asset.id
    ? _c("div", [
        _vm.assetType === "simple"
          ? _c("div", { staticClass: "asset-container simple" }, [
              _vm.asset
                ? _c(
                    "div",
                    { class: ["asset-wrapper", { hover: _vm.hover }] },
                    [
                      _vm.canModal
                        ? _c(
                            "a",
                            {
                              attrs: { href: `/asset/info/${_vm.asset.id}` },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.$onInfo(
                                    _vm.asset.id,
                                    [_vm.asset],
                                    _vm.modalActions,
                                    _vm.assetActions
                                  )
                                },
                              },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: _vm.thumbnail_url,
                                  alt: _vm.title,
                                },
                              }),
                              _vm._v(" "),
                              _c("div", { staticClass: "click_ico" }),
                            ]
                          )
                        : _c("img", {
                            attrs: { src: _vm.thumbnail_url, alt: _vm.title },
                          }),
                      _vm._v(" "),
                      _vm.canDownload || _vm.canEmail || _vm.canCollections
                        ? _c("div", { staticClass: "asset-buttons" }, [
                            _vm.canDownload
                              ? _c(
                                  "button",
                                  {
                                    staticClass: "secondary",
                                    attrs: {
                                      type: "button",
                                      role: "button",
                                      title: _vm.$t(
                                        "site_actions_download_label"
                                      ),
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.$onAsset(
                                          _vm.asset.id,
                                          _vm.asset.title
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("icon", {
                                      attrs: { id: _vm._download_get },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.canEmail && _vm.currentUser
                              ? _c(
                                  "button",
                                  {
                                    staticClass: "secondary",
                                    attrs: {
                                      type: "button",
                                      role: "button",
                                      title: _vm.$t("site_actions_share_label"),
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.$onShare(_vm.asset.id)
                                      },
                                    },
                                  },
                                  [
                                    _c("icon", {
                                      attrs: { id: _vm._share_view },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.canCollections && _vm.currentUser
                              ? _c(
                                  "button",
                                  {
                                    staticClass: "secondary",
                                    attrs: {
                                      type: "button",
                                      role: "button",
                                      title: _vm.$t(
                                        "features_collections_general_label"
                                      ),
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.$onLightbox(_vm.asset.id)
                                      },
                                    },
                                  },
                                  [
                                    _c("icon", {
                                      attrs: { id: _vm._collections_add },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ])
                        : _vm._e(),
                    ]
                  )
                : _vm._e(),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.assetType === "complex"
          ? _c(
              "div",
              {
                staticClass: "asset-container complex",
                class: { with_image: _vm.showImage },
              },
              [
                _vm.showImage
                  ? _c("div", {
                      staticClass: "image",
                      style: { backgroundImage: `url('${_vm.thumbnail_url}')` },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.showTitle
                  ? _c("div", { staticClass: "title" }, [
                      _vm._v("\n      " + _vm._s(_vm.title) + "\n    "),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.showDetails
                  ? _c("div", { staticClass: "combined_details" }, [
                      _vm._v(
                        "\n      " + _vm._s(_vm.combined_details) + "\n    "
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.canDownload ||
                _vm.canEmail ||
                _vm.canCollections ||
                _vm.canModal
                  ? _c("div", { staticClass: "actions" }, [
                      _vm.canDownload
                        ? _c(
                            "button",
                            {
                              staticClass: "download",
                              attrs: {
                                type: "button",
                                role: "button",
                                title: _vm.$t("site_actions_download_label"),
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.$onAsset(
                                    _vm.asset.id,
                                    _vm.asset.title
                                  )
                                },
                              },
                            },
                            [_c("icon", { attrs: { id: _vm._download_get } })],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.canEmail && _vm.currentUser
                        ? _c(
                            "button",
                            {
                              staticClass: "email",
                              attrs: {
                                type: "button",
                                role: "button",
                                title: _vm.$t("site_actions_share_label"),
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.$onShare(_vm.asset.id)
                                },
                              },
                            },
                            [_c("icon", { attrs: { id: _vm._share_view } })],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.canCollections && _vm.currentUser
                        ? _c(
                            "button",
                            {
                              staticClass: "collections",
                              attrs: {
                                type: "button",
                                role: "button",
                                title: _vm.$t(
                                  "features_collections_general_label"
                                ),
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.$onLightbox(_vm.asset.id)
                                },
                              },
                            },
                            [
                              _c("icon", {
                                attrs: { id: _vm._collections_add },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.canModal
                        ? _c(
                            "button",
                            {
                              staticClass: "info",
                              attrs: {
                                type: "button",
                                role: "button",
                                title: _vm.$t("site_actions_info_label"),
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.$onInfo(
                                    _vm.asset.id,
                                    [_vm.asset],
                                    _vm.modalActions,
                                    _vm.assetActions
                                  )
                                },
                              },
                            },
                            [_c("icon", { attrs: { id: "ic_info" } })],
                            1
                          )
                        : _vm._e(),
                    ])
                  : _vm._e(),
              ]
            )
          : _vm._e(),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }