import store from "@core/store";
const meta = { layout: "main" };

function checkValidUser(to, next) {
  // Redirect to 401 if the user is anonymous
  const user = store.getters["Users/currentUser"];
  const validUser = !!user?.id ?? false;

  if (validUser) {
    next();
  } else {
    const route = {
      name: "unauthorized",
      params: {
        entity: to?.params?.entity ?? undefined,
        locale: to?.params?.locale ?? undefined,
      },
    };

    next(route);
  }
}

export default [
  {
    path: "bookmarks",
    name: "bookmarks.index",
    beforeEnter(to, from, next) {
      checkValidUser(to, next);
    },
    component: () =>
      import(
        /* webpackChunkName: "main-bookmarks-index" */
        "@core/components/bookmarks/index.vue"
      ),
  },
  {
    path: "collection",
    meta,
    beforeEnter(to, from, next) {
      checkValidUser(to, next);
    },
    component: () =>
      import(
        /* webpackChunkName: "main-lightboxes-index" */
        "@core/components/lightboxes/index.vue"
      ),
    children: [
      {
        path: "",
        name: "collection.index",
        meta,
        component: () =>
          import(
            /* webpackChunkName: "main-lightboxes-edit" */
            "@core/components/lightboxes/lightbox__edit.vue"
          ),
        redirect: "my",
      },
      {
        path: "new",
        name: "collection.new",
        meta,
        component: () =>
          import(
            /* webpackChunkName: "main-lightboxes-edit" */
            "@core/components/lightboxes/lightbox__edit.vue"
          ),
      },
      {
        path: "edit/:id",
        name: "collection.edit",
        meta,
        component: () =>
          import(
            /* webpackChunkName: "main-lightboxes-edit" */
            "@core/components/lightboxes/lightbox__edit.vue"
          ),
      },
      {
        path: "view/:id",
        name: "collection.view",
        meta,
        component: () =>
          import(
            /* webpackChunkName: "main-lightboxes-lightbox" */
            "@core/components/lightboxes/lightbox.vue"
          ),
      },
      {
        path: ":tag",
        name: "collection.list",
        meta,
        component: () =>
          import(
            /* webpackChunkName: "main-lightboxes-lightboxes" */
            "@core/components/lightboxes/lightboxes.vue"
          ),
      },
    ],
  },
  {
    path: "lightbox",
    redirect: "collection",
    beforeEnter(to, from, next) {
      checkValidUser(to, next);
    },
    children: [
      {
        path: "*",
        name: "lightbox.index",
        redirect: "collection",
      },
    ],
  },
  {
    path: "profile",
    name: "profile.index",
    beforeEnter(to, from, next) {
      checkValidUser(to, next);
    },
    component: () =>
      import(
        /* webpackChunkName: "main-profile-index" */
        "@core/components/profile/index.vue"
      ),
  },
  {
    path: "requests",
    name: "requests.index",
    beforeEnter(to, from, next) {
      checkValidUser(to, next);
    },
    component: () =>
      import(
        /* webpackChunkName: "main-requests-index" */
        "@core/components/requests/index.vue"
      ),
  },
];
