export default {
  path: 'entities',
  component: () => import(
    /* webpackChunkName: "admin-settings-entities-index" */
    '@cms/components/settings/entities/index.vue'),

  children: [
    {
      path: '',
      name: 'admin.entities.index',
      component: () => import(
        /* webpackChunkName: "admin-settings-entities-entities" */
        '@cms/components/settings/entities/entities.vue')
    },
    {
      path: ':id',
      name: 'admin.entities.entity',
      component: () => import(
        /* webpackChunkName: "admin-settings-entities-entity" */
        '@cms/components/settings/entities/entity.vue')
    }
  ]
}
