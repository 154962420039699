export default {
  path: '/admin/users',
  component: () => import(
    /* webpackChunkName: "admin-users-index" */
    '@cms/components/users/index.vue'),
  meta: { layout: 'admin' },
  children: [
    {
      path: '',
      name: 'admin.users.index',
      component: () => import(
        /* webpackChunkName: "admin-users-users" */
        '@cms/components/users/users.vue')
    },
    {
      path: 'new',
      name: 'admin.users.new',
      component: () => import(
        /* webpackChunkName: "admin-users-new-user" */
        '@cms/components/users/new-user.vue')
    }
  ]
}
