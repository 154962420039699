var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.$route.name !== "Home"
    ? _c(
        "div",
        {
          staticClass: "interior-page-banner-container",
          class: _vm.customClass,
          attrs: { id: "interior-page-banner-container" },
        },
        [
          _c("InteriorPageFavorites"),
          _vm._v(" "),
          _c("InteriorPageHeader", { attrs: { id: "interior-banner" } }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }