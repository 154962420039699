import actions from '@core/store/actions/actions'
import mutations from '@core/store/mutations/mutations'

export default {
  namespaced: true,
  state: {
    showcase_submissions: [],
    filterParameters: { status: 'featured' }
  },
  mutations,
  actions
}
