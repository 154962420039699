var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "entity-avatar",
      class: {
        medium: _vm.avatarString.length === 2,
        small: _vm.avatarString.length === 3,
      },
    },
    [_c("span", [_vm._v(_vm._s(_vm.avatarString))])]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }