import CmsRoutes from './routes/cms'
import CoreRoutes from './routes/core'

import { RouteUtils } from '@core/js'

export default {
  install (Vue, options) {
    if (!options) {
      throw new Error('The DXG Extension requires an options object!')
    }

    if (!options.router) {
      throw new Error('The DXG Extension requires a router option!')
    }

    if (!options.env) {
      throw new Error('The DXG Extension requires an env (cms|core) option!')
    }

    // Add routes to router
    const DxgRoutes = (options.env === 'cms') ? CmsRoutes : CoreRoutes

    let params = RouteUtils.getRouteParams(options.router.getRoutes())

    DxgRoutes.forEach(route => {
      route = RouteUtils.standardizePluginRoutePath(route, params)
      options.router.addRoute(route)
    })
  }
}
