/* Much of this mixin is temporary, until the mosaic grid is opened to all
 * users. Some of this code is used in other components, so that will need
 * to be updated/removed as well.
 * Check @core/mixins/contentBlock.vue, as the actionButtons are using functions
 * from this mixin.
 */

export default {
  computed: {
    _isMosaic () {
      return !!(this.row && this.row.name.includes('mosaic'))
    }
  },
  methods: {
    $rowClass (row) {
      let classArray = []
      if (row) {
        if (this._isMosaic) {
          const classes = row.name.split('_')
          classArray = classArray.concat(classes)
        } else {
          classArray.push(row.name)
        }
      }

      // Delete this once mosaic grids are opened up to all admins
      this.$checkRootClass(classArray)

      return (classArray.length) ? classArray.join(' ').trim() : false
    },
    $checkRootClass (array = []) {
      // Temporary check, only good until we remove root-user-only limitations
      // Used @cms/components/menuitems/components/content-item__section__row.vue
      if (this._isMosaic && this._isAdminArea) {
        array.push('root-only')
      }
      return array
    },
    $preventBlockDrop (e) {
      // Only allow card type blocks to be used in the mosaic layout
      // This is disabled for now, as mosaic layout will only be available to root
      // Used @cms/components/menuitems/components/content-item__section__row__column.vue

      if (this._isMosaic) {
        /* ==========================================================================
         * This is a temporary work-around, loaded from the mosaicLayouts mixin.
         * Only root users may add blocks to the mosaic grid for now. This can
         * be removed once the future requirements are determined.
         * (See other comment below)
         */
        if (!this._isSuperAdminUser) {
          // Setting global flag to prevent auto-edit modal
          // This will need to be unset in the contentBlock mixin on created
          this.$store.commit('Global/SET_PROPERTY', {
            collection: 'preventModuleDrop',
            data: true
          })
          const modID = e.added.element.id
          this.col.modules = this.col.modules.filter(mod => modID !== mod.id)
        }
        /* ========================================================================== */

        /* ==========================================================================
         * This code can be used to define block types allowed into the mosaic.
         * It will need to be finished and activated per the future requirements.
         */

        /* const type = e.added?.element?.type ?? false
        if (type !== 'Cards & Lists Modules') {
          // Setting global flag to prevent auto-edit modal
          // This will need to be unset in the contentBlock mixin on created
          this.$store.commit('Global/SET_PROPERTY', {
            collection: 'preventModuleDrop',
            data: true
          })
          const modID = e.added.element.id
          this.col.modules = this.col.modules.filter(mod => {
            return modID !== mod.id
          })
        } */
        /* ========================================================================== */
      }
    }
  },
  updated () {
    // This is a fugly hack to disable dragging for mosaic blocks by non-root users
    // Should be removed after mosaic grids are opened up to all admins
    if (this.$options.name === 'ModuleControls' && !this._isSuperAdminUser) {
      const ab = this.$utilities.deepMerge(this.actionButtons)

      if (Array.isArray(ab) && ab.join() === 'edit') {
        // This throws a Vue warning, but there's not really an elegant solution
        this.dragDisabled = true
      }
    }
  }
}
