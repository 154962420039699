var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        role: "presentation",
        xmlns: "http://www.w3.org/2000/svg",
        width: "20",
        height: "20",
        viewBox: "0 0 20 20",
      },
    },
    [
      _c("g", { attrs: { fill: "none", "fill-rule": "evenodd" } }, [
        _c("path", { attrs: { d: "M-2-2h24v24H-2z" } }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            fill: "#717E8B",
            "fill-rule": "nonzero",
            d: "M10 20C4.477 20 0 15.523 0 10S4.477 0 10 0s10 4.477 10 10-4.477 10-10 10z",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            fill: "#FFF",
            "fill-rule": "nonzero",
            d: "M10.82 9.17h2.355a.825.825 0 0 1 0 1.65H10.82v2.355a.825.825 0 0 1-1.65 0V10.82H6.825a.825.825 0 0 1 0-1.65H9.17V6.825a.825.825 0 0 1 1.65 0V9.17z",
          },
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }