var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("i18n", {
    staticClass: "footer-copyright",
    attrs: { path: "site_messaging_copyright", tag: "div" },
    scopedSlots: _vm._u([
      {
        key: "currentYear",
        fn: function () {
          return [_vm._v(_vm._s(_vm.currentYear))]
        },
        proxy: true,
      },
      {
        key: "clientName",
        fn: function () {
          return [_vm._v(_vm._s(_vm.clientNameCapitalized))]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }