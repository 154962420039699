export default {
  computed: {
    link() {
      let url = this.$createUrl();
      const parts = url.split("?");

      let params = new URLSearchParams(parts[1]);

      if (this.file && params) {
        params.set("asset_id", this.file.id);
      }

      const query = params ? "?" + params.toString() : "";

      return parts[0] + query;
    },
  },
  methods: {
    onCopy () {
      this.$store.commit('Global/SET_SUCCESS', this.$t('notifications_toaster_link-copy_success_message'), {
        root: true
      })
    },
    onFail () {
      this.$store.commit('Global/SET_ERRORS', this.$t('notifications_toaster_link-copy_failure_message'), {
        root: true
      })
    }
  }
}
