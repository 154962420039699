import actions from '../actions/actions'
import mutations from '../mutations/mutations'

export default {
  namespaced: true,
  state: {
    translations: [],
    // filterParameters: null,
    // searchParameters: null,
    // isLoading: false,
    // newElementCreated: false
  },
  mutations,
  actions,
  getters: {
    messages: state => {
      const locales = window.AppStore.getters['Locales/activeLocales']
      let messages = {}

      locales.forEach(locale => {
        messages[locale.locale] = {}
        const results = state.translations.filter(t => locale.id === t.locale_id)
        results.forEach(result => {
          messages[locale.locale][result.key] = result.value
        })
      })

      return messages
    }
  }
}
