import actions from '../actions/actions'
import mutations from '../mutations/mutations'

export default {
  namespaced: true,
  state: {
    libraries: [],
    spreadsheet_errors: [],
    spreadsheet_name_local: 'No spreadsheet',
    asset_batch: {},
    asset_batches: [],
    filterParameters: {
      library: null
    },
    searchParameters: null,
    isLoading: false,
    newElementCreated: false,
    showBulkEditModal: false
  },
  mutations,
  actions
}
