var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "audio-player-preview" },
    [
      _c("AudioPlayer", {
        attrs: {
          sources: [_vm.audioSourceFile?.preview_url],
          loop: false,
          html5: true,
          "asset-thumbnail": _vm.assetThumbnail,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }