/**
  * See: https://monigleassociates.atlassian.net/wiki/spaces/B/pages/712671233/Page+Builder+Content+Modules+Building+Blocks
  * Template for content module default data
  * Top-level items are static data, "data" is component instance data,
  * usually updated by the component's edit mode.
  * "data" should always be an Object
  *
  * Boilerplate:
  {
    name: 'building-block-name', (unique ID for each building-block)
    icon: 'icon-id-from-admin-iconography',
    label: 'Building Block Label', (displays in Page Builder area)
    permissions: {
      pageTypes: [empty|page-type-string(s)], allow on all page-types, or specify allowed types
      roles: [empty|role-string(s)] allow any role, or specify allowed roles
    },
    type: 'Basic Modules'|'Media Modules'|'Full Width Modules'|'Cards & Lists Modules', (pick one)
    data: {...}
  }
**/

import _ from "underscore";

// Import client modules and deletions and merge
import ClientConfig from "@client/config";

const core_modules = [
  {
    name: "headline",
    icon: "headline",
    label: "Headline",
    permissions: {
      pageTypes: [],
      roles: [],
    },
    type: "Basic Elements",
    data: {
      levels: 6,
      selectedLevel: 1,
      text: "",
    },
  },
  {
    name: "wysiwyg",
    icon: "wysiwyg",
    label: "Editor",
    permissions: {
      pageTypes: [],
      roles: [],
    },
    type: "Basic Elements",
    data: {
      text: "",
    },
  },
  {
    name: "pullquote",
    icon: "quote",
    label: "Quote",
    permissions: {
      pageTypes: ["page", "homepage"],
      roles: [],
    },
    type: "Basic Elements",
    data: {
      text: "",
      attribution: "",
    },
  },
  {
    name: "cta",
    icon: "cta",
    label: "Button",
    permissions: {
      pageTypes: [],
      roles: [],
    },
    type: "Basic Elements",
    data: {
      text: "",
      url: "",
      newTab: false,
      alignment: "left",
      type: "primary",
    },
  },
  {
    name: "divider",
    icon: "divider",
    label: "Divider",
    permissions: {
      pageTypes: [],
      roles: [],
    },
    type: "Basic Elements",
    data: {
      color: "#000",
      thickness: 1,
      marginTop: 4,
      marginBottom: 4,
    },
  },
  {
    name: "colorswatch",
    icon: "color",
    label: "Color",
    permissions: {
      pageTypes: ["page", "homepage"],
      roles: [],
    },
    type: "Media Elements",
    data: {
      name: "",
      radius: "0",
      border: "1",
      pantone: "",
      color: {
        hex: "#ffffff",
        rgba: {
          r: "255",
          g: "255",
          b: "255",
          a: "1",
        },
        cmyk: {
          c: "",
          m: "",
          y: "",
          k: "",
        },
      },
    },
  },
  {
    name: "imageembed",
    icon: "image-icon",
    label: "Image",
    permissions: {
      pageTypes: [],
      roles: [],
    },
    type: "Media Elements",
    data: {
      url: "",
      title: "",
      caption: "",
      fullBleed: false,
      link: "",
      marginBottom: "0",
      marginTop: "0",
      maxWidth: "100%",
      target: false,
    },
    image_data: {
      width: "",
      height: "",
    },
  },
  {
    name: "videoembed",
    icon: "video-icon",
    label: "Video",
    permissions: {
      pageTypes: [],
      roles: [],
    },
    type: "Media Elements",
    data: {
      url: "",
      poster: "",
      title: "",
      desc: "",
      caption: "",
      alt: "",
      player: {
        controls: true,
        autoplay: false,
        modal: false,
        caption: "",
        loop: false,
        description: "",
        muted: false,
        url: "",
      },
    },
  },
  {
    name: "asset",
    icon: "asset",
    label: "Asset",
    permissions: {
      pageTypes: ["page", "homepage"],
      roles: [],
    },
    type: "Media Elements",
    data: {
      libraryId: "",
      assetId: "",
      displayOptions: {},
    },
  },
  {
    name: "assetlibrary",
    icon: "library",
    label: "Library",
    permissions: {
      pageTypes: ["page", "homepage"],
      roles: [],
    },
    type: "Full Width Elements",
    data: {
      libraryId: "",
      filterPresets: [],
      hideTitle: false,
      defaultView: "grid",
    },
  },
  {
    name: "carousel",
    icon: "carousel",
    label: "Carousel",
    permissions: {
      pageTypes: ["page", "homepage"],
      roles: [],
    },
    type: "Full Width Elements",
    data: {
      component_items: [],
      description: "",
      data: {},
      type: "carousel",
      name: "Homepage Carousel",
      carouselType: false,
    },
  },
  {
    name: "infocard",
    icon: "cardgrid",
    label: "Info Card",
    permissions: {
      pageTypes: ["page", "homepage"],
      roles: ["root"],
    },
    type: "Cards & Lists Elements",
    data: {
      type: "infocard",
      name: "InfoCard",
      autoplay: true,
      background: null,
      controls: true,
      label: null,
      loop: false,
      headline: null,
      description: null,
      linkTitle: null,
      linkType: 0,
      linkUrl: null,
      muting: false,
      newTab: false,
      videoEmbed: "",
      videoType: "uploaded",
      videoUrl: "",
    },
  },
  {
    name: "favorite",
    icon: "favorite-black",
    label: "Favorites",
    permissions: {
      pageTypes: ["page", "homepage"],
      roles: ["root"],
    },
    type: "Cards & Lists Elements",
    data: {
      data: {
        title: "Favorites",
        iconUrl: "",
      },
      type: "favorite",
      name: "Favorites",
    },
  },
  {
    name: "popular",
    icon: "popular",
    label: "Popular Pages",
    permissions: {
      pageTypes: ["page", "homepage"],
      roles: ["root"],
    },
    type: "Cards & Lists Elements",
    data: {
      data: {
        title: "Popular",
        iconUrl: "",
        display: "list",
      },
      type: "popular",
      name: "Popular",
    },
  },
  {
    name: "popular-assets",
    icon: "popular",
    label: "Popular Assets",
    permissions: {
      pageTypes: ["page", "homepage"],
      roles: ["root"],
    },
    type: "Cards & Lists Elements",
    data: {
      data: {
        title: "Popular Assets",
        iconUrl: "",
      },
      type: "popular-assets",
      name: "Popular Assets",
    },
  },
  {
    name: "search-block",
    icon: "search",
    label: "Search",
    permissions: {
      pageTypes: ["page", "homepage"],
      roles: [],
    },
    type: "Basic Elements",
    data: {
      type: "search-block",
      name: "Search",
      data: {
        title: "Search",
        label: "Search for...",
      },
    },
  },
  {
    name: "flipcard",
    icon: "flipcard",
    label: "Flip card",
    permissions: {
      pageTypes: ["page", "homepage"],
      roles: [],
    },
    type: "Media Elements",
    data: {
      front: {
        image: null,
        caption: "",
        alt: "",
      },
      back: {
        image: null,
        caption: "",
        alt: "",
      },
    },
  },
  {
    name: "formpicker",
    icon: "formicon",
    label: "Form Picker",
    permissions: {
      pageTypes: ["auth", "page"],
      roles: ["root"],
    },
    type: "Basic Elements",
    data: {
      formId: "",
    },
  },
  {
    name: "quicklinks",
    icon: "quicklinks",
    label: "Quicklinks",
    permissions: {
      pageTypes: ["page", "homepage"],
      roles: ["root"],
    },
    type: "Cards & Lists Elements",
    data: {
      type: "quicklinks2",
      name: "Quicklinks",
      iconUrl: "",
      title: "",
      subtype: "",
      links: [
        {
          text: "",
          url: "",
          target: "",
          show: true,
        },
        {
          text: "",
          url: "",
          target: "",
          show: true,
        },
        {
          text: "",
          url: "",
          target: "",
          show: true,
        },
        {
          text: "",
          url: "",
          target: "",
          show: true,
        },
      ],
    },
  },
  {
    name: "zoomcard",
    icon: "zoomcard",
    label: "Zoom",
    permissions: {
      pageTypes: ["page", "homepage"],
      roles: [],
    },
    type: "Media Elements",
    data: {
      title: {
        title: "",
      },
      small: {
        image: null,
        alt: "",
      },
      large: {
        image: null,
        image_data: null,
        alt: "",
      },
    },
  },
  {
    name: "FileUpload",
    icon: "upload",
    label: "File upload",
    type: "Media Elements",
    data: {
      downloadNotification: true,
    },
  },
  {
    name: "DataTable",
    icon: "divider",
    label: "Data Table",
    permissions: {
      pageTypes: [],
      roles: [],
    },
    type: "Full Width Elements",
    data: {
      tableId: "",
    },
  },
];

const client_modules = [
  {
    name: "salesforce-carousel",
    icon: "salesforce",
    label: "Carousel",
    permissions: {
      pageTypes: ["homepage"],
      roles: ["root"],
    },
    type: "Full Width Elements",
    data: [
      {
        overline: "My Overline",
        headline: "My Headline",
        linkLabel: "My Link Label",
        linkUrl: "",
        imgUrl: "",
        target: "",
        show: true,
        childrenImages: [null, null, null],
      },
    ],
  },
  {
    name: "ShowcaseCarousel",
    icon: "ShowcaseCarousel",
    label: "Showcase",
    permissions: {
      pageTypes: ["page", "homepage"],
      roles: [],
    },
    type: "Full Width Elements",
    data: {
      height: 576,
      link: false,
      title: false,
      titleStyle: "h1",
    },
  },
];

const include_modules = ClientConfig.include_modules ?? [];
const add_modules = client_modules.filter((obj) =>
  include_modules.includes(obj.name)
);

// Merge client-specified and core module arrays
let modules = _.uniq(
  _.union(add_modules, core_modules),
  false,
  function (item, key, name) {
    return item.name;
  }
);

// Remove any unwanted modules
const exclude_modules = ClientConfig.exclude_modules ?? [];

if (exclude_modules.length) {
  modules = modules.filter((obj) => !exclude_modules.includes(obj.name));
}

// Sort modules by type for consistency
const typeOrder = [
  "Basic Elements",
  "Media Elements",
  "Full Width Elements",
  "Cards & Lists Elements",
];
let content_modules = [];

typeOrder.forEach((typeName) => {
  modules.forEach((mod) => {
    if (typeName === mod.type) {
      content_modules.push(mod);
    }
  });
});

export default content_modules;
