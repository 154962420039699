var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.impersonate === "true"
    ? _c("div", { attrs: { id: "impersonate" } }, [
        _vm._v("\n  You are impersonating "),
        _c("span", { staticClass: "titlecase" }, [
          _vm._v(_vm._s(_vm.fullName)),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }