import Vue from 'vue'
import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/tracing'

const env = process.env.NODE_ENV

const InitSentry = (router) => {
  if (router && env === 'production') {
    Sentry.init({
      Vue,
      dsn: 'https://34f5beccd000489ea5f74eed8637a3c4@o344710.ingest.sentry.io/2024003',
      environment: env,
      integrations: [
        new BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(router),
          tracingOrigins: ['localhost', 'localdev', window.location.hostname, /^\//]
        })
      ],
      tracesSampleRate: 1.0
    })
  }
}

export { InitSentry }
