var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "module flipcard-module" },
    [
      _c("module-controls", {
        attrs: {
          placeholder: _vm.moduleLabel,
          "action-buttons": _vm._actionButtons,
        },
        on: {
          "on-edit": _vm.$openModal,
          "on-remove": _vm.$deleteContentModuleConfirm,
        },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "module_content" },
        [
          _vm._isAdminArea && _vm.isEmpty
            ? [
                _c("div", { staticClass: "empty" }, [
                  _vm._v(
                    "\n        This module is empty. Start editing!\n      "
                  ),
                ]),
              ]
            : _vm._e(),
          _vm._v(" "),
          !_vm.isEmpty
            ? [
                _c("div", { staticClass: "flipcard-content" }, [
                  _c(
                    "div",
                    {
                      class: [
                        "flipping_card_container",
                        { flipped: _vm.isFlipped },
                      ],
                    },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "pass-thru",
                          attrs: {
                            type: "button",
                            title: _vm.$t(
                              "components_flipcard_actions_turn-over_label"
                            ),
                          },
                          on: { click: _vm.flip },
                        },
                        [
                          _c("div", { staticClass: "flipping_card" }, [
                            _c("div", { staticClass: "front" }, [
                              _c("div", {
                                staticClass: "card_content",
                                style: {
                                  backgroundImage:
                                    "url(" + _vm.frontImage + ")",
                                },
                                attrs: {
                                  "aria-label": _vm.frontAlt,
                                  role: "img",
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "flip_btn" },
                                [_c("Icon", { attrs: { id: "ic_refresh" } })],
                                1
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "back" }, [
                              _c("div", {
                                staticClass: "card_content",
                                style: {
                                  backgroundImage: "url(" + _vm.backImage + ")",
                                },
                                attrs: {
                                  "aria-label": _vm.backAlt,
                                  role: "img",
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "flip_btn" },
                                [_c("Icon", { attrs: { id: "ic_refresh" } })],
                                1
                              ),
                            ]),
                          ]),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  !_vm.isFlipped
                    ? _c(
                        "div",
                        { staticClass: "caption", on: { click: _vm.flip } },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.frontCaption) +
                              "\n        "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isFlipped
                    ? _c(
                        "div",
                        { staticClass: "caption", on: { click: _vm.flip } },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.backCaption) +
                              "\n        "
                          ),
                        ]
                      )
                    : _vm._e(),
                ]),
              ]
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }