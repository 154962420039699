var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { ref: "navSection", staticClass: "nav-section" }, [
    _c(
      "nav",
      {
        directives: [
          {
            name: "scroll",
            rawName: "v-scroll",
            value: _vm.stickyScroll,
            expression: "stickyScroll",
          },
        ],
        staticClass: "nav-items",
        attrs: { "aria-label": _vm.$t("layout_regions_page-side-nav_label") },
      },
      [
        _vm.sections.length > 1
          ? _c(
              "ul",
              { staticClass: "list-unstyled" },
              [
                _vm._l(_vm.sections, function (section) {
                  return [
                    section.title && section.isAnchor !== false
                      ? _c("li", { key: section.id, staticClass: "nav-item" }, [
                          _c(
                            "span",
                            {
                              staticClass: "nav-link",
                              class: { active: _vm.isActive === section.id },
                              attrs: { hash: section.title, tabindex: "0" },
                              on: {
                                click: function ($event) {
                                  return _vm.scrollMeTo(section.id)
                                },
                                keydown: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  )
                                    return null
                                  return _vm.scrollMeTo(section.id)
                                },
                              },
                            },
                            [_vm._v(_vm._s(section.title))]
                          ),
                        ])
                      : _vm._e(),
                  ]
                }),
              ],
              2
            )
          : _vm._e(),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }