var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "form-input select-input",
      class:
        (_vm.customClasses ? _vm.customClasses : "") +
        (_vm.required && _vm.error ? " has-error" : ""),
    },
    [
      !_vm.hideLabel
        ? _c("label", {
            directives: [
              {
                name: "sanitize",
                rawName: "v-sanitize",
                value: _vm.fieldLabel,
                expression: "fieldLabel",
              },
            ],
            class: { active: _vm.isActive },
            attrs: { for: _vm.id },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { ref: "selectContainer", class: [_vm.disable, "select-container"] },
        [
          _c(
            "span",
            { class: { active: _vm.isActive } },
            [
              _c("span", [_vm._v(_vm._s(_vm.selectedValue))]),
              _vm._v(" "),
              _c("icon", { attrs: { id: "ic_arrow_drop_down" } }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.input,
                  expression: "input",
                },
              ],
              attrs: {
                id: _vm.id,
                role: "listbox",
                disabled: _vm.disabled,
                "data-check": "Input_Select",
              },
              on: {
                change: [
                  function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.input = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                  _vm.changeEvent,
                ],
                keyup: _vm.addFocusClass,
                focusout: _vm.removeFocusClass,
              },
            },
            [
              _vm.label
                ? _c(
                    "option",
                    {
                      attrs: {
                        disabled: "disabled",
                        "data-check": "Option_One",
                      },
                    },
                    [_vm._v("\n        " + _vm._s(_vm.fieldLabel) + "\n      ")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm._l(_vm.list, function (label, index) {
                return [
                  _c(
                    "option",
                    {
                      key: index,
                      attrs: {
                        role: "option",
                        "data-check": "Option_Two",
                        disabled: _vm.isDisabled(index),
                        "aria-selected": _vm.isSelected(
                          label.id ? label.id : index
                        ),
                      },
                      domProps: { value: label.name ? label.name : label },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(label.name ? label.name : label) +
                          "\n        "
                      ),
                    ]
                  ),
                ]
              }),
            ],
            2
          ),
        ]
      ),
      _vm._v(" "),
      _vm.required && _vm.error
        ? _c("div", { staticClass: "error" }, [
            _vm._v("\n    " + _vm._s(_vm.errorText) + "\n  "),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }