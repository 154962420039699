var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "scroller" },
    [
      _c(
        "FormRoot",
        {
          attrs: { "submit-button-text": "Send File", "form-id": "email-form" },
        },
        [
          _c("h3", [_vm._v(_vm._s(_vm.title))]),
          _vm._v(" "),
          _c("EmailsInput", {
            ref: "recipientEmail",
            attrs: {
              label: "Recipient Email(s)",
              model: _vm.recipientEmail,
              required: "true",
            },
            on: {
              "update:model": function ($event) {
                _vm.recipientEmail = $event
              },
            },
          }),
          _vm._v(" "),
          _c("p", { staticClass: "rule" }, [
            _c("small", [
              _vm._v("Use commas to separate multiple email addresses"),
            ]),
          ]),
          _vm._v(" "),
          _c("TextInput", {
            ref: "subject",
            attrs: { label: "Subject", model: _vm.subject, required: "true" },
            on: {
              "update:model": function ($event) {
                _vm.subject = $event
              },
            },
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "message-textarea" },
            [
              _c("TextareaInput", {
                ref: "subject",
                attrs: {
                  label: "Message",
                  model: _vm.message,
                  required: "true",
                },
                on: {
                  "update:model": function ($event) {
                    _vm.message = $event
                  },
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "form-input" }, [
            _c(
              "label",
              { staticClass: "attachment", attrs: { for: "asset_id" } },
              [
                _c("icon", { attrs: { id: "ic_attach_file" } }),
                _vm._v("\n        " + _vm._s(_vm.fileTitle) + "\n      "),
              ],
              1
            ),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.id,
                  expression: "id",
                },
              ],
              attrs: { id: "asset_id", type: "hidden", name: "id" },
              domProps: { value: _vm.id },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.id = $event.target.value
                },
              },
            }),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }