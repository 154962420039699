var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "form-input select-all-input",
      class: {
        checked: _vm.allSelected,
        indeterminate: _vm.someSelected,
        unchecked: _vm.noneSelected,
      },
    },
    [
      _c("input", {
        attrs: {
          id: _vm.id,
          type: "checkbox",
          role: "checkbox",
          disabled: _vm.disabled,
        },
        domProps: { value: !_vm.noneSelected, indeterminate: _vm.someSelected },
        on: { change: _vm.toggleSelect },
      }),
      _vm._v(" "),
      _c(
        "label",
        { class: { disabled: _vm.disabled }, attrs: { for: _vm.id } },
        [
          _c("icon", { attrs: { id: _vm.checkIcon } }),
          _vm._v(" " + _vm._s(_vm.selectLabel) + "\n  "),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }