var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "duplicate-page-modal" }, [
    _c("h3", [_vm._v("Duplicate Page")]),
    _vm._v(" "),
    _c("p", [_vm._v("Do you want to duplicate this page?")]),
    _vm._v(" "),
    _c("fieldset", [
      _c("p", [
        _c("label", { attrs: { for: "dupe_content" } }, [
          _vm._v("Duplicate the page exactly, preserving all content"),
        ]),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.duplicateType,
              expression: "duplicateType",
            },
          ],
          attrs: { id: "dupe_content", type: "radio", value: "1" },
          domProps: { checked: _vm._q(_vm.duplicateType, "1") },
          on: {
            change: function ($event) {
              _vm.duplicateType = "1"
            },
          },
        }),
      ]),
      _vm._v(" "),
      _c("p", [
        _c("label", { attrs: { for: "dupe_structure" } }, [
          _vm._v("Duplicate the page structure, removing all content"),
        ]),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.duplicateType,
              expression: "duplicateType",
            },
          ],
          attrs: { id: "dupe_structure", type: "radio", value: "2" },
          domProps: { checked: _vm._q(_vm.duplicateType, "2") },
          on: {
            change: function ($event) {
              _vm.duplicateType = "2"
            },
          },
        }),
      ]),
      _vm._v(" "),
      _vm.options.moduleType !== "homepage"
        ? _c("p", [
            _c("label", { attrs: { for: "dupe_header" } }, [
              _vm._v(
                "Duplicate the page structure, removing all content, except the header and footer content"
              ),
            ]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.duplicateType,
                  expression: "duplicateType",
                },
              ],
              attrs: { id: "dupe_header", type: "radio", value: "3" },
              domProps: { checked: _vm._q(_vm.duplicateType, "3") },
              on: {
                change: function ($event) {
                  _vm.duplicateType = "3"
                },
              },
            }),
          ])
        : _vm._e(),
    ]),
    _vm._v(" "),
    _vm.moduleType === "auth"
      ? _c("fieldset", [
          _c("label", { attrs: { for: "duplicate_locale_select" } }, [
            _vm._v("Choose the locale of the duplicate page"),
          ]),
          _vm._v(" "),
          _vm.hasError
            ? _c("p", { staticClass: "error" }, [
                _vm._v(
                  "\n      You must assign a locale to this duplicate page\n    "
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.locale,
                  expression: "locale",
                },
              ],
              attrs: { id: "duplicate_locale_select" },
              on: {
                change: function ($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function (o) {
                      return o.selected
                    })
                    .map(function (o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.locale = $event.target.multiple
                    ? $$selectedVal
                    : $$selectedVal[0]
                },
              },
            },
            [
              _c("option", { attrs: { value: "" } }, [
                _vm._v("\n        Choose a language...\n      "),
              ]),
              _vm._v(" "),
              _vm._l(_vm.availableLocales, function (label, key) {
                return _c(
                  "option",
                  { key: `locale_${key}`, domProps: { value: key } },
                  [_vm._v("\n        " + _vm._s(label) + "\n      ")]
                )
              }),
            ],
            2
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("footer", [
      _c(
        "button",
        {
          staticClass: "default",
          attrs: {
            type: "button",
            title: "Cancel Page Duplication",
            value: "Cancel",
          },
          on: { click: _vm.handleDismiss },
        },
        [_vm._v("\n      Cancel\n    ")]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "primary",
          attrs: {
            type: "button",
            title: "Duplicate This Page",
            value: "Duplicate",
          },
          on: { click: _vm.handleProceed },
        },
        [_vm._v("\n      Duplicate\n    ")]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }