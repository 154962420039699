var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        role: "presentation",
        viewBox: "0 0 13.9 14",
        "enable-background": "new 0 0 13.9 14",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M12.5 1.2h-.3v-.5c0-.4-.3-.7-.7-.7h-.1c-.4 0-.7.3-.7.7v.5h-1.5v-.5c0-.4-.3-.7-.7-.7h-.1c-.3 0-.7.3-.7.7v.5h-1.5v-.5c0-.4-.3-.7-.7-.7h-.1c-.4 0-.7.3-.7.7v.5h-1.5v-.5c0-.4-.3-.7-.7-.7h-.1c-.4 0-.7.3-.7.7v.5h-.3c-.8 0-1.4.6-1.4 1.4v10c0 .8.6 1.4 1.4 1.4h11.1c.8 0 1.4-.6 1.4-1.4v-10c0-.8-.6-1.4-1.4-1.4zm.4 11.4c0 .2-.2.4-.4.4h-11.1c-.2 0-.4-.2-.4-.4v-8.4h12v8.4zm-7.4-6.2v-.6c0-.3.3-.6.6-.6h1.6c.3 0 .6.3.6.6v.6c.1.4-.2.6-.5.6h-1.6c-.4 0-.7-.2-.7-.6zm3.5 2.6v-.7c0-.3.3-.6.7-.6h1.6c.3 0 .6.3.6.6v.7c0 .3-.3.6-.6.6h-1.6c-.4 0-.7-.3-.7-.6zm-3.5 0v-.7c0-.3.3-.6.6-.6h1.6c.3 0 .6.3.6.6v.7c0 .3-.3.6-.6.6h-1.5c-.4 0-.7-.3-.7-.6zm-3.5 2.5v-.6c0-.3.3-.6.6-.6h1.6c.3 0 .6.3.6.6v.6c0 .3-.3.6-.6.6h-1.5c-.4 0-.7-.2-.7-.6zm7-5.1v-.6c0-.3.3-.6.6-.6h1.6c.3 0 .6.3.6.6v.6c0 .3-.3.6-.6.6h-1.5c-.4 0-.7-.2-.7-.6zm-7 2.6v-.7c0-.3.3-.6.7-.6h1.6c.3 0 .6.3.6.6v.7c0 .3-.3.6-.6.6h-1.6c-.4 0-.7-.3-.7-.6zm0-2.6v-.6c0-.3.3-.6.6-.6h1.6c.3 0 .6.3.6.6v.6c.1.4-.2.6-.5.6h-1.6c-.4 0-.7-.2-.7-.6zm3.5 5.1v-.6c0-.3.3-.6.6-.6h1.6c.3 0 .6.3.6.6v.6c0 .3-.3.6-.6.6h-1.5c-.4 0-.7-.2-.7-.6z",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }