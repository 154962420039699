export default {
  path: 'search',
  meta: { layout: 'main' },
  component: () => import(
    /* webpackChunkName: "main-search-index" */
    '@core/components/search/index.vue'),
  children: [
    {
      path: '',
      name: 'search.index',
      redirect: 'pages'
    },
    {
      path: 'pages',
      name: 'search.pages',
      component: () => import(
        /* webpackChunkName: "main-search-pages" */
        '@core/components/search/SearchPages.vue'),
      meta: {
        preserveFocus: true
      }
    },
    {
      path: 'files',
      name: 'search.files',
      component: () => import(
        /* webpackChunkName: "main-search-files" */
        '@core/components/search/SearchFiles.vue'),
      meta: {
        preserveFocus: true
      }
    },
    {
      path: 'showcases',
      name: 'search.showcases',
      component: () => import(
        /* webpackChunkName: "main-search-pages" */
        '@core/components/search/SearchShowcases.vue'),
      meta: {
        preserveFocus: true
      }
    }
  ]
}
