var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        role: "presentation",
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 16 16",
        "enable-background": "new 0 0 16 16",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M9.1 13.2h1.7c0 1.6-1.3 2.8-2.9 2.8h-5c-1.6 0-2.9-1.3-2.9-2.9v-5.1c0-1.6 1.3-2.9 2.9-2.9v1.7c-.7.1-1.2.6-1.2 1.2v5.1c0 .6.5 1.1 1.1 1.1h5.2c.6.1 1.1-.4 1.1-1zm6.9-10.3v6.3c0 1.6-1.3 2.9-2.9 2.9h-6.2c-1.6-.1-2.9-1.4-2.9-3v-6.2c0-1.6 1.3-2.9 2.9-2.9h6.3c1.5 0 2.8 1.3 2.8 2.9zm-2.9 2.8c0-.3-.3-.6-.6-.6h-1.7v-1.7c0-.3-.3-.6-.6-.6h-.5c-.3 0-.6.3-.6.6v1.7h-1.7c-.3 0-.6.3-.6.6v.6c0 .3.3.6.6.6h1.7v1.7c0 .3.3.6.6.6h.6c.3 0 .6-.3.6-.6v-1.7h1.7c.3 0 .6-.3.6-.6v-.6z",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }