var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      staticStyle: { "enable-background": "new 0 0 20 14.8" },
      attrs: {
        role: "presentation",
        version: "1.1",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
        x: "0px",
        y: "0px",
        viewBox: "0 0 20 14.8",
        "xml:space": "preserve",
      },
    },
    [
      _c("path", {
        attrs: {
          transform: "rotate(-180 12 12)",
          d: "M9.7,17.3H7.4c-1.8-0.4-3.2-1.7-3.2-3.9c0-2.5,2-4.2,4.4-4.2c3,0,4.7,2.4,4.7,5.8\n  c0,5-3.3,8.6-9.3,9.1v-1.7C7.4,21.8,9.5,20.1,9.7,17.3z M20.5,17.3h-2.3c-1.8-0.4-3.2-1.7-3.2-3.9c0-2.5,1.9-4.2,4.3-4.2\n  c3,0,4.7,2.4,4.7,5.8c0,5-3.2,8.6-9.3,9.1v-1.7C18.2,21.8,20.1,20.1,20.5,17.3z",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }