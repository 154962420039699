var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        role: "presentation",
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 20 15.6",
        "enable-background": "new 0 0 20 15.6",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M2.7 4.5c0-1.1.9-1.9 2-1.9s1.9.9 1.9 1.9c0 1-.9 1.9-2 1.9-1 0-1.9-.9-1.9-1.9zm17.3-2.4v11.3c0 1.5-.7 2.1-2.2 2.1h-15.7c-1.4 0-2.1-.6-2.1-2v-11.4c0-1.5.7-2.1 2.2-2.1h15.6c1.5 0 2.2.6 2.2 2.1zm-1.3 11.1v-10.8c0-1-.1-1.1-1.1-1.1h-15.1c-.8 0-1.1.3-1.1 1.1v10.9c0 .8.3 1 1.1 1h15.1c.8 0 1.1-.3 1.1-1.1zm-1.9-5.2c-1-1-2.1-2-3.1-3.1-.5-.5-.8-.5-1.2 0l-3.6 3.3c-1.2 1.2-1.2 1.2-2.4.1-.3-.2-.5-.5-.9-.1-.8.8-1.8 1.7-2.6 2.6-.5.5-.6 1.1-.4 1.7.2.5 1 .4 1.4.4h12.4c.4 0 .9.1.9-.4v-3.8c-.1-.4-.3-.6-.5-.7z",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }