var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("focus-trap", { attrs: { active: _vm.displayModal } }, [
    _c("section", { staticClass: "modal", attrs: { tabindex: "-1" } }, [
      _c("div", { staticClass: "modal__body email" }, [
        _c("div", { staticClass: "modal__controls" }, [
          _c(
            "button",
            {
              staticClass: "close",
              attrs: {
                type: "button",
                title: _vm.$t("site_actions_close_label"),
              },
              on: { click: _vm.onClose, keydown: _vm.onClose },
            },
            [_vm._v("\n          ×\n        ")]
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "modal__contents" },
          [
            _c("header", [_c("icon", { attrs: { id: "ic_email" } })], 1),
            _vm._v(" "),
            _c("EmailModalForm", {
              attrs: {
                id: _vm.id,
                file: _vm.file,
                title: _vm.$t("site_actions_email_label"),
              },
              on: { "email-sent": _vm.onClose },
            }),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }