import actions from '../actions/actions'
import mutations from '../mutations/mutations'

export default {
  namespaced: true,
  state: {
    assets: [],
    pagination: {
      per_page: 12,
      page: 1,
      pages: 1,
      items: 0
    },
    filterParameters: null,
    searchParameters: null,
    isLoading: false,
    view: 'list'
  },
  mutations,
  actions
}
