/**
 * Usage:
 * import BeamPlugins from '.../plugins'
 * Vue.use(BeamPlugins, {
 *  env: {'admin'|'main'},
 *  store
 * })
 */

import BeamMixins from './BeamMixins'
import BeamUtilities from './BeamUtilities'
import CrossAuthentication from './CrossAuthentication'
import CustomComponents from './CustomComponents'
import FormInputs from './FormInputs'
import Iconography from './Iconography'
import PageBuilder from './PageBuilder'
import VueColor from './VueColor'
import VueClip from './VueClip'

export default {
  install (Vue, options) {
    BeamMixins.install(Vue)
    BeamUtilities.install(Vue)
    CrossAuthentication.install(Vue)
    CustomComponents.install(Vue)
    FormInputs.install(Vue)
    Iconography.install(Vue, options)
    PageBuilder.install(Vue)
    VueColor.install(Vue)
    VueClip.install(Vue)
  }
}
