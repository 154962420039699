var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "favorites" }, [
    _c("div", { staticClass: "favorites-wrapper" }, [
      _c("div", { staticClass: "icon-container" }, [
        _c(
          "button",
          {
            staticClass: "pass-thru",
            class: { bookmarked: _vm.toggleFavorites },
            attrs: { type: "button" },
            on: { click: _vm.handleToggleFavorites },
          },
          [
            _c("icon", {
              staticClass: "large star-favorites",
              attrs: { id: _vm.favoritesId, "aria-hidden": "true" },
            }),
            _vm._v(" "),
            _c("span", { staticClass: "favorites-text" }, [
              _vm._v("\n          " + _vm._s(_vm.toggleText) + "\n        "),
            ]),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }