var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        role: "presentation",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
        width: "10",
        height: "10",
        viewBox: "0 0 10 10",
      },
    },
    [
      _c("defs", [
        _c("path", {
          attrs: {
            id: "a",
            d: "M8.5 8.5h-7v-7H5v-1H1.5a1 1 0 0 0-1 1v7a1 1 0 0 0 1 1h7c.55 0 1-.45 1-1V5h-1v3.5zM6 .5v1h1.795L2.88 6.415l.705.705L8.5 2.205V4h1V.5H6z",
          },
        }),
      ]),
      _vm._v(" "),
      _c("g", { attrs: { "fill-rule": "evenodd" } }, [
        _c("mask", { attrs: { id: "b" } }, [
          _c("use", { attrs: { "xlink:href": "#a" } }),
        ]),
        _vm._v(" "),
        _c("g", { attrs: { mask: "url(#b)" } }, [
          _c("path", { attrs: { d: "M-1-1h12v12H-1z" } }),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }