var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        role: "presentation",
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 20 20",
        "enable-background": "new 0 0 20 20",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M10 20c-5.5 0-10-4.5-10-10s4.5-10 10-10 10 4.5 10 10-4.5 10-10 10zm2.9-11c-.2-.1-.4 0-.5.1-.5.6-1.4.9-2.4.9s-1.9-.3-2.4-.9c-.1-.1-.3-.1-.5-.1-2.1.8-3.2 2.6-3.2 5.1 0 .3.2.5.5.5h11.3c.3 0 .5-.2.5-.5-.1-2.5-1.2-4.3-3.3-5.1zm-5.7-3.1c0 1.8 1.3 3.3 2.9 3.3 1.6 0 2.9-1.5 2.9-3.2 0-1.8-1.3-3.3-2.9-3.3s-2.9 1.4-2.9 3.2z",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }