export default {
  namespaced: true,
  state: {
    pageTitle: 'Default Title'
  },
  getters: {
    getTitle: (state) => {
      return state.pageTitle
    }
  },
  mutations: {
    updateTitle: (state, payload) => {
      state.pageTitle = payload.title
    }
  },
  actions: {
    updateTitle: ({ commit }, payload) => {
      commit('updateTitle', payload)
    }
  }
}
