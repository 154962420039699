import ClientTheme from "@client/theme";
const theme = ClientTheme?.theme ?? "legacy";
const authPages = window.AppStore?.getters["Settings/beamFeatures"]?.authPages;
const mfaActive =
  window.AppStore?.getters["Settings/beamFeatures"].multiFactorAuthentication;
const hasAuth = !!("published" === authPages);
const hasMfa = !!("published" === mfaActive);

let routes = [
  {
    path: "contact",
    name: "auth.contact",
    meta: { layout: "auth", pageTitle: "Contact Us" },
    component: () =>
      import(
        /* webpackChunkName: "auth-contact" */
        "@core/components/auth/contact.vue"
      ),
  },
  {
    path: "forgot",
    name: "auth.forgot",
    meta: { layout: "auth", pageTitle: "Forgot Password" },
    component: () =>
      import(
        /* webpackChunkName: "auth-forgot" */
        "@core/components/auth/forgot.vue"
      ),
  },
  {
    path: "login",
    name: "auth.login",
    meta: { layout: "auth", pageTitle: "Login" },
    component: () =>
      import(
        /* webpackChunkName: "auth-login" */
        "@core/components/auth/login.vue"
      ),
  },
  {
    path: "logout",
    name: "auth.logout",
    meta: { layout: "auth", pageTitle: "Logout" },
    component: () =>
      import(
        /* webpackChunkName: "auth-logout" */
        "@core/components/auth/logout.vue"
      ),
  },
  {
    path: "request-access",
    name: "auth.request-access",
    meta: { layout: "auth", pageTitle: "Request Access" },
    component: () =>
      import(
        /* webpackChunkName: "auth-request-access" */
        "@core/components/auth/request-access.vue"
      ),
  },
  {
    path: "reset/:verification_code/new",
    name: "auth.reset.new",
    meta: { layout: "auth", pageTitle: "Reset Password" },
    component: () =>
      import(
        /* webpackChunkName: "auth-reset" */
        "@core/components/auth/reset.vue"
      ),
  },
  {
    path: "reset/:verification_code",
    name: "auth.reset",
    meta: { layout: "auth", pageTitle: "Reset Password" },
    component: () =>
      import(
        /* webpackChunkName: "auth-reset" */
        "@core/components/auth/reset.vue"
      ),
  },
  {
    path: "reset",
    redirect: { name: "auth.forgot" },
  },
  {
    path: "sso",
    name: "auth.sso",
    meta: { layout: "auth", pageTitle: "SSO Login" },
    component: () =>
      import(
        /* webpackChunkName: "auth-sso" */
        "@core/components/auth/sso.vue"
      ),
  },
];

if (hasMfa && hasAuth) {
  routes.push({
    path: "mfa",
    name: "auth.mfa",
    meta: { layout: "auth", pageTitle: "MFA Login", requiresMFA: true },
  });
}

if (theme !== "legacy" && hasAuth) {
  routes.forEach((route) => {
    if (route.name === "auth.sso") {
      return;
    }
    route.component = () =>
      import(
        /* webpackChunkName: "auth-routes" */
        "@theme/layouts/MainContent.vue"
      );
  });
}

export default routes;
