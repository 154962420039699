var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "app", tabindex: "-1" } },
    [
      _c(
        "header",
        {
          staticClass: "page-region",
          attrs: {
            id: "page-primary-banner",
            "aria-label": _vm.$t("layout_regions_page-banner_label"),
          },
        },
        [_c("interior-page-banner")],
        1
      ),
      _vm._v(" "),
      _c("LocaleChanger", { attrs: { "use-dropdown": true } }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "page-region",
          attrs: {
            id: "page-main-area",
            role: "region",
            "aria-label": _vm.$t("layout_regions_page-main_label"),
          },
        },
        [_c("router-view")],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }