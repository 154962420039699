export default [
  {
    path: '/dxg',
    component: () => import('../components/Dxg.vue'),
    children: [
      {
        path: '',
        name: 'dxg.index',
        component: () => import('../components/Core.vue')
      },
      {
        path: 'category/1',
        name: 'dxg.redirect',
        redirect: '/dxg'
      },
      {
        path: 'category/:categoryId',
        name: 'dxg.category',
        component: () => import('../components/DxgCategory.vue')
      },
      {
        path: 'patterns/:patternId',
        name: 'dxg.patterns',
        component: () => import('../components/DxgPatterns.vue')
      },
      {
        path: 'preview/:previewId',
        name: 'dxg.preview',
        component: () => import('../components/DxgPreview.vue')
      }
    ]
  },
  {
    path: '/admin/dxg',
    name: 'admin.dxg.index',
    meta: { layout: 'admin' },
    component: () => import('../components/Cms.vue')
  }
]
