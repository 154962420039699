var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    { attrs: { viewBox: "0 0 110 110", role: "presentation" } },
    [
      _c("defs", [
        _c("path", {
          attrs: { id: "a", d: "M0 109.317V.177h109.822v109.14H0z" },
        }),
      ]),
      _vm._v(" "),
      _c("g", { attrs: { fill: "none", "fill-rule": "evenodd" } }, [
        _c("path", {
          attrs: {
            fill: "#FFF",
            d: "M34.32 84.812a2.276 2.276 0 0 1-2.288-2.274v-54.57c0-.801.425-1.545 1.12-1.957a2.315 2.315 0 0 1 2.263-.04l50.336 27.286a2.27 2.27 0 0 1 0 3.992L35.415 84.534a2.31 2.31 0 0 1-1.096.278zm2.287-53.014v46.91l43.27-23.455-43.27-23.455z",
          },
        }),
        _vm._v(" "),
        _c("g", { attrs: { transform: "translate(0 .506)" } }, [
          _c("mask", { attrs: { id: "b", fill: "#fff" } }, [
            _c("use", { attrs: { "xlink:href": "#a" } }),
          ]),
          _vm._v(" "),
          _c("path", {
            attrs: {
              fill: "#FFF",
              d: "M54.91 4.724c27.756 0 50.336 22.44 50.336 50.022 0 27.583-22.58 50.023-50.335 50.023-27.755 0-50.335-22.44-50.335-50.023 0-27.582 22.58-50.022 50.335-50.022m0-4.547C24.585.177 0 24.609 0 54.747s24.585 54.57 54.91 54.57c30.327 0 54.912-24.432 54.912-54.57S85.237.176 54.911.176",
              mask: "url(#b)",
            },
          }),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }