var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isRootSite && _vm.domains.length
    ? _c(
        "div",
        { attrs: { id: "cross-site-auth" } },
        _vm._l(_vm.domains, function (domain, i) {
          return _c("CrossAuthIframe", {
            key: `cross-auth-${i}`,
            attrs: { domain: domain },
          })
        }),
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }