import { HttpClient } from "@core/js";

const AuthRequest = (url, data) => {
  return HttpClient.post(url, data).then((response) => {
    return response.data;
  });
};

const AuthGetRequest = (url, data) => {
  return HttpClient.get(url, data).then((response) => {
    return response.data;
  });
};

const GeographyCountries = () => {
  return HttpClient.get("/geography/countries").then((response) => {
    return response.data;
  });
};

const GeographyStates = () => {
  return HttpClient.get("/geography/states").then((response) => {
    return response.data;
  });
};

export default {
  install(Vue) {
    Vue.prototype.$services = {
      AuthRequest,
      GeographyCountries,
      GeographyStates,
      AuthGetRequest,
    };
  },
};
