var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "module divider-module" },
    [
      _c("module-controls", {
        attrs: {
          placeholder: _vm.moduleLabel,
          "action-buttons": _vm._actionButtons,
        },
        on: {
          "on-edit": _vm.$openModal,
          "on-remove": _vm.$deleteContentModuleConfirm,
        },
      }),
      _vm._v(" "),
      _c("div", { class: { container: _vm._isAdminArea } }, [
        _c("span", {
          staticClass: "divider",
          style: {
            marginTop: _vm.marginTop + "px",
            marginBottom: _vm.marginBottom + "px",
            height: _vm.thickness + "px",
            backgroundColor: _vm.color,
          },
        }),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }