var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._localesIsPublished && _vm.publishedLocales.length > 0
    ? _c(
        "div",
        { staticClass: "locale-changer" },
        [
          _c("VueSelect", {
            staticClass: "locales-dropdown",
            attrs: {
              searchable: false,
              clearable: false,
              options: _vm.publishedLocales,
              label: "label",
            },
            on: { input: _vm.updateLocation },
            scopedSlots: _vm._u(
              [
                {
                  key: "open-indicator",
                  fn: function () {
                    return [_c("Icon", { attrs: { id: "ic_arrow_drop_down" } })]
                  },
                  proxy: true,
                },
                {
                  key: "option",
                  fn: function (item) {
                    return [
                      _c("span", [_vm._v(_vm._s(item.label))]),
                      _vm._v(" "),
                      _vm.locale.locale === item.locale
                        ? _c(
                            "span",
                            { staticClass: "pull-right" },
                            [_c("Icon", { attrs: { id: "ic_done" } })],
                            1
                          )
                        : _vm._e(),
                    ]
                  },
                },
              ],
              null,
              false,
              699263157
            ),
            model: {
              value: _vm.locale,
              callback: function ($$v) {
                _vm.locale = $$v
              },
              expression: "locale",
            },
          }),
          _vm._v(" "),
          !_vm.useDropdown &&
          _vm.publishedLocales.length < 4 &&
          !_vm.overrideMobile
            ? _c(
                "ul",
                { staticClass: "list-unstyled locales-list" },
                _vm._l(_vm.publishedLocales, function (item, index) {
                  return _c("li", { key: index }, [
                    _c(
                      "a",
                      {
                        class: {
                          active:
                            _vm.locale && _vm.locale.locale === item.locale,
                        },
                        attrs: { href: "#" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.updateLocation(item)
                          },
                        },
                      },
                      [_vm._v("\n        " + _vm._s(item.label) + "\n      ")]
                    ),
                  ])
                }),
                0
              )
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }