var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "module pullquote-module" },
    [
      _c("module-controls", {
        attrs: {
          placeholder: _vm.moduleLabel,
          "action-buttons": _vm._actionButtons,
        },
        on: {
          "on-edit": _vm.$openModal,
          "on-remove": _vm.$deleteContentModuleConfirm,
        },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "module_content" },
        [
          _vm._isAdminArea && _vm.isEmpty
            ? _c("div", { staticClass: "empty" }, [
                _vm._v("\n      This module is empty. Start editing!\n    "),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm._isAdminArea && !_vm.isEmpty
            ? [
                _c("blockquote", [
                  _c("p", [
                    _vm._v(
                      _vm._s(
                        _vm.moduleData.text.length
                          ? _vm.moduleData.text
                          : "Pull Quote"
                      )
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "cite",
                    [
                      _vm.moduleData.attribution.length
                        ? [_vm._v(_vm._s(_vm.moduleData.attribution))]
                        : _vm._e(),
                    ],
                    2
                  ),
                ]),
              ]
            : _vm._e(),
          _vm._v(" "),
          !_vm._isAdminArea && !_vm.isEmpty
            ? [
                _c("blockquote", [
                  _c("p", [
                    _vm._v(
                      _vm._s(
                        _vm.moduleData.text.length
                          ? _vm.moduleData.text
                          : "Pull Quote"
                      )
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "cite",
                    [
                      _vm.moduleData.attribution.length
                        ? [_vm._v(_vm._s(_vm.moduleData.attribution))]
                        : _vm._e(),
                    ],
                    2
                  ),
                ]),
              ]
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }