// Global redirect for Beam2 bookmarks

export default [
  {
    path: 'site',
    redirect: { name: 'home.index' },
    children: [
      {
        path: '*',
        redirect: { name: 'home.index' },
      }
    ]
  },
  {
    path: 'panel',
    redirect: { name: 'home.index' },
    children: [
      {
        path: '*',
        redirect: { name: 'home.index' },
      }
    ]
  },
]
