var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.lightbox
    ? _c(
        "FormRoot",
        {
          staticClass: "collections-form",
          attrs: {
            "submit-button-text": _vm.$t("site_actions_submit_label"),
            "form-id": "collections-form",
            "data-check": "RequestForm",
            "show-cancel-button": _vm.isModal,
            "disable-validation-on-cancel": true,
          },
        },
        [
          _c("fieldset", [
            _c("legend", { staticClass: "offscreen" }, [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("forms_collections_type_label")) +
                  "\n    "
              ),
            ]),
            _vm._v(" "),
            _c("div", { attrs: { id: "radio-wrapper" } }, [
              _c("div", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.LightboxTag,
                      expression: "LightboxTag",
                    },
                  ],
                  attrs: {
                    id: "LightboxTag_private",
                    name: "LightboxTag",
                    type: "radio",
                    value: "private",
                  },
                  domProps: { checked: _vm._q(_vm.LightboxTag, "private") },
                  on: {
                    click: _vm.lightboxTypeChange,
                    change: function ($event) {
                      _vm.LightboxTag = "private"
                    },
                  },
                }),
                _vm._v(" "),
                _c("label", { attrs: { for: "LightboxTag_private" } }, [
                  _vm._v(_vm._s(_vm.$t("features_collections_types_my"))),
                ]),
              ]),
              _vm._v(" "),
              _c("div", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.LightboxTag,
                      expression: "LightboxTag",
                    },
                  ],
                  attrs: {
                    id: "LightboxTag_shared",
                    name: "LightboxTag",
                    type: "radio",
                    value: "shared",
                  },
                  domProps: { checked: _vm._q(_vm.LightboxTag, "shared") },
                  on: {
                    click: _vm.lightboxTypeChange,
                    change: function ($event) {
                      _vm.LightboxTag = "shared"
                    },
                  },
                }),
                _vm._v(" "),
                _c("label", { attrs: { for: "LightboxTag_shared" } }, [
                  _vm._v(_vm._s(_vm.$t("features_collections_types_shared"))),
                ]),
              ]),
              _vm._v(" "),
              _vm._isAdminUser
                ? _c("div", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.LightboxTag,
                          expression: "LightboxTag",
                        },
                      ],
                      attrs: {
                        id: "LightboxTag_public",
                        name: "LightboxTag",
                        type: "radio",
                        value: "public",
                      },
                      domProps: { checked: _vm._q(_vm.LightboxTag, "public") },
                      on: {
                        click: _vm.lightboxTypeChange,
                        change: function ($event) {
                          _vm.LightboxTag = "public"
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("label", { attrs: { for: "LightboxTag_public" } }, [
                      _vm._v(
                        _vm._s(_vm.$t("features_collections_types_public"))
                      ),
                    ]),
                  ])
                : _vm._e(),
            ]),
          ]),
          _vm._v(" "),
          _c("TextInput", {
            ref: "LightboxName",
            attrs: {
              label: _vm.$t("forms_collections_name_label"),
              model: _vm.populateLightbox.name,
              name: "name",
              required: "true",
            },
            on: {
              "update:model": function ($event) {
                return _vm.$set(_vm.populateLightbox, "name", $event)
              },
            },
          }),
          _vm._v(" "),
          _c("TextareaInput", {
            ref: "LightboxDesc",
            attrs: {
              label: _vm.$t("forms_collections_description_label"),
              model: _vm.populateLightbox.description,
              name: "description",
              required: true,
            },
            on: {
              "update:model": function ($event) {
                return _vm.$set(_vm.populateLightbox, "description", $event)
              },
            },
          }),
          _vm._v(" "),
          _vm.LightboxTag === "shared" || _vm.LightboxTag === "public"
            ? _c(
                "div",
                { staticClass: "row row_50_50" },
                [
                  _c(
                    "div",
                    { staticClass: "col", attrs: { id: "collection-editor" } },
                    [
                      _c("MultiselectInput", {
                        ref: "LightboxEditors",
                        staticClass: "no-dropdown",
                        attrs: {
                          list: _vm.editorsResults,
                          model: _vm.lightbox.editors,
                          required: true,
                          label: _vm.$t(
                            "forms_collections_search-editors_label"
                          ),
                        },
                        on: {
                          "update:model": function ($event) {
                            return _vm.$set(_vm.lightbox, "editors", $event)
                          },
                          select: _vm.onEditorSelect,
                          onSearchChange: function ($event) {
                            return _vm.onSearchChange($event, "editors")
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.LightboxTag === "shared"
                    ? [
                        _c(
                          "div",
                          {
                            staticClass: "col",
                            attrs: { id: "collection-user" },
                          },
                          [
                            _c("MultiselectInput", {
                              ref: "LightboxUsers",
                              staticClass: "no-dropdown",
                              attrs: {
                                list: _vm.usersResults,
                                model: _vm.lightbox.users,
                                required: true,
                                label: _vm.$t(
                                  "forms_collections_search-users_label"
                                ),
                              },
                              on: {
                                "update:model": function ($event) {
                                  return _vm.$set(_vm.lightbox, "users", $event)
                                },
                                select: _vm.onUserSelect,
                                onSearchChange: function ($event) {
                                  return _vm.onSearchChange($event, "users")
                                },
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                ],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.LightboxTag === "public"
            ? _c("div", { staticClass: "row row_50_50" }, [
                _c("div", { staticClass: "col" }, [
                  _c(
                    "div",
                    { attrs: { id: "groups-wrapper" } },
                    [
                      _c("MultiselectInput", {
                        ref: "LightboxGroups",
                        attrs: {
                          list: _vm.formattedGroups,
                          model: _vm.populateLightbox.groups,
                          required: true,
                          label: _vm.$t(
                            "forms_collections_select-groups_label"
                          ),
                        },
                        on: {
                          "update:model": function ($event) {
                            return _vm.$set(
                              _vm.populateLightbox,
                              "groups",
                              $event
                            )
                          },
                          select: _vm.onGroupSelect,
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "row row_50_50" },
            [
              _c("DateInput", {
                staticClass: "col",
                attrs: {
                  label: _vm.$t("forms_collections_expiration_label"),
                  model: _vm.populateLightbox.expires_at,
                  name: "expires_at",
                },
                on: {
                  "update:model": function ($event) {
                    return _vm.$set(_vm.populateLightbox, "expires_at", $event)
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }