var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "input iconpicker" },
    [
      _vm.label
        ? _c("label", { attrs: { for: _vm.inputId } }, [
            _vm._v(_vm._s(_vm.label)),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "b-dropdown",
        { ref: _vm.name, attrs: { id: _vm.inputId, text: _vm.niceName } },
        [
          _c(
            "b-dropdown-item",
            {
              attrs: { active: !_vm.icon },
              on: {
                click: function ($event) {
                  return _vm.changeEvent("")
                },
              },
            },
            [_vm._v("\n      No Icon\n    ")]
          ),
          _vm._v(" "),
          _vm._l(_vm.iconsList, function (iconId) {
            return _c(
              "b-dropdown-item",
              {
                key: iconId,
                attrs: { active: _vm.icon === iconId },
                on: {
                  click: function ($event) {
                    return _vm.changeEvent(iconId)
                  },
                },
              },
              [
                _c("icon", { attrs: { id: iconId } }),
                _vm._v(
                  "\n      " + _vm._s(_vm._f("simplify")(iconId)) + "\n    "
                ),
              ],
              1
            )
          }),
        ],
        2
      ),
      _vm._v(" "),
      _c("icon", { attrs: { id: "ic_arrow_drop_down" } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }