
import { FooterCopyright, FooterLinks, FooterLogo } from './components'

export default {
  name: 'ClientFooter',
  components: {
    FooterCopyright,
    FooterLinks,
    FooterLogo
  }
}
