var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      staticStyle: { "enable-background": "new 0 0 20 20" },
      attrs: {
        role: "presentation",
        version: "1.1",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
        x: "0px",
        y: "0px",
        viewBox: "0 0 20 20",
        "xml:space": "preserve",
        width: "16",
        height: "16",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M8.5,0C13.2,0,17,3.8,17,8.5c0,2-0.7,3.8-1.8,5.3l4.5,4.5c0.4,0.4,0.4,1,0,1.4c-0.4,0.4-1,0.4-1.4,0l0,0l-4.5-4.5\n  c-1.4,1.1-3.3,1.8-5.3,1.8C3.8,17,0,13.2,0,8.5S3.8,0,8.5,0z M8.5,2C4.9,2,2,4.9,2,8.5S4.9,15,8.5,15c1.8,0,3.4-0.7,4.5-1.9l0-0.1\n  c0,0,0,0,0,0c1.1-1.2,1.9-2.8,1.9-4.5C15,4.9,12.1,2,8.5,2z",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }