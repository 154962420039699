import actions from '../actions/actions'
import mutations from '../mutations/mutations'

export default {
  namespaced: true,
  state: {
    currentLibrary: null,
    categories: [],
    filters: [],
    selectedFilters: [],
    isLoading: false
  },
  mutations,
  actions
}
