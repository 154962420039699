var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "vc-material",
      attrs: { role: "application", "aria-label": "Material color picker" },
    },
    [
      _c("ed-in", {
        staticClass: "vc-material-hex",
        style: { borderColor: _vm.colors.hex },
        attrs: { label: "hex" },
        on: { change: _vm.onChange },
        model: {
          value: _vm.colors.hex,
          callback: function ($$v) {
            _vm.$set(_vm.colors, "hex", $$v)
          },
          expression: "colors.hex",
        },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "vc-material-split" }, [
        _c(
          "div",
          { staticClass: "vc-material-third" },
          [
            _c("ed-in", {
              attrs: { label: "r" },
              on: { change: _vm.onChange },
              model: {
                value: _vm.colors.rgba.r,
                callback: function ($$v) {
                  _vm.$set(_vm.colors.rgba, "r", $$v)
                },
                expression: "colors.rgba.r",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "vc-material-third" },
          [
            _c("ed-in", {
              attrs: { label: "g" },
              on: { change: _vm.onChange },
              model: {
                value: _vm.colors.rgba.g,
                callback: function ($$v) {
                  _vm.$set(_vm.colors.rgba, "g", $$v)
                },
                expression: "colors.rgba.g",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "vc-material-third" },
          [
            _c("ed-in", {
              attrs: { label: "b" },
              on: { change: _vm.onChange },
              model: {
                value: _vm.colors.rgba.b,
                callback: function ($$v) {
                  _vm.$set(_vm.colors.rgba, "b", $$v)
                },
                expression: "colors.rgba.b",
              },
            }),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }