var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        role: "presentation",
        width: "14px",
        height: "20px",
        viewBox: "0 0 14 20",
        version: "1.1",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
      },
    },
    [
      _c(
        "g",
        {
          attrs: {
            stroke: "none",
            "stroke-width": "1",
            fill: "none",
            "fill-rule": "evenodd",
          },
        },
        [
          _c(
            "g",
            { attrs: { transform: "translate(-593.000000, -8.000000)" } },
            [
              _c(
                "g",
                { attrs: { transform: "translate(590.000000, 8.000000)" } },
                [
                  _c("g", [
                    _c("rect", {
                      attrs: { x: "0", y: "0", width: "20", height: "20" },
                    }),
                    _vm._v(" "),
                    _c("path", {
                      attrs: {
                        d: "M9.99936081,-3.49720253e-15 C13.6809061,-3.49720253e-15 16.6653883,2.98448216 16.6653883,6.66602747 C16.6653883,8.82331102 15.640626,10.7412485 14.0513335,11.9596078 L14.9916431,19.0552371 C15.0834134,19.7469277 14.33524,20.2383283 13.7369226,19.8793379 L13.7369226,19.8793379 L9.99936081,17.6368008 L6.26179899,19.8793379 C5.6634341,20.2383569 4.91521761,19.7468513 5.00709426,19.0551189 L5.00709426,19.0551189 L5.94784776,11.9599601 C4.35829323,10.7416183 3.33333333,8.82351898 3.33333333,6.66602747 C3.33333333,2.98448216 6.31781549,-3.49720253e-15 9.99936081,-3.49720253e-15 Z M9.99936081,13.3320549 C9.12005973,13.3320549 8.28052237,13.1618062 7.5119138,12.8524738 L6.88669567,17.5609356 L9.57065545,15.9505598 C9.83453214,15.7922337 10.1641895,15.7922337 10.4280662,15.9505598 L10.4280662,15.9505598 L13.1122971,17.5610983 L12.4878147,12.8520685 C11.7189325,13.1616575 10.8790459,13.3320549 9.99936081,13.3320549 Z M9.99936081,1.66650687 C7.23820182,1.66650687 4.9998402,3.90486849 4.9998402,6.66602747 C4.9998402,9.42718646 7.23820182,11.6655481 9.99936081,11.6655481 C10.9752294,11.6655481 11.885795,11.3859526 12.6553208,10.9024985 C12.6895774,10.8743908 12.7269506,10.8504443 12.7663242,10.8295178 C14.1122811,9.93447439 14.9988814,8.40378923 14.9988814,6.66602747 C14.9988814,3.90486849 12.7605198,1.66650687 9.99936081,1.66650687 Z",
                        fill: "#000000",
                      },
                    }),
                  ]),
                ]
              ),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }