var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        role: "presentation",
        xmlns: "http://www.w3.org/2000/svg",
        width: "14",
        height: "17",
        viewBox: "0 0 14 17",
      },
    },
    [
      _c("g", { attrs: { fill: "none", "fill-rule": "evenodd" } }, [
        _c("path", { attrs: { d: "M-3-2h20v20H-3z" } }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            stroke: "#979797",
            "stroke-width": "1.5",
            d: "M6.472 15.833a.916.916 0 0 1-.389-.75l-.75.75h1.14zm1.052 0h1.143l-.75-.75a.919.919 0 0 1-.393.75zm-6.44-2.106l-.22.22.185-.447h.034v.227zm.227-.227h11.378l-1.439-1.44V7.584c0-2.31-1.216-4.071-3.173-4.537L7.5 2.91V1.75c0-.277-.223-.5-.5-.5s-.5.223-.5.5v1.16l-.577.136C3.959 3.512 2.75 5.263 2.75 7.583v4.478L1.31 13.5zm11.606 0h.034l.185.447-.22-.22V13.5z",
          },
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }