var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      staticStyle: { "enable-background": "new 0 0 18 16.1" },
      attrs: {
        role: "presentation",
        version: "1.1",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
        x: "0px",
        y: "0px",
        viewBox: "0 0 18 16.1",
        "xml:space": "preserve",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M7.7,15.3C3.1,11.1,0,8.3,0,4.9C0,2.2,2.2,0,4.9,0C6.5,0,8,0.7,9,1.9C10,0.7,11.5,0,13,0c2.8,0,5,2.2,5,4.9\n  c0,3.4-3.1,6.2-7.7,10.4l-0.6,0.5c-0.4,0.4-1.1,0.4-1.5,0L7.7,15.3z",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }