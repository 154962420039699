import actions from '../actions/actions'
import mutations from '../mutations/mutations'
import Modules from './content__modules'
import Rows from './content__rows'

export default {
  namespaced: true,
  state: {
    content: {},
    content_layouts: [],
    content_refs: {},
    entities: [],
    modules: Modules,
    popular: [],
    rows: Rows,
    sections: [],
    isLoading: false,
    newElementCreated: false,
    filterParameters: null,
    searchParameters: null
  },
  mutations,
  actions,
  getters: {
    contentLoaded: state => {
      return state.content.id && state.content.id > 0
    },
    contentModuleType: state => {
      return state.content?.menu_item?.type ?? null
    },
    contentStatus: state => {
      return state.content?.menu_item?.status ?? null
    },
    contentMenuItemId: state => {
      return state.content?.menu_item?.id ?? 0
    },
    isContentTypeHome: state => {
      const type = state.content?.menu_item?.type ?? null
      return type === 'homepage'
    },
    isContentTypePage: state => {
      const type = state.content?.menu_item?.type ?? null
      return type === 'page'
    },
    contentLayoutName: state => {
      return state.content?.content_layout?.name ?? 'default'
    }
  }
}
