var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      staticStyle: { "enable-background": "new 0 0 24 15" },
      attrs: {
        role: "presentation",
        version: "1.1",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
        x: "0px",
        y: "0px",
        viewBox: "0 0 24 15",
        "xml:space": "preserve",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M24,11v4H0v-4H24z M24,7v1h-4V7H24z M19,7v1h-4V7H19z M14,7v1h-4V7H14z M9,7v1H5V7H9z M4,7v1H0V7H4z M24,0v4H0V0H24z",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }