var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      key: _vm.toast.id,
      staticClass: "custom-toast",
      class: _vm.toast.variant,
      attrs: { id: _vm.toast.id },
    },
    [
      _c(
        "div",
        { staticClass: "close", on: { click: _vm.closeToaster } },
        [_c("Icon", { attrs: { id: "ic_close" } })],
        1
      ),
      _vm._v(" "),
      _vm.toast.variant === "info"
        ? _c("Icon", { staticClass: "icon-info", attrs: { id: "ic_info" } })
        : _vm._e(),
      _vm._v(" "),
      _vm.toast.title
        ? _c("div", { staticClass: "title" }, [
            _vm._v("\n    " + _vm._s(_vm.toast.title) + "\n  "),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "message" }, [
        _vm._v("\n    " + _vm._s(_vm.toast.message) + "\n  "),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }