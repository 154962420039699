var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      staticStyle: { "enable-background": "new 0 0 24 14.4" },
      attrs: {
        role: "presentation",
        version: "1.1",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
        x: "0px",
        y: "0px",
        viewBox: "0 0 24 14.4",
        "xml:space": "preserve",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M22.6,0H1.4C0.6,0,0,0.6,0,1.4v7.2c0,0.8,0.6,1.4,1.4,1.4h11.7l1.5,3.5c0.2,0.5,0.8,0.6,1.2,0.2l0.9-0.9l1.3,1.3\n  c0.3,0.3,0.7,0.3,1,0l1.1-1.1c0.3-0.3,0.3-0.7,0-1l-1.3-1.3l0.7-0.7h3.1c0.8,0,1.4-0.6,1.4-1.4V1.4C24,0.6,23.4,0,22.6,0z\n   M17.7,10.8l1.8,1.8l-1.1,1.1l-1.8-1.8l-1.4,1.4l-2.9-6.9l6.8,2.9L17.7,10.8z",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }