var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        role: "presentation",
        width: "20px",
        height: "20px",
        viewBox: "0 0 20 20",
        version: "1.1",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
      },
    },
    [
      _c(
        "g",
        {
          attrs: {
            stroke: "none",
            "stroke-width": "1",
            fill: "none",
            "fill-rule": "evenodd",
          },
        },
        [
          _c(
            "g",
            { attrs: { transform: "translate(-446.000000, -8.000000)" } },
            [
              _c(
                "g",
                { attrs: { transform: "translate(446.000000, 8.000000)" } },
                [
                  _c("g", [
                    _c("rect", {
                      attrs: { x: "0", y: "0", width: "20", height: "20" },
                    }),
                    _vm._v(" "),
                    _c("path", {
                      attrs: {
                        d: "M0.921493221,13.7939887 C1.1273176,13.3823399 1.62787846,13.2154863 2.03952721,13.4213107 L2.03952721,13.4213107 L10.0001825,17.4016383 L17.9608379,13.4213107 C18.3724866,13.2154863 18.8730475,13.3823399 19.0788719,13.7939887 C19.2846962,14.2056374 19.1178426,14.7061983 18.7061939,14.9120227 L18.7061939,14.9120227 L10.3728605,19.0786893 C10.1382543,19.1959924 9.86211079,19.1959924 9.62750455,19.0786893 L9.62750455,19.0786893 L1.29417122,14.9120227 C0.88252247,14.7061983 0.715668847,14.2056374 0.921493221,13.7939887 Z M0.921493221,9.627322 C1.1273176,9.21567326 1.62787846,9.04881963 2.03952721,9.25464401 L2.03952721,9.25464401 L10.0001825,13.2349717 L17.9608379,9.25464401 C18.3724866,9.04881963 18.8730475,9.21567326 19.0788719,9.627322 C19.2846962,10.0389708 19.1178426,10.5395316 18.7061939,10.745356 L18.7061939,10.745356 L10.3728605,14.9120227 C10.1382543,15.0293258 9.86211079,15.0293258 9.62750455,14.9120227 L9.62750455,14.9120227 L1.29417122,10.745356 C0.88252247,10.5395316 0.715668847,10.0389708 0.921493221,9.627322 Z M10.3728605,0.921310674 L18.7061939,5.08797734 C19.320401,5.3950809 19.320401,6.27158577 18.7061939,6.57868933 L10.3728605,10.745356 C10.1382543,10.8626591 9.86211079,10.8626591 9.62750455,10.745356 L1.29417122,6.57868933 C0.679964101,6.27158577 0.679964101,5.3950809 1.29417122,5.08797734 L9.62750455,0.921310674 C9.86211079,0.804007553 10.1382543,0.804007553 10.3728605,0.921310674 Z M10.0001825,2.59836166 L3.5302392,5.83333333 L10.0001825,9.06830501 L16.4701259,5.83333333 L10.0001825,2.59836166 Z",
                        fill: "#fff",
                      },
                    }),
                  ]),
                ]
              ),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }