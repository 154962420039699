var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "form-input time-input" }, [
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.input,
          expression: "input",
        },
      ],
      attrs: {
        id: _vm.id,
        type: "time",
        autocomplete: "disabled",
        disabled: _vm.disabled,
      },
      domProps: { value: _vm.input },
      on: {
        focus: _vm.toggleLabel,
        blur: _vm.toggleLabel,
        input: function ($event) {
          if ($event.target.composing) return
          _vm.input = $event.target.value
        },
      },
    }),
    _vm._v(" "),
    _c("label", {
      directives: [
        {
          name: "sanitize",
          rawName: "v-sanitize",
          value: _vm.fieldLabel,
          expression: "fieldLabel",
        },
      ],
      staticClass: "active",
      attrs: { for: _vm.id },
      domProps: { innerHTML: _vm._s(_vm.fieldLabel) },
    }),
    _vm._v(" "),
    _vm.required && _vm.error
      ? _c("div", { staticClass: "error" }, [
          _vm._v("\n    " + _vm._s(_vm.errorMsg) + "\n  "),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }