export default {
  path: 'appearance',
  name: 'admin.settings.appearance',
  component: () => import(
    /* webpackChunkName: "admin-settings-appearance-index" */
    '@cms/components/settings/appearance/index.vue'
  ),

  children: [
    {
      path: 'animation',
      name: 'admin.settings.appearance.animation',
      component: () =>
        import(
          /* webpackChunkName: "admin-settings-appearance-animation" */
          '@cms/components/settings/appearance/animation/animation.vue'
        )
    },
    {
      path: 'auth',
      name: 'admin.settings.appearance.auth',
      component: () =>
        import(
          /* webpackChunkName: "admin-settings-appearance-auth" */
          '@cms/components/settings/appearance/auth.vue'
        )
    },
    {
      path: 'elements',
      name: 'admin.settings.appearance.elements',
      component: () =>
        import(
          /* webpackChunkName: "admin-settings-appearance-elements" */
          '@cms/components/settings/appearance/elements.vue'
        )
    },
    {
      path: 'icons',
      name: 'admin.settings.appearance.icons',
      component: () =>
        import(
          /* webpackChunkName: "admin-settings-appearance-icons" */
          '@cms/components/settings/appearance/SettingsIcons.vue'
        )
    },
    {
      path: 'footer',
      name: 'admin.settings.appearance.footer',
      component: () =>
        import(
          /* webpackChunkName: "admin-settings-appearance-footer" */
          '@cms/components/settings/appearance/footer.vue'
        )
    },
    // {
    //   path: 'homepage',
    //   name: 'admin.settings.appearance.homepage',
    //   component: () =>
    //     import(
    //       /* webpackChunkName: "admin-settings-appearance-home" */
    //       '@cms/components/settings/appearance/homepage.vue'
    //     )
    // },
    {
      path: 'logos',
      name: 'admin.settings.appearance.logos',
      component: () =>
        import(
          /* webpackChunkName: "admin-settings-appearance-logos" */
          '@cms/components/settings/appearance/logos/index.vue'
        )
    },
    {
      path: 'navigation',
      name: 'admin.settings.appearance.navigation',
      component: () =>
        import(
          /* webpackChunkName: "admin-settings-appearance-navigation" */
          '@cms/components/settings/appearance/navigation/navigation.vue'
        )
    },
    {
      path: 'theme',
      name: 'admin.settings.appearance.theme',
      component: () =>
        import(
          /* webpackChunkName: "admin-settings-appearance-theme" */
          '@cms/components/settings/appearance/theme.vue'
        )
    },
    {
      path: 'typography',
      name: 'admin.settings.appearance.typography',
      component: () =>
        import(
          /* webpackChunkName: "admin-settings-appearance-typography" */
          '@cms/components/settings/appearance/typography/typography.vue'
        )
    }
  ]
}
