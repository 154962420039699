var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ul",
    _vm._l(_vm.items, function (item, index) {
      return _c(
        "li",
        {
          key: `L1-${index}`,
          ref: "navItems",
          refInFor: true,
          class: [
            {
              "has-sub": item.children_tree.length,
              active: _vm.isActive(item.id),
              "active-parent": _vm.activeParent(item.id),
            },
          ],
        },
        [
          item.type === "directory"
            ? [
                _c(
                  "router-link",
                  {
                    staticClass: "nav-toggle",
                    attrs: {
                      to: "#",
                      event: "",
                      "aria-haspopup": "true",
                      "aria-expanded": _vm.isOpen,
                      role: "button",
                    },
                    on: {
                      mouseenter: _vm.toggleTopState,
                      mouseleave: _vm.toggleTopState,
                      keydown: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        _vm.level > 1 ? _vm.toggleActive(item.id) : null
                      },
                    },
                    nativeOn: {
                      click: function ($event) {
                        _vm.overrideMobile || _vm.level > 1
                          ? _vm.toggleActive(item.id)
                          : null
                      },
                      keydown: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k($event.keyCode, "space", 32, $event.key, [
                            " ",
                            "Spacebar",
                          ])
                        )
                          return null
                        $event.preventDefault()
                        _vm.level > 1 ? _vm.toggleActive(item.id) : null
                      },
                    },
                  },
                  [
                    _c("span", [_vm._v(_vm._s(item.label))]),
                    _vm._v(" "),
                    item.children_tree.length
                      ? [
                          _vm.overrideMobile
                            ? _c("Icon", { attrs: { id: "ic_chevron_right" } })
                            : _vm.level > 1
                            ? _c("Icon", { attrs: { id: "ic_expand_more" } })
                            : _vm._e(),
                        ]
                      : _vm._e(),
                  ],
                  2
                ),
                _vm._v(" "),
                item.children_tree.length
                  ? _c("PrimaryNavigation", {
                      class: [`level-${_vm.level + 1}`, "nav-menu"],
                      style: {
                        "max-height":
                          _vm.level === 1 ? _vm.dropdownHeight : null,
                        overflow: _vm.level === 1 ? "auto" : null,
                      },
                      attrs: {
                        level: _vm.level + 1,
                        items: item.children_tree,
                      },
                      on: { "close-menu": _vm.closeMenu },
                    })
                  : _vm._e(),
              ]
            : item.type === "page"
            ? _c(
                "router-link",
                {
                  staticClass: "nav-link",
                  attrs: { title: item.label, to: _vm.$getRouteObject(item) },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.closeMenu.apply(null, arguments)
                    },
                  },
                },
                [_c("span", [_vm._v(_vm._s(item.label))])]
              )
            : item.type === "external"
            ? _c(
                "a",
                {
                  staticClass: "menu-link",
                  attrs: {
                    href: item.action,
                    target: item.new_tab ? "_blank" : "_self",
                  },
                },
                [_c("span", [_vm._v(_vm._s(item.label))])]
              )
            : _vm._e(),
        ],
        2
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }