var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "14",
        height: "16",
        viewBox: "0 0 14 16",
        role: "presentation",
      },
    },
    [
      _c("g", { attrs: { fill: "none", "fill-rule": "evenodd" } }, [
        _c("path", { attrs: { d: "M-3-2h20v20H-3z" } }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            fill: "#E87722",
            "fill-rule": "nonzero",
            d: "M7 2.455a6.5 6.5 0 1 1-6.5 6.5.5.5 0 1 1 1 0 5.5 5.5 0 1 0 5.5-5.5V5.66a.25.25 0 0 1-.404.197L3.13 3.152a.25.25 0 0 1 0-.394L6.596.053A.25.25 0 0 1 7 .25v2.205z",
          },
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }