var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "form-input date-input" },
    [
      _c("flat-pickr", {
        attrs: {
          id: _vm.id,
          config: _vm.flatPickrOptions,
          value: _vm.input,
          disabled: _vm.disabled,
          placeholder: _vm.placeholder,
        },
        on: { input: _vm.onInput },
      }),
      _vm._v(" "),
      _c("label", {
        directives: [
          {
            name: "sanitize",
            rawName: "v-sanitize",
            value: _vm.fieldLabel,
            expression: "fieldLabel",
          },
        ],
        class: { active: _vm.isActive },
        attrs: { for: _vm.id },
      }),
      _vm._v(" "),
      _vm.required && _vm.error
        ? _c("div", { staticClass: "error" }, [
            _vm._v("\n    " + _vm._s(_vm.errorText) + "\n  "),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }