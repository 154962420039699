var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "20",
        height: "20",
        viewBox: "0 0 20 20",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M4.583 13.262v2.155h2.155l-2.155-2.155zM13.506 3.16l3.333 3.333c.326.326.326.853 0 1.179l-9.166 9.166c-.157.157-.369.244-.59.244H3.75c-.46 0-.833-.373-.833-.833v-3.333c0-.221.087-.433.244-.59l9.166-9.166c.326-.326.853-.326 1.179 0z",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }