var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        role: "presentation",
        viewBox: "0 0 48 48",
        "enable-background": "new 0 0 48 48",
      },
    },
    [
      _c("polygon", {
        attrs: {
          fill: "#000000",
          points: "40.6,12.1 17,35.7 7.4,26.1 4.6,29 17,41.3 43.4,14.9",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }