import actions from "@core/store/actions/actions";
import mutations from "@core/store/mutations/mutations";

export default {
  namespaced: true,
  state: {
    tables: [],
    columns: [],
    selectedDataTable: {},
    filterParameters: {
      table: null,
    },
    searchParameters: null,
    isLoading: false,
    newElementCreated: false,
    editRows: false,
    rowsToDelete: [],
    rowOrderChanged: false,
    columnsUpdated: false,
    selectedCell: null,
  },
  mutations,
  actions,
};
