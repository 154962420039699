var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        role: "presentation",
        width: "20px",
        height: "19px",
        viewBox: "0 0 20 19",
        version: "1.1",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
      },
    },
    [
      _c(
        "g",
        {
          attrs: {
            stroke: "none",
            "stroke-width": "1",
            fill: "none",
            "fill-rule": "evenodd",
          },
        },
        [
          _c(
            "g",
            { attrs: { transform: "translate(-518.000000, -9.000000)" } },
            [
              _c(
                "g",
                { attrs: { transform: "translate(518.000000, 8.000000)" } },
                [
                  _c("g", [
                    _c("rect", {
                      attrs: { x: "0", y: "0", width: "20", height: "20" },
                    }),
                    _vm._v(" "),
                    _c("path", {
                      attrs: {
                        d: "M6.78619658,2.81818182 L2.72727273,2.81818182 C2.22519568,2.81818182 1.81818182,3.22519568 1.81818182,3.72727273 L1.81818182,16.4545455 C1.81818182,16.9566225 2.22519568,17.3636364 2.72727273,17.3636364 L17.2727273,17.3636364 C17.7748043,17.3636364 18.1818182,16.9566225 18.1818182,16.4545455 L18.1818182,6.45454545 C18.1818182,5.95246841 17.7748043,5.54545455 17.2727273,5.54545455 L9.09090909,5.54545455 C8.78695165,5.54545455 8.50310499,5.39354442 8.33449973,5.14063654 L6.78619658,2.81818182 Z M9.57743978,3.72727273 L17.2727273,3.72727273 C18.7789584,3.72727273 20,4.94831432 20,6.45454545 L20,16.4545455 C20,17.9607766 18.7789584,19.1818182 17.2727273,19.1818182 L2.72727273,19.1818182 C1.22104159,19.1818182 0,17.9607766 0,16.4545455 L0,3.72727273 C0,2.22104159 1.22104159,1 2.72727273,1 L7.27272727,1 C7.57668472,1 7.86053138,1.15191012 8.02913663,1.404818 L9.57743978,3.72727273 Z",
                      },
                    }),
                  ]),
                ]
              ),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }