var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.closeMenu,
          expression: "closeMenu",
        },
      ],
      staticClass: "menu",
      class: { active: _vm.active },
    },
    [
      _c(
        "div",
        { ref: "libraryMenu", class: ["three-dots", { opened: _vm.active }] },
        [
          _c("Icon", {
            attrs: { id: "cms_overflow" },
            nativeOn: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.openOptions.apply(null, arguments)
              },
            },
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "btn-menu" },
            _vm._l(_vm.items, function (item, i) {
              return _c(
                "div",
                {
                  key: i,
                  staticClass: "more-link",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.clickEvent(item)
                    },
                  },
                },
                [
                  _c(
                    "span",
                    [
                      item.icon
                        ? _c("icon", { attrs: { id: item.icon } })
                        : _vm._e(),
                      _vm._v(
                        "\n          " + _vm._s(item.label) + "\n        "
                      ),
                    ],
                    1
                  ),
                ]
              )
            }),
            0
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }