var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "nav",
    {
      staticClass: "sticky-footer-nav",
      attrs: { "aria-label": _vm.$t("layout_regions_admin-footer_label") },
    },
    [
      _vm.showHelpDesk
        ? _c(
            "router-link",
            { attrs: { to: _vm.$generateRoute({ name: "helpdesk.index" }) } },
            [
              _c(
                "span",
                { staticClass: "sticky-helpdesk" },
                [_c("icon", { attrs: { id: _vm._helpdesk_view } })],
                1
              ),
              _vm._v(" "),
              _c("span", [
                _vm._v(_vm._s(_vm.$t("features_helpdesk_general_label"))),
              ]),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showFooterItem("collections")
        ? _c(
            "router-link",
            { attrs: { to: _vm.$generateRoute({ name: "collection.index" }) } },
            [
              _c(
                "span",
                { staticClass: "sticky-collections" },
                [_c("icon", { attrs: { id: _vm._collections_view } })],
                1
              ),
              _vm._v(" "),
              _c("span", [
                _vm._v(_vm._s(_vm.$t("features_collections_general_label"))),
              ]),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showFooterItem("favorites")
        ? _c(
            "router-link",
            { attrs: { to: _vm.$generateRoute({ name: "bookmarks.index" }) } },
            [
              _c(
                "span",
                { staticClass: "sticky-favorites" },
                [_c("icon", { attrs: { id: _vm._favorites_view } })],
                1
              ),
              _vm._v(" "),
              _c("span", [
                _vm._v(_vm._s(_vm.$t("features_favorites_general_label"))),
              ]),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showFooterItem("profile")
        ? _c(
            "router-link",
            { attrs: { to: _vm.$generateRoute({ name: "profile.index" }) } },
            [
              _c(
                "span",
                { staticClass: "sticky-profile" },
                [_c("icon", { attrs: { id: _vm._profile_view } })],
                1
              ),
              _vm._v(" "),
              _c("span", [
                _vm._v(_vm._s(_vm.$t("features_profile_general_label"))),
              ]),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showFooterItem("logout")
        ? _c(
            "router-link",
            { attrs: { to: _vm.$generateRoute({ name: "auth.logout" }) } },
            [
              _c(
                "span",
                { staticClass: "sticky-logout" },
                [_c("icon", { attrs: { id: _vm._logout_icon } })],
                1
              ),
              _vm._v(" "),
              _c("span", [_vm._v(_vm._s(_vm.$t("site_actions_logout_label")))]),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }