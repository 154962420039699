export default {
  path: 'features',
  component: () => import(
    /* webpackChunkName: "admin-settings-features-index" */
    '@cms/components/settings/features/index.vue'),

  children: [
    {
      path: '',
      name: 'admin.features.index',
      component: () => import(
        /* webpackChunkName: "admin-settings-features-features" */
        '@cms/components/settings/features/features.vue')
    }
  ]
}
