var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        role: "presentation",
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 32 32",
        "enable-background": "new 0 0 32 32",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M16 0c-8.8 0-16 7.2-16 16s7.2 16 16 16 16-7.2 16-16-7.2-16-16-16zm-2.5 5.6h4.9v3.9h-4.9v-3.9zm5 20.8h-5v-15h5v15z",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }