import { mapGetters, mapState } from 'vuex'

export default {
  install (Vue) {
    Vue.mixin({
      computed: {
        ...mapGetters({
          getActiveLocaleById: 'Locales/getActiveLocaleById'
        }),
        ...mapState({
          currentEntity: state => state.Entities.currentEntity,
          currentUser: state => state.Users.currentUser
        }),
        _query () {
          const query = this.$route?.query ?? {}
          if (this._isAdminArea) {
            if (!query.entity && this.currentEntity && this.currentEntity.id) {
              query.entity = this.currentEntity.id
            }
            if (!query.locale && this.$root.$i18n.locale) {
              query.locale = this.$root.$i18n.locale
            }
          }

          return query
        }
      },
      methods: {
        /**
         * @public
         * @param {object} route takes Vue Router object as arg
         * @returns {object} mutable Vue Router route object
         *
         */
        $generateMutableRouteObject (route = {}) {
          return {
            name: route.name,
            meta: (route.meta) ? this.$utilities.deepMerge(route.meta) : {},
            params: (route.params) ? this.$utilities.deepMerge(route.params) : {},
            query: (route.query) ? this.$utilities.deepMerge(route.query) : {}
          }
        },
        /**
         * @public
         * @param {object|string} route takes Vue Router object or location string as arg
         * @returns {object|string} Vue Router route object, or a string as fallback.
         *
         */
        $generateRoute (route = {}) {
          if (route === null || route === '' || typeof route === 'undefined') {
            return route
          }
          // use this global method to add entity and locale params to any Router route object
          // this outputs a Vue Router route object, not a URL, it will not work as an HREF!!!!!
          // the route argument should be an object with a "name" property, using named routes is ideal
          // e.g. { name: 'home.index' }
          if (typeof route === 'string') {
            // Pass-through for external or admin links
            if (route.indexOf('/admin') === 0 || route.indexOf('http') === 0 || route.indexOf('//') === 0 || route.indexOf('/login') === 0) {
              return route
            }
            // Failsafe: convert client-side path string to route object, necessary for params
            const routeObj = this.$router.resolve(route)
            if (!!routeObj.route) {
              route = routeObj.route
            } else {
              route = { fullPath: route }
            }
          }

          const entity = this.currentEntity?.name ?? undefined
          const locale = this.$root?.$i18n?.locale ?? undefined

          const hasEntity = !!entity && this._entitiesIsPublished
          const hasLocale = !!locale && this._localesIsPublished

          if (!route.params && (hasEntity || hasLocale)) {
            // Initialize params object, if necessary
            route.params = {}
          }

          // add entity parameter
          if (hasEntity && !route.params.entity && entity !== 'default') {
            route.params.entity = entity
          }

          // add locale parameter
          if (hasLocale && !route.params.locale) {
            const userLocaleObj = this.getActiveLocaleById(this?.currentUser?.locale_id ?? 0)
            const userLocale = userLocaleObj?.locale ?? null

            if (locale !== this.$root.$i18n.fallbackLocale || userLocale !== this.$root.$i18n.fallbackLocale) {
              route.params.locale = locale
            }
          }
          // return route object for use in router-link :to prop, or router.push() method
          return route
        },
        /**
         * Replaces old getURL() method from the NavItems mixin
         * @public
         * @param {object} item takes menu-item object as arg
         * @returns {object|string} Vue Router route object or string for href, router path, or placeholder #.
         *
         */
        $getRouteObject (item) {
          let result = ''

          switch (item.type) {
            case 'page':
              result = {
                name: 'content.page',
                params: { guid: item.action }
              }
              break

            case 'directory':
              result = '#'
              break

            case 'external':
              result = item.action
              break

            case 'assetlibrary':
              result = {
                name: 'assets.list',
                params: { tag: item.action }
              }
              break

            case 'collection':
            case 'lightbox':
              result = {
                name: 'collection.list',
                params: { tag: item.action }
              }
              break

            case 'assetqueue':
            case 'bookmarks':
            case 'helpdesk':
              result = { name: `${item.type}.index` }
              break
          }

          if (typeof result !== 'string') {
            result = this.$generateRoute(result)
          }

          return result
        }
      }
    })
  }
}
