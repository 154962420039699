import CmsRoutes from "./routes/cms";
import DataTables from "@core/plugins/DataTables/store/data-tables";
import Columns from "@core/plugins/DataTables/store/columns";
import Rows from "@core/plugins/DataTables/store/rows";
import Cells from "@core/plugins/DataTables/store/cells";

import { RouteUtils } from "@core/js";

export default {
  install(Vue, options) {
    if (!options) {
      throw new Error("The FormEditor Extension requires an options object!");
    }

    if (!options.router) {
      throw new Error("The FormEditor Extension requires a router option!");
    }

    // // Register the FormEditor store module
    window.AppStore.registerModule("DataTables", DataTables);
    window.AppStore.registerModule("Columns", Columns);
    window.AppStore.registerModule("Rows", Rows);
    window.AppStore.registerModule("Cells", Cells);

    // Add routes to router
    const DataTablesRoutes = CmsRoutes;

    let params = RouteUtils.getRouteParams(options.router.getRoutes());

    DataTablesRoutes.forEach((route) => {
      route = RouteUtils.standardizePluginRoutePath(route, params);
      options.router.addRoute(route);
    });
  },
};
