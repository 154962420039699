var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm._globalImages.secondaryLogo
        ? [
            _c("img", {
              style: `height:${_vm.height}px;width:${_vm.width}`,
              attrs: {
                src: _vm._globalImages.secondaryLogo,
                alt: _vm.$t("site_messaging_site-name_label"),
              },
            }),
          ]
        : [_c("span", [_vm._v(" ")])],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }