var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        role: "presentation",
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 16 16",
        "enable-background": "new 0 0 16 16",
      },
    },
    [
      _c("g", { attrs: { fill: "none", "fill-rule": "nonzero" } }, [
        _c("path", {
          attrs: {
            fill: "#E87722",
            "fill-rule": "nonzero",
            d: "M11.804 4H4.196C1.879 4 0 5.89 0 8.222c0 2.332 1.879 4.222 4.196 4.222 1.684 0 2.523-2.438 3.804-2.438 1.281 0 2.12 2.438 3.804 2.438 2.317 0 4.196-1.89 4.196-4.222C16 5.89 14.121 4 11.804 4zM4.143 9.646c-1.08 0-1.955-.972-1.955-2.172 0-1.2 3.91-1.2 3.91 0s-.876 2.172-1.955 2.172zm7.714 0c-1.08 0-1.954-.972-1.954-2.172 0-1.2 3.909-1.2 3.909 0s-.875 2.172-1.955 2.172z",
          },
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }