var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "16",
        height: "19",
        viewBox: "0 0 16 19",
        role: "presentation",
      },
    },
    [
      _c("g", { attrs: { fill: "none", "fill-rule": "nonzero" } }, [
        _c("path", {
          attrs: {
            stroke: "#979797",
            "stroke-width": "1.5",
            d: "M11 17l-5-4-5 4V6a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v11z",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            fill: "#979797",
            stroke: "#FFF",
            "stroke-width": ".5",
            d: "M15 14h-2V4a1 1 0 0 0-1-1H4V1h9a2 2 0 0 1 2 2v11z",
          },
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }