var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "vc-swatches",
      attrs: {
        role: "application",
        "aria-label": "Swatches color picker",
        "data-pick": _vm.pick,
      },
    },
    [
      _c(
        "div",
        { staticClass: "vc-swatches-box", attrs: { role: "listbox" } },
        _vm._l(_vm.palette, function (group, $idx) {
          return _c(
            "div",
            { key: $idx, staticClass: "vc-swatches-color-group" },
            _vm._l(group, function (c) {
              return _c(
                "div",
                {
                  key: c,
                  class: [
                    "vc-swatches-color-it",
                    { "vc-swatches-color--white": c === "#FFFFFF" },
                  ],
                  style: { background: c },
                  attrs: {
                    role: "option",
                    "aria-label": "Color:" + c,
                    "aria-selected": _vm.equal(c),
                    "data-color": c,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.handlerClick(c)
                    },
                  },
                },
                [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.equal(c),
                          expression: "equal(c)",
                        },
                      ],
                      staticClass: "vc-swatches-pick",
                    },
                    [
                      _c(
                        "svg",
                        {
                          staticStyle: { width: "24px", height: "24px" },
                          attrs: { viewBox: "0 0 24 24" },
                        },
                        [
                          _c("path", {
                            attrs: {
                              d: "M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z",
                            },
                          }),
                        ]
                      ),
                    ]
                  ),
                ]
              )
            }),
            0
          )
        }),
        0
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }