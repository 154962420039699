import AppearanceRoutes from './settings__appearance'
import EntitiesRoutes from './settings__entities'
import FeaturesRoutes from './settings__features'
import GroupsRoutes from './settings__groups'
import RolesRoutes from './settings__roles'
import LocalizationRoutes from './settings__localization'
import TrackingRoutes from './settings__tracking'
import SettingsIndex from '@cms/components/settings/index.vue'
import SettingsImports from './settings__imports'

export default {
  path: '/admin/settings',
  name: 'admin.settings',
  meta: { layout: 'admin' },
  component: SettingsIndex,
  children: [
    AppearanceRoutes,
    EntitiesRoutes,
    FeaturesRoutes,
    GroupsRoutes,
    RolesRoutes,
    LocalizationRoutes,
    SettingsImports,
    TrackingRoutes
  ]
}
