var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.id
    ? _c("span", { staticClass: "icon", attrs: { role: "presentation" } }, [
        _c(
          "svg",
          {
            class: _vm.id,
            attrs: {
              xmlns: "http://www.w3.org/2000/svg",
              role: "presentation",
              preserveAspectRatio: "xMidYMid meet",
              width: "20",
              height: "20",
            },
          },
          [
            _c("use", {
              class: _vm.id,
              attrs: { href: `#${_vm.id}`, "xlink:href": `#${_vm.id}` },
            }),
          ]
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }