import Vue from "vue";

const parseRoute = function (route) {
  return {
    name: route.name,
    hash: route.hash,
    meta: route.meta ? JSON.parse(JSON.stringify(route.meta)) : {},
    params: route.params ? JSON.parse(JSON.stringify(route.params)) : {},
    query: route.query ? JSON.parse(JSON.stringify(route.query)) : {},
  };
};

const setDestinationCookie = function (to) {
  // Store original route in a cookie until login/logout
  // Do not save a cookie for login, home, 401 or 404
  const excludedRoutes = [
    "home.index",
    "auth.login",
    "auth.logout",
    "unauthorized",
    "notfound",
  ];

  if (!excludedRoutes.includes(to.name)) {
    window.CookieManager.setCookie("beam_destination", to.fullPath, 300);
  }
};

/** * ********** Route Guards ********** ***/

/**
 * Check Logout
 *
 * @description if user hits the logout route,
 * destroy any cookies, abort navigation, and redirect to login.
 *
 */
const checkLogout = async function (to, from, next) {
  if (to.name === "auth.logout") {
    // window.CookieManager.unsetCookie('beam_destination')

    let redirect = false;

    try {
      const result = await Vue.prototype.$services.AuthRequest(
        "authentication/logout"
      );
      if (result) {
        window.CookieManager.unsetCookie("jwt");
        window.CookieManager.unsetCookie("impersonate");
        await window.AppStore.dispatch("JWT/unsetJWT");

        if (result.redirect_url) {
          redirect = result.redirect_url;
        }
      }
    } catch (err) {
      if (err) {
        /* eslint-disable-next-line no-console */
        console.log(
          "Logout error: ",
          err?.response?.data?.error ??
            "There was an issue with the logout process"
        );
      }
    }

    if (redirect) {
      window.location = redirect;
    } else {
      // Commenting this out prevents bogus navigation error in the console
      // next(false)
      window.location =
        "published" === window.AppStore.getters["Settings/beamFeatures"].public
          ? "/"
          : "/login";
    }
  } else {
    next();
  }
};

/**
 * Check Route
 *
 * @description check to see which side of the app is active, main or admin.
 *
 */
const checkEnvironment = function (to, from, next) {
  // Redirect if user is in the wrong area
  const isCMS = !!window.AppStore.state.Global.cms;
  const isCMSRoute = !!(to.path.indexOf("/admin") === 0);

  if (isCMS !== isCMSRoute) {
    // Commenting this out prevents bogus navigation error in the console
    // next(false)
    window.location = to.path;
  } else {
    if (typeof next === "function") {
      next();
    }
  }
};

/**
 * Require Auth
 *
 * @description check to see if app has public pages enabled.
 * If app is private, and user not authenticated then redirect to login
 *
 */
const checkAuthentication = async function (to, from, next) {
  const authToken = window.CookieManager.getCookie("jwt");
  // Sometimes the token unsets to the string 'false'
  const hasToken = authToken && "false" !== authToken;
  const isAuthRoute = !!(to.meta.layout === "auth");
  const hasInternalRequests =
    "published" ===
    window.AppStore.getters["Settings/beamFeatures"].internalAccessRequests;

  /** * Set destination cookie ***/
  if (!hasToken && !isAuthRoute) {
    // Store original destination in a cookie for use after login
    setDestinationCookie(to);
  }

  /** * Auth Pages ***/
  if (isAuthRoute) {
    if (
      hasToken &&
      (to.name !== "auth.contact" ||
        (to.name === "auth.request-access" && !hasInternalRequests))
    ) {
      // Redirect authenticated user to home for any auth page except Contact or Internal Request Access
      let params = {
        entity: to?.params?.entity ?? null,
        locale: to?.params?.locale ?? null,
      };

      next({ name: "home.index", params: params });
    } else {
      // Allow unauthenticated users access to all auth pages
      next();
    }
    return;
  }

  /** * Public Pages ***/
  const isPublicSite =
    "published" === window.AppStore.getters["Settings/beamFeatures"].public;

  if (isPublicSite) {
    next();
    return;
  }

  /** * Authenticated Users ***/
  // this should never happen, but...
  // if a token exists without a currentUser, request the user data
  if (hasToken && !window.AppStore.getters["Users/currentUser"]) {
    await window.AppStore.dispatch("Users/getProperty", {
      collection: "currentUser",
      url: "account/me",
    });
  }

  if (hasToken && !!window.AppStore.getters["Users/currentUser"]) {
    // Authenticated users have full access
    next();
    return;
  }

  /** * Bouncer: Send any unauthenticated users to Logout ***/
  next({ name: "auth.logout" });
};

const checkEntityParam = function (to, from, next) {
  const isCMSRoute = !!(to.path.indexOf("/admin") === 0);

  const excludedRoutes = [
    "unauthorized",
    "notfound",
    "auth.login",
    "auth.logout",
    "auth.request-access",
    "auth.contact",
    "auth.reset",
    "auth.forgot",
    "auth.mfa",
  ];

  if (isCMSRoute || excludedRoutes.includes(to.name)) {
    next();
    return;
  }

  let currentUser = window.AppStore.getters["Users/currentUser"];
  let canUseEntities =
    window.AppStore.getters["Settings/beamFeatures"].entities === "published" &&
    window.AppStore.state.Client.theme.theme !== "legacy";

  /*
   * on initial page load starting entity logic has to be added to either chose the user’s current entity if set in
   * database or final the highest ranked entity in entities list to load as the initial entity
   */

  if (canUseEntities) {
    const currentEntityName =
      window.AppStore.state.Entities?.currentEntity?.name ?? null;
    const entities = window?.AppStore?.state?.Entities?.entities ?? [];
    const entityName =
      to?.params?.entity ?? from?.params?.entity ?? currentEntityName;

    let firstEntity =
      window.AppStore.getters["Entities/activeEntitiesRanked"][0];
    let currentEntity = entities.find(
      (ent) => ent.id === window.AppStore.state.Users.currentUser.entity_id
    );
    currentEntity = currentEntity ?? firstEntity;

    // Update current entity when the entity in the URL changes
    if (entityName !== currentEntityName) {
      const entity = entities.find((ent) => ent.name === entityName);
      currentEntity = entity ? entity : firstEntity;

      if (currentUser && currentUser.entity_id) {
        currentEntity = window.AppStore.getters["Entities/activeEntities"].find(
          (item) => item.id === currentUser.entity_id
        );
      }

      window.AppStore.commit("Entities/SET_PROPERTY", {
        collection: "currentEntity",
        data: currentEntity,
      });
    }
    if (
      currentUser &&
      currentUser.entity_id &&
      typeof currentEntity !== "undefined"
    ) {
      switchEntity(currentEntity, to, next);
      return;
    } else if (firstEntity) {
      switchEntity(firstEntity, to, next);
      return;
    }
  }

  next();
};

const checkLocaleParam = function (to, from, next) {
  let locale = null;
  // if a locale is specified in the URL, it overrides everything else:
  if (!!to.params.locale) {
    // check to route for a locale parameter
    locale = to.params.locale;
  } else if (!!from.params.locale) {
    // check from route for a locale parameter
    locale = from.params.locale;
  }

  if (locale) {
    if (locale !== window.AppI18n.locale) {
      // update plugin locale
      window.AppI18n.locale = locale;
    }
    next();
    return;
  }

  // if no locale is included in the URL, check the user's preferences
  if (!locale) {
    const currentUser = window.AppStore.getters["Users/currentUser"];
    const getActiveLocaleById =
      window.AppStore.getters["Locales/getActiveLocaleById"];
    const userLocale = getActiveLocaleById(currentUser.locale_id);
    locale = userLocale?.locale ?? null;
  }

  // if user doesn't have a default locale, look for the locale cookie
  if (!locale) {
    locale = window.CookieManager.getCookie("locale");
  }

  // if the locale does not match the default, add the locale param to the route
  if (locale && locale !== window.AppI18n.fallbackLocale) {
    let route = parseRoute(to);
    // update locale for route param and plugin
    window.AppI18n.locale = locale;
    route.params.locale = locale;
    next(route);
    return;
  }

  next();
};

const switchEntity = function (entity, to, next) {
  if (
    to.params.hasOwnProperty("entity") &&
    entity.name !== to.params.entity &&
    !to.params.entity
  ) {
    to.params.entity = entity.name;

    window.AppStore.commit("Entities/SET_PROPERTY", {
      collection: "currentEntity",
      data: entity,
    });
    next(to);
  } else {
    next();
  }
};

const importCss = function (to) {
  const entity = to?.params?.entity ?? null;
  if (!entity) {
    import("@client/scss/entry/index.scss");
  } else {
    import(`@client/scss/entry/${entity}.scss`).catch((err) => {
      // eslint-disable-next-line no-console
      console.log("Stylesheet not found: ", err);
      import("@client/scss/entry/index.scss");
    });
  }
};

const checkMfaAccess = function (to, from, next) {
  const mfaToken = window.CookieManager.getCookie("mfa");
  const isMFACompleted = !!mfaToken;

  if (isMFACompleted) {
    next();
  } else {
    next({ name: "auth.login" });
  }
};

export {
  checkLogout,
  checkEntityParam,
  checkLocaleParam,
  checkEnvironment,
  checkAuthentication,
  checkMfaAccess,
  importCss,
};
