var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      staticStyle: { "enable-background": "new 0 0 15 20" },
      attrs: {
        role: "presentation",
        version: "1.1",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
        x: "0px",
        y: "0px",
        viewBox: "0 0 15 20",
        "xml:space": "preserve",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M9.4,0C5.1,2.5,5.6,9.4,5.6,9.4S3.8,8.8,3.8,5.9C1.5,7.2,0,9.7,0,12.5C0,16.6,3.4,20,7.5,20s7.5-3.4,7.5-7.5\n  C15,6.4,9.4,5.2,9.4,0z M8.2,17.4c-1.5,0.4-3-0.5-3.4-2s0.5-3,2-3.4c3.6-0.9,4.1-3,4.1-3S12.7,16.3,8.2,17.4z",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }