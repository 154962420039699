export default {
  install(Vue) {
    Vue.mixin({
      computed: {
        _isBookmarksPage() {
          return (
            this.$route.name && this.$route.name.indexOf("bookmarks.") === 0
          );
        },
        _isCollectionsPage() {
          return (
            this.$route.name &&
            (this.$route.name.indexOf("lightbox.") === 0 ||
              this.$route.name.indexOf("collection.") === 0)
          );
        },
        _isContentPage() {
          return this.$route.name && this.$route.name.indexOf("content.") === 0;
        },
        _isDxgPage() {
          return this.$route.name && this.$route.name.indexOf("dxg.") === 0;
        },
        _isHelpdeskPage() {
          return (
            this.$route.name && this.$route.name.indexOf("helpdesk.") === 0
          );
        },
        _isProfilePage() {
          return this.$route.name && this.$route.name.indexOf("profile.") === 0;
        },
        _isSearchPage() {
          return this.$route.name && this.$route.name.indexOf("search.") === 0;
        },
        _isShowcasePage() {
          return (
            this.$route.name && this.$route.name.indexOf("showcase.") === 0
          );
        },
        _isAssetPage() {
          return (
            this.$route.name && this.$route.name.indexOf("asset-viewer.") === 0
          );
        },
      },
    });
  },
};
