var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "module quicklinks-module" },
    [
      _vm._isAdminArea
        ? _c("module-controls", {
            attrs: {
              placeholder: "Quicklinks",
              "action-buttons": _vm._actionButtons,
            },
            on: {
              "on-edit": _vm.$openModal,
              "on-remove": _vm.$deleteContentModuleConfirm,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("quicklinks-display", { attrs: { "component-data": _vm.moduleData } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }