var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.sections.length > 0
    ? _c(
        "ul",
        {
          directives: [
            {
              name: "scroll",
              rawName: "v-scroll",
              value: _vm.activeAtScroll,
              expression: "activeAtScroll",
            },
          ],
          staticClass: "nav-anchors list-unstyled",
        },
        [
          _vm._l(_vm.sections, function (section) {
            return [
              _c("li", { key: section.id, staticClass: "nav-anchor" }, [
                _c(
                  "span",
                  {
                    class: { active: _vm.isActive === section.id },
                    attrs: { hash: section.title },
                    on: {
                      click: function ($event) {
                        return _vm.scrollMeTo(section.id)
                      },
                    },
                  },
                  [_vm._v(_vm._s(section.title))]
                ),
              ]),
            ]
          }),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }