import Vue from "vue";
import Vuex from "vuex";

import AssetArchives from "@core/store/modules/asset-archives";
import AssetQueue from "@core/store/modules/asset-queue";
import AssetTracking from "@core/store/modules/asset-tracking";
import AssetPermissions from "@core/store/modules/asset-permissions";
import Assets from "@core/store/modules/assets";
import Bookmarks from "@core/store/modules/bookmarks";
import Client from "@core/store/modules/client";
import Config from "@core/store/modules/config";
import Connections from "@core/store/modules/connections";
import Content from "@core/store/modules/content";
import Dxg from "@core/store/modules/digital-experience-guide";
import Entities from "@core/store/modules/entities";
import Filters from "@core/store/modules/filters";
import Geography from "@core/store/modules/geography";
import Global from "@core/store/modules/global";
import Groups from "@core/store/modules/groups";
import HomepagePopularItems from "@core/store/modules/homepage-popular-items";
import InteriorPageTitle from "@core/store/modules/interior-page-title";
import JWT from "@core/store/modules/jwt";
import Libraries from "@core/store/modules/libraries";
import Lightboxes from "@core/store/modules/lightboxes";
import Locales from "@core/store/modules/locales";
import MenuItems from "@core/store/modules/menu-items";
import MFA from "@core/store/modules/mfa";
import MobileMenu from "@core/store/modules/mobile-menu";
import NavigationMenuItems from "@core/store/modules/navigation-menu-items";
import Reports from "@core/store/modules/reports";
import Roles from "@core/store/modules/roles";
import Search from "@core/store/modules/search";
import Settings from "@core/store/modules/settings";
import SubscriberEmails from "@core/store/modules/subscriber-emails";
import Tags from "@core/store/modules/tags";
import Theme from "@core/store/modules/theme";
import Translations from "@core/store/modules/translations";
import Users from "@core/store/modules/users";
import Notifications from "@core/store/modules/notifications";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    AssetArchives,
    AssetQueue,
    AssetTracking,
    AssetPermissions,
    Assets,
    Bookmarks,
    Client,
    Config,
    Connections,
    Content,
    Dxg,
    Entities,
    Filters,
    Geography,
    Global,
    Groups,
    HomepagePopularItems,
    InteriorPageTitle,
    JWT,
    Libraries,
    Lightboxes,
    Locales,
    MenuItems,
    MFA,
    MobileMenu,
    NavigationMenuItems,
    Notifications,
    Reports,
    Roles,
    Search,
    Settings,
    SubscriberEmails,
    Tags,
    Theme,
    Translations,
    Users,
  },
});
