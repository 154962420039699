
import { mapState } from 'vuex'
import SingleToaster from '@core/components/global/app-toaster/SingleToaster.vue'

export default {
  name: 'AppToaster',
  components: {
    SingleToaster
  },
  data () {
    return {
      toasts: []
    }
  },
  computed: {
    ...mapState({
      errors: state => state.Global.errors,
      success: state => state.Global.success,
      info: state => state.Global.info
    })
  },
  watch: {
    errors (errorToast) {
      if (errorToast) {
        this.formatErrorToasts(errorToast)
      }
    },
    success (successToast) {
      if (successToast) {
        this.formatSuccessToasts(successToast)
      }
    },
    info (infoToast) {
      if (infoToast) {
        this.formatInfoToasts(infoToast)
      }
    }
  },
  methods: {
    /**
     * @public
     * @param {string} variant error or success
     * @param {string} message message to be displayed in toast body
     */
    async pushToast (variant, message) {
      const data = {
        id: this.$utilities.randomID(),
        variant: variant,
        title: variant === 'success' ? 'Success' : (variant === 'error' ? 'Error' : message.title),
        message: variant !== 'info' ? message : message.message
      }
      /**
       * @note reset the store before showing toast to support multiple toasts with same message
       * For example, adding a page to favorites, then adding another page to favorites. Both trigger
       * "Bookmark created" messages so we need to change the value in store so the watchers fire.
       */
      if (variant === 'success') {
        await this.$store.commit('Global/SET_SUCCESS', null)
      } else if (variant === 'error') {
        await this.$store.commit('Global/SET_ERRORS', null)
      } else {
        await this.$store.commit('Global/SET_INFO', null)
      }
      this.toasts.push(data)
    },
    /**
     * @public
     * @param {string} toast current success message in store
     */
    formatSuccessToasts (successToast) {
      this.pushToast('success', successToast)
    },
    formatInfoToasts (infoToast) {
      this.pushToast('info', infoToast)
    },
    /**
     * @public
     * @param {*} toast current error message in store
     * @description format toast based on data type
     */
    formatErrorToasts (errorToast) {
      if (typeof errorToast !== 'string') {
        if (errorToast.message && typeof errorToast.message === 'object') {
          const messages = Object.values(errorToast.message)

          for (let i = 0; i < messages.length; i++) {
            let inputMsg = messages[i].toString()
            let errorMsg =
              inputMsg.charAt(0).toUpperCase() + inputMsg.substring(1)
            this.pushToast('error', errorMsg)
          }
        } else if (
          errorToast.message &&
          typeof errorToast.message === 'string'
        ) {
          this.pushToast('error', errorToast.message)
        } else {
          this.pushToast('error', 'Something went wrong.')
        }
      } else {
        this.pushToast('error', errorToast)
      }
    },
    removeToaster (id) {
      this.toasts.splice(this.toasts.findIndex(item => item.id === id), 1)
    }
  }
}
