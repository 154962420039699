var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "module salesforce-carousel-module" },
    [
      _c("module-controls", {
        attrs: {
          placeholder: "Carousel",
          "action-buttons": _vm._actionButtons,
        },
        on: { "on-edit": _vm.onEdit, "on-remove": _vm.onRemove },
      }),
      _vm._v(" "),
      _c("homepage-carousel", {
        attrs: { id: "homepage_slider", "carousel-data": _vm.moduleData },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }