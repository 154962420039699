import Directives from './modules/Directives'
import Filters from './modules/Filters'
import Services from './modules/Services'
import Utilities from './modules/Utilities'

export default {
  install (Vue) {
    Directives.install(Vue)
    Filters.install(Vue)
    Services.install(Vue)
    Utilities.install(Vue)
  }
}
