import actions from '../actions/actions'
import mutations from '../mutations/mutations'

export default {
  namespaced: true,
  state: {
    results: [],
    assets: [],
    content: [],
    showcases: [],
    libraries: [],
    selection: {
      id: false,
      data: false
    },
    filterParameters: {
      bulkUpload: null,
      keyword: null,
      order: 'relevant',
      tag: null,
      tags: null
    },
    searchParameters: {
      category: null,
      type: 'assets',
      term: '',
      order: 'DESC',
      match: 'relevance',
      keywords: null
    },
    pagination: {
      items: 0,
      page: 1,
      per_page: 12
    },
    assets_pagination: {
      items: 0,
      page: 1,
      per_page: 12
    },
    content_pagination: {
      items: 0,
      page: 1,
      per_page: 12
    },
    showcases_pagination: {
      items: 0,
      page: 1,
      per_page: 12
    },
    isLoading: false,
    view: 'list'
  },
  mutations,
  actions
}
