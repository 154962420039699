import CoreRoutes from './routes/core'
import PowerPoints from '@core/plugins/PPTGenerator/store/power-points'
import ClientConfig from '@client/config'

import { RouteUtils } from '@core/js'

export default {
  install (Vue, options) {
    const hasPPT = ClientConfig?.extensions?.pptGenerator ?? null

    if (hasPPT) {
      if (!options) {
        throw new Error('The PPT Extension requires an options object!')
      }

      if (!options.router) {
        throw new Error('The PPT Extension requires a router option!')
      }

      if (!options.env) {
        throw new Error('The PPT Extension requires an env (cms|core) option!')
      }

      window.AppStore.registerModule('PowerPoints', PowerPoints)

      // Add routes to router
      const DxgRoutes = CoreRoutes

      let params = RouteUtils.getRouteParams(options.router.getRoutes())

      DxgRoutes.forEach(route => {
        route = RouteUtils.standardizePluginRoutePath(route, params)
        options.router.addRoute(route)
      })
    }
  }
}
