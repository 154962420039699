var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        role: "presentation",
        width: "15px",
        height: "15px",
        viewBox: "0 0 15 15",
        version: "1.1",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
      },
    },
    [
      _c(
        "g",
        {
          attrs: {
            stroke: "none",
            "stroke-width": "1",
            fill: "none",
            "fill-rule": "evenodd",
          },
        },
        [
          _c(
            "g",
            { attrs: { transform: "translate(-157.000000, -11.000000)" } },
            [
              _c("g", [
                _c(
                  "g",
                  { attrs: { transform: "translate(132.000000, 0.000000)" } },
                  [
                    _c("g", [
                      _c(
                        "g",
                        {
                          attrs: {
                            transform: "translate(22.000000, 8.000000)",
                          },
                        },
                        [
                          _c("g", [
                            _c("rect", {
                              attrs: {
                                x: "0",
                                y: "0",
                                width: "20",
                                height: "20",
                              },
                            }),
                            _vm._v(" "),
                            _c("path", {
                              attrs: {
                                d: "M8.58925565,11.577411 C8.91469256,11.9028479 8.91469256,12.4304854 8.58925565,12.7559223 C8.26381874,13.0813592 7.73618126,13.0813592 7.41074435,12.7559223 L7.41074435,12.7559223 L3.24407768,8.58925565 C3.23997819,8.58515616 3.23593033,8.58102457 3.23193412,8.57686171 C3.21505689,8.55928782 3.1986405,8.54060331 3.18307397,8.52120749 C3.17571252,8.51211776 3.16848697,8.50272487 3.16148887,8.49321703 C3.14780662,8.47448516 3.13510219,8.45562629 3.1231692,8.43624175 C3.11629704,8.42521302 3.10978544,8.41405068 3.10355497,8.40277456 C3.03764526,8.28359352 3,8.14619302 3,8 C3,7.85380698 3.03764526,7.71640648 3.1037708,7.59696349 C3.11189752,7.58212679 3.12074414,7.56723213 3.13009482,7.55256613 C3.13989232,7.5373211 3.15032552,7.52208513 3.16123972,7.50722441 C3.18618576,7.47322901 3.21371536,7.44110667 3.24407768,7.41074435 L3.18629004,7.47481029 C3.20089691,7.45683639 3.21623767,7.4394829 3.23226667,7.42279546 L3.24407768,7.41074435 L7.41074435,3.24407768 C7.73618126,2.91864077 8.26381874,2.91864077 8.58925565,3.24407768 C8.91469256,3.56951459 8.91469256,4.09715207 8.58925565,4.42258898 L8.58925565,4.42258898 L5.845,7.166 L13.8333333,7.16666667 C16.0647869,7.16666667 17.8865405,8.92080049 17.9949007,11.1253746 L18,11.3333333 L18,17.1666667 C18,17.626904 17.626904,18 17.1666667,18 C16.7064294,18 16.3333333,17.626904 16.3333333,17.1666667 L16.3333333,17.1666667 L16.3333333,11.3333333 C16.3333333,9.95262146 15.2140452,8.83333333 13.8333333,8.83333333 L13.8333333,8.83333333 L5.844,8.833 Z",
                                fill: "#75A3B3",
                                "fill-rule": "nonzero",
                                transform:
                                  "translate(10.500000, 10.500000) scale(1, -1) translate(-10.500000, -10.500000) ",
                              },
                            }),
                          ]),
                        ]
                      ),
                    ]),
                  ]
                ),
              ]),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }