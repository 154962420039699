import actions from '../actions/actions'
import mutations from '../mutations/mutations'

const fallbackLocale = {
  id: 1,
  locale: 'en',
  label: 'English',
  order: 0,
  active: 1,
  published: 1,
  locked: 1
}

export default {
  namespaced: true,
  state: {
    locales: [],
    filterParameters: null,
    searchParameters: null,
    isLoading: false,
    newElementCreated: false
  },
  mutations,
  actions,
  getters: {
    activeLocales: state => {
      return state.locales.filter(locale => !!locale.active)
    },
    publishedLocales: state => {
      return state.locales.filter(locale => !!locale.published)
    },
    getActiveLocaleById: (state) => (id = 0) => {
      const ID = parseInt(id, 10)
      return (!isNaN(ID)) ? state.locales.find(item => {
        return (!!item.active && ID === parseInt(item.id, 10))
      }) : false
    },
    getActiveLocaleByLocale: (state) => (locale = 'en') => {
      return state.locales.find(item => {
        return (!!item.active && locale === item.locale)
      })
    },
    getUserLocale: (state, getters, rootState) => {
      let user = rootState.Users.currentUser

      if (user && user.locale_id) {
        return getters.getActiveLocaleById(user.locale_id)
      }
    },
    defaultLocale: state => {
      return state.locales.find(locale => 1 === locale.id) ?? fallbackLocale
    }
  }
}
