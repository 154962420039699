var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        role: "presentation",
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 72 60",
        "enable-background": "new 0 0 72 60",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M27.5 25.3l8.5 5.9 8.5-5.9h-17zm-.7 1.7v8.5h18.4v-8.5l-8.7 6.1c-.3.2-.6.2-.9.1l-.2-.1-8.6-6.1zm-.8-3.5h20c.6 0 1 .5 1 1v11.8c0 .6-.5 1-1 1h-20c-.6 0-1-.5-1-1v-11.8c0-.5.5-1 1-1zm10-5.5c.4 0 .8.3.8.8v2c0 .4-.3.8-.8.8s-.8-.3-.8-.8v-2c0-.5.4-.8.8-.8zm4.9 1.7c.4.2.5.6.4 1l-.2.5c-.2.4-.6.5-1 .4s-.5-.6-.4-1l.2-.5c.2-.4.6-.6 1-.4zm-9.8 0c.4-.2.8 0 1 .4l.2.5c.2.4 0 .8-.4 1s-.8 0-1-.4l-.2-.5c-.2-.4 0-.8.4-1zm4.9 22.9c-.4 0-.8-.3-.8-.8v-2c0-.4.3-.8.8-.8s.8.3.8.8v2c0 .5-.4.8-.8.8zm4.9-1.7c-.4.2-.8 0-1-.4l-.2-.5c-.2-.4 0-.8.4-1s.8 0 1 .4l.2.5c.2.4 0 .8-.4 1zm-9.8 0c-.4-.2-.5-.6-.4-1l.2-.5c.2-.4.6-.5 1-.4s.5.6.4 1l-.2.5c-.2.4-.6.6-1 .4z",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }