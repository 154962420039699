import * as types from '../mutations/mutation-types'
export const actionsHelpers = {

  /**
   * @note consider replacing with nanoId
   * @link https://www.npmjs.com/package/nanoid
   * @returns random id
   */

  randomID: function () {
    let text = ''
    const initial = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'
    const initialLength = initial.length
    const seed = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    const seedLength = seed.length
    for (let i = 0; i < 8; i++) {
      // javascript doesn't like IDs that begin with numbers
      if (i > 0) {
        text += seed.charAt(Math.floor(Math.random() * seedLength))
      } else {
        text += initial.charAt(Math.floor(Math.random() * initialLength))
      }
    }

    return text
  },

  /**
   *
   * sets loading status
   *
   * @todo tie to specific component / callee rather than global.
   * we may not always want to show global loading overlay
   *
   * @param {*} context
   * @param {*} id random id generated at beginning of action call
   * @param {Boolean} loader
   */
  initStatus: function (context, id, loader) {
    if (loader !== false) {
      context.commit(`Global/${types.SET_LOADING_STATUS}`, {
        id: id,
        status: true
      }, {
        root: true
      })
    }
  },
  /**
   *
   * @param {*} context
   * @param {*} id random id generated at beginning of action call
   */
  unsetLoadingStatus: function (context, id) {
    context.commit(`Global/${types.SET_LOADING_STATUS}`, {
      id: id,
      status: false
    }, {
      root: true
    })
  },
  /**
   *
   * takes the error from the API and commits
   * message which triggers a toast
   *
   * @param {*} error message from the API response
   * @param {*} context
   */
  handleError: function (error, data, context) {
    const errorMessage = error?.response?.data?.error ?? false

    if (errorMessage) {
      context.commit(`Global/${types.SET_ERRORS}`, errorMessage, {
        root: true
      })
    }
  },
  /**
   *
   * takes the success message from the API, commits
   * and triggers a toast
   *
   * @param {*} context
   * @param {*} data argument provided to dispatch {collection:'foo', url:'bar'}
   * @param {*} message success message from api response
   *
   * @todo remove if's. consolidate into one commit. coordinate api responses.
   *
   */
  handleMessage: function (context, data, message) {
    const toasterMessage = data.message
      ? data.message
      : message
    if (toasterMessage) {
      context.commit(`Global/${types.SET_SUCCESS}`, toasterMessage, {
        root: true
      })
    }
  }
}
