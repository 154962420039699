import * as types from '../mutations/mutation-types'
import
{ actionsHelpers }
  from './actions-helpers'
import {
  getResource,
  getNestedResource,
  createResource,
  updateResource,
  patchResource,
  deleteResource
} from '@core/store/services'

function pruneObject (obj = {}) {
  let result = {}

  for (const [key, value] of Object.entries(obj)) {
    if (!!value) {
      if (typeof value !== 'object') {
        result[key] = value
      } else {
        if (Object.keys(value).length) {
          result[key] = pruneObject(value)
        }
      }
    }
  }

  return (Object.keys(result).length) ? result : undefined
}

export default {
  async getProperty (context, data) {
    const id = actionsHelpers.randomID()

    actionsHelpers.initStatus(context, id, data.loader)

    if (data.params) {
      data.params = JSON.parse(JSON.stringify(data.params))
    } else {
      data.params = {}
    }

    /**
     * @temp wrap these in an if to avoid setting in tests...
     */

    // Merge
    if (context.state) {
      data.params.pagination = {...data.params.pagination, ...context.state.pagination}
      data.params.queryParameters = {...data.params.queryParameters, ...context.state.queryParameters}
      data.params.searchParameters = context.state.searchParameters || null
      data.params.filterParameters = {...data.params.filterParameters, ...context.state.filterParameters}
    }

    try {
      const result = await getResource(data)

      context.commit(types.SET_PROPERTY, {
        collection: data.collection,
        data: result[data.element || data.collection]
      })

      if (result.pagination) {
        context.commit(types.SET_PROPERTY, {
          collection: 'pagination',
          data: result.pagination
        })
      }

      if (result.libraries) {
        context.commit(types.SET_PROPERTY, {
          collection: 'libraries',
          data: result.libraries
        })
      }

      if (result.stats) {
        context.commit(types.SET_PROPERTY, {
          collection: 'stats',
          data: result.stats
        })
      }

      // combine calls for assets and pinned assets
      if (result.pinned_assets) {
        context.commit(types.SET_PROPERTY, {
          collection: 'pinned_assets',
          data: result.pinned_assets
        })
      }
    } catch (error) {
      actionsHelpers.handleError(error, data, context, id)
      return error
    } finally {
      actionsHelpers.unsetLoadingStatus(context, id)
    }
  },
  async getNestedProperty (context, data) {
    const id = actionsHelpers.randomID()

    actionsHelpers.initStatus(context, id, data.loader)

    try {
      const result = await getNestedResource(data.url)

      context.commit(types.SET_NESTED_PROPERTY, {
        collection: data.collection,
        data: result[data.element]
      })

      actionsHelpers.handleMessage(context, data, result.message)
    } catch (error) {
      actionsHelpers.handleError(error, data, context, id)
      return error
    } finally {
      actionsHelpers.unsetLoadingStatus(context, id)
    }
  },
  async createProperty (context, data) {
    const id = actionsHelpers.randomID()

    actionsHelpers.initStatus(context, id, data.loader)

    Object.keys(data.data).forEach(key => {
      data.data[key] === null && delete data.data[key]
    })

    try {
      const result = await createResource(data)

      context.commit(types.SET_PROPERTY, {
        collection: data.collection,
        data: result[data.element || data.collection]
      })

      context.commit(types.SET_PROPERTY_CREATED, result[data.element].id)

      actionsHelpers.handleMessage(context, data, result.message)
    } catch (error) {
      actionsHelpers.handleError(error, data, context, id)
      return error
    } finally {
      actionsHelpers.unsetLoadingStatus(context, id)
    }
  },
  async createNestedProperty (context, data) {
    const id = actionsHelpers.randomID()

    actionsHelpers.initStatus(context, id, data.loader)

    Object.keys(data.data).forEach((key) => {
      data.data[key] === null && delete data.data[key]
    })

    try {
      const result = await createResource(data)

      context.commit(types.SET_NESTED_PROPERTY, {
        collection: data.collection,
        data: result[data.element]
      })

      context.commit(types.SET_PROPERTY_CREATED, result[data.element].id)
      actionsHelpers.handleMessage(context, data, result.message)
    } catch (error) {
      actionsHelpers.handleError(error, data, context, id)
      return error
    } finally {
      actionsHelpers.unsetLoadingStatus(context, id)
    }
  },
  async updateProperty (context, data) {
    const id = actionsHelpers.randomID()

    actionsHelpers.initStatus(context, id, data.loader)

    if (Array.isArray(data.data)) {
      data.data.forEach((element) => {
        Object.keys(element)
          .forEach((key) => {
            element[key] === null && delete element[key]
          })
      })
    } else if (typeof data.data === 'object') {
      Object.keys(data.data)
        .forEach((key) => {
          data.data[key] === null && delete data.data[key]
        })
    }

    try {
      const result = await updateResource(data)

      context.commit(types.SET_PROPERTY, {
        collection: data.collection,
        data: result[data.element || data.collection]
      })

      actionsHelpers.handleMessage(context, data, result.message)
    } catch (error) {
      actionsHelpers.handleError(error, data, context, id)
      return error
    } finally {
      actionsHelpers.unsetLoadingStatus(context, id)
    }
  },
  async updateNestedProperty (context, data) {
    const id = actionsHelpers.randomID()

    actionsHelpers.initStatus(context, id, data.loader)

    Object.keys(data.data).forEach((key) => {
      data.data[key] === null && delete data.data[key]
    })

    try {
      const result = await updateResource(data)

      if (data.collection && data.element) {
        context.commit(types.SET_NESTED_PROPERTY, {
          collection: data.collection,
          data: result[data.element]
        })
      }

      actionsHelpers.handleMessage(context, data, result.message)
    } catch (error) {
      actionsHelpers.handleError(error, data, context, id)
      return error
    } finally {
      actionsHelpers.unsetLoadingStatus(context, id)
    }
  },
  async patchProperty (context, data) {
    const id = actionsHelpers.randomID()
    actionsHelpers.initStatus(context, id, data.loader)
    try {
      const result = await patchResource(data)
      context.commit(types.SET_PROPERTY, {
        collection: data.collection,
        data: result[data.element || data.collection]
      })
      actionsHelpers.handleMessage(context, data, result.message)
    } catch (error) {
      actionsHelpers.handleError(error, data, context, id)
      return error
    } finally {
      actionsHelpers.unsetLoadingStatus(context, id)
    }
  },
  async patchNestedProperty (context, data) {
    const id = actionsHelpers.randomID()

    actionsHelpers.initStatus(context, id, data.loader)

    Object.keys(data.data).forEach((key) => {
      data.data[key] === null && delete data.data[key]
    })

    try {
      const result = await patchResource(data)
      context.commit(types.SET_NESTED_PROPERTY, {
        collection: data.collection,
        data: result[data.element]
      })
      actionsHelpers.handleMessage(context, data, result.message)
    } catch (error) {
      actionsHelpers.handleError(error, data, context, id)
      return error
    } finally {
      actionsHelpers.unsetLoadingStatus(context, id)
    }
  },
  async deleteProperty (context, data) {
    const id = actionsHelpers.randomID()
    actionsHelpers.initStatus(context, id, data.loader)

    try {
      const result = await deleteResource(data.url)
      context.commit(types.DELETE_PROPERTY, data)
      actionsHelpers.handleMessage(context, data, result.message)
    } catch (error) {
      actionsHelpers.handleError(error, data, context, id)
      return error
    } finally {
      actionsHelpers.unsetLoadingStatus(context, id)
    }
  },
  async deleteNestedProperty (context, data) {
    const id = actionsHelpers.randomID()

    actionsHelpers.initStatus(context, id, data.loader)

    try {
      const result = await deleteResource(data.url)
      actionsHelpers.handleMessage(context, data, result.message)
      data.response = result
      context.commit(types.DELETE_NESTED_PROPERTY, data)
    } catch (error) {
      actionsHelpers.handleError(error, data, context, id)
      return error
    } finally {
      actionsHelpers.unsetLoadingStatus(context, id)
    }
  },
  setProperty (context, data) {
    context.commit(types.SET_PROPERTY, {
      collection: data.collection,
      data: data.data
    })
  },
  setNestedProperty (context, data) {
    context.commit(types.SET_NESTED_PROPERTY, {
      collection: data.collection,
      data: data.data
    })
  }
}
