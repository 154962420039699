var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        role: "presentation",
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 20 20",
        "enable-background": "new 0 0 20 20",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M0 10c0-5.5 4.5-10 10-10s10 4.5 10 10-4.5 10-10 10-10-4.5-10-10zm15.3.7c.4-.4.4-.9 0-1.3l-4.4-4.4c-.7-.7-1.8.3-1.1 1l3 3c.1.1.1.3-.1.3h-8.2c-.4 0-.8.3-.8.8 0 .4.3.8.8.8h8.2c.2 0 .3.1.1.3l-3 3c-.7.7.4 1.8 1.1 1.1 0-.2 4.4-4.6 4.4-4.6z",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }