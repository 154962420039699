/**
 * current query string keys:
 * "filters" (array of filter IDs),
 * "search" (asset library search term),
 * "section" (ID of desired scroll-to-headline on page, conflicts with "tab"),
 * "tab" (ID of desired scroll-to-tab on page, conflicts with "section"),
 * "term" (global search term)
 **/
import { mapState } from "vuex";

export default {
  install(Vue) {
    Vue.mixin({
      data() {
        return {
          queryObject: {
            filters: false,
            search: false,
            section: false,
            tab: false,
            term: false,
            page: false,
            per_page: false,
          },
        };
      },
      computed: {
        ...mapState({
          assetFilterParameters: (state) => state.Assets.filterParameters,
          assetSearchParameters: (state) => state.Assets.searchParameters,
          currentEntity: state => state.Entities.currentEntity,
          globalSearchParameters: (state) => state.Search.searchParameters,
        }),
        pagination() {
          const name = this.$route?.name ?? "";
          if (name.indexOf("content.") === 0) {
            return this.$store.state.Assets.pagination;
          }

          // this.$route.name === 'search.files'
          return this.$store.state.Search.pagination;
        },
        _getQueryFilters() {
          return this.queryObject.filters
            ? this.queryObject.filters.split(",")
            : [];
        },
        _getQueryPage() {
          return this.queryObject.page ? this.queryObject.page : false;
        },
        _getQueryPerPage() {
          return this.queryObject.per_page ? this.queryObject.per_page : false;
        },
        _getQuerySearch() {
          return this.queryObject.search ? this.queryObject.search : false;
        },
        _getQuerySection() {
          // To avoid conflict, do not return the section value if a tab query is present
          return this.queryObject.section && !this._getQueryTab
            ? this.queryObject.section
            : false;
        },
        _getQueryTab() {
          return this.queryObject.tab ? this.queryObject.tab : false;
        },
        _getQueryTerm() {
          return this.queryObject.term ? this.queryObject.term : false;
        },
      },
      methods: {
        $createQueryLink(type = 'asset-viewer') {
          this.queryObject.asset_id = false;

          const filters = this.$utilities.deepMerge(this.assetFilterParameters);
          // const search = this.$utilities.deepMerge(this.assetSearchParameters)
          const term = this.$utilities.deepMerge(this.globalSearchParameters);
          const pagination = this.$utilities.deepMerge(this.pagination);

          // Only add filters params if it's a content page
          const name = this.$route?.name ?? "";
          if (name.indexOf("content.") === 0) {
            this.queryObject.filters = filters.filters.length
              ? filters.filters.join(",")
              : false;
          }

          this.queryObject.term = term?.term ?? false;
          // this.queryObject.order = filters.order

          if (pagination) {
            const page = parseInt(pagination.page, 10);
            const per_page = parseInt(pagination.per_page, 10);
            this.queryObject.page = page !== 1 ? page : false;
            this.queryObject.per_page = per_page !== 12 ? per_page : false;
          }

          return this.$createUrl(type);
        },
        $querySeparator(url) {
          return url.indexOf("?") === -1 ? "?" : "&";
        },
        $createUrl(type = 'asset-viewer') {
          const entity = this.currentEntity?.name ?? null;
          const entitySlug = entity ? `/${entity}` : '';
          let url = ''

          if (type === 'asset-viewer') {
            url = encodeURI(window.location.origin + entitySlug + "/asset-viewer");
          } else {
            url = encodeURI(window.location.origin + window.location.pathname)

            for (const [key, val] of Object.entries(this.queryObject)) {
              if (val && val !== false) {
                url +=
                  this.$querySeparator(url) +
                  encodeURIComponent(key) +
                  "=" +
                  encodeURIComponent(val);
              }
            }
          }

          return url;
        },
        $initQueryData() {
          let routeQuery = this?.$route?.query ?? {};
          const query = this.$utilities.deepMerge(routeQuery);
          for (const [key, val] of Object.entries(query)) {
            if (val) {
              this.queryObject[key] = val;
            }
          }
        },
        $updateQueryData(key, value) {
          if (!key) {
            return false;
          } else if (key && !value) {
            this.queryObject[key] = false;
          } else {
            value = this.$utilities.deepMerge(value);
            if (Array.isArray(value)) {
              value = value.join(",");
            }
            this.queryObject[key] = value;
          }

          this.$setQueryString();
        },
        $setQueryString() {
          let url = this.$createUrl('reset');
          if (url && window.history.pushState) {
            window.history.pushState({ path: url }, "", url);
          }
        },
        $resetQueryObject() {
          this.queryObject = {
            asset_id: false,
            filters: false,
            search: false,
            section: false,
            tab: false,
            term: false,
            page: false,
            per_page: false,
          };
        },
        // Removes all params from URL
        $resetUrl() {
          this.$resetQueryObject();
          this.$setQueryString();
        },
      },
      created() {
        this.$initQueryData();
      },
    });
  },
};
