export default {
  path: 'roles',
  component: () => import(
    /* webpackChunkName: "admin-settings-roles-index" */
    '@cms/components/settings/roles/index.vue'),
  children: [
    {
      path: '',
      name: 'admin.roles.index',
      component: () => import(
        /* webpackChunkName: "admin-settings-roles-roles" */
        '@cms/components/settings/roles/roles.vue')
    },
    {
      path: ':id',
      name: 'admin.roles.role',
      component: () => import(
        /* webpackChunkName: "admin-settings-roles-role" */
        '@cms/components/settings/roles/role.vue')
    }
  ]
}
