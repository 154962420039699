import actions from '../actions/actions'
import * as types from '../mutations/mutation-types'
import mutations from '../mutations/mutations'

export default {
  namespaced: true,
  state: {
    cms: false,
    errors: null,
    filterParameters: null,
    formUpdated: {},
    globalNav: false,
    info: null,
    isLoading: {},
    language: 'en',
    modal: {
      type: '',
      data: {}
    },
    nav: false,
    navRedirects: [],
    preventModuleDrop: false,
    scrollTriggerAnimationTypes: ['None', 'Left to Right', 'Right to Left', 'Top to Bottom', 'Bottom to Top', 'Opacity'],
    searchParameters: null,
    strings: false,
    success: null,
    tracking: {}
  },
  mutations,
  getters: Object.assign({
    globalLoading: state => {
      if (state.isLoading) {
        for (let k in state.isLoading) {
          if (state.isLoading[k] === true) {
            return true
          }
        }
      }
      return false
    },
    globalLanguage: state => {
      return state?.language ?? 'en'
    }
  }),
  actions: Object.assign({
    setModal (context, data) {
      let _body = document.querySelector('body')
      if (data.type !== false) {
        if (_body.className.indexOf('no-scroll') < 0) {
          _body.classList.add('no-scroll')
        }
      } else {
        _body.classList.remove('no-scroll')
      }
      context.commit(types.SET_PROPERTY, {
        collection: 'modal',
        data: data
      })
    },
    setMessage (context, data) {
      let type = data.type ? data.type : 'SET_SUCCESS'
      let _set_success = `Global/${types[type]}`
      context.commit(_set_success, data.message, {
        root: true
      })
    }
  }, actions)
}
