import CmsRoutes from './routes/cms'
import CoreRoutes from './routes/core'
import HelpdeskFiles from '@core/plugins/HelpDesk/store/helpdesk-files'
import HelpdeskMessages from '@core/plugins/HelpDesk/store/helpdesk-messages'
import HelpdeskRequests from '@core/plugins/HelpDesk/store/helpdesk-requests'
import HelpdeskStatuses from '@core/plugins/HelpDesk/store/helpdesk-statuses'
import HelpdeskTopics from '@core/plugins/HelpDesk/store/helpdesk-topics'

import { RouteUtils } from '@core/js'

export default {
  install (Vue, options) {
    if (!options) {
      throw new Error('The Help Desk Extension requires an options object!')
    }

    if (!options.router) {
      throw new Error('The Help Desk Extension requires a router option!')
    }

    if (!options.env) {
      throw new Error('The Help Desk Extension requires an env (cms|core) option!')
    }

    // Register the Help Desk store module
    window.AppStore.registerModule('HelpdeskFiles', HelpdeskFiles)
    window.AppStore.registerModule('HelpdeskMessages', HelpdeskMessages)
    window.AppStore.registerModule('HelpdeskRequests', HelpdeskRequests)
    window.AppStore.registerModule('HelpdeskStatuses', HelpdeskStatuses)
    window.AppStore.registerModule('HelpdeskTopics', HelpdeskTopics)

    // Add routes to router
    const HelpDeskRoutes = (options.env === 'cms') ? CmsRoutes : CoreRoutes

    let params = RouteUtils.getRouteParams(options.router.getRoutes())

    HelpDeskRoutes.forEach(route => {
      route = RouteUtils.standardizePluginRoutePath(route, params)
      options.router.addRoute(route)
    })
  }
}
