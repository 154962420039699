import Vue from 'vue'

import { GetSanitizeConfig } from '@core/js'

import BootstrapVue from 'bootstrap-vue'

import ClientConfig from '@client/config'

import { FocusTrap } from 'focus-trap-vue'
import VSanitize from 'v-sanitize'
import VuejsDialog from 'vuejs-dialog'
import Vue2TouchEvents from 'vue2-touch-events'
import VueClipboard from 'vue-clipboard2'

// CMS plugins
import DuplicatePageModal from '@cms/components/modals/duplicate-page.vue'
import VueFlatPickr from 'vue-flatpickr-component'

import BeamPlugins from '@core/plugins'

// Initialize general plugins
const InitPlugins = (options) => {
  Vue.config.devtools = process.env.NODE_ENV === 'development'
  // Vue.config.devtools = false
  Vue.config.productionTip = false
  Vue.prototype.$themeConfig = ClientConfig.theme
  Vue.prototype.$themeSettings = ClientConfig.settings
  VueClipboard.config.autoSetContainer = true

  if (options.env === 'cms') {
    Vue.config.ignoredElements = ['i']
  }

  Vue.use(BootstrapVue)
  Vue.use(require('bootstrap-vue/dist/bootstrap-vue.common.min'))

  Vue.use(VSanitize, GetSanitizeConfig(options.env))

  Vue.use(Vue2TouchEvents)
  Vue.use(VueClipboard)
  Vue.use(VuejsDialog, {
    okText: 'Confirm',
    cancelText: 'Cancel',
    animation: 'fade'
  })

  // Custom Beam plugins
  Vue.use(BeamPlugins, options)

  Vue.component('focus-trap', FocusTrap)

  if (options.env === 'cms') {
    Vue.dialog.registerComponent('duplicate-page-modal', DuplicatePageModal)
    Vue.use(VueFlatPickr)
  }
}

export { InitPlugins }
