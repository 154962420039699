import actions from '../actions/actions'
import mutations from '../mutations/mutations'

export default {
  namespaced: true,
  state: {
    assets: [],
    pinned_assets: [],
    asset: '',
    asset_total: 0,
    contentAssets: [],
    pagination: {
      per_page: 12,
      page: 1,
      pages: 1,
      items: 0
    },
    filterParameters: {
      library: null,
      filters: [],
      order: 'newest',
      origin: '',
      entity: null,
      group: null,
      role: null
    },
    searchParameters: {
      term: null
    },
    searchParametersEachLibrary: {
      module_id: {}
    },
    currentUser: {
      assets: [],
      pagination: {
        per_page: 200,
        page: 1,
        pages: 1,
        items: 0
      },
      filterParameters: {
        library: null,
        filters: [],
        order: 'relevant'
      },
      searchParameters: {
        term: null
      }
    },
    selectedFiles: [],
    addReplaceFilters: 'add',
    uploads: [],
    roles: [],
    isLoading: false,
    newAssetCreated: false,
    view: 'list',
    bulk_fields_updated: [],
    bulkEditAssetConnections: []
  },
  getters: {
    getBulkFieldsUpdated: state => {
      const bulkFieldsUpdated = state.bulk_fields_updated
      const firstEditedAsset = state.bulk_edit_assets[0]

      let data = {}

      for (let i = 0; i < bulkFieldsUpdated.length; i++) {
        if (bulkFieldsUpdated[i].id === 'connections') {
          data['connections'] = []

          state.bulkEditAssetConnections.forEach(item => {
            if (!item.isNew || item.active) {
              data['connections'].push(item)
            }
          })
        } else {
          let item = firstEditedAsset[bulkFieldsUpdated[i].id]

          // Check if it's an array of objects, and if so, only return the id from the object
          if (Array.isArray(item) && typeof item[0] === 'object' && item[0] !== null && !Array.isArray(item[0])) {
            data[bulkFieldsUpdated[i].id] = item.map(i => {
              return i.id
            })
          } else {
            data[bulkFieldsUpdated[i].id] = item
          }
        }
      }

      return data
    },
    allAssets: state => {
      return [...state.assets, ...state.pinned_assets]
    },
    validateBulkEditAssetConnections: state => {
      const connections = state.bulkEditAssetConnections

      for (let i = 0; i < connections.length; i++) {
        if (connections[i].active && (!connections[i].external_id || !connections[i].external_location)) {
          return false
        }
      }

      return true
    }
  },
  mutations,
  actions
}
