import store from '@core/store'

export default {
  path: '/admin/content',
  name: 'admin.content.index',
  meta: { layout: 'admin' },
  component: () => import(
    /* webpackChunkName: "admin-menuitems-content" */
    '@cms/components/menuitems/MenuItemsContent.vue'),
  children: [
    {
      path: ':id',
      name: 'admin.content.page',
      meta: { pageTitle: 'Page Builder' },
      component: () => import(
        /* webpackChunkName: "admin-menuitems-building-blocks" */
        '@cms/components/menuitems/MenuItemsBuildingBlocks.vue')
    },
    {
      path: ':id/settings',
      name: 'admin.content.settings',
      meta: { pageTitle: 'Settings' },
      component: () => import(
        /* webpackChunkName: "admin-menuitems-settings" */
        '@cms/components/menuitems/MenuItemsSettings.vue')
    },
    {
      path: ':id/permissions',
      name: 'admin.content.permissions',
      meta: { pageTitle: 'Permissions' },
      beforeEnter (to, from, next) {
        const user = store.getters['Users/currentUser']
        const abilities = user.permissions.abilities
        const canManage = (!!abilities.homepages && abilities.homepages.manage) || (!!abilities.pages && abilities.pages.manage)

        if (canManage) {
          next()
        } else {
          next('/401')
        }
      },
      component: () => import(
        /* webpackChunkName: "admin-menuitems-permissions" */
        '@cms/components/menuitems/MenuItemsPermissions.vue')
    },
    {
      path: '/admin/content/*',
      name: 'admin.content.notfound',
      redirect: '/404'
    }
  ]
}
