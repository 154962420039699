export default {
  path: 'assets',
  name: 'assets.index',
  component: () => import(
    /* webpackChunkName: "main-assets-index" */
    '@core/components/assets/index.vue'),
  children: [
    {
      path: ':tag',
      name: 'assets.list',
      component: () => import(
        /* webpackChunkName: "main-assets-assets" */
        '@core/components/assets/assets.vue')
    },
    {
      path: 'info/:id',
      name: 'assets.info'
    },
    {
      path: 'asset-viewer',
      name: 'asset-viewer',
      component: () => import(
        '@core/components/assets/asset-viewer.vue'
      )
    }
  ]
}
