/** urlNodes returns an array of these possibilities
  * 0 => always empty
  * 1 => will be locale (optional) or entity
  * 2 => will be entity (optional), if locale exists
*/

const SetParameters = {
  entity () {
    const urlNodes = window.location.pathname.split('/')
    const entities = window.AppStore.getters['Entities/activeEntities']
    let currentEntity = null

    if (urlNodes.length) {
      urlNodes.splice(3)

      for (let i=0; i<entities.length; i++) {
        if (urlNodes.includes(entities[i].name)) {
          currentEntity = entities[i]
          break
        }
      }
    }

    if (currentEntity) {
      window.AppStore.commit('Entities/SET_PROPERTY', {
        collection: 'currentEntity',
        data: currentEntity
      })
    }
  },

  locale () {
    const urlNodes = window.location.pathname.split('/')
    const locales = window.AppStore.getters['Locales/activeLocales']

    // Overwrite default with locale in URL, if present
    if (locales.length && urlNodes.length) {
      urlNodes.splice(3)

      for (let i=0; i<locales.length; i++) {
        if (urlNodes.includes(locales[i].locale)) {
          window.AppI18n.locale = locales[i].locale
          window.CookieManager.setCookie('locale', locales[i].locale)

          return
        }
      }
    }

    // If there's no locale in URL, check for the user's default locale
    const currentUser = window.AppStore.getters['Users/currentUser']

    if (currentUser && currentUser.locale_id) {
      const getActiveLocaleById = window.AppStore.getters['Locales/getActiveLocaleById']
      const userLocale = getActiveLocaleById(currentUser.locale_id)

      if (userLocale && userLocale.locale) {
        window.AppI18n.locale = userLocale.locale
        window.CookieManager.setCookie('locale', userLocale.locale)

        return
      }
    }

    let cookieLocale = window.CookieManager.getCookie('locale')

    // If user's not logged in or doesn't have a default language, check for the cookie locale
    if (cookieLocale) {
      window.AppI18n.locale = cookieLocale

      return
    }

    window.CookieManager.setCookie('locale', window.AppI18n.locale)
  }
}

export { SetParameters }
