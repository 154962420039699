var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", [
      _c(
        "button",
        {
          staticClass: "cta-button full-width secondary download-btn",
          attrs: {
            type: "button",
            title: _vm.$t("site_actions_download_label"),
          },
          on: {
            click: function ($event) {
              !_vm._isAdminArea ? _vm.onDownload(_vm.file) : null
            },
          },
        },
        [
          _c("icon", { attrs: { id: _vm._download_get } }),
          _vm._v(
            "\n      " +
              _vm._s(_vm.$t("site_actions_download_label")) +
              "\n    "
          ),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _vm.canResize(_vm.file) && !_vm.overrideMobile
      ? _c("div", [
          _c(
            "button",
            {
              staticClass: "cta-button full-width secondary resize-btn",
              attrs: {
                type: "button",
                title: _vm.$t("site_actions_resize_label"),
              },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  !_vm._isAdminArea ? _vm.$onResize(_vm.file) : null
                },
              },
            },
            [
              _c("icon", { attrs: { id: "ic_create" } }),
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("site_actions_resize_label")) +
                  "\n    "
              ),
            ],
            1
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", [
      _vm.canEmail && _vm.currentUser
        ? _c(
            "button",
            {
              staticClass: "cta-button full-width secondary email-btn",
              attrs: {
                type: "button",
                title: _vm.$t("site_actions_share_label"),
              },
              on: {
                click: function ($event) {
                  !_vm._isAdminArea ? _vm.$onShare(_vm.file.id) : null
                },
              },
            },
            [
              _c("icon", { attrs: { id: _vm._share_view } }),
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("site_actions_share_label")) +
                  "\n    "
              ),
            ],
            1
          )
        : _vm._e(),
    ]),
    _vm._v(" "),
    _c("div", [
      _vm.canCollections && _vm.currentUser
        ? _c(
            "button",
            {
              staticClass: "cta-button full-width secondary collections-btn",
              attrs: {
                type: "button",
                title: _vm.$t("features_collections_actions_add_label"),
              },
              on: {
                click: function ($event) {
                  !_vm._isAdminArea ? _vm.$onLightbox(_vm.file.id) : null
                },
              },
            },
            [
              _c("icon", { attrs: { id: _vm._collections_add } }),
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("features_collections_general_label")) +
                  "\n    "
              ),
            ],
            1
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }