var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      staticStyle: { "enable-background": "new 0 0 20 18" },
      attrs: {
        role: "presentation",
        version: "1.1",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
        x: "0px",
        y: "0px",
        viewBox: "0 0 20 18",
        "xml:space": "preserve",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M19.5,2.6c-2.8-3.4-8.9-3.6-14,0c-5.1,3.5-7,9.3-4.3,12.8c2.7,3.6,8.8,3.3,14,0c2.7-1.7,3-2.7,2.8-3.6\n  c-0.2-1.2-1.4-1.2-1.6-2.8c-0.3-1.8,1-2.2,2.7-3.4C19.9,5,20.4,3.7,19.5,2.6z M4.5,15.4c-1,0-1.8-0.8-1.8-1.7c0-1,0.8-1.7,1.8-1.7\n  c1,0,1.8,0.8,1.8,1.7C6.3,14.6,5.5,15.4,4.5,15.4z M4.5,10.3c-1,0-1.8-0.8-1.8-1.7c0-1,0.8-1.7,1.8-1.7c1,0,1.8,0.8,1.8,1.7\n  C6.3,9.5,5.5,10.3,4.5,10.3z M5.9,4.4c0-1,0.8-1.7,1.8-1.7s1.8,0.8,1.8,1.7c0,1-0.8,1.7-1.8,1.7S5.9,5.4,5.9,4.4z M9.9,15.4\n  c-1,0-1.8-0.8-1.8-1.7c0-1,0.8-1.7,1.8-1.7s1.8,0.8,1.8,1.7C11.7,14.6,10.9,15.4,9.9,15.4z M13,5.2c-1,0-1.8-0.8-1.8-1.7\n  c0-1,0.8-1.7,1.8-1.7c1,0,1.8,0.8,1.8,1.7C14.8,4.4,14,5.2,13,5.2z",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }