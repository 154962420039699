import ComponentPermissions from "./ComponentPermissions.js";
import MosaicLayouts from "./MosaicLayouts.js";

export default {
  mixins: [ComponentPermissions, MosaicLayouts],
  props: {
    colId: [Number, String],
    colIndex: [Number, String],
    elementId: [Number, String],
    moduleData: [Array, Object],
    modId: [Number, String],
    rows: Array,
    rowId: [Number, String],
    sectionId: [Number, String],
    moduleName: String,
    moduleLabel: String,
  },
  computed: {
    id() {
      return this.moduleData ? this.moduleData.id : false;
    },
    _userCanCreate() {
      return (
        (this._isHomepageAdminRoute && this.$userCan("homepages.create")) ||
        (!this._isHomepageAdminRoute && this.$userCan("pages.create"))
      );
    },
    _userCanDelete() {
      return (
        (this._isHomepageAdminRoute && this.$userCan("homepages.delete")) ||
        (!this._isHomepageAdminRoute && this.$userCan("pages.delete"))
      );
    },
    _userCanUpdate() {
      return (
        (this._isHomepageAdminRoute && this.$userCan("homepages.update")) ||
        (!this._isHomepageAdminRoute && this.$userCan("pages.update"))
      );
    },
    _isAutoEditBlock() {
      return (
        this.moduleData &&
        this.moduleData.dropped &&
        typeof this.onEdit === "function"
      );
    },
    _preventDrop() {
      // flag set in store in $preventBlockDrop function
      // @core/plugins/PageBuilder/mixins/MosaicLayouts.js
      return this.$store.state.Global.preventModuleDrop;
    },
    row() {
      /* This allows the _isMosaic property (mosaicLayouts mixin)
       * to work for content modules
       */
      return this.rows ? this.rows.find((row) => this.rowId === row.id) : false;
    },
    _isLocked() {
      return (
        (this._isMosaic && !this._isSuperAdminUser) ||
        (this.moduleData && this.moduleData.locked) ||
        this._hasLockedModule
      );
    },
    _actionButtons() {
      /* This first conditional part will be temporary, until the mosaic grid
       * is open to all users. All content modules are now passing this as a
       * prop to the module-controls component allowing centralized logic.
       */

      if (this._isLocked) {
        return ["edit"];
      }
      return ["edit", "delete"];
    },
    _modalName() {
      return (
        this.moduleName.charAt(0).toUpperCase() +
        this.moduleName.slice(1) +
        "Modal"
      );
    },
  },
  methods: {
    $openModal() {
      /**
       * Open modal for editing
       */
      this.$store.dispatch("Global/setModal", {
        type: this._modalName,
        data: {
          modId: this.modId,
        },
      });
    },
    $deleteContentModule() {
      /**
       * Delete building block data from page content
       */

      let getModulesArr = this.rows.filter((row) => {
        return row.id === this.rowId;
      })[0].cols[this.colIndex].modules;

      let getModuleIndex = getModulesArr.findIndex((element) => {
        return element.id === this.modId;
      });
      getModulesArr.splice(getModuleIndex, 1);

      this.$formUpdated("menu_content", true);
    },
    $deleteContentModuleConfirm() {
      this.$dialog
        .confirm(`Are you sure you want to delete ${this.moduleLabel}?`)
        .then(() => {
          this.$deleteContentModule();
          this.$formUpdated("menu_content", true);
        })
        .catch(() => {
          return false;
        });
    },
  },
  created() {
    // open edit mode by default, if "onEdit" exists
    if (!this._preventDrop && this._isAutoEditBlock) {
      this.moduleData.dropped = false;
      this.onEdit();
    }

    if (this._preventDrop) {
      /*
       * If _preventDrop is true, it needs to be unset to allow future modules
       * to open the edit modal on creation, if needed.
       * Set in @core/src/js/components/admin/menuitems/components/content-item__section__row__column.vue
       */
      this.$store.commit("Global/SET_PROPERTY", {
        collection: "preventModuleDrop",
        data: false,
      });
    }
  },
};
