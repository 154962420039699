export default {
  methods: {
    /**
      * Allows keyboard usage of tabs per accessibility standards: https://www.w3.org/TR/wai-aria-practices/examples/tabs/tabs-2/tabs.html
      * Sets focus on the newly selected tab.
      * Returns the ID string value of the newly selected tab, or false on failure
      * @param {Event} evt - the triggering event, a keydown in this case
      * @param {String} tabId - the ID of the selected tab
      * @param {String} parentId - the ID of the parent container (tablist)
    **/
    $keyboardTabNav (evt, tabId, parentId) {
      const keyCode = (evt) ? evt.keyCode : null

      if (keyCode && tabId) {
        // Navigate tabs using enter, space, left or right arrow, home, or end keys
        if (keyCode === 13 || keyCode === 32 || keyCode === 35 || keyCode === 36 || keyCode === 37 || keyCode === 39) {
          evt.stopPropagation()
          evt.preventDefault()
          let target = tabId

          if (keyCode === 13 || keyCode === 32) {
            // enter or space bar
            // do nothing, target is already set to tabId by default
          } else {
            // left arrow || right arrow || home key || end key
            const section = (parentId) ? document.getElementById(parentId) : null
            const parent = (section) || document
            const tabs = parent.querySelectorAll('[role="tab"]')

            if (tabs.length > 1) {
              // if only one tab exists, it's always selected
              let index = 0

              if (keyCode === 36) {
                // "home" key, get first tab
                // do nothing, index is already zero

              } else if (keyCode === 35) {
                // "end" key, get last tab
                index = tabs.length - 1
              } else {
                for (let i = 0; i < tabs.length; i++) {
                  if (tabs[i].id === tabId) {
                    if (keyCode === 37) {
                      // left arrow = get previous tab, or last tab if at the beginning
                      index = (i === 0) ? (tabs.length - 1) : (i - 1)
                    }
                    if (keyCode === 39) {
                      // right arrow = get next tab, or first tab if at the end
                      index = (i === tabs.length - 1) ? 0 : (i + 1)
                    }

                    break
                  }
                }
              } // end if/else if/else

              target = tabs[index].id
            } // end if tabs.length
          } // end keyCode equals...

          if (target) {
            document.getElementById(target).focus()
            return target
          }
        }
      }
      return false
    }
  }
}
