var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("focus-trap", { attrs: { active: _vm.displayModal } }, [
    _c("section", { staticClass: "modal", attrs: { tabindex: "-1" } }, [
      _c("div", { staticClass: "modal__body asset-tracking-modal" }, [
        _c("div", { staticClass: "modal__controls" }, [
          _c(
            "button",
            {
              staticClass: "close",
              attrs: {
                type: "button",
                title: _vm.$t("site_actions_close_label"),
              },
              on: { click: _vm.onClose, keydown: _vm.onClose },
            },
            [_c("icon", { attrs: { id: "ic_close" } })],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "modal__contents" }, [
          _c("header", [_c("h4", [_vm._v("Asset Tracking")])]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "body-wrapper scroller" },
            [
              _c("h2", [_vm._v("Where will this asset be used?")]),
              _vm._v(" "),
              _c("h4", [
                _vm._v(
                  "Please select one of the following to provide where this asset will be used and provide a short description."
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { attrs: { id: "asset-uses" } },
                [
                  _c("p", [_vm._v("Select one")]),
                  _vm._v(" "),
                  _c("CheckboxArrayInput", {
                    ref: "Uses",
                    attrs: {
                      name: "uses",
                      model: _vm.uses,
                      list: _vm.list,
                      required: "true",
                      validate: "true",
                    },
                    on: {
                      "update:model": function ($event) {
                        _vm.uses = $event
                      },
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("TextareaInput", {
                ref: "AdditionalDetails",
                attrs: {
                  model: _vm.details,
                  required: "true",
                  validate: "true",
                  label: "Additional Details",
                  placeholder:
                    "Please provide some additional detail on how this asset will be used...",
                },
                on: {
                  "update:model": function ($event) {
                    _vm.details = $event
                  },
                },
              }),
              _vm._v(" "),
              _c("div", [
                _c(
                  "button",
                  {
                    staticClass: "primary",
                    attrs: {
                      type: "button",
                      role: "button",
                      title: _vm.$t("site_actions_submit_label"),
                      disabled: !_vm.formIsReady,
                    },
                    on: { click: _vm.saveChanges },
                  },
                  [_vm._v("\n              Submit\n            ")]
                ),
              ]),
            ],
            1
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }