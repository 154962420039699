var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "module-controls" }, [
    _vm._isAdminArea && !_vm.hideTabs
      ? _c("div", { class: ["element-tab", { "no-drag": _vm.dragDisabled }] }, [
          _c("div", { staticClass: "controls" }, [
            _c(
              "span",
              { class: ["handle-module", { "no-drag": _vm.dragDisabled }] },
              [
                !_vm.dragDisabled
                  ? _c("Icons", { attrs: { name: "drag" } })
                  : _vm._e(),
                _vm._v("\n        " + _vm._s(_vm.placeholder) + "\n      "),
              ],
              1
            ),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm._isAdminArea && (_vm._userCanUpdate || _vm._userCanDelete)
      ? _c("div", { staticClass: "actions" }, [
          _vm.canEdit && _vm._userCanUpdate
            ? _c(
                "button",
                {
                  staticClass: "action-btn edit",
                  attrs: {
                    type: "button",
                    title: _vm.$t("site_actions_edit_label"),
                  },
                  on: { click: _vm.onEdit },
                },
                [_c("Icons", { attrs: { name: "edit" } })],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.canDelete && _vm._userCanDelete && !_vm._hasLockedModule
            ? _c(
                "button",
                {
                  staticClass: "action-btn delete",
                  attrs: {
                    type: "button",
                    title: _vm.$t("site_actions_delete_label"),
                  },
                  on: { click: _vm.onDelete },
                },
                [_c("Icons", { attrs: { name: "delete" } })],
                1
              )
            : _vm._e(),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }