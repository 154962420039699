module.exports = {
  "Cache-Control": "max-age=0, no-cache, no-store, must-revalidate",
  "Pragma": "no-cache",
  "Expires": "Thu, 01 Jan 1970 05:00:00 GMT",
  "X-XSS-Protection":  "1; mode=block",
  "X-Frame-Options": "SAMEORIGIN",
  "Referrer-Policy": "strict-origin-when-cross-origin",
  "X-Content-Type-Options": "nosniff",
  "Strict-Transport-Security": "'max-age=31536000; includeSubDomains;' env=HTTPS",
  "Content-Security-Policy": "default-src 'self' https:; font-src 'self' data: typekit.net; img-src 'self' https: blob: data:; object-src 'self'; script-src 'self' https: googleapis.com googletagmanager.com google-analytics.com adobedtm.com; style-src 'self' https: typekit.net; frame-src 'none'; frame-ancestors 'self'"
}
