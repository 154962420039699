import actions from '@core/store/actions/actions'
import mutations from '@core/store/mutations/mutations'

export default {
  namespaced: true,
  state: {
    cells: [],
    filterParameters: {
      cell: null
    },
    searchParameters: null,
    isLoading: false,
    newElementCreated: false
  },
  mutations,
  actions
}
