const InitStore = async function (options) {
  window.AppStore = require('@core/store').default
  window.AppStore.state.Global.cms = options.env && options.env === 'cms'

  const authToken = window.CookieManager.getCookie('jwt')

  window.AppStore.commit('Client/SET_PROPERTY', {
    collection: 'theme',
    data: require('@client/theme')
  })

  window.AppStore.commit('Client/SET_PROPERTY', {
    collection: 'config',
    data: require('@client/config').default
  })

  if (authToken && 'false' !== authToken) {
    await window.AppStore.dispatch('Users/getProperty', {
      collection: 'currentUser',
      url: 'account/me'
    })
  }

  return Promise.all([
    window.AppStore.dispatch('Locales/getProperty', {
      collection: 'locales',
      url: 'locales'
    }),

    window.AppStore.dispatch('Entities/getProperty', {
      collection: 'entities',
      url: 'entities'
    }),

    window.AppStore.dispatch('Config/getProperty', {
      collection: 'config',
      url: 'config'
    }),

    window.AppStore.dispatch('Settings/getProperty', {
      collection: 'settings',
      url: 'settings'
    }),

    window.AppStore.dispatch('Translations/getProperty', {
      collection: 'translations',
      url: 'translations'
    })
  ])
}

export { InitStore }
