var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        role: "presentation",
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 16 12",
        "enable-background": "new 0 0 16 12",
      },
    },
    [
      _c("path", {
        attrs: { d: "M8 9l8-6v9h-16v-9l8 6zm-8-9v1l8 6 8-6v-1h-16z" },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }