import actions from '../actions/actions'
import mutations from '../mutations/mutations'

export default {
  namespaced: true,
  state: {
    states: [],
    countries: [],
    filterParameters: null,
    searchParameters: null
  },
  mutations,
  actions
}
