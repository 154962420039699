export default {
  path: 'tracking',
  component: () => import(
    /* webpackChunkName: "admin-settings-tracking-index" */
    '@cms/components/settings/tracking/index.vue'),
  children: [
    {
      path: '',
      name: 'admin.tracking.index',
      component: () => import(
        /* webpackChunkName: "admin-settings-tracking-tracking" */
        '@cms/components/settings/tracking/tracking.vue')
    }
  ]
}
