var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        role: "presentation",
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 14 14",
        "enable-background": "new 0 0 14 14",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M0 6h2v2h-2v-2zm0-4h2v-2h-2v2zm0 3h2v-2h-2v2zm0 9h2v-2h-2v2zm0-3h2v-2h-2v2zm3 3h11v-2h-11v2zm0-14v2h11v-2h-11zm0 5h11v-2h-11v2zm0 6h11v-2h-11v2zm0-3h11v-2h-11v2z",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }