
import { ClientFooter } from '@core/components/index.js'
import StickyFooter from '@core/components/app-footer/partials/StickyFooter.vue'

export default {
  name: 'AppFooter',
  components: {
    ClientFooter,
    StickyFooter
  },
  computed: {
    settings () {
      return this.$store.state.Settings.settings || []
    },
    company_name () {
      let client_display_name =
        _.findWhere(this.settings, {
          key: 'client-display-name'
        }) || false

      if (!client_display_name) {
        client_display_name =
          _.findWhere(this.settings, {
            key: 'site.company.long'
          }) || false
      }

      return client_display_name.value || '{ Company }'
    },
    showNav () {
      return !(this.userToolbarPosition === 'nav' || !this.currentUser)
    },
    userToolbarPosition () {
      return this.settings.find(setting => setting.key === 'user-toolbar-position')?.value ?? 'footer'
    }
  }
}
