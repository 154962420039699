export default [
  {
    path: '/showcase/:showcaseId',
    component: () => import('../core/index.vue'),
    beforeEnter: (to, from, next) => {
      const authToken = window.CookieManager.getCookie('jwt')
      const hasToken = authToken && 'false' !== authToken

      if (!hasToken) {
        next({ name: 'unauthorized' })
      }

      next()
    },
    children: [
      {
        path: '',
        name: 'showcase.index',
        component: () => import('../core/showcase.vue')
      },
      {
        path: 'submission/:submissionId',
        name: 'showcase.submission',
        component: () => import('../core/components/client-showcase-submission.vue')
      },
      {
        path: 'submit',
        name: 'showcase.submit',
        component: () => import('../core/showcase__submission-form.vue')
      },
      {
        path: 'submit-success',
        name: 'showcase.submit-success',
        component: () => import('../core/components/client-showcase-submission-success.vue')
      }
    ]
  }
]
