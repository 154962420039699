
/**
 * @displayName Utility Block
 */
export default {
  name: 'UtilityBlock',
  props: {
    blank: {
      type: Boolean,
      required: false
    },
    height: {
      type: String,
      default: '350'
    },
    text: {
      type: String,
      required: false
    },
    title: {
      type: String,
      required: false
    },
    hideText: {
      type: Boolean,
      required: false
    },
    state: {
      type: String,
      required: false,
      validator: value =>
        ['loading', 'error', 'complete', 'content'].indexOf(value) !== -1
    }
  }
}
