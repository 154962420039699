var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        version: "1.0",
        role: "presentation",
        xmlns: "http://www.w3.org/2000/svg",
        width: "300.000000pt",
        height: "156.000000pt",
        viewBox: "0 0 300.000000 156.000000",
        preserveAspectRatio: "xMidYMid meet",
      },
    },
    [
      _c(
        "g",
        {
          attrs: {
            transform:
              "translate(0.000000,156.000000) scale(0.100000,-0.100000)",
            fill: "#0098d7",
            stroke: "none",
          },
        },
        [
          _c("path", {
            attrs: {
              d: "M1783 1428 c-35 -102 -45 -147 -53 -248 -27 -353 96 -697 335 -935\n          70 -71 222 -185 245 -185 23 0 174 114 246 185 162 159 272 374 321 623 21\n          110 21 318 0 422 -16 81 -53 186 -67 195 -5 3 -35 -5 -67 -18 -263 -109 -550\n          -116 -812 -21 -53 20 -103 38 -111 40 -12 4 -21 -11 -37 -58z",
            },
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              d: "M510 1285 l0 -195 -200 0 -200 0 0 -310 0 -310 200 0 200 0 0 -200 0\n          -200 315 0 315 0 2 198 3 197 198 3 197 2 0 310 0 310 -200 0 -200 0 0 195 0\n          195 -315 0 -315 0 0 -195z",
            },
          }),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }