import actions from '../actions/actions'
import mutations from '../mutations/mutations'

export default {
  namespaced: true,
  state: {
    config: {},
    isLoading: false,
    newElementCreated: false,
    searchParameters: null
  },
  mutations,
  actions,
  getters: {
    themeStrings: state => {
      return state.config?.theme?.themeStrings?.en ?? {}
    }
  }
}
