import actions from "../actions/actions";
import mutations from "../mutations/mutations";

export default {
  namespaced: true,
  state: {
    requests: [],
  },
  mutations,
  actions,
};
