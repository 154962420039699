export default [
  {
    path: 'content/preview/:id',
    name: 'content.preview',
    component: () => import(
      /* webpackChunkName: "main-content-preview" */
      '@theme/layouts/Preview.vue'
    )
  },
  {
    path: 'content/:guid',
    name: 'content.page',
    component: () => import(
      /* webpackChunkName: "main-content-index" */
      '@theme/layouts/MainContent.vue'
    )
  }
]
