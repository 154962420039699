export default {
  name: "AssetConversion",
  data() {
    return {
      fileTypes: [
        "ai",
        "eps",
        "gif",
        "jpeg",
        "jpg",
        "pdf",
        "png",
        "psd",
        "svg",
        "tif",
        "tiff",
      ],
      convertableFileTypes: ["jpg", "jpeg", "png", "tif", "tiff"],
      resizableFileTypes: ["jpeg", "jpg", "png", "tif", "tiff"],
      conversionFileTypes: {
        ai: null,
        eps: {
          eps: "EPS",
          jpg: "JPG",
          png: "PNG",
          tiff: "TIFF",
        },
        gif: {
          gif: "GIF",
          png: "PNG",
        },
        jpg: {
          jpg: "JPG",
          png: "PNG",
          tiff: "TIFF",
        },
        pdf: null,
        png: {
          jpg: "JPG",
          png: "PNG",
        },
        psd: null,
        svg: {
          jpg: "JPG",
          png: "PNG",
          svg: "SVG",
        },
        tiff: {
          jpg: "JPG",
          png: "PNG",
          tiff: "TIFF",
        },
        tif: {
          jpg: "JPG",
          png: "PNG",
          tiff: "TIFF",
        },
      },
      // Mime types: http://help.dottoro.com/lapuadlp.php
      // List of vector eps/svg mime-type substrings:
      vectorTypes: ["/svg", "/eps", "/x-eps", "/postscript"],
    };
  },
  computed: {
    themeStyles() {
      return this.theme ? this.theme.themeStyles : "";
    },
    assetConversionEnabled() {
      if (this.themeStyles && this.themeStyles.asset_conversion) {
        return this.themeStyles.asset_conversion === "1";
      }

      return false;
    },
  },
  methods: {
    getFileType(fileName) {
      return fileName.split(".").pop();
    },
    isImage(fileName) {
      let fileType = this.getFileType(fileName);

      return this.fileTypes.includes(fileType);
    },
    canConvert(fileName) {
      let fileType = this.getFileType(fileName);

      return (
        this.assetConversionEnabled &&
        this.convertableFileTypes.includes(fileType)
      );
    },
    canResize(file) {
      let fileType = this.getFileType(file.file_name);

      return (
        this.assetConversionEnabled &&
        this.resizableFileTypes.includes(fileType) &&
        (this.hasDimensions(file) || this.isValidVectorType(file.mime_type))
      );
    },
    hasDimensions(file) {
      return file.width && file.height;
    },
    getConversionFileTypes(fileName) {
      let fileType = this.getFileType(fileName);

      return this.conversionFileTypes[fileType];
    },
    isValidVectorType(mime_type) {
      return (
        typeof mime_type === "string" &&
        this.vectorTypes.some((type) => mime_type.includes(type))
      );
    },
  },
};
