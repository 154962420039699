var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "vc-editable-input" }, [
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.val,
          expression: "val",
        },
      ],
      ref: "input",
      staticClass: "vc-input__input",
      attrs: { "aria-labelledby": _vm.labelId },
      domProps: { value: _vm.val },
      on: {
        keydown: _vm.handleKeyDown,
        input: [
          function ($event) {
            if ($event.target.composing) return
            _vm.val = $event.target.value
          },
          _vm.update,
        ],
      },
    }),
    _vm._v(" "),
    _c(
      "span",
      {
        staticClass: "vc-input__label",
        attrs: { for: _vm.label, id: _vm.labelId },
      },
      [_vm._v(_vm._s(_vm.labelSpanText))]
    ),
    _vm._v(" "),
    _c("span", { staticClass: "vc-input__desc" }, [_vm._v(_vm._s(_vm.desc))]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }