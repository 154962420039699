import actions from '@core/store/actions/actions'
import mutations from '@core/store/mutations/mutations'

export default {
  namespaced: true,
  state: {
    files: [],
    helpdesk_file_url: null,
    isLoading: false,
    newElementCreated: false,
    filterParameters: null,
    searchParameters: null
  },
  mutations,
  actions
}
