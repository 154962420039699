/**
 * icon types = 'default', 'rounded', 'outline', 'sharp'
 *
 */
module.exports = {
  theme: 'clockwork',
  config: {
    show_utility_bar: false
  },
  icons: {
    type: 'default',
    custom: {
      ic_collections: {
        viewbox: '0 0 24 24',
        content: '<path d="M15,7H20.5L15,1.5V7M8,0H16L22,6V18A2,2 0 0,1 20,20H8C6.89,20 6,19.1 6,18V2A2,2 0 0,1 8,0M4,4V22H20V24H4A2,2 0 0,1 2,22V4H4Z"></path>'
      },
      ic_share: {
        viewbox: '0 0 24 24',
        content: '<path d="m18 16.08c-0.76 0-1.44 0.3-1.96 0.77l-7.13-4.15c0.05-0.23 0.09-0.46 0.09-0.7s-0.04-0.47-0.09-0.7l7.05-4.11c0.54 0.5 1.25 0.81 2.04 0.81 1.66 0 3-1.34 3-3s-1.34-3-3-3-3 1.34-3 3c0 0.24 0.04 0.47 0.09 0.7l-7.05 4.11c-0.54-0.5-1.25-0.81-2.04-0.81-1.66 0-3 1.34-3 3s1.34 3 3 3c0.79 0 1.5-0.31 2.04-0.81l7.12 4.15c-0.05 0.21-0.08 0.43-0.08 0.66 0 1.61 1.31 2.91 2.92 2.91s2.92-1.3 2.92-2.91-1.31-2.92-2.92-2.92m0-12.08c0.55 0 1 0.45 1 1s-0.45 1-1 1-1-0.45-1-1 0.45-1 1-1m-12 9c-0.55 0-1-0.45-1-1s0.45-1 1-1 1 0.45 1 1-0.45 1-1 1m12 7c-0.55 0-1-0.45-1-1s0.45-1 1-1 1 0.45 1 1-0.45 1-1 1z" />'
      },
      ic_tune: {
        viewbox: '0 0 24 24',
        content: '<path d="M6,13H18V11H6M3,6V8H21V6M10,18H14V16H10V18Z"></path>'
      },
    }
  },
  colors: {
    brand_1: {
      name: 'Red',
      value: '#ff0000'
    },
    brand_2: {
      name: 'Blue',
      value: '#0000ff'
    },
    mono_1: {
      name: 'White',
      value: '#fff'
    },
    mono_2: {
      name: 'Black',
      value: '#000'
    },
    error: {
      name: 'Error',
      value: '#cc0000'
    },
    info: {
      name: 'Info',
      value: '#0099cc'
    },
    success: {
      name: 'Success',
      value: '#007e33'
    },
    warning: {
      name: 'Warning',
      value: '#ff8800'
    }
  },
  fonts: {
    font_1: {
      name: 'Serif',
      value: 'Georgia, "Times New Roman", Times, serif'
    },
    font_2: {
      name: 'Sans-serif',
      value: 'Arial, Helvetica, sans-serif'
    },
    font_3: {
      name: 'Modern',
      value: 'Tahoma, Verdana, Geneva, sans-serif'
    },
    font_4: {
      name: 'Monospace',
      value: '"Courier New", Courier, monospace'
    }
  }
}
