var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.showNotification && _vm._notificationsArePublished
    ? _c(
        "div",
        { staticClass: "notification", class: { custom: _vm.hasCustomColor } },
        [
          _c("p", [_vm._v(_vm._s(_vm.notification.message))]),
          _vm._v(" "),
          _c("div", { staticClass: "actions" }, [
            _vm.notification.link_url
              ? _c(
                  "a",
                  {
                    staticClass: "view-link",
                    attrs: {
                      href: _vm.notification.link_url,
                      target: "_blank",
                    },
                  },
                  [_vm._v("View")]
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "close-btn",
                attrs: { type: "button" },
                on: { click: _vm.closeNotification },
              },
              [_c("Icon", { attrs: { id: "ic_close" } })],
              1
            ),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }