var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [
      _vm.items
        ? [
            _c("div", { staticClass: "display-sorting" }, [
              _c("div", [
                _c("fieldset", { staticClass: "display-mode" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.view,
                        expression: "view",
                      },
                    ],
                    staticClass: "list-input",
                    attrs: {
                      id: `display-view-list_${_vm.id}`,
                      name: `display-view_${_vm.id}`,
                      type: "radio",
                      value: "list",
                    },
                    domProps: { checked: _vm._q(_vm.view, "list") },
                    on: {
                      change: function ($event) {
                        _vm.view = "list"
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "label",
                    {
                      staticClass: "list-label",
                      attrs: {
                        for: `display-view-list_${_vm.id}`,
                        title: _vm.$t(
                          "filters_actions_group-display-list_label"
                        ),
                      },
                    },
                    [
                      _c("icon", { attrs: { id: "ic_view_stream" } }),
                      _vm._v(" "),
                      _c("span", { staticClass: "offscreen" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t("filters_actions_group-display-list_label")
                          )
                        ),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.view,
                        expression: "view",
                      },
                    ],
                    staticClass: "grid-input",
                    attrs: {
                      id: `display-view-grid_${_vm.id}`,
                      name: `display-view_${_vm.id}`,
                      type: "radio",
                      value: "grid",
                    },
                    domProps: { checked: _vm._q(_vm.view, "grid") },
                    on: {
                      change: function ($event) {
                        _vm.view = "grid"
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "label",
                    {
                      staticClass: "grid-label",
                      attrs: {
                        for: `display-view-grid_${_vm.id}`,
                        title: _vm.$t(
                          "filters_actions_group-display-grid_label"
                        ),
                      },
                    },
                    [
                      _c("icon", { attrs: { id: "ic_view_module" } }),
                      _vm._v(" "),
                      _c("span", { staticClass: "offscreen" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t("filters_actions_group-display-grid_label")
                          )
                        ),
                      ]),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("fieldset", { staticClass: "display-order" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.orderBy,
                        expression: "orderBy",
                      },
                    ],
                    attrs: {
                      id: `display-order-newest_${_vm.id}`,
                      name: `display-order_${_vm.id}`,
                      type: "radio",
                      value: "newest",
                      tabindex: "-1",
                    },
                    domProps: { checked: _vm._q(_vm.orderBy, "newest") },
                    on: {
                      click: _vm.changeOrder,
                      change: function ($event) {
                        _vm.orderBy = "newest"
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "label",
                    {
                      attrs: {
                        for: `display-order-newest_${_vm.id}`,
                        tabindex: "0",
                      },
                      on: {
                        keydown: function ($event) {
                          return _vm.$triggerClickEvent(
                            $event,
                            `display-order-newest_${_vm.id}`
                          )
                        },
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.$t("filters_actions_sort-order_date_label"))
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.orderBy,
                        expression: "orderBy",
                      },
                    ],
                    attrs: {
                      id: `display-order-relevant_${_vm.id}`,
                      name: `display-order_${_vm.id}`,
                      type: "radio",
                      value: "relevant",
                      tabindex: "-1",
                    },
                    domProps: { checked: _vm._q(_vm.orderBy, "relevant") },
                    on: {
                      click: _vm.changeOrder,
                      change: function ($event) {
                        _vm.orderBy = "relevant"
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "label",
                    {
                      attrs: {
                        for: `display-order-relevant_${_vm.id}`,
                        tabindex: "0",
                      },
                      on: {
                        keydown: function ($event) {
                          return _vm.$triggerClickEvent(
                            $event,
                            `display-order-relevant__${_vm.id}`
                          )
                        },
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.$t("filters_actions_sort-order_relevant_label")
                        )
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.orderBy,
                        expression: "orderBy",
                      },
                    ],
                    attrs: {
                      id: `display-order-alpha_${_vm.id}`,
                      name: `display-order_${_vm.id}`,
                      type: "radio",
                      value: "alpha",
                      tabindex: "-1",
                    },
                    domProps: { checked: _vm._q(_vm.orderBy, "alpha") },
                    on: {
                      click: _vm.changeOrder,
                      change: function ($event) {
                        _vm.orderBy = "alpha"
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "label",
                    {
                      attrs: {
                        for: `display-order-alpha_${_vm.id}`,
                        tabindex: "0",
                      },
                      on: {
                        keydown: function ($event) {
                          return _vm.$triggerClickEvent(
                            $event,
                            `display-order-alpha_${_vm.id}`
                          )
                        },
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.$t("filters_actions_sort-order_alpha_label"))
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.orderBy,
                        expression: "orderBy",
                      },
                    ],
                    attrs: {
                      id: `display-order-popular_${_vm.id}`,
                      name: `display-order_${_vm.id}`,
                      type: "radio",
                      value: "popular",
                      tabindex: "-1",
                    },
                    domProps: { checked: _vm._q(_vm.orderBy, "popular") },
                    on: {
                      click: _vm.changeOrder,
                      change: function ($event) {
                        _vm.orderBy = "popular"
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "label",
                    {
                      attrs: {
                        for: `display-order-popular_${_vm.id}`,
                        tabindex: "0",
                      },
                      on: {
                        keydown: function ($event) {
                          return _vm.$triggerClickEvent(
                            $event,
                            `display-order-popular_${_vm.id}`
                          )
                        },
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.$t("filters_actions_sort-order_popular_label")
                        )
                      ),
                    ]
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", [
                _c("div", { staticClass: "display-results" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.$tc("site_messaging_results", parseInt(_vm.items))
                      ) +
                      "\n        "
                  ),
                ]),
                _vm._v(" "),
                _c("fieldset", { staticClass: "display-per-page" }, [
                  _c("div", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.perPage,
                          expression: "perPage",
                        },
                      ],
                      attrs: {
                        id: `per_page_12_${_vm.id}`,
                        name: `per_page_${_vm.id}`,
                        type: "radio",
                        value: "12",
                        tabindex: "-1",
                      },
                      domProps: { checked: _vm._q(_vm.perPage, "12") },
                      on: {
                        change: function ($event) {
                          _vm.perPage = "12"
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "label",
                      {
                        attrs: { for: `per_page_12_${_vm.id}`, tabindex: "0" },
                        on: {
                          keydown: function ($event) {
                            return _vm.$triggerClickEvent(
                              $event,
                              `per_page_12_${_vm.id}`
                            )
                          },
                          click: function ($event) {
                            return _vm.$resetUrl()
                          },
                        },
                      },
                      [_vm._v("12")]
                    ),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.perPage,
                          expression: "perPage",
                        },
                      ],
                      attrs: {
                        id: `per_page_24_${_vm.id}`,
                        name: `per_page_${_vm.id}`,
                        type: "radio",
                        value: "24",
                        tabindex: "-1",
                      },
                      domProps: { checked: _vm._q(_vm.perPage, "24") },
                      on: {
                        change: function ($event) {
                          _vm.perPage = "24"
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "label",
                      {
                        attrs: { for: `per_page_24_${_vm.id}`, tabindex: "0" },
                        on: {
                          keydown: function ($event) {
                            return _vm.$triggerClickEvent(
                              $event,
                              `per_page_24_${_vm.id}`
                            )
                          },
                          click: function ($event) {
                            return _vm.$resetUrl()
                          },
                        },
                      },
                      [_vm._v("24")]
                    ),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.perPage,
                          expression: "perPage",
                        },
                      ],
                      attrs: {
                        id: `per_page_48_${_vm.id}`,
                        name: `per_page_${_vm.id}`,
                        type: "radio",
                        value: "48",
                        tabindex: "-1",
                      },
                      domProps: { checked: _vm._q(_vm.perPage, "48") },
                      on: {
                        change: function ($event) {
                          _vm.perPage = "48"
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "label",
                      {
                        attrs: { for: `per_page_48_${_vm.id}`, tabindex: "0" },
                        on: {
                          keydown: function ($event) {
                            return _vm.$triggerClickEvent(
                              $event,
                              `per_page_48_${_vm.id}`
                            )
                          },
                          click: function ($event) {
                            return _vm.$resetUrl()
                          },
                        },
                      },
                      [_vm._v("48")]
                    ),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.perPage,
                          expression: "perPage",
                        },
                      ],
                      attrs: {
                        id: `per_page_96_${_vm.id}`,
                        name: `per_page_${_vm.id}`,
                        type: "radio",
                        value: "96",
                        tabindex: "-1",
                      },
                      domProps: { checked: _vm._q(_vm.perPage, "96") },
                      on: {
                        change: function ($event) {
                          _vm.perPage = "96"
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "label",
                      {
                        attrs: { for: `per_page_96_${_vm.id}`, tabindex: "0" },
                        on: {
                          keydown: function ($event) {
                            return _vm.$triggerClickEvent(
                              $event,
                              `per_page_96_${_vm.id}`
                            )
                          },
                          click: function ($event) {
                            return _vm.$resetUrl()
                          },
                        },
                      },
                      [_vm._v("96")]
                    ),
                  ]),
                ]),
              ]),
            ]),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }