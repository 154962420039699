var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        role: "presentation",
        xmlns: "http://www.w3.org/2000/svg",
        width: "18",
        height: "16",
        viewBox: "0 0 18 16",
      },
    },
    [
      _c("g", { attrs: { fill: "none", "fill-rule": "evenodd" } }, [
        _c("path", { attrs: { d: "M-1-2h20v20H-1z" } }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            fill: "#C00",
            d: "M9 15.792l-1.208-1.1C3.5 10.8.667 8.233.667 5.083.667 2.517 2.683.5 5.25.5A4.99 4.99 0 0 1 9 2.242 4.99 4.99 0 0 1 12.75.5c2.567 0 4.583 2.017 4.583 4.583 0 3.15-2.833 5.717-7.125 9.617L9 15.792z",
          },
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }