var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: ["vc-chrome", _vm.disableAlpha ? "vc-chrome__disable-alpha" : ""],
      attrs: { role: "application", "aria-label": "Chrome color picker" },
    },
    [
      _c(
        "div",
        { staticClass: "vc-chrome-saturation-wrap" },
        [
          _c("saturation", {
            on: { change: _vm.childChange },
            model: {
              value: _vm.colors,
              callback: function ($$v) {
                _vm.colors = $$v
              },
              expression: "colors",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "vc-chrome-body" }, [
        _c("div", { staticClass: "vc-chrome-controls" }, [
          _c(
            "div",
            { staticClass: "vc-chrome-color-wrap" },
            [
              _c("div", {
                staticClass: "vc-chrome-active-color",
                style: { background: _vm.activeColor },
                attrs: { "aria-label": `current color is ${_vm.colors.hex}` },
              }),
              _vm._v(" "),
              !_vm.disableAlpha ? _c("checkboard") : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "vc-chrome-sliders" }, [
            _c(
              "div",
              { staticClass: "vc-chrome-hue-wrap" },
              [
                _c("hue", {
                  on: { change: _vm.childChange },
                  model: {
                    value: _vm.colors,
                    callback: function ($$v) {
                      _vm.colors = $$v
                    },
                    expression: "colors",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            !_vm.disableAlpha
              ? _c(
                  "div",
                  { staticClass: "vc-chrome-alpha-wrap" },
                  [
                    _c("alpha", {
                      on: { change: _vm.childChange },
                      model: {
                        value: _vm.colors,
                        callback: function ($$v) {
                          _vm.colors = $$v
                        },
                        expression: "colors",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ]),
        ]),
        _vm._v(" "),
        !_vm.disableFields
          ? _c("div", { staticClass: "vc-chrome-fields-wrap" }, [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.fieldsIndex === 0,
                      expression: "fieldsIndex === 0",
                    },
                  ],
                  staticClass: "vc-chrome-fields",
                },
                [
                  _c(
                    "div",
                    { staticClass: "vc-chrome-field" },
                    [
                      !_vm.hasAlpha
                        ? _c("ed-in", {
                            attrs: { label: "hex", value: _vm.colors.hex },
                            on: { change: _vm.inputChange },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.hasAlpha
                        ? _c("ed-in", {
                            attrs: { label: "hex", value: _vm.colors.hex8 },
                            on: { change: _vm.inputChange },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.fieldsIndex === 1,
                      expression: "fieldsIndex === 1",
                    },
                  ],
                  staticClass: "vc-chrome-fields",
                },
                [
                  _c(
                    "div",
                    { staticClass: "vc-chrome-field" },
                    [
                      _c("ed-in", {
                        attrs: { label: "r", value: _vm.colors.rgba.r },
                        on: { change: _vm.inputChange },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "vc-chrome-field" },
                    [
                      _c("ed-in", {
                        attrs: { label: "g", value: _vm.colors.rgba.g },
                        on: { change: _vm.inputChange },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "vc-chrome-field" },
                    [
                      _c("ed-in", {
                        attrs: { label: "b", value: _vm.colors.rgba.b },
                        on: { change: _vm.inputChange },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  !_vm.disableAlpha
                    ? _c(
                        "div",
                        { staticClass: "vc-chrome-field" },
                        [
                          _c("ed-in", {
                            attrs: {
                              label: "a",
                              value: _vm.colors.a,
                              "arrow-offset": 0.01,
                              max: 1,
                            },
                            on: { change: _vm.inputChange },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.fieldsIndex === 2,
                      expression: "fieldsIndex === 2",
                    },
                  ],
                  staticClass: "vc-chrome-fields",
                },
                [
                  _c(
                    "div",
                    { staticClass: "vc-chrome-field" },
                    [
                      _c("ed-in", {
                        attrs: { label: "h", value: _vm.hsl.h },
                        on: { change: _vm.inputChange },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "vc-chrome-field" },
                    [
                      _c("ed-in", {
                        attrs: { label: "s", value: _vm.hsl.s },
                        on: { change: _vm.inputChange },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "vc-chrome-field" },
                    [
                      _c("ed-in", {
                        attrs: { label: "l", value: _vm.hsl.l },
                        on: { change: _vm.inputChange },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  !_vm.disableAlpha
                    ? _c(
                        "div",
                        { staticClass: "vc-chrome-field" },
                        [
                          _c("ed-in", {
                            attrs: {
                              label: "a",
                              value: _vm.colors.a,
                              "arrow-offset": 0.01,
                              max: 1,
                            },
                            on: { change: _vm.inputChange },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "vc-chrome-toggle-btn",
                  attrs: {
                    role: "button",
                    "aria-label": "Change another color definition",
                  },
                  on: { click: _vm.toggleViews },
                },
                [
                  _c("div", { staticClass: "vc-chrome-toggle-icon" }, [
                    _c(
                      "svg",
                      {
                        staticStyle: { width: "24px", height: "24px" },
                        attrs: { viewBox: "0 0 24 24" },
                        on: {
                          mouseover: _vm.showHighlight,
                          mouseenter: _vm.showHighlight,
                          mouseout: _vm.hideHighlight,
                        },
                      },
                      [
                        _c("path", {
                          attrs: {
                            fill: "#333",
                            d: "M12,18.17L8.83,15L7.42,16.41L12,21L16.59,16.41L15.17,15M12,5.83L15.17,9L16.58,7.59L12,3L7.41,7.59L8.83,9L12,5.83Z",
                          },
                        }),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.highlight,
                        expression: "highlight",
                      },
                    ],
                    staticClass: "vc-chrome-toggle-icon-highlight",
                  }),
                ]
              ),
            ])
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }