var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "module library-module" },
    [
      _c("module-controls", {
        attrs: {
          placeholder: _vm.placeholder,
          "action-buttons": _vm.actionButtons,
        },
        on: {
          "on-edit": _vm.$openModal,
          "on-remove": _vm.$deleteContentModuleConfirm,
        },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "module_content" }, [
        _vm._isAdminArea && _vm.isEmpty
          ? _c("div", { staticClass: "empty" }, [
              _vm._v("\n      This module is empty. Start editing!\n    "),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm._isAdminArea && _vm.libraries.length === 0
          ? _c("div", { staticClass: "container display-mode" }, [
              _c(
                "p",
                { staticClass: "display-text" },
                [
                  _vm._v(
                    "\n        There are currently no libraries, please click\n        "
                  ),
                  _c(
                    "router-link",
                    { attrs: { to: "/admin/library-management/new" } },
                    [_vm._v("\n          here\n        ")]
                  ),
                  _vm._v("\n        to create one.\n      "),
                ],
                1
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.libraryId
          ? _c(
              "div",
              [
                !_vm.moduleData.hideTitle
                  ? _c("h2", [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.setClientLibraryName) +
                          "\n      "
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("filter-controls", {
                  attrs: {
                    "library-id": _vm.libraryId,
                    "module-data": _vm.moduleData,
                    filters: _vm.library.filters,
                    collection: "assets",
                    namespace: "Assets",
                    route: "assets",
                    local: "true",
                  },
                  on: { loadAssets: _vm.loadAssets },
                }),
                _vm._v(" "),
                _c("sort-controls", {
                  attrs: {
                    collection: "assets",
                    namespace: "Assets",
                    route: "assets",
                    local: "true",
                    "sort-order": _vm.sortOrder,
                  },
                  on: {
                    "update:sortOrder": function ($event) {
                      _vm.sortOrder = $event
                    },
                    "update:sort-order": function ($event) {
                      _vm.sortOrder = $event
                    },
                  },
                }),
                _vm._v(" "),
                _vm.assetsLoading
                  ? [_c("LoadingBlock", { attrs: { "hide-text": "" } })]
                  : _vm._e(),
                _vm._v(" "),
                _vm.assets.length && !_vm.assetsLoading
                  ? [
                      _c(
                        "div",
                        [
                          _c("assets-table", {
                            attrs: {
                              "library-id": _vm.libraryId,
                              namespace: "Assets",
                              collection: "assets",
                              local: "true",
                            },
                          }),
                          _vm._v(" "),
                          !_vm._isAdminArea
                            ? _c("pagination", {
                                staticClass: "library-pagination",
                                attrs: {
                                  collection: "assets",
                                  namespace: "Assets",
                                  route: "assets",
                                  local: "true",
                                },
                                on: {
                                  onNext: _vm.onNext,
                                  onPrevious: _vm.onPrevious,
                                  onGoto: _vm.onGoto,
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _vm.assets.length === 0 && !_vm.assetsLoading
                  ? [_vm._m(0)]
                  : _vm._e(),
              ],
              2
            )
          : _vm._e(),
        _vm._v(" "),
        !_vm._isAdminArea && !_vm.libraryId
          ? _c("p", [_vm._v("\n      Please choose a library\n    ")])
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "no-results-block" }, [
      _c("span", [
        _vm._v(
          "\n            No results for applied filters. Please try again.\n          "
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }