var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        role: "presentation",
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 15 15",
        "enable-background": "new 0 0 15 15",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M8.2 3.5v4c0 .2-.1.4-.2.5l-2.5 2.5c-.3.3-.7.3-1 0s-.3-.7 0-1l2.3-2.3v-3.7c0-.4.3-.7.7-.7s.7.3.7.7zm6.8 4c0 4.1-3.4 7.5-7.5 7.5s-7.5-3.4-7.5-7.5 3.4-7.5 7.5-7.5 7.5 3.4 7.5 7.5zm-1.4 0c0-3.4-2.7-6.1-6.1-6.1s-6.1 2.7-6.1 6.1 2.7 6.1 6.1 6.1 6.1-2.7 6.1-6.1z",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }