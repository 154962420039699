var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "app", tabindex: "-1" } },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm._isAdminUser,
              expression: "_isAdminUser",
            },
          ],
          attrs: {
            id: "page-admin-nav",
            role: "region",
            "aria-label": _vm.$t("layout_regions_admin-nav_label"),
          },
        },
        [_vm._isAdminUser ? _c("AdminNavigation") : _vm._e()],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "page-region",
          attrs: { id: "page-utility-nav", role: "region" },
        },
        [
          _vm._entitiesIsPublished || _vm._localesIsPublished
            ? _c(
                "div",
                [_c("EntitySwitcher"), _vm._v(" "), _c("LocaleChanger")],
                1
              )
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "page-region",
          attrs: {
            id: "page-primary-nav",
            role: "region",
            "aria-label": _vm.$t("layout_regions_primary-nav_label"),
          },
        },
        [_c("ClientNav")],
        1
      ),
      _vm._v(" "),
      _vm._notificationsArePublished
        ? _c(
            "div",
            {
              staticClass: "page-notification",
              attrs: { id: "page-notification", role: "region" },
            },
            [_c("Notification")],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "header",
        {
          staticClass: "page-region",
          attrs: {
            id: "page-primary-banner",
            "aria-label": _vm.$t("layout_regions_page-banner_label"),
          },
        },
        [_vm.showPageBanner ? _c("InteriorPageBanner") : _vm._e()],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "page-region",
          attrs: {
            id: "page-main-area",
            role: "region",
            "aria-label": _vm.$t("layout_regions_page-main_label"),
          },
        },
        [
          _c(
            "aside",
            {
              attrs: {
                id: "page-primary-sidebar",
                "aria-label": _vm.$t("layout_regions_page-aside_label"),
              },
            },
            [_c("MainSidebar")],
            1
          ),
          _vm._v(" "),
          _c("router-view"),
        ],
        1
      ),
      _vm._v(" "),
      _c("AppFooter"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }