var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      staticStyle: { "enable-background": "new 0 0 48 48" },
      attrs: {
        version: "1.1",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
        role: "presentation",
        x: "0px",
        y: "0px",
        viewBox: "0 0 48 48",
        "xml:space": "preserve",
      },
    },
    [
      _c("g", [
        _c("path", {
          attrs: {
            d: "M32.1,30.1L30.3,30l0,0c2.3-2.5,3.6-5.7,3.6-9.3c0-7.6-6.2-13.8-13.8-13.8C12.5,6.9,6.3,13,6.3,20.7\n  c0,7.6,6.2,13.8,13.8,13.8c3.4,0,6.6-1.3,9-3.3l0,0l0.2,1.8l9.6,9.6l2.9-2.9L32.1,30.1z M20.1,30.4c-5.4,0-9.7-4.4-9.7-9.7\n  s4.4-9.7,9.7-9.7s9.7,4.4,9.7,9.7S25.5,30.4,20.1,30.4z",
          },
        }),
        _vm._v(" "),
        _c("polygon", {
          attrs: {
            points:
              "21,15.7 18.9,15.7 18.9,19.8 14.8,19.8 14.8,21.9 18.9,21.9 18.9,26 21,26 21,21.9 25.1,21.9 25.1,19.8 21,19.8\n  ",
          },
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }