import actions from '@core/store/actions/actions'
import mutations from '@core/store/mutations/mutations'

export default {
  namespaced: true,
  state: {
    pagination: {
      per_page: 12,
      page: 1,
      pages: 1,
      items: 0
    },
    helpdesk_requests: [],
    request: null,
    parentRequest: null,
    filterParameters: {},
    searchParameters: null,
    orderParameters: {
      type: 'updated_at',
      dir: 'DESC'
    },
    config: {},
    isLoading: false,
    newElementCreated: false,
    view: 'list'
  },
  actions,
  mutations
}
