var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.id !== false && _vm.menuItems.length && _vm.navItems.length
    ? _c("nav", [
        _vm.navItems.length > 1
          ? _c(
              "ul",
              [
                _vm._l(_vm.navItems, function (n) {
                  return [
                    _c(
                      "li",
                      { key: n.id },
                      [
                        _c(
                          "router-link",
                          {
                            attrs: {
                              to: _vm.$generateRoute({
                                name: "content.page",
                                params: {
                                  guid: n.action,
                                },
                              }),
                            },
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.strip(n.label)) +
                                "\n        "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]
                }),
              ],
              2
            )
          : _vm._e(),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }