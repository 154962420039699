import actions from '@core/store/actions/actions'
import mutations from '@core/store/mutations/mutations'

export default {
  namespaced: true,
  state: {
    forms: [],
    currentForm: null,
    formUpdated: false,
    newElementCreated: false
  },
  mutations,
  actions,
  getters: {
    getFormById: (state) => (id = 0) => {
      const ID = parseInt(id, 10)
        return (!isNaN(ID)) ? state.forms.find(item => ID === parseInt(item.id, 10)) : false
    },
    getFormByName: (state) => (name = '') => {
      return (name) ? state.forms.find(item => name === item.name) : false
    }
  }
}
