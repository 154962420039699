import actions from '../actions/actions'
import mutations from '../mutations/mutations'

export default {
  namespaced: true,
  state: {
    archives: [],
    isLoading: false,
    filterParameters: null,
    searchParameters: null
  },
  mutations,
  actions
}
