var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.displayModal
    ? _c("focus-trap", { attrs: { active: _vm.displayModal } }, [
        _c("section", { staticClass: "modal", attrs: { tabindex: "-1" } }, [
          _c("div", { staticClass: "modal__body link-copy" }, [
            _c("div", { staticClass: "modal__controls" }, [
              _c(
                "button",
                {
                  staticClass: "close",
                  attrs: {
                    type: "button",
                    title: _vm.$t("site_actions_close_label"),
                  },
                  on: { click: _vm.onClose, keydown: _vm.onClose },
                },
                [_c("Icon", { attrs: { id: "ic_close" } })],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "modal__contents" }, [
              _c("header", [_c("h2", [_vm._v("Get Sharable Link")])]),
              _vm._v(" "),
              _c("div", { staticClass: "link-details" }, [
                _c("div", { staticClass: "form-input text-input" }, [
                  _c("input", {
                    attrs: { type: "text", readonly: "" },
                    domProps: { value: _vm.link },
                  }),
                ]),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    directives: [
                      {
                        name: "clipboard",
                        rawName: "v-clipboard:copy",
                        value: _vm.link,
                        expression: "link",
                        arg: "copy",
                      },
                      {
                        name: "clipboard",
                        rawName: "v-clipboard:success",
                        value: _vm.onCopy,
                        expression: "onCopy",
                        arg: "success",
                      },
                      {
                        name: "clipboard",
                        rawName: "v-clipboard:error",
                        value: _vm.onFail,
                        expression: "onFail",
                        arg: "error",
                      },
                    ],
                    staticClass: "secondary",
                    attrs: { title: _vm.$t("site_actions_copy-link_label") },
                  },
                  [
                    _c("icon", { attrs: { id: "ic_content_copy" } }),
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$t("site_actions_copy-link_label")) +
                        "\n          "
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "message" }, [
                _c("small", [
                  _vm._v(_vm._s(_vm.$t("site_messaging_download-link_access"))),
                ]),
              ]),
            ]),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }