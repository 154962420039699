import actions from '../actions/actions'
import mutations from '../mutations/mutations'
import Modules from './subscriber-emails__modules'
import Rows from './subscriber-emails__rows'

export default {
  namespaced: true,
  state: {
    subscriberEmails: [],
    rows: Rows,
    modules: Modules,
    isLoading: false,
    newElementCreated: false,
    filterParameters: null,
    searchParameters: null,
    pagination: {
      per_page: 12,
      page: 1,
      pages: 1,
      items: 0
    },
    view: 'list'
  },
  mutations,
  actions
}
