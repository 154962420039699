var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("focus-trap", { attrs: { active: _vm.displayModal } }, [
    _c("section", { staticClass: "modal", attrs: { tabindex: "-1" } }, [
      _c("div", { staticClass: "modal__body lightbox" }, [
        _c("div", { staticClass: "modal__controls" }, [
          _c(
            "button",
            {
              staticClass: "close",
              attrs: {
                title: _vm.$t("site_actions_close_label"),
                type: "button",
              },
              on: { click: _vm.onClose, keydown: _vm.onClose },
            },
            [_c("icon", { attrs: { id: "ic_close" } })],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "modal__contents" }, [
          _c(
            "header",
            [_c("icon", { attrs: { id: _vm._collections_add } })],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "scroller" }, [
            !_vm.showCreate
              ? _c(
                  "div",
                  { staticClass: "modal-body" },
                  [
                    _c(
                      "h3",
                      {
                        class: {
                          "no-message": _vm.userToolbarPosition === "footer",
                        },
                      },
                      [
                        _vm._v(
                          "\n              " +
                            _vm._s(
                              _vm.$t("features_collections_actions_add_label")
                            ) +
                            "\n            "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.userToolbarPosition === "nav"
                      ? _c("p", { staticClass: "message" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm.$t(
                                  "features_collections_messaging_alt-location_text"
                                )
                              ) +
                              "\n            "
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("SelectInput", {
                      attrs: {
                        label: _vm.$t(
                          "features_collections_actions_select_label"
                        ),
                        list: _vm.lightboxes,
                        model: _vm.selectedLightbox,
                        "custom-classes": "select-lightbox",
                      },
                      on: {
                        "update:model": function ($event) {
                          _vm.selectedLightbox = $event
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "lightbox-selection-buttons" }, [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.showCollectionError,
                              expression: "showCollectionError",
                            },
                          ],
                          staticClass: "collection-error-text",
                          attrs: { id: "error" },
                        },
                        [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.collectionErrorMessage) +
                              "\n              "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _vm.lightboxes.length
                        ? _c(
                            "button",
                            {
                              ref: "lightboxAdd",
                              staticClass: "cta-button primary",
                              attrs: {
                                title: _vm.$t(
                                  "features_collections_actions_add_label"
                                ),
                                type: "button",
                              },
                              on: { click: _vm.onAdd, keydown: _vm.onAdd },
                            },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(
                                    _vm.$t(
                                      "features_collections_actions_add_label"
                                    )
                                  ) +
                                  "\n              "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          ref: "lightboxCreate",
                          staticClass: "cta-button primary",
                          attrs: {
                            title: _vm.$t(
                              "features_collections_actions_create_label"
                            ),
                            type: "button",
                          },
                          on: {
                            click: _vm.onShowCreate,
                            keydown: _vm.onShowCreate,
                          },
                        },
                        [
                          _vm._v(
                            "\n                " +
                              _vm._s(
                                _vm.$t(
                                  "features_collections_actions_create_label"
                                )
                              ) +
                              "\n              "
                          ),
                        ]
                      ),
                    ]),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.showCreate
              ? _c(
                  "div",
                  { staticClass: "create-lightbox-container" },
                  [
                    _c("h4", [
                      _vm._v(
                        "\n              " +
                          _vm._s(
                            _vm.$t(
                              "features_collections_messaging_add-asset-to-new_text"
                            )
                          ) +
                          "\n            "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("lightbox-form", {
                      attrs: { "is-modal": true },
                      on: {
                        lightboxCreated: _vm.onAddNewLightbox,
                        onCancel: _vm.onCancel,
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }