var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "form-input radio-input" }, [
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.input,
          expression: "input",
        },
      ],
      attrs: { id: _vm.id, name: _vm.name, role: "radio", type: "radio" },
      domProps: { value: _vm.value, checked: _vm._q(_vm.input, _vm.value) },
      on: {
        change: [
          function ($event) {
            _vm.input = _vm.value
          },
          _vm.emitVal,
        ],
      },
    }),
    _vm._v(" "),
    _c("label", {
      attrs: { for: _vm.id },
      domProps: { textContent: _vm._s(_vm.label) },
    }),
    _vm._v(" "),
    _vm.error
      ? _c("div", {
          staticClass: "error",
          domProps: { textContent: _vm._s(_vm.errorText) },
        })
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }