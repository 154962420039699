var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "18",
        height: "18",
        viewBox: "0 0 18 18",
        role: "presentation",
      },
    },
    [
      _c("path", {
        attrs: {
          fill: "#222428",
          "fill-rule": "nonzero",
          d: "M6.985 2.182a.758.758 0 1 1 0 1.515H2.939a.758.758 0 0 0-.757.758V15.06c0 .418.339.757.757.757h10.606c.419 0 .758-.339.758-.757v-4.046a.758.758 0 0 1 1.515 0v4.046a2.273 2.273 0 0 1-2.273 2.272H2.94a2.273 2.273 0 0 1-2.272-2.272V4.455a2.273 2.273 0 0 1 2.272-2.273zM14.08.889l3.03 3.03a.758.758 0 0 1 0 1.071l-7.575 7.576a.758.758 0 0 1-.536.222H5.97a.758.758 0 0 1-.758-.758V9c0-.2.08-.394.222-.536L13.01.89a.758.758 0 0 1 1.071 0zm-.536 1.607L6.727 9.314v1.959h1.96l6.817-6.818-1.959-1.96z",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }