import actions from '@core/store/actions/actions'
import mutations from '@core/store/mutations/mutations'

export default {
  namespaced: true,
  state: {
    showcase_submission_files: []
    /*
    filterParameters: null,
    searchParameters: null,
    config: {},
    isLoading: false,
    newElementCreated: false,
    view: "list"
    */
  },
  mutations,
  actions
}
