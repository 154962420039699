var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "router-link",
    {
      attrs: {
        id: "primary-logo",
        to: _vm.$generateRoute({ name: "home.index" }),
      },
    },
    [
      _vm.showAltHomepageLogo
        ? [
            _c("img", {
              attrs: {
                alt: `${_vm.siteName} Home Page`,
                src: _vm.altHomepageDesktopLogoSrc,
              },
            }),
          ]
        : _vm._globalImages.primaryLogo
        ? [
            _c("img", {
              attrs: {
                alt: `${_vm.siteName} Home Page`,
                src: _vm._globalImages.primaryLogo,
              },
            }),
          ]
        : [_c("h4", [_vm._v("Home")])],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }